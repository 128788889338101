import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActions, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import { getCardStyles } from "./util";
import Book from "../../assets/images/book.svg";
import MultiArrow from "../../assets/images/multi_arrow.svg";
import Heart from "../../assets/images/heart.svg";
import RedHeart from "../../assets/images/red_heart.svg";
import { ComboSelect } from "../ComboSelect";
import useMediaQuery from '@mui/material/useMediaQuery';
// import TextField from '@mui/material/TextField';

// const StyledTextInput = styled(TextField)(({ theme: { palette: { neutral, status } }}) => ({
//   width: "30%",
//   "& label": {
//     fontFamily: "Aeonik Regular, Arial",
//     "&.Mui-focused": {
//       color: neutral.black,
//     },
//     "&.Mui-error": {
//       color: status.error,
//     },
//   },

//   "& .MuiInput-underline": {
//     "&:after": {
//       borderBottomColor: neutral.gray2,
//     },
//   },
// });

const CustomCard = ({
  data,
  index,
  variant,
  cardId,
  cardImage,
  cardAgeGroup,
  cardSubTitle,
  cardTitle,
  cardDescription,
  cardSubDesc,
  buttonLabel,
  buttonLink,
  // inputFieldError,
  onButtonClick = () => { },
  onSelectQuantity = () => { },
  onSelectBlurHandler = () => { },
  onFavClick = () => { },
  onSelectKeyUpHandler = () => {},
  sx,
  CardBadge,
  hidePrice = false,
  // onInputBlurHandler,
  ...props
}) => {
  const cardBaseStyle = {
    borderRadius: "0px 0px 48px 0px",
    boxShadow: "0px 3px 6px #22222229",
    position: "relative",
  };
  const cardContentBaseStyle = {
    boxSizing: "border-box",
  };
  const cardActionsBaseStyle = {
    padding: "0px",
  };
  const [heartComponent, setHeartComponent] = useState(Heart);
  const [cartonsValue, setCartonsValue] = useState(0);
  const [zeroCartonSelectedError, setZeroCartonSelectedError] = useState(false)
  const [defaultDosesValue, setDefaultDosesValue] = useState(props?.quantitydefaultvalue || null);
  // const [defaultDosesValue, setDefaultDosesValue] = useState(0);
  const [loading, setLoading] = useState(false)

  const favClickHandler = () => {
    heartComponent === Heart
      ? setHeartComponent(RedHeart)
      : setHeartComponent(Heart);
    onFavClick();
  };
  const addToCart = async () => {
    if(cartonsValue <= 0) {
      setZeroCartonSelectedError(true)
      return
    }
    setZeroCartonSelectedError(false)
    const dropDownValue = 0;
    setDefaultDosesValue({ "label": dropDownValue.toString(), "value": dropDownValue })
    setCartonsValue(0)
    setDefaultDosesValue(0)
    setLoading(true)
    await onButtonClick(data, data?.dosesPerCarton)
    setLoading(false)
  }
  const {
    cardStyle,
    cardContentStyle,
    cardActionsStyle,
    cardImageStyle,
    subTitleStyle,
    titleStyle,
    descriptionStyle,
    subDescStyle,
    imageHeight,
    imageWidth,
    ButtonComponent,
  } = getCardStyles(variant, buttonLink, buttonLabel, addToCart, loading);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <Card sx={{ ...cardBaseStyle, ...cardStyle, ...sx }} {...props} product_name={cardTitle} product_id={cardId} product_detail={cardDescription+"|"+cardAgeGroup}>
      {(variant === "Wide" || variant === "Long" || variant === "Product") && (
        <CardMedia
          id={`cardImg-${index}`}
          component="img"
          height={imageHeight}
          width={imageWidth}
          image={cardImage?.src}
          alt="Card Image"
          sx={{ ...cardImageStyle }}
        />
      )}
      <CardContent sx={{ ...cardContentBaseStyle, ...cardContentStyle }}>
        {variant !== "Info" && (
          <Typography
            id={`cardSubTitle-${index}`}
            gutterBottom
            variant="subtitle1"
            component="div"
            sx={{ ...subTitleStyle }}
          >
            {cardSubTitle}
          </Typography>
        )}
        <Box display='flex' justifyContent='space-between'>
          <Typography
            id={`cardTitle-${index}`}
            gutterBottom
            variant="h5"
            component="div"
            sx={{ ...titleStyle }}
          >
            {cardTitle}
          </Typography>
          {variant === "Info" && (
            <Box component='img' 
              sx={{cursor:'pointer'}}
              src={heartComponent}
              alt="Favourite Button"
              height="30px"
              width="30px"
              onClick={favClickHandler}
            />
          )}
          {variant === "Product" && CardBadge}
        </Box>
        {variant === "Request" && cardImage?.src && (
          <Box component={'img'} mb={3}
            src={cardImage?.src}
            alt="Benefit Card Icon"
            height={80}
            width={80}
          />
        )}
        <Typography id={`cardDescription-${index}`} variant="body2" sx={{ ...descriptionStyle }}>
          {cardDescription}
        </Typography>
        {variant === "Long" && (
          <Box display='flex' justifyContent='flex-start' alignItems='center' paddingBottom='16px'>
            <img src={Book} alt="Reading Icon" height="24px" width="24px" />
            <Typography variant="subtitle2" sx={{ ...subDescStyle }}>
              {cardSubDesc}
            </Typography>
          </Box>
        )}

        {variant === "Product" && !hidePrice && (
          <Box mt={'12px'}>
            { props.pricedata?.defaultPrice && <Typography variant={'p2'} color='primary.main' sx={{textDecoration:'line-through'}} id={`strikeThroughPrice-${index}`}>{ props.pricedata?.defaultPrice }</Typography> }
            <Typography variant={'h5'} color='primary.main' lineHeight='1.2 !important' id={`price-${index}`}>{props.pricedata?.price}</Typography>
          </Box>
        )}

        {variant !== "Wide" && variant !== "Product" && (
          <CardActions sx={{ ...cardActionsBaseStyle, ...cardActionsStyle }}>
            {ButtonComponent}
          </CardActions>
        )}

        {variant === "Product" && (
          <div>
          <CardActions sx={{ ...cardActionsBaseStyle, ...cardActionsStyle }}>
          <ComboSelect
              variant={props && props?.comboselectvariant}
              paddingbottom='0'
              id={`comboSelect-${index}`}
              sx={{"@media screen and (max-width: 500px)": {
                  width:150,
                  height:48,
                }}}
              label={props && props?.quantitylabel}
              options={props && props?.quantitydata}
              onChangeHandler={(e, value) => {
                setCartonsValue(
                  parseInt(value.value) / parseInt(props?.dosespercarton)
                );
                onSelectQuantity(e, value);
              }}
              onBlurHandler={(event) => {
                if (event.target.value) {
                  setCartonsValue(Math.ceil(parseInt(event.target.value) / parseInt(props?.dosespercarton)))
                  let dropDownValue = (Math.ceil(parseInt(event.target.value) / parseInt(props?.dosespercarton))) * parseInt(props?.dosespercarton);
                  setDefaultDosesValue({ "label": dropDownValue.toString(), "value": dropDownValue })
                } else {
                  setCartonsValue(0)
                  let dropDownValue = 0;
                  setDefaultDosesValue({ "label": dropDownValue.toString(), "value": dropDownValue })
                }
                onSelectBlurHandler(event);
              }}
              
              onKeyUpHandler={(event) => {
                if(event.key === 'Enter') {
                if (event.target.value) {
                  setCartonsValue(Math.ceil(parseInt(event.target.value) / parseInt(props?.dosespercarton)))
                  let dropDownValue = (Math.ceil(parseInt(event.target.value) / parseInt(props?.dosespercarton))) * parseInt(props?.dosespercarton);
                  setDefaultDosesValue({ "label": dropDownValue.toString(), "value": dropDownValue })
                } else {
                  setCartonsValue(0)
                  let dropDownValue = 0;
                  setDefaultDosesValue({ "label": dropDownValue.toString(), "value": dropDownValue })
                }
                onSelectKeyUpHandler(event);
              }
              }}
              error={zeroCartonSelectedError.toString()}
              defaultValue={defaultDosesValue}
            />
              {/* <StyledTextInput
                name={`dosesField`}
                label={ "Doses" }
                variant="standard" 
                placeholder="[Qty]"
                focused
                error={inputFieldError}
                onBlur={(event) => {
                  if (event.target.value) {
                    setCartonsValue(Math.ceil(parseInt(event.target.value) / parseInt(props?.dosespercarton)))
                    let inputFieldValue = (Math.ceil(parseInt(event.target.value) / parseInt(props?.dosespercarton))) * parseInt(props?.dosespercarton);
                    setDefaultDosesValue(inputFieldValue)
                  } 
                  onInputBlurHandler(event);
                }}
                onChange={(event) => setDefaultDosesValue(event.target.value)}
                value={defaultDosesValue}
              /> */}

            <Typography
              id={`subtitle-${index}`}
              variant="p2"
              sx={{ paddingBottom : "20px", paddingTop : "20px", marginLeft : "20px", color : 'primary.main' , width :"68px"}}
            >
              {`${cartonsValue} cartons`}
            </Typography> 
            {desktop && <div>{ButtonComponent}</div>}
          </CardActions>
          {mobile && <div style={{textAlign:"center"}}>{ButtonComponent}</div>}
          </div>
        )}

        {variant === "Secondary" && (
          <Box component={'img'}
            transform='rotate(180deg)'
            position='absolute'
            right='-35px'
            bottom='-80px'
            src={MultiArrow}
            alt="Multi Colored Arrow"
            height="240px"
            width="240px"
          />
        )}
      </CardContent>
    </Card>
  );
};

CustomCard.propTypes = {
  variant: PropTypes.oneOf([
    "Wide",
    "Square",
    "Secondary",
    "Info",
    "Request",
    "Product",
  ]),
  onClick: PropTypes.func,
  cardSubTitle: PropTypes.string,
  cardTitle: PropTypes.string,
  cardDescription: PropTypes.string,
  cardSubDesc: PropTypes.string,
  buttonLabel: PropTypes.string,
  onFavClick: PropTypes.func,
  cardImage: PropTypes.object,
  buttonVisible: PropTypes.bool,
};

export { CustomCard as Card };
