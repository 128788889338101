export const CheckCircleIcon = ({ color = "#000000", width = "24", height = "24"}) => (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="check-circle">
                <path id="Icon" d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
);

export const WarningInfoIcon = ({ color = "#FDB022", width = "24px", height = "24px" }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon">
            <g id="WarningCircle">
                <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5ZM3 16C3 8.8203 8.8203 3 16 3C23.1797 3 29 8.8203 29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16Z" fill={color}/>
                <path id="Vector (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M16 9C16.5523 9 17 9.44772 17 10V17C17 17.5523 16.5523 18 16 18C15.4477 18 15 17.5523 15 17V10C15 9.44772 15.4477 9 16 9Z" fill={color}/>
                <path id="Vector" d="M16 23C16.8284 23 17.5 22.3284 17.5 21.5C17.5 20.6716 16.8284 20 16 20C15.1716 20 14.5 20.6716 14.5 21.5C14.5 22.3284 15.1716 23 16 23Z" fill={color}/>
            </g>
        </g>
    </svg>
);