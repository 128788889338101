import {gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector} from "@mui/x-data-grid";
import {Box, IconButton, Typography} from "@mui/material";
import {KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined} from "@mui/icons-material";
import React from "react";

function Actions(props) {
    const { count, page, rowsPerPage, onPageChange} = props;
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    return (
        <>
            {pageCount > 0 && <Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center', gap: 10 }}>
                <IconButton
                    disableRipple
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <KeyboardArrowLeftOutlined fontSize="large" sx={{color: 'secondary.graphical', opacity: page === 0 ? 0.5 : 1}}/>
                </IconButton>
                <Typography variant={'p1'}>{page + 1} of {pageCount}</Typography>
                <IconButton
                    disableRipple
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <KeyboardArrowRightOutlined fontSize="large" sx={{color:'secondary.graphical', opacity: page === pageCount - 1 ? 0.5 : 1}}/>
                </IconButton>
            </Box>}
        </>
    );
}

function DataGridPagination (props) {
    return <GridPagination labelRowsPerPage={null} rowsPerPageOptions={[]} labelDisplayedRows={() => ''} ActionsComponent={Actions} {...props} sx={{'.MuiTablePagination-toolbar' : { padding: '0px'}}}/>;
}

export default DataGridPagination;