"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCampaignAttributes = exports.CampaignAttribute = void 0;
exports.CampaignAttribute = {
    'Campaign Tactic Code': 'tc',
    'Double Click ID': 'dclid',
    'Facebook Click ID': 'fbclid',
    'Google Braid': 'gbraid',
    'Google Click ID': 'gclid',
    'Google Click Source': 'gclsrc',
    'Microsoft Click ID': 'msclkid',
    'TikTok Click ID': 'ttclid',
    'UTM Campaign': 'utm_campaign',
    'UTM Content': 'utm_content',
    'UTM Medium': 'utm_medium',
    'UTM Source': 'utm_source',
    'UTM Term': 'utm_term',
    'Web Braid': 'wbraid'
};
const getCampaignAttributes = (searchParams) => {
    const attributeEntries = Object.entries(exports.CampaignAttribute);
    const attributes = {};
    const searchParamsNext = new URLSearchParams();
    searchParams.forEach((searchParamValue, searchParamKey) => {
        const searchParamLowerCase = searchParamKey.toLowerCase();
        const entry = attributeEntries.find(([_, searchParam]) => searchParam === searchParamLowerCase);
        if (entry) {
            attributes[entry[0]] = searchParamValue;
        }
        else {
            searchParamsNext.set(searchParamKey, searchParamValue);
        }
    });
    return {
        attributes,
        search: searchParamsNext.toString()
    };
};
exports.getCampaignAttributes = getCampaignAttributes;
