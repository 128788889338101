import React from "react"
import { styled } from '@mui/material/styles';
import { useSearchBox, useHits } from "react-instantsearch-hooks-web";
import { Accordion } from "../Accordion";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";

const accordionCustomStyle = {
    borderRadius: "0px !important",
    boxShadow: "none",
    borderBottom: `1px solid ${'neutral.gray3'}`,
    borderTop: "none",
    margin: "0px !important",
};

const HeaderContainer = styled(Box)(({ theme: { palette: { neutral } }}) => `
    padding: 12px 0px;
    border-bottom: 1px solid ${neutral.gray3};
`);

const FaqAlgoliaHits = () => {
    const { query } = useSearchBox()
    const { hits, results } = useHits();

    const options = {
        renderNode: {
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                return <Link to={node?.data?.target?.fields?.pageReference?.fields?.slug}>
                    {children}
                </Link>;
            },
            [BLOCKS.PARAGRAPH]: (_, children) => <p style={{ whiteSpace: "pre-wrap" }}>{children}</p>,
            [BLOCKS.UL_LIST]: (_, children) => <ul style={{ listStyleType: "initial", marginLeft: "20px" }}>{children}</ul>,
        },
    };

    return (
        <Box p='22px' m='0 auto' minHeight='350px' maxWidth='1020px'>
            <HeaderContainer>
                <Typography variant={'h3'} color='primary.main'>Search results for "{query}"</Typography>
                <Typography variant={'p1'} color='primary.main'>{results?.nbHits} result(s)</Typography>
            </HeaderContainer>
            <Box m='0 auto'>
                {hits?.map((hit, index) => (
                    <Accordion
                        id={`accordion-hit-${index}`}
                        key={index}
                        heading={hit?.fields?.question["en-US"]}
                        description={documentToReactComponents(hit?.fields?.answer["en-US"], options)}
                        accordionCustomStyle={accordionCustomStyle}
                    />
                ))
                }
                {results?.nbHits === 0 && <Typography variant={'p1'} color='primary.main' textAlign='center' mt={2}>No results found</Typography>}
            </Box>
        </Box>
    )
}

export default FaqAlgoliaHits