import React from "react";
import { styled } from '@mui/material/styles';
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";


const DisclaimerText = styled('div')(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    "& div": {
        width: '95%',
        marginTop: '10px'
    }
}));

const DisclaimerContainer = styled(Box)(({theme: {palette: {primary}}}) => `
    max-width: 700px;
    width: 80%;
    color: ${primary.red};
    font-size: 12px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 16px;
`);

const options = {
    renderNode: {
        [BLOCKS.HEADING_3]: (node, children) => <Typography
            component={'h3'}
            variant={'disclaimerH3'}
        >
            {children}
        </Typography>,
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <div>
                <Typography
                    component={'p'}
                    variant={'disclaimerParagraph'}
                    color='neutral.gray1'
                >
                    {children}
                </Typography>
            </div>
    }
};

export const DisclaimerMessageFooter = ({disclaimerMessage}) => {
    return (
        <DisclaimerContainer id="disclaimerMessage_box">
            <DisclaimerText>
                {documentToReactComponents(disclaimerMessage, options)}
            </DisclaimerText>
        </DisclaimerContainer>
    );
};

