import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CustomCard from "../CustomCardComponent/CustomCard";
import { isExternalURL } from "../../utils/Helper";
import { useEffect, useState } from "react";
import { Button } from "../Button";
import { useModal } from '../../hooks/useModal'
import { useOktaAuth } from "@okta/okta-react";
import {styled} from "@mui/material/styles";

const ContentContainer = styled('div')(({ isReservations, theme: { breakpoints } }) => `
    display: flex;
    padding: 32px;
    flex-wrap: wrap;
    flex-direction: row;
    ${breakpoints.down('sm')} {
        flex-direction: column; 
        padding: 10px 32px; 
        margin-top: 0; 
    }
`);

const ContentLeftWrapper = styled('div') (({ theme: { breakpoints } }) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 20%;
    ${breakpoints.down('sm')} {
        flex-basis: 100%; 
        max-width: 100%; 
    }
`);

const ContentRightWrapper = styled('div') (({ theme: { breakpoints } }) =>`
    display: flex;
    padding: 17px;
    flex-direction: column;
    flex: 1 1 60%;
    margin-left: 48px;
`);

const ContentRightBodyWrapper = styled('div') (({ theme: { breakpoints } }) =>`
    display: flex;
    padding-top: 10px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
`);

const imageStyle = {
    objectFit: "contain",
    width: "240px",
    minHeight: "116px",
};

export const MResviaCard = ({ ...props }) => {
    const { homePage: homePageContent } = useSelector(
        (store) => store?.contentful
    );
    const locale = useSelector((state) => state.globalStates.locale);
    const imageUrl = homePageContent?.rsvBannerImage?.fields?.file?.url;
    const imageAlt = "mResvia Vaccine Logo";
    const informationUrl = process.env.MRESVIA_URL;
    const [dataExternalModal, setDataExternalModal] = useState(true);
    const remindMeExternal = localStorage.getItem("dontRemindMeExternal");
    const isNewOrderingEnabled = process.env.IS_NEW_ORDER_EXPERIENCE_ENABLED === 'true';
    const history = useHistory();
    const { authState } = useOktaAuth() || "";
    const isAuthenticated = authState?.isAuthenticated;
    const { handleOpenModal } = useModal();
    useEffect(() => {
        if (remindMeExternal === "true") {
            setDataExternalModal(false);
        }
    }, [remindMeExternal])

    const handleRedirect = (e, url, type) => {
        props?.setExternalLinkModalType(type);
        if(isExternalURL(url) && !localStorage.getItem("dontRemindMeExternal")){
            props?.setShowNavigationAlert(true);
            props?.setExternalLink(url);
            e.preventDefault();
        }else{
            props?.setShowNavigationAlert(false);
            props?.setExternalLink("");
        }
    }

    const handleLink = (url) => {
        if(locale !== 'en-US' && window.location.href.includes(locale)) {
            return window.location.origin + "/" + locale + "/" + url;
        }
        else{
            return "/" + locale + "/" + url;
        }
    }

    const navigateOrLogin = (path) => {
        if (isAuthenticated) {
          history.push(`/${locale}/${path}`);
        } else {
          handleOpenModal(`/${locale}/${path}`);
        }
      };

    const onOrderNowClick = () => {
        navigateOrLogin('order-form');
    }

    return (
        <CustomCard>
            <ContentContainer>
                <ContentLeftWrapper>
                    <CardMedia
                        component="img"
                        image={imageUrl}
                        alt={imageAlt}
                        sx={imageStyle}
                    />
                </ContentLeftWrapper>
                <ContentRightWrapper>
                    <Typography
                        gutterBottom component="div"
                        fontSize={{ xs: '16px', sm: '16px', md: '20px', lg: '24px' }}
                        fontFamily={'Aeonik Regular'}
                        fontWeight={500}
                        color={"#024667"}
                    >
                        {homePageContent?.rsvBannerHeader}
                    </Typography>
                    <ContentRightBodyWrapper>
                        {isNewOrderingEnabled ?
                            <>
                                <Button
                                  buttonType='mds-primary'
                                  sx={{textTransform: "none", padding: "16px 32px", width: "fit-content"}}
                                  onClick={onOrderNowClick}
                                >
                                    Order now
                                </Button>
                            </>
                          :
                          <Typography
                            color={"#383D47"}
                            fontSize={{ xs: '14px', sm: '14px', md: '14px', lg: '18px' }}
                            fontFamily={'Aeonik Regular'}
                            fontWeight={500}
                            lineHeight={'28px'}
                          >
                              {homePageContent?.rsvBannerText}
                          </Typography>
                        }
                        {/*TODO: Uncomment once get approval to show Learn More link*/}
                        {/*<Box>*/}
                        {/*    <Link to={{ pathname: handleLink(informationUrl) }}*/}
                        {/*          target="_blank" rel="noreferrer"*/}
                        {/*          data-external-modal={dataExternalModal}*/}
                        {/*          onClick={(e)=> handleRedirect(e, informationUrl, "extrenalLink")}>*/}
                        {/*        <Typography*/}
                        {/*            fontFamily="Aeonik Regular"*/}
                        {/*            fontSize="18px"*/}
                        {/*            color="#0379B2"*/}
                        {/*            fontWeight={500}*/}
                        {/*            lineHeight={'28px'}>*/}
                        {/*            Learn More*/}
                        {/*        </Typography>*/}
                        {/*    </Link>*/}
                        {/*</Box>*/}
                    </ContentRightBodyWrapper>
                </ContentRightWrapper>
            </ContentContainer>
        </CustomCard>
    );
};
