import React from "react"
import { styled } from '@mui/material/styles';
import { useSearchBox } from "react-instantsearch-hooks-web";
import { FaqComponent } from "../FaqComponent";
import FaqAlgoliaHits from "../FaqAlgoliaHits";

const FaqContainer = styled('section')`
  padding: 22px;
  max-width: 1020px;
  width: 100%;
  margin: 0 auto;
`;

const FaqList = ({ faqCategoriesData = [] }) => {
    const { query } = useSearchBox()
    return (
        <>
            {query?.length > 0 ?
                <FaqAlgoliaHits />
                :
                <FaqContainer>
                    {faqCategoriesData?.map((dataValue, index) => (
                        <FaqComponent
                            key={index}
                            index={index}
                            title={dataValue?.fields?.name}
                            data={dataValue?.fields?.faqEntries}
                            iconUrl={dataValue?.fields?.icon?.fields?.file?.url}
                        />
                    ))}

                </FaqContainer>
            }
        </>
    )
}

export default FaqList