import { configureStore, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit";
import contentfulReducer from "./features/contentful/contentfulSlice";
import globalMessageReducer from "./features/globalMessages/globalMessageSlice";
import commerceReducer from "./features/commerce/commerceSlice";
import globalStatesReducer from "./features/globalStates/globalStatesSlice";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore,  FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER, } from 'redux-persist';

const rootReducer = combineReducers({
        contentful: contentfulReducer,
        globalMessages: globalMessageReducer,
        commerce: commerceReducer,
        globalStates: globalStatesReducer
})

const persistConfig = {
    key: 'root',
    storage,
}

const persistedGlobalStatesReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedGlobalStatesReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false
    }),
});

/**
 * Special setup to allow for testing with preloaded state
 * 
 * @param {*} preloadedState state to initialize the store with
 * @returns a preloaded store instance
 */
export const setupStore = (preloadedState) => {
    return configureStore({
        reducer: persistedGlobalStatesReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
                serializableCheck: false
        }),
        preloadedState
    })
}

export const persistor = persistStore(store)