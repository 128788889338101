import React, { useEffect } from "react";
import { NonAuthHeader } from "./NonAuthHeader";
import { NewHomePageHeader } from "./NewHomepageHeader";
import { styled } from '@mui/material/styles';
import { AuthHeader } from "./AuthHeader";
import { useOktaAuth } from '@okta/okta-react';
import { useSelector, useDispatch } from "react-redux";
import { loadHeaderStaticContent } from "../../features/contentful/contentfulThunkApi";

const NonPrintableWrapper = styled('div')`
  @media print {
    display: none;
  }
`;

const Header = ({ authenticated = false, baseStoreData, setProcessing }) => {
  const { authState } = useOktaAuth() || '';
  const { headerContent: headerData } = useSelector(store => store?.contentful);
  const dispatch = useDispatch();
  const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);  
  const locale = useSelector((state) => state.globalStates.locale);
  const isNewHomepageEnabled = process.env.IS_NEW_HOMEPAGE_ENABLED === 'true' && locale === 'en-US';

  useEffect(() => {
    dispatch(loadHeaderStaticContent({ title: `Header - ${localeISOCode}`}));
  }, [localeISOCode, dispatch]);

  return (
    <>
      <NonPrintableWrapper>
      {
        (localStorage.getItem("currentUser") && localStorage.getItem("currentUser") !== "undefined" && authState?.isAuthenticated) || authenticated ? (
          <AuthHeader
            headerData={headerData}
            baseStoreData={baseStoreData}
          />
        ) : (
          isNewHomepageEnabled ? (
            <NewHomePageHeader headerData={headerData} setProcessing={setProcessing}/>
          ) : (
            <NonAuthHeader headerData={headerData} baseStoreData={baseStoreData} />
          )
        )
      }
    </NonPrintableWrapper>
    </>
  );
};

export default Header;