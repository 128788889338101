import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { Button } from "..";
import { useHistory } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { loadResetSuccessStaticContent } from "../../features/contentful/contentfulThunkApi";
import { Box, Typography } from "@mui/material";

const ButtonContainer = styled('div')(({ theme: { breakpoints } }) => `
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  margin: 50px auto 40% auto;
  ${[breakpoints.down('sm')]} {
    width: 60%;
    margin-bottom: 230px;
  }
`);

export const ResetSuccess = () => {
  const { resetSuccessContent } = useSelector((store) => store?.contentful);
  const { staticContent = [] } = resetSuccessContent;
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector((state) => state.globalStates.locale);
  useEffect(() => {
    window.scrollTo(0,0);
    if (Object.keys(resetSuccessContent).length === 0) {
      dispatch(loadResetSuccessStaticContent({ title: "Reset Password Success Confirmation Component" }));
    }
  },[]);

  const signInHandler = () => {
    history.push(`/${locale}`);
  }

  return (
    <div>
      <Box component={'img'} margin={{xs:'50px auto 20px auto',sm:'20px auto'}} display='flex' id="resetSucessImg" data-testid="resetSucessImg" src={staticContent?.icon?.fields?.file?.url} width="90px" height="90px"></Box>
      <Typography variant={'h1'} textAlign='center' color='primary.main' id="headline" data-testid="headline">{staticContent?.headline}</Typography>
      <Typography variant={'p1'} textAlign='center' mt='20px' id="resetSuccessText" data-testid="resetSuccessText">{documentToReactComponents(staticContent?.message)}</Typography>
      <ButtonContainer>
        <Button
          data-testid="resetSuccessButton"
          id="resetSuccessButton"
          buttonType="primary"
          onClick={signInHandler}
        >{staticContent?.signInButtonLabel}
        </Button>
      </ButtonContainer>
    </div>
  )
};