import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Box } from "@mui/material";

const CustomSelect = styled(Autocomplete)(({ error = false, variant = "round", paddingbottom = 10, theme: { palette: { primary, neutral, secondary, status }, breakpoints } }) => ({
  "&": {
    width: 130,
    borderRadius: variant === "round" ? 20 : 5,
    paddingBottom: paddingbottom,
    "&.cart": {
      paddingBottom: 0,
    },
    "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
      color: `${error === "true" ? status.error : primary.main}`
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: `${error === "true" ? status.error : neutral.gray2}`,
      boxShadow: '0px 3px 6px #22222229'
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${neutral.gray2}`,
        borderColor: `${error === "true" ? status.error: neutral.gray2}`,
        borderRadius: variant === "round" ? 20 : 5,
        height: 44
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${error === "true" ? status.error : neutral.gray2}`,
      borderRadius: variant === "round" ? 20 : 5,
      height: 44,
      top: 3,
    },
    "& .MuiAutocomplete-popupIndicator:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiAutocomplete-popupIndicator": {
      color: `${error === "true" ? status.error : secondary.graphical}`,
    },
}}));

const CustomLabel = styled('label')(
  ({ error, theme: { palette: { primary, status } } }) => `
  font-size: 10px;
  font-family: Aeonik Bold;
  color: ${error === "true" ? status.error : primary.main};
  `
);

const CustomSelectField = ({ options, label, onChangeHandler, onBlurHandler, onKeyUpHandler, error = false, defaultValue, sx, className, inputProps={ className: "" }, variant = "round", paddingbottom}) => {
  const [value, setValue] = useState(defaultValue !== "false" ? defaultValue : options[0]);
  const [inputValue, setInputValue] = React.useState("");
  
  useEffect(() => {
    defaultValue !== "false" ? setValue(defaultValue) : setValue(options[0])
  }, [defaultValue])

  return (
    <Box pb={"5px"} display={"flex"} flexDirection={'column'}>
      <CustomLabel error={error}>{label}</CustomLabel>
      <CustomSelect
        className={className}
        variant={variant}
        paddingbottom={paddingbottom}
        error={error}
        value={value}
        inputValue={inputValue}
        options={options}
        disableClearable={true}
        popupIcon={<KeyboardArrowDownOutlinedIcon/>}
        forcePopupIcon={true}
        onChange={(event, newValue) => {
          onChangeHandler(event, newValue);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          newInputValue = newInputValue.replace(/\D/g,'');
          if(newInputValue > parseInt(process.env.DOSES_MAX_LIMIT)) {
            newInputValue = process.env.DOSES_MAX_LIMIT;
          }
          setInputValue(newInputValue);
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => {
            let updatedProps = params?.inputProps;
            updatedProps.className = `${updatedProps?.className} ${inputProps?.className}`;
            params.inputProps = updatedProps;
            return <TextField {...params} />
          }
        }
        freeSolo
        onBlur={(event) => onBlurHandler(event)}
        onKeyUp={(event) => onKeyUpHandler(event)}
        sx={sx}
      />
    </Box>
  );
};

export {CustomSelectField as ComboSelect};