import axiosInstance from "../../sevices/axiosInstance";

// TODO ECOM-958
export const activateUserApi = async (payload) => {
  try {
    const res = await axiosInstance({
      url: `identityservice/v1/identity/activation`,
      method: "POST",
      data: JSON.stringify(payload),
      headers: {}
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const resendActivationEmailApi = async (payload) => {
  try {
    const res = await axiosInstance({
      url: `identityservice/v1/identity/verification/resend`,
      method: "POST",
      data: JSON.stringify(payload),
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const resetPasswordApi = async (payload) => {
  try {
    const res = await axiosInstance({
      url: `identityservice/v1/identity/password/reset`,
      method: "POST",
      data: JSON.stringify(payload),
    });
    return res;
  } catch (error) {
    throw error;
  }
};