import checkBadgeIcon from "../images/checkBadgeIcon.svg";
import warningIcon from "../images/warningTriangleIcon.svg";

export const EmailVerificationSucceedConstants = {
    headlineIcon: checkBadgeIcon,
    headline: "Thanks for verifying your account",
    message: "You can now continue the registration process.",
    callToActionButtonLabel: "Go to Moderna Direct"
}

export const EmailVerificationTokenExpiredConstants = {
    headlineIcon: warningIcon,
    headline: "Your verification has expired",
    message: "Sorry, for security reasons verification must be completed within 24 hours. " +
             "We’re happy to resend the verification.",
    callToActionButtonLabel: "Resend verification email"
}

export const EmailVerificationPageConstants = {
    heading: 'Please check your email',
    mainText:'In order to make sure we can reach you, please verify your email address.',
    mainTextWithEmail:'We sent a message to earlthomlinson@gmail.com with instructions.',
    noEmailHeading:'Didn’t get an email?',
    noEmailText:'Please check your spam folder. If you still can’t find it, ',
    NoEmailLink:'click here to resend the email. ',
    bannerHeader: 'Email successfully re-sent',
    bannerMessage: "If you still don't see it, please check your spam folder. If your organization uses a virus/malware checking system, the  email may take several minutes to arrive.",
}