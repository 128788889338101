import { createAsyncThunk } from "@reduxjs/toolkit";
import commerceAuthAxiosInstance from "../sevices/commerceAuthAxiosInstance";

export const cartValidationApi = createAsyncThunk(
    "cart/validation",
    async ({ payload, cartId }, { rejectWithValue }) => {
        try {
            const response = await commerceAuthAxiosInstance({
                url: `cart/${cartId}/validation`,
                method: "POST",
                data: JSON.stringify(payload),
            });
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);
