import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "..";
import Box from "@mui/material/Box";
import { Link, useHistory } from "react-router-dom";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { loadUserRegistrationFinalStepStaticContent } from "../../features/contentful/contentfulThunkApi";
import Typography from "@mui/material/Typography";
import { fetchUserApi } from "../../features/commerce/commerceThunkApi";
import { searchCustomerApi } from "../../utils/commerceEmpUsersApi";
import { useUserInfoContext } from "../../contexts";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { styled } from '@mui/material/styles';

const CustomBold = styled('b')`
  font-family: 'Aeonik Bold', 'Arial';
`;

export const AsmUserFinalStep = ({ isASMUser }) => {
  const { userRegistrationFinalStepContent } = useSelector((store) => store?.contentful);
  const { staticContent = [] } = userRegistrationFinalStepContent;
  const dispatch = useDispatch();
  const history = useHistory();
  const { setUserInfo } = useUserInfoContext() || "";
  const locale = useSelector((state) => state.globalStates.locale);
  const [processing, setProcessing] = useState(false);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Typography variant={"p1"} component={"p"} m="20px auto 0px auto" textAlign="center" color="primary.main" width="90%">{children}</Typography>,
    },
    renderMark: {
        [MARKS.BOLD]: (text) => <CustomBold>{text}</CustomBold>
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(userRegistrationFinalStepContent).length === 0) {
      dispatch(loadUserRegistrationFinalStepStaticContent({title : "Admin Registration Confirmation Component"}));
    }
  }, []);

  const startSessionHandler = async () => {
    setProcessing(true);
    const customerInfo = localStorage.getItem("contactInfo") ? JSON.parse(localStorage.getItem("contactInfo")) : "";
    const accountInfo = localStorage.getItem("accountInfo") ? JSON.parse(localStorage.getItem("accountInfo")) : "";
    const empInfo = localStorage.getItem("employeeUser") ? JSON.parse(localStorage.getItem("employeeUser")) : "";
    const empEmail = empInfo?.uid;

    const requestData = {
      employeeEmail: empEmail,
      email: customerInfo?.email,
      hin: accountInfo?.hin || "",
      dea: accountInfo?.dea || "",
    };

    try {
      const customerSearch = await searchCustomerApi(requestData);
      if (customerSearch.status === 200) {
        dispatch(fetchUserApi({ userId: customerSearch?.data?.uid }))
        .unwrap()
        .then((user) => {
          if (user?.data) {
            localStorage.setItem("currentUser", JSON.stringify(user?.data));
            setUserInfo(user.data);
            localStorage.removeItem("accountInfo");
            localStorage.removeItem("licenseInfo");
            localStorage.removeItem("contactInfo");
            setProcessing(false);
            history.push("/home");
          }
        })
      }
    } catch (err) {
      setProcessing(false);
      console.log(err);
    }
  };

  return (
    <Box position="relative" m="60px auto 0 auto" width={{ xs: "90%", sm: "60%" }}>
      <Box component={"img"} m={{ xs: "20px auto 40px auto", sm: "20px auto" }} display="flex" src={staticContent?.icon?.fields?.file?.url} sx={{ width: "90px", height: "90px" }}></Box>
      <Typography variant={"h2"} textAlign="center" color="primary.main">
        { staticContent?.headline }
      </Typography>
      {documentToReactComponents(staticContent?.benefitsMessage, options)}
      <Box component={"div"} position={"inherit"} textAlign={"center"}>
        <Button id="returnToHome" loading={processing} sx={{ marginTop: "32px", textAlign: "center" }} onClick={startSessionHandler}>
          {staticContent?.launchPrebookButtonLabel}
        </Button>
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "15px", justifyContent: "center" }}>
          <Link to={`/${locale}/admin`} id="manageLink" sx={{ marginTop: "50px" }}>
              <Typography variant={"Link"} component={"p"} color="secondary.textLinks" textAlign={"center"}>
                {staticContent?.adminConsoleLinkLabel}
              </Typography>
            </Link>
        </Box>
      </Box>
    </Box>
  );
};
