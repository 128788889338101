import { styled } from "@mui/material/styles";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import cart  from "../../assets/images/cart.svg";
import user from "../../assets/images/user.svg";
import { LocaleSelector } from "../LocaleSelector";
import { Button as LoginButton } from "../Button";
import { useModal } from "../../hooks/useModal";
import { useAuth } from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";

const BottomAppContainer = styled('div')(({ theme: { palette: { neutral }, breakpoints }}) => `
  height: 90px;
  padding: 20px 72px;
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr auto auto;
  justify-content: space-between;
  align-items: center;
  background-color: ${neutral.white};
  box-shadow: 0px 3px 16px #00000029;
  @media(max-width: 620px){
    padding: 20px 20px;
  }
  ${[breakpoints.down('sm')]} {
    height:80px;
    padding: 0px 30px;
    position: fixed;
    grid-template-columns: auto auto;
    z-index: 1000000;
    width: 100vw;
  }
`);

export const NewHomePageHeader = ({ headerData, baseStoreData, setProcessing }) => {
  const locale = useSelector((state) => state.globalStates.locale);
  const theme = useTheme();
  const { handleOpenModal } = useModal();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  const location = useLocation();

  const isProduct2023Disabled = process.env.IS_PRODUCT2023_DISABLED === 'true';
  const isReservationEnabled = process.env.IS_RESERVATIONS_ENABLED === 'true'
  
  const openLoginModal = (redirectPath) => {
    handleOpenModal(redirectPath);
  };

  const { authState, oktaAuth } = useOktaAuth() || '';
  const productsLink = (
    <Link
      to="#"
      onClick={() => openLoginModal(`/${locale}/products`)}
      id="headerLink-products"
    >
      <Typography
        fontSize="18px"
        fontWeight="500"
        fontStyle="normal"
        fontFamily="Aeonik Bold"
        color="primary.main"
        lineHeight="24px"
      >
        Products
      </Typography>
    </Link>
  );

  const reservationLink = (
    <Link
      to="#"
      onClick={() => openLoginModal(`/${locale}/reservation`)}
      id="headerLink-products"
    >
      <Typography
        fontSize="18px"
        fontWeight="500"
        fontStyle="normal"
        fontFamily="Aeonik Bold"
        color="primary.main"
        lineHeight="24px"
      >
        Reserve
      </Typography>
    </Link>
  );

  const noop = () => null;
  const { signIn, loadData } = useAuth({
    setInvalidEmail: noop,
    setInvalidPassword: noop,
    setProcessing,
    locale
  });

  const [refreshAuthRunning, setRefreshAuthRunning] = useState(false);
  useEffect(() => {
    if (!window.location.href.includes("callback") && authState && !authState.isAuthenticated && !refreshAuthRunning) {
      setRefreshAuthRunning(true);
      (async () => {
        try {
          let session = await oktaAuth.session.get();
          if (session?.status === "ACTIVE") {
            // There is already an active Okta session, call the signIn function to refresh the website state.
            // This is a bit of a hack and passes dummy values to signIn as they don't actually matter in this case.
            // With some rework, this may be able to be managed more cleanly.
            await signIn("dummy@email.com", "dummy", noop);
          }
        } catch (e) {
        }
      })();
    }
  })

  return ( 
    <div>
      <BottomAppContainer>
          <Link to={`/${locale}`}>
            <img
              id="UnAuthLogo"
              src={headerData?.logo?.fields.file?.url}
              alt="Moderna Logo"
              width="158px"
            />
          </Link>
          { desktop &&
          <>
            <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap="32px"
            sx={{
              borderBottomColor: `${theme.palette.neutral.white} !important`,
              '&.active': {
                borderBottomColor: `${theme.palette.primary.button} !important`,
                borderBottom: '3px solid'
              }
            }}
            key="index"
          >
            {isReservationEnabled ? reservationLink : productsLink}
            <Link to="#" onClick={() => openLoginModal(`/${locale}/orders`)} id="headerLink-order-history">
              <Typography fontSize='18px' fontWeight='500' fontStyle='normal' fontFamily='Aeonik Bold' color="primary.main" lineHeight="24px">Order History</Typography>
            </Link>
            </Box>
            <Box display={'flex'} flexDirection={'row'} gap={'16px'}>
            <LocaleSelector baseStoreData={baseStoreData} />
            {!isProduct2023Disabled && <Box component={'img'} sx={{objectFit: 'cover'}} alt='cart icon' height="32px" width="32px" src={cart}/>}
            <Box component={'div'} sx={{border:"1px solid", borderColor:'neutral.gray6', height:45}}></Box>
            </Box>
          </>
          }
          <LoginButton leftIcon={user} onClick={() => openLoginModal(location?.pathname)} buttonType='mds-tertiary' sx={{textTransform:"none"}}>Log in</LoginButton>
      </BottomAppContainer>
    </div>
  );
};
