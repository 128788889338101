import React, { useState } from "react";
import Box from "@mui/material/Box";
import { EmailFactor } from "./EmailFactor";
import { RecoveryLink } from "./RecoveryLink";

export const RequestResetPassword = () => {
  const [comp, setComp] = useState('emailFactor');

  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      { 
      comp === 'emailFactor' ? <EmailFactor setComp={setComp}/> :
      comp === 'recoveryLink' ? <RecoveryLink setComp={setComp}/> : '' 
      }
    </Box>
    </>
  );
};
