
import { ModalComponent } from "../ModalComponent";
import ShippingAddressForm from "../Onboarding/ShippingAddressAndLicense/ShippingAddressForm";

export const UserAddressFormModal = ({openModal, closeModal, handleNewAddresses, isOrdering, setExternalProcessing, isAddingAdditionalLocation, errorMsgHandler}) => {
    return (
        <ModalComponent 
            openModal={openModal}
            closeModalHandler={closeModal} 
            modalContent={
                <ShippingAddressForm
                    closeModal={closeModal}
                    handleNewAddresses={handleNewAddresses}
                    isOrdering={isOrdering}
                    setExternalProcessing={setExternalProcessing}
                    isAddingAdditionalLocation={isAddingAdditionalLocation}
                    errorMsgHandler={errorMsgHandler}
                />}
            style={{
                width: '50%',
                '#titleContainer':{
                    marginBottom: '0px',
                    padding: '5px'
                },
            }}
        />
    )
}
