import { useState } from 'react';
import { Box } from '@mui/system';

const ImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images?.[0].original);
  
  return (
    <Box width="520px" height="320px" flexShrink={0} display="flex" gap="16px" data-testid="imageGallery">
      <Box data-testid="thumbnailSection" display="inline-flex" flexDirection="column" gap="16px">
        {images?.map((image, i) => {
          return (
            <Box
              key={i}
              width="96px"
              height="96px"
              border="1px solid #9BA5B7"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={image.thumbnail} width="81px" height="81px" onClick={() => setSelectedImage(image.original)}/>
            </Box>
          )
        })}
      </Box>
      <Box
        data-testid="selectedImage"
        display="flex"
        width="409px"
        height="320px"
        justifyContent="center"
        alignItems="center"
        border="1px solid #9BA5B7"
      >
        <img src={selectedImage} height="100%" />
      </Box>
    </Box>
  )
}

export default ImageGallery