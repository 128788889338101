import { OrderErrorCodes } from "./errorCodes"

export const OrderErrorMessages = {
    [OrderErrorCodes.PRICING.PRICING_CALCULATION_ERROR]: "We've encountered an issue with your cart, please refresh the page. \
                                                    If the problem persists, contact customer support.",
    [OrderErrorCodes.GENERAL.INVALID_REQUEST_PARAMETERS]: "We've encountered an issue with your cart. Please return to the order form.",
    [OrderErrorCodes.PAYMENTS.GENERIC_DECLINE]: "Your payment method could not be processed. Please add a new payment method and try again.",
    [OrderErrorCodes.PAYMENTS.FRAUDULENT]: "Your payment method could not be processed. Please add a new payment method and try again.",
    [OrderErrorCodes.PAYMENTS.CARD_DECLINED]: "Your payment method could not be processed. Please add a new payment method and try again.",
    [OrderErrorCodes.PAYMENTS.EXPIRED_CARD]: "Your payment method has expired. Please use a different payment method to place this order.",
    [OrderErrorCodes.PAYMENTS.CAPTURE_FAILURE]: "Your payment method could not be processed. Please add a new payment method and try again.",
    [OrderErrorCodes.PAYMENTS.BALANCE_DATA_UNAVAILABLE]: "Your payment method could not be processed. Please add a new payment method and try again.",
    [OrderErrorCodes.PAYMENTS.INSUFFICIENT_FUNDS]: "Your payment method could not be processed. Please add a new payment method and try again."
};
