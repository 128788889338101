import {
  Box,
  Grid,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { StatusIndicators,OrderItemGrid,DisclaimerMessageFooter } from "../components";
import { useUserInfoContext } from "../contexts";
import { loadOrderDetailSummaryComponent } from "../features/contentful/contentfulThunkApi";
import { toTitleCase } from "../utils/commonUtils";
import { fetchOrderFromSAPUsingCode } from "../utils/orderApis";
import { KeyboardArrowLeft } from "@mui/icons-material";

const fetchOrderDetails = async (userId, code) => await fetchOrderFromSAPUsingCode(userId, code);

function hasTrackingIds(orderDetails) {
  return orderDetails && orderDetails.consignments && Array.isArray(orderDetails.consignments) &&
      orderDetails.consignments.some(item => item && typeof item.trackingID === 'string' && item.trackingID.trim() !== '');
}

function getTrackingIds(orderDetails) {
  if (hasTrackingIds(orderDetails)) {
    const trackingIds = orderDetails.consignments.reduce((result, item) => {
      if (item && typeof item.trackingID === 'string' && item.trackingID.trim() !== '') {
        result.push(item.trackingID);
      }
      return result;
    }, []);
    return trackingIds;
  }
  return null;
}

const HistoricOrderDetailsPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { userInfo } = useUserInfoContext();
  const userId = userInfo?.uid;
  const { id } = useParams();
  const { orderDetailSummaryComponent: orderDetailSummaryContent } = useSelector((store) => store?.contentful);

  const [ orderDetails, setOrderDetails ] = useState();
  const locale = useSelector((state) => state.globalStates.locale);

  useEffect(() => {
    fetchOrderDetails(userId, id)
        .then((res) => setOrderDetails(res))
  }, [id]);

  useEffect(() => {
    if (orderDetailSummaryContent && Object.keys(orderDetailSummaryContent).length === 0)
      dispatch(loadOrderDetailSummaryComponent());
  }, [orderDetailSummaryContent, dispatch]);

  return (
      <>
        <Box
            mx={"100px"}
            my={"60px"}
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                mx: "auto",
              },
            })}
        >
          <Link
            to={`/${locale}/orders`}
            variant={'link'}
            data-testid='backToOrderHistory'
          >
            <Box display='flex' alignItems='center' mb={"35px"} ml={{ xs: "30px", md: 0 }}>
              <KeyboardArrowLeft />
              <Typography variant='link'>{ orderDetailSummaryContent?.orderHistoryLinkLabel }</Typography>
            </Box>
          </Link>
          <Typography data-testid="orderDetailsHeader" variant={'h1'} fontSize={"34px"} mb={"60px"} ml={{ xs: "36px", md: 0 }}>{orderDetailSummaryContent?.headerLabel}</Typography>
          <Grid
              container
              columns={12}
              spacing={1}
              display={"flex"}
              flexWrap={"nowrap"}
              sx={{
                width: "100%",
                flexDirection: {xs: "column", sm: "row"},
                justifyContent: {xs: "center", sm: "flex-start"},
                alignItems: {xs: "center", sm: "stretch"},
              }}
          >
            <Grid
                display="flex"
                xs={11}
                sm={8}
                sx={{width: "100%"}}
                item
            >
              <Box
                  color={"primary.main"}
                  p={3}
                  display="flex"
                  width="100%"
                  justifyContent={"space-between"}
                  borderRadius={"16px"}
                  border={"1px solid"}
                  borderColor={"neutral.gray6"}
                  borderTop={"7px solid"}
                  borderTopColor={"primary.main"}
              >
                <Box display={"grid"} gap={2} data-testid="BoxContainer">
                  <Box>
                    <Typography variant={"h6"} textTransform={"uppercase"}>
                      { orderDetailSummaryContent?.modernaIdLabel }
                    </Typography>
                    <Typography variant={"h4"} marginTop={"5px"}>
                      { orderDetails?.code }
                    </Typography>
                  </Box>
                  <Divider orientation="horizontal" variant="middle" />
                  <Box>
                    <Typography variant={"h6"} textTransform={"uppercase"}>
                      { orderDetailSummaryContent?.datePlacedLabel }
                    </Typography>
                    <Typography variant={"p2"}>
                      { dayjs(orderDetails?.created).format("MMMM D, YYYY") }
                    </Typography>
                  </Box>
                </Box>
                <Box display={"grid"} gap={2}>
                  <Box>
                    <Typography variant={"h6"} textTransform={"uppercase"}>
                      {orderDetailSummaryContent?.referenceIdLabel}
                    </Typography>
                    <Typography variant={"p2"}>
                      { orderDetails?.salesOrderNumber ? orderDetails?.salesOrderNumber : '-' }
                    </Typography>
                  </Box>
                  <Divider orientation="horizontal" variant="middle" />
                  <Box>
                    <Typography variant={"h6"} textTransform={"uppercase"}>
                      { orderDetailSummaryContent?.poNumberLabel }
                    </Typography>
                    <Typography variant={"p2"}>
                      { orderDetails?.purchaseOrderNumber ? orderDetails?.purchaseOrderNumber : '-' }
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid
                display="flex"
                xs={11}
                sm={4}
                sx={{
                  width: "100%",
                }}
                item
            >
              <Box
                  color={"primary.main"}
                  p={3}
                  width="100%"
                  display="grid"
                  borderRadius={"16px"}
                  border={"1px solid"}
                  borderColor={"neutral.gray6"}
                  borderTop={"7px solid"}
                  borderTopColor={"primary.main"}
              >
                <Box display={"grid"} gap={2}>
                  <Box>
                    <Typography variant={"h6"} textTransform={"uppercase"}>
                      { orderDetailSummaryContent?.subtotalAmountLabel }
                    </Typography>
                    <Typography variant={"h4"} marginTop={"5px"}>
                      { orderDetails?.totalPrice?.formattedValue }
                    </Typography>
                  </Box>
                  <Divider orientation="horizontal" variant="middle" />
                  <Box>
                    <Typography variant={"h6"} textTransform={"uppercase"}>
                      { orderDetailSummaryContent?.savingsLabel }
                    </Typography>
                    <Typography variant={"p2"}>
                      { orderDetails?.totalDiscounts?.formattedValue }
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid
                display="flex"
                xs={11}
                sx={{
                  width: "100%",
                }}
                item
            >
              <Box
                  color={"primary.main"}
                  p={3}
                  width="100%"
                  display="flex"
                  sx={{
                    flexDirection: {xs:"column", sm: "row"},
                  }}
                  gap={2}
                  justifyContent={"start"}
                  borderRadius={"16px"}
                  border={"1px solid"}
                  borderColor={"neutral.gray6"}
                  borderTop={"7px solid"}
                  borderTopColor={"primary.main"}
              >
                <Box display={"grid"} gap={2} flexGrow={1} flexBasis={'50%'}>
                  <Box>
                    <Typography variant={"h6"} textTransform={"uppercase"}>
                      { orderDetailSummaryContent?.statusLabel }
                    </Typography>
                    <Box
                        display={"flex"}
                        padding={"0px"}
                        height={'50px'}
                    >
                      <Typography variant={"h4"} marginTop={"5px"}>
                        { toTitleCase(orderDetails?.status) }
                      </Typography>
                      <StatusIndicators
                          status={orderDetails?.status}
                          className={"orderDetails"}
                      ></StatusIndicators>
                    </Box>
                    <Divider orientation="horizontal" variant="middle" />
                  </Box>
                  <Box>
                    <Typography variant={"h6"} textTransform={"uppercase"}>
                      { orderDetailSummaryContent?.trackingLabel }
                    </Typography>
                    <Typography variant={"p2"}>
                      { hasTrackingIds(orderDetails) ? getTrackingIds(orderDetails).join(', ') : '-'}
                    </Typography>
                  </Box>
                </Box>
                <Divider
                    orientation={useMediaQuery(theme.breakpoints.down("sm")) ? "horizontal" : "vertical"}
                    variant="middle"
                />
                <Box display={"flex"} justifyContent={"space-between"} flexGrow={1} flexBasis={'50%'}>
                  <Box display={"grid"}>
                    <Typography variant={"h6"} textTransform={"uppercase"}>
                      { orderDetailSummaryContent?.deliveryLocationTitleLabel }
                    </Typography>
                    <Typography variant={"p1"}>
                      { toTitleCase(orderDetails?.deliveryAddress?.companyName) }
                      <br></br>
                      { toTitleCase(orderDetails?.deliveryAddress?.formattedAddress) }
                    </Typography>
                    <Typography variant={"p2"} color={"neutral.gray1"} fontStyle={"italic"}>
                      { orderDetails?.deliveryAddress?.instructions
                          ? orderDetailSummaryContent?.deliveryLocationSubtitleLabel + " " + orderDetails?.deliveryAddress?.instructions
                          : ""
                      }
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box mt={8} px={{xs: 3, sm: 0}}>
            <Typography variant={"h2"} textTransform={"uppercase"}>
              {orderDetailSummaryContent.itemHeader} ({orderDetails?.entries.length ? orderDetails?.entries.length : '0' })
            </Typography>
            {orderDetails?.entries && <OrderItemGrid productsData={orderDetails?.entries}/>}
          </Box>
        </Box>
        <DisclaimerMessageFooter
            disclaimerMessage={orderDetailSummaryContent?.disclaimerMessage}
        />
      </>
  );
};

export default HistoricOrderDetailsPage;
