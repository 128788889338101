import React, { useEffect } from "react";
import ProfileComponent from "../components/Profile";
import { useDispatch, useSelector } from "react-redux";
import { loadProfileStaticContent } from "../features/contentful/contentfulThunkApi";

const MyProfilePage = () => {
    const { profilePageContent } = useSelector((store) => store?.contentful)
    const { staticContent = [], staticJobRoles = [], staticJobTitles = [] } = profilePageContent
    const dispatch = useDispatch()

    useEffect(() => {
        if (Object.keys(profilePageContent).length === 0)
            dispatch(loadProfileStaticContent())
    }, [])

    return (
        <ProfileComponent
            staticContent={staticContent}
            staticJobRoles={staticJobRoles}
            staticJobTitles={staticJobTitles}
        />
    )
}

export default MyProfilePage;
