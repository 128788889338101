import { Box, Typography } from "@mui/material";
import { TypographyH1 } from "../../assets/fonts/typographyTheme";
import { paymentBox, PaymentCard } from "./PaymentCard";
import PlusIcon from "../../assets/images/plusIcon.svg";

const paymentContainer = {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    width:'100%',
    maxWidth: '700px'
}

const hoverStyles = {
    border: '2px solid #079AE0',
    transform: 'translateY(-2px)'
}

export const PaymentMethods = ({ 
    paymentList, isProfilePage = false,
    handleSetAsDefaultPayment = () => {},
    handleAddNewPaymentMethod = () => {},
    handlePaymentMethodSelection = () => {},
    handleDeletePaymentMethod = () => {},
    closeModal = () => {} }) => {
        return (
        <Box>
            <Box  display="flex" justifyContent={ 'left'} alignItems={'flex-start'}>
                {!isProfilePage && <TypographyH1 id="modalTitle" data-testid='title'>Payment Method</TypographyH1>}
            </Box>
            <Box mt={3} sx={isProfilePage ? {} : paymentContainer}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '16px', alignItems: 'flexStart' }}>
                    {paymentList?.map((paymentInfo) => (
                        <PaymentCard
                            key={paymentInfo.paymentMethodId}
                            cardInfo={paymentInfo}
                            handleSetAsDefaultPayment={handleSetAsDefaultPayment}
                            isDefault={paymentInfo?.isDefault}
                            handlePaymentMethodSelection={handlePaymentMethodSelection}
                            closeModal={closeModal}
                            isProfilePage={isProfilePage}
                            handleDeletePaymentMethod={handleDeletePaymentMethod}
                        />
                    ))}
                    <Box onClick={handleAddNewPaymentMethod} sx={{ ...paymentBox, cursor: 'pointer', ...(isProfilePage ? {} : {'&:hover': hoverStyles} )}}>
                        <Box display="flex" justifyContent="center" alignItems="center" gap="8px">
                            <img src={PlusIcon} alt="plus icon" width="32" height="32"/>
                            <Typography fontWeight="700" fontFamily="Aeonik Regular" color="#0379B2">Add a new payment method</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
