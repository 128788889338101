import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Box, Typography} from "@mui/material";
import { Button } from '../Button';
import warningInfoIcon from '../../assets/images/warningInfoIcon.svg'
import { loadConfirmationModalComponent } from '../../features/contentful/contentfulThunkApi';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ModalComponent } from '../ModalComponent';

const MessageContentStyles = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '18px',
    padding: '12px 16px',
    borderRadius: '0px 8px 8px 0px',
    borderLeft: '2px solid #FDB022',
    background: '#FFF9EC',
};

const ConfirmationModal = ({ closeModal, openModal, contentType, handleConfirm }) => {
  const dispatch = useDispatch();
  const { confirmationModalComponent: staticContent } = useSelector((store) => store?.contentful);

  useEffect(() => {
    if(staticContent && Object.keys(staticContent).length === 0) {
      dispatch(loadConfirmationModalComponent());
    }
  },[dispatch, staticContent]);

  const options = {
    renderText: text => {
      return text.split('\n').flatMap((text, index, array) => array.length - 1 === index ? text : [text, <br key={index} />]);
    }
  };

  const getContentBasedOnType = (contentType) => {
    const isCancel = contentType === "cancel";
    return {
      headerText: isCancel
        ? staticContent.cancelHeader
        : staticContent.discardHeader,
      messageText: isCancel
        ? staticContent.cancelMessage
        : staticContent.discardMessage,
      noButtonText: isCancel ? "No, keep this reservation" : "No, keep editing",
      yesButtonText: isCancel
        ? "Yes, cancel this reservation"
        : "Yes, discard changes",
    };
  }

  const { headerText, messageText, noButtonText, yesButtonText } = getContentBasedOnType(contentType);

  const modalContent = () => { 
    return (
    <>
        <Box sx={MessageContentStyles}>
            <img src={warningInfoIcon} alt="info icon" width="32" height="32" />
            <Box data-testid="messageContent" display="flex" flexDirection="column" alignItems="flex-start" gap="10px" flex="1 0 0">
                <Typography fontFamily="Aeonik Regular" fontSize="16px" fontWeight="700">
                  {headerText}
                </Typography>
                <Typography fontFamily="Aeonik Regular" textAlign="left">
                  {documentToReactComponents(messageText, options)}
                </Typography>
            </Box>
        </Box>
        <Box data-testid="buttonGroup" display="flex" justifyContent="center" gap="10px" width="100%">
            <Button
                buttonType='mds-secondary'
                sx={{ padding: "16px 24px", textTransform: "none" }}
                onClick={closeModal}
            >
                <Typography fontFamily="Aeonik Regular" fontSize="14px" fontWeight="700">
                  {noButtonText}
                </Typography>
            </Button>
            <Button buttonType='mds-primary' sx={{ padding: "16px 24px", textTransform: "none" }} onClick={() => handleConfirm()}>
                <Typography fontFamily="Aeonik Regular" fontSize="14px" fontWeight="700">
                  {yesButtonText}
                </Typography>
            </Button>
        </Box>
    
    </>
  )}

  return (
    <ModalComponent 
      title={contentType === 'cancel' ? 'Cancel reservation' : 'Discard changes'}
      openModal={openModal}
      closeModalHandler={closeModal}
      style={{ 
        maxWidth: '521px',
        width: 'auto',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '23px',
        '#titleContainer':{
            marginLeft: '0px',
            padding: '5px'
        },
    }}
    modalContent={modalContent()}
    />
  )
}

export default ConfirmationModal