import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import CheckIcon from "../../assets/images/check.svg";
import { Button } from '../../components';
import { setCanAccessOrderConfirmationPage, setIsUserValidated } from '../../features/globalStates/globalStatesSlice';
import MessageBanner from '../../components/MessageBanner/MessageBanner';
import { taxExemptionContent } from '../../components/Taxes/TaxExemptionContent';

const OrderConfirmationPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const locale = useSelector((state) => state.globalStates.locale);
    const { userData } = useSelector((store) => store?.commerce);
    const canAccessOrderConfirmationPage = useSelector((state) => state.globalStates.canAccessOrderConfirmationPage)
    const isUserValidated = useSelector((state) => state.globalStates.isUserValidated); // TO-DO: Where does this needs to be set?
    const isTaxPhase3Enabled = process.env.IS_TAX_PHASE_3_ENABLED === 'true';

    useEffect(() => {
        if(!canAccessOrderConfirmationPage) {
        history?.push(`/${locale}/`);
        } else {
        dispatch(setCanAccessOrderConfirmationPage(false));
        }
    }, [history]);

    return (
        <Container sx={{ minHeight: "550px", display: "flex", flexDirection:"column", alignItems: "center", paddingTop:"64px"}}>
            <img src={CheckIcon} alt='check icon' className='CheckIcon' height='56' width='56'/>
            <Box data-testid='ConfirmationMsgSection' display="flex" flexDirection="column" alignItems="center" gap={2}>
                <Typography data-testid='ThankYouMsg' fontFamily="Aeonik Regular" fontSize="24px" fontWeight={500}>Thank you, {`${userData?.firstName} ${userData?.lastName}`}.</Typography>
                <Typography data-testid='OrderPlacedMsg' fontFamily="Aeonik Regular" fontSize="24px" fontWeight={700}>Your order has been received.</Typography>
            </Box>
            <Box data-testid='OtherInstructionSection' display="flex" flexDirection="column" alignItems="center" gap={4} padding={"32px"}>
                   {!isUserValidated && <MessageBanner
                        type="sync"
                        headerText="Your account is being validated"
                        messageText="Your orders will appear “on hold” until your account is fully validated. We will contact you if we need any other information. No further action is required at this time."
                        dataTestId="AccountBeingValidatedBanner"
                        style={{width: "545px"}}
                    />}
            </Box>
            {isTaxPhase3Enabled && <Container sx={{minHeight: "300px"}} dataTestId="TaxInstructionContainer">
                <MessageBanner
                    type='info'
                    headerText="Tax-exempt certification"
                    bannerContentComponent={taxExemptionContent()}
                    dataTestId="TaxInstructionSection"
                />
            </Container>}
            <Button
                buttonType='mds-secondary'
                data-testid='placeAnotherOrderButton'
                sx={{ padding: "16px 24px", textTransform: "none", marginBottom: "100px"}}
                onClick={() => history.push(`/${locale}/order-form`)}
            >
                Place another order
            </Button>
        </Container>
    )
}

export default OrderConfirmationPage;
