import React from "react";
import Grid from "@mui/material/Grid";
import { StyledTextInput } from "./ContactInfo";
import { SelectDropdown } from "..";
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)`
  && {
    max-width: 812px;
  }
`;

export const UnvalidatedRegistration = ({unvalidatedRegisterContent, unvalidatedRegisterForm, invalidUnvalidatedRegisterForm, setUnvalidatedRegisterForm, setInvalidUnvalidatedRegisterForm }) => {
    const handleChange = (label, event) => {
        setUnvalidatedRegisterForm({
            ...unvalidatedRegisterForm,
            [label]: event.target.value.trimStart()
        });
        setInvalidUnvalidatedRegisterForm({
            ...invalidUnvalidatedRegisterForm,
            [label]: ""
        });
    };
    const handleStateDropdown = (value) => {
        setUnvalidatedRegisterForm({
            ...unvalidatedRegisterForm,
            addressStateLabel: value
        });
        setInvalidUnvalidatedRegisterForm({
            ...invalidUnvalidatedRegisterForm,
            addressStateLabel: ""
        });
    };

    const stateListOptions = unvalidatedRegisterContent?.addressStatePlaceholder?.map((role) => {
        return({
            "value": role,
            "label": role,
        })
    })

    const renderRows = (key, placeholder, rowWidth, required=true) => {
        return (
            <Grid item xs={rowWidth} >
                <StyledTextInput
                    required={required}
                    label={unvalidatedRegisterContent?.[key]}
                    inputType="text"
                    variant="standard"
                    value={unvalidatedRegisterForm[key]}
                    focused
                    placeholder={placeholder}
                    helperText={invalidUnvalidatedRegisterForm[key]}
                    error={!!invalidUnvalidatedRegisterForm[key]}
                    onChange={(event) => handleChange(key, event)}
                />

            </Grid>
        )
    }
    const rowWidth = { LARGE: 12, MEDIUM: 6}

    return (
        <StyledContainer maxWidth="xs">
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {renderRows('accountNameLabel',unvalidatedRegisterContent?.accountNamePlaceholder, rowWidth.LARGE)}
                {renderRows('addressLineLabel',unvalidatedRegisterContent?.accountNamePlaceholder, rowWidth.LARGE)}
                {renderRows('addressLine2Label',unvalidatedRegisterContent?.accountNamePlaceholder, rowWidth.LARGE)}
                {renderRows('addressCityLabel',unvalidatedRegisterContent?.addressCityPlaceholder, rowWidth.LARGE)}

                <Grid item xs={6}>
                    <SelectDropdown
                        required
                        label={unvalidatedRegisterContent?.addressStateLabel}
                        options={stateListOptions}
                        value={unvalidatedRegisterForm.addressStateLabel}
                        onChangeHandler={(value) => handleStateDropdown(value)}
                        helperText={invalidUnvalidatedRegisterForm.addressStateLabel}
                        error={!!invalidUnvalidatedRegisterForm.addressStateLabel}
                        formSx={{ marginTop: '-15px' }}
                    />
                </Grid>
                {renderRows('addressZipLabel',unvalidatedRegisterContent?.addressZipPlaceholder, rowWidth.MEDIUM)}
                {renderRows('accountEmailAddressLabel',unvalidatedRegisterContent?.accountEmailAddressPlaceholder, rowWidth.LARGE)}
                {renderRows('accountPhoneNumberLabel',unvalidatedRegisterContent?.accountPhoneNumberPlaceholder, rowWidth.LARGE)}
                {renderRows('accountHinLabel',unvalidatedRegisterContent?.accountHinPlaceholder, rowWidth.MEDIUM)}
                {renderRows('accountDeaLabel',unvalidatedRegisterContent?.accountDeaPlaceholder, rowWidth.MEDIUM)}
            </Grid>
        </StyledContainer>
    )
};