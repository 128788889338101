import { TypographyH1, TypographyP1 } from "../../../../assets/typographyTheme";
import { Box, Typography } from "@mui/material";
import MessageBanner from "../../../MessageBanner/MessageBanner";
import { useState } from "react";
import { MatchedOrgCards } from "./MatchedOrgCards";
import { CustomPagination } from "../../../CustomPagination/CustomPagination";
import { Button } from "../../../Button";
import OrgNotFoundConfirmationModal from "../OrgNotFoundConfirmationModal";

export const MatchFoundSection = ({searchOrgResults, searchForOrganizations, ...props}) => {
    const [page, setPage] = useState(1);
    const CELL_PER_PAGE = 4;
    const totalPages = Math.ceil(searchOrgResults?.total / CELL_PER_PAGE);
    const [isOrgNotFoundModalOpen, setIsOrgNotFoundModalOpen] = useState(false);

    const {
        tabIndex,
        onTabChange,
        handleErrorMsg,
        userOrganizationInfo
    } = props;

    const closeOrgNotFoundConfirmationModal = () => {
        setIsOrgNotFoundModalOpen(false)
    }
    const handlePageChange = async (newPage) => {
        await searchForOrganizations(newPage - 1)
        setPage(newPage);
    };

    return (
        <Box maxWidth={'850px'} mt={3}>
            { isOrgNotFoundModalOpen &&
                <OrgNotFoundConfirmationModal
                    openModal={isOrgNotFoundModalOpen}
                    closeModal={closeOrgNotFoundConfirmationModal}
                    tabIndex={tabIndex}
                    onTabChange={onTabChange}
                    handleErrorMsg={handleErrorMsg}
                    userOrganizationInfo={userOrganizationInfo}
                />
            }
            <TypographyH1 mb={1}>
                Organization matches found
            </TypographyH1>
            <TypographyP1 mb={1}>
                We've identified organizations in our system that match some of the information you entered.
                If you see your organization below, please select it to prevent duplicate entries.
            </TypographyP1>
            <TypographyP1 mb={3}>
                <span style={{ fontWeight: 700 }}>Please note: </span>
                Selecting one of these options will automatically import the associated billing
                and shipping addresses, medical licenses, and purchasing contract details, which could expedite
                the set-up of your account and shipping time for the first order.
            </TypographyP1>
            <Box mb={3}>
                <MessageBanner
                    type='warning'
                    messageText={
                        <TypographyP1>
                            If you choose an organization, your account will be permanently associated with the organization you selected.
                        </TypographyP1>
                    }
                    dataTestId="associateAccountWarning"
                />
            </Box>
            <Box mb={3}>
                <MessageBanner
                    type='warning'
                    messageText= 'Please check the accuracy of the billing address. If you require a different billing address than the one listed, choose “None of these matches my organization” at the bottom of the screen.'
                    dataTestId="diffBillingWarning"
                />
            </Box>
            {searchOrgResults?.data?.map((orgInfo) => (
                <MatchedOrgCards key={orgInfo?.organization?.id} orgInfo={orgInfo} {...props} />
            ))}

            <Box mb={7} mt={1} display={'flex'} justifyContent={'center'} flexGrow={1} >
                <CustomPagination page={page} pageCount={totalPages} onPageChange={handlePageChange} />
            </Box>

            <Box
                style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Button
                    sx={{ textTransform: "none"}}
                    buttonType="primary"
                    data-testid={'noOrgFoundButton'}
                    onClick={() => { setIsOrgNotFoundModalOpen(true) }}
                >
                    <Typography fontWeight={700}>
                        None of these matches my organization
                    </Typography>
                </Button>
            </Box>
        </Box>
    )
}