"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProductionApiKey = void 0;
const is_production_domain_1 = require("./is-production-domain");
// If we are not running on a production domain, we shouldn't use the
// production API key
const getProductionApiKey = (amplitudeApiKey) => {
    if (amplitudeApiKey === undefined) {
        return undefined;
    }
    else if ((0, is_production_domain_1.isProductionDomain)()) {
        return amplitudeApiKey;
    }
    else {
        // If the integration is not occurring on a production domain, then we should
        // force it to use the developer project's Amplitude key.
        return '8fb30518a9a2181216cf32099b32dfe2';
    }
};
exports.getProductionApiKey = getProductionApiKey;
