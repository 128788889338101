import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Button, TextInput, Checkbox, SelectDropdown, AlertMessage, BackComponent} from "..";
import { styled } from '@mui/material/styles';
import { validateFields } from "../../utils/Helper";
import Grid from "@mui/material/Grid";
import { getOktaUserStatusApi } from "../../utils/commerceOktaApi";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useRef } from "react";
import { loadUserRegistrationContactStaticContent } from "../../features/contentful/contentfulThunkApi";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { UnvalidatedRegistration } from "./UnvalidatedRegistration";
import { validateAdminAccount } from "../../utils/registerValidation";

export const StyledTextInput = styled(TextInput)(({ theme: { breakpoints } }) => `
  height: 70px;
  width: 100%;
  margin-top: 30px !important;
  ${[breakpoints.down('sm')]} {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
`);

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  margin: 70px auto 90px auto;
`;

const ResetContainer = styled('div')(({ theme: { breakpoints } }) => `
  position: relative;
  margin: 50px auto 0 auto;
  width: 60%;
  ${[breakpoints.down('sm')]} {
    width: 85%;
    margin-top: 70px;
  }
`);

export const ContactInfo = ({ setComp, setProgressValue, accountStatus, ...props }) => {
  const [firstName, setFirstName] = useState("");
  const [invalidFirstName, setInvalidFirstName] = useState(false);
  const [lastName, setLastName] = useState("");
  const [invalidLastName, setInvalidLastName] = useState(false);
  const [invalidRole, setInvalidRole] = useState(false);
  const [invalidJobTitle, setInvalidJobTitle] = useState(false);
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [role, setRole] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [invalidConfirmEmail, setInvalidConfirmEmail] = useState("");
  const [promotions, setPromotions] = useState(false);
  const [processing, setProcessing] = useState(false);
  const { userRegistrationContactContent } = useSelector((store) => store?.contentful);
  const { staticContent = [], staticJobRoles = [], staticJobTitles = [] } = userRegistrationContactContent;
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [jobList, setJobList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const isASMuser = props?.isASMuser || false;

  const alertRef = useRef(null)

  const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
  const missingFirstNameErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "MISSING_FIRST_NAME");
  const missingFirstNameError = missingFirstNameErrorData && missingFirstNameErrorData[0]?.fields?.message;

  const missingLastNameErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "MISSING_LAST_NAME");
  const missingLastNameError = missingLastNameErrorData && missingLastNameErrorData[0]?.fields?.message;

  const missingEmailErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "MISSING_EMAIL");
  const missingEmailError = missingEmailErrorData && missingEmailErrorData[0]?.fields?.message;

  const emailMismacthErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "EMAIL_MISMATCH");
  const emailMismacthError = emailMismacthErrorData && emailMismacthErrorData[0]?.fields?.message;

  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;

  const unvalidatedRegisterContent = staticContent?.adminRegistrationAddressForm?.fields

  const unvalidatedRegisterFormFields = {
    accountNameLabel: '',
    addressLineLabel: '',
    addressLine2Label: '',
    addressCityLabel: '',
    addressStateLabel: '',
    addressZipLabel: '',
    accountEmailAddressLabel: '',
    accountPhoneNumberLabel: '',
    accountHinLabel: '',
    accountDeaLabel: ''
  }

  const [unvalidatedRegisterForm, setUnvalidatedRegisterForm] = useState({...unvalidatedRegisterFormFields});
  const [invalidUnvalidatedRegisterForm, setInvalidUnvalidatedRegisterForm] = useState({...unvalidatedRegisterFormFields});
  const unvalidatedAccount = accountStatus === 'Unvalidated'
  const locale = useSelector((state) => state.globalStates.locale);
  const countryRegistered = locale? locale.slice(-2) : 'US'
  const [showFirstRegistrationPage, setShowFirstRegistrationPage] = useState(true)

  useEffect(() => {
    window.scrollTo(0,0);
    if (Object.keys(userRegistrationContactContent).length === 0) {
      dispatch(loadUserRegistrationContactStaticContent({title : isASMuser ? "Admin Registration Contact Component" : "Registration Contact Component"}));
    }
  },[]);

  useEffect(() => {
    if(staticContent?.notFoundText && (staticJobRoles[0]?.displayName !== staticContent?.notFoundText) && (staticJobTitles[0]?.displayName !== staticContent?.notFoundText) && staticJobRoles.length && staticJobTitles.length) {
      setJobList(() => {
        return staticJobTitles?.map((title) => {
          return({
            "value": title?.fields?.displayValue,
            "label": title?.fields?.displayName
          })
        })
      });
    
      setRoleList(() => {
        return staticJobRoles?.map((role) => {
          return({
            "value": role?.fields?.displayValue,
            "label": role?.fields?.displayName
          })
        })
      });
    }
  }, [staticContent, staticJobRoles, staticJobTitles])

  useEffect(() => {
    const contactInfo = localStorage.getItem('contactInfo');
    if (contactInfo) {
      try {
        const contactData = JSON.parse(contactInfo);
        setFirstName(contactData?.firstName);
        setLastName(contactData?.lastName);
        setEmail(contactData?.email);
        setConfirmEmail(contactData?.confirmEmail);
        setRole(contactData?.role);
        setJobTitle(contactData?.jobTitle);
        setPromotions(contactData?.promotions);
      } catch (error) {
        console.error('Error parsing contactInfo:', error);
      }
    }

    const accountInfo = localStorage.getItem('accountInfo');
    if (accountInfo) {
      try {
        const infoData = JSON.parse(accountInfo);
        if (infoData?.legalAccountName) {
          setUnvalidatedRegisterForm({
            ...unvalidatedRegisterForm,
            accountNameLabel: infoData?.legalAccountName,
            addressLineLabel: infoData?.officeAddress?.line1,
            addressStateLabel: infoData?.officeAddress?.region?.name ? infoData?.officeAddress?.region?.name : "",
            addressZipLabel: infoData?.officeAddress?.postalCode,
            addressCityLabel: infoData?.officeAddress?.town,
            accountEmailAddressLabel: infoData?.officeAddress?.email,
            accountPhoneNumberLabel: infoData?.officeAddress?.phone,
            accountHinLabel: infoData?.hin,
            accountDeaLabel: infoData?.deaLicense
          });
        }
      } catch (error) {
        console.error('Error parsing accountInfo:', error);
      }
    }
  }, []);

  const setAccountInformation = (accountInformationData) => {
    const accountInformation = {
      legalAccountName: accountInformationData?.accountNameLabel,
      hin: accountInformationData?.accountHinLabel,
      deaLicense: accountInformationData?.accountDeaLabel,
      officeAddress: {
        email: accountInformationData?.accountEmailAddressLabel,
        line1: accountInformationData?.addressLineLabel,
        phone: accountInformationData?.accountPhoneNumberLabel,
        postalCode: accountInformationData?.addressZipLabel,
        region: {
          name: accountInformationData?.addressStateLabel,
          isocodeShort: `${countryRegistered}-${accountInformationData?.addressStateLabel}`,
          isocode: `${countryRegistered}-${accountInformationData?.addressStateLabel}`
        },
        town: accountInformationData?.addressCityLabel,
        country: {
          name: countryRegistered,
          isocode: countryRegistered
        },
        shippingAddress: true,
        billingAddress: false,
      }
    }
    localStorage.setItem('accountInfo', JSON.stringify(accountInformation))
  }


  const handleFirstName = (event) => {
    setFirstName(event.target.value.trimStart());
    setInvalidFirstName("");
  };

  const handleLastName = (event) => {
    setLastName(event.target.value.trimStart());
    setInvalidLastName("");
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setInvalidEmail("");
  };

  const handleConfirmEmail = (event) => {
    setConfirmEmail(event.target.value);
    setInvalidConfirmEmail("");
  };

  const handleRole = (value) => {
    setRole(value);
    setInvalidRole("");
  };

  const handleJobTitle = (value) => {
    setJobTitle(value)
    setInvalidJobTitle("");
  };
  
  const validate = () => {
    let isValid = true;
    if (!firstName) {
      isValid = false;
      setInvalidFirstName(missingFirstNameError);
    }

    if (!lastName) {
      isValid = false;
      setInvalidLastName(missingLastNameError);
    }

    if (!email) {
      isValid = false;
      setInvalidEmail(missingEmailError);
    }

    if (!validateFields("email", email) || email.endsWith("@modernatx.com")) {
      isValid = false;
      setInvalidEmail("Please enter valid email");
    }

    if (!confirmEmail) {
      isValid = false;
      setInvalidConfirmEmail("Please enter confirm email Address.");
    }

    if (confirmEmail !== "undefined") {
      if(email !== confirmEmail){
        isValid = false;
        setInvalidConfirmEmail(emailMismacthError);
      }
    }

    if(role === "" || role === "-"){
      isValid = false;
      setInvalidRole("Please select role");
    }

    if(jobTitle === "" || jobTitle === "-"){
      isValid = false;
      setInvalidJobTitle("Please select job title");
    }

    return isValid;
  };

  const validateAddress = () => {
    const result = validateAdminAccount(unvalidatedRegisterForm, invalidUnvalidatedRegisterForm, unvalidatedRegisterContent)
    const isValid = result.validStatus;
    setInvalidUnvalidatedRegisterForm(result.invalidRegisterForm)
    return isValid
  }

  const handleNext = async (e) => {
    e.preventDefault();
    const validatedForm = unvalidatedAccount? validate() && validateAddress() : validate()
    if(validatedForm) {
      setProcessing(true);
      try {
        const response = await getOktaUserStatusApi(email);
        if (response?.status === 200){
          setProcessing(false);
          if (response?.data?.userOktaAccountStatus === "ACTIVE") {
            setInvalidEmail("This Email address already exist. Please try logging in.");
          } else if (response.data.userOktaAccountStatus === "STAGED" || response?.data?.userOktaAccountStatus === "RECOVERY") {
            setInvalidEmail("This Email address already exists, please activate account");
          } else if (response.data.userOktaAccountStatus === "LOCKED_OUT") {
            setInvalidEmail("This account is locked");
          }
        }
      } catch (error) {
        setProcessing(false);
        const contactInformation = {
          "firstName": firstName,
          "lastName": lastName,
          "email": email,
          "confirmEmail": confirmEmail,
          "role": role,
          "jobTitle": jobTitle,
          "promotions": promotions
        }
        localStorage.setItem('contactInfo', JSON.stringify(contactInformation));

        if(unvalidatedAccount){
          setAccountInformation(unvalidatedRegisterForm);
        }

        if (error?.response?.data?.errorCode === "E0000007") {
          isASMuser ? setComp('review') : setComp('setPassword');
          isASMuser ? setProgressValue(80) : setProgressValue(66);
        } else {
          setErrorMsg(generalApiError);
          alertRef.current?.openAlert()
        }
      }
    }
  }

  const handleNextForm = async (e) => {
    e.preventDefault();
    if(validate()) {
      setShowFirstRegistrationPage(false)
    }
  }

  const backBtnHandler = () => {
    setShowFirstRegistrationPage(true);
  };

  return(
      <>
        {unvalidatedAccount && !showFirstRegistrationPage? <BackComponent id="backBtn" data-testid="formBackbtn" sx={{ top: "110px" }} onClickHandler={backBtnHandler} /> : "" }
        <ResetContainer>
          <AlertMessage variant={"filled"} type={"error"} message={errorMsg} ref={alertRef}/>
          <Typography variant={'h1'} textAlign='center' color='primary.main' mt={{xs:'20px', sm:'0'}} id="headline">{staticContent?.headline}</Typography>
          <Typography variant={'p1'} component={'div'} textAlign='center' mt='20px' mb='60px' id="text">{documentToReactComponents(staticContent?.message)}</Typography>

          {showFirstRegistrationPage?
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={6}>
                  <StyledTextInput
                      required
                      label={staticContent?.firstNameLabel}
                      inputType="text"
                      variant="standard"
                      value={firstName}
                      focused
                      placeholder={staticContent?.firstNamePlaceholder}
                      sx={{ marginBottom: "20px" }}
                      helperText={invalidFirstName}
                      error={!!invalidFirstName}
                      onChange={handleFirstName}
                      data-testid="firstNameInputField"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextInput
                      required
                      label={staticContent?.lastNameLabel}
                      inputType="text"
                      variant="standard"
                      value={lastName}
                      focused
                      placeholder={staticContent?.lastNamePlaceholder}
                      sx={{ marginBottom: "20px" }}
                      helperText={invalidLastName}
                      error={!!invalidLastName}
                      onChange={handleLastName}
                      data-testid="lastNameInputField"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextInput
                      required
                      label={staticContent?.emailLabel}
                      sublabel={ isASMuser ? `(${staticContent?.emailLabelHelp})` : ''}
                      inputType="text"
                      variant="standard"
                      value={email}
                      focused
                      placeholder={staticContent?.emailPlaceholder}
                      sx={{ marginBottom: "20px" }}
                      helperText={invalidEmail}
                      error={!!invalidEmail}
                      onChange={handleEmail}
                      data-testid="emailInputField"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextInput
                      required
                      label={staticContent?.confirmEmailLabel}
                      inputType="text"
                      variant="standard"
                      value={confirmEmail}
                      focused
                      placeholder={staticContent?.confirmEmailPlaceholder}
                      sx={{ marginBottom: "20px" }}
                      helperText={invalidConfirmEmail}
                      error={!!invalidConfirmEmail}
                      onChange={handleConfirmEmail}
                      data-testid="confirmEmailInputField"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <SelectDropdown
                      required
                      label={staticContent?.roleLabel}
                      options={roleList}
                      value={role}
                      onChangeHandler={(value) => handleRole(value)}
                      helperText={invalidRole}
                      error={!!invalidRole}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectDropdown
                      required
                      label={staticContent?.titleLabel}
                      options={jobList}
                      value={jobTitle}
                      onChangeHandler={(value) => handleJobTitle(value)}
                      helperText={invalidJobTitle}
                      error={!!invalidJobTitle}
                  />
                </Grid>
                <Grid item xs={12} sx={{mt: 6 , '@media screen and (max-width: 500px)': {mt : 3}}}>
                  { !isASMuser && !unvalidatedAccount && <Checkbox label={staticContent?.consentMessage} name="consent" value={promotions} onChange={(e) => {setPromotions(e?.target?.checked)}} /> }
                </Grid>
                <Box display='flex' justifyContent='space-evenly' width='40%' margin='40px auto'>
                  {
                    unvalidatedAccount?
                        <Button data-testid="nextRegisterPageBtn" buttonType="primary" onClick={handleNextForm}>{staticContent?.nextButtonLabel}</Button>
                        :
                        <Button data-testid="nextPageBtn" buttonType="primary" loading={processing} onClick={handleNext}>{staticContent?.nextButtonLabel}</Button>
                  }
                </Box>
              </Grid>
              :
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <UnvalidatedRegistration unvalidatedRegisterContent={unvalidatedRegisterContent}
                                         unvalidatedRegisterForm={unvalidatedRegisterForm}
                                         setUnvalidatedRegisterForm={setUnvalidatedRegisterForm}
                                         invalidUnvalidatedRegisterForm={invalidUnvalidatedRegisterForm}
                                         setInvalidUnvalidatedRegisterForm={setInvalidUnvalidatedRegisterForm}
                />
                <Box display='flex' justifyContent='space-evenly' width='40%' margin='40px auto'>
                  <Button data-testid="nextPageBtn" buttonType="primary" loading={processing} onClick={handleNext}>{staticContent?.nextButtonLabel}</Button>
                </Box>
              </Grid>
          }
        </ResetContainer>
      </>
  )
};
