import { useState } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { Button, ModalComponent, Spinner } from "../../../components";
import MessageBanner from "../../MessageBanner/MessageBanner";
import { useHistory } from "react-router-dom";
import {setIsOnboardingSuccessful, setUserStatus} from "../../../features/globalStates/globalStatesSlice";
import { useDispatch, useSelector } from "react-redux";
import { AddressCard } from "../../AddressCard/AddressCard";
import { affiliateOrgWithPerson, getSAPUserByEmail, getUserByEmail } from "../../../utils/UserService/UserApis";
import {ADDRESS_TYPE, USER_STATUS} from "../../../constants";
import {getUserErrorMessages} from "../../../utils/getErrorMessage";


const AffiliateOrgConfirmationModal = ({ closeModal, openModal, soldToAddress, billToAddress, orgId, dbaName, tabIndex, handleErrorMsg, onTabChange = () => {}}) => {
    const theme = useTheme();
    const history = useHistory();
    const [processing, setProcessing] = useState(false);
    const dispatch = useDispatch();
    const locale = useSelector((state) => state.globalStates.locale);
    const userId = useSelector((store) => store?.commerce?.userData?.userId) || '';
    const userEmail = useSelector((store) => store?.commerce?.userData?.email) || '';

    const handleAccountAssociation = async () => {
        try {
            setProcessing(true);
            const response = await affiliateOrgWithPerson(affiliateOrgWithPersonRequestBody);
            if (response?.status === 200) {
                const userRes = await dispatch(getUserByEmail({ emailAddress: userEmail })).unwrap();
                dispatch(getSAPUserByEmail({ emailAddress: userEmail })).unwrap();
                dispatch(setUserStatus(userRes?.userStatus));
                if (userRes?.userStatus == USER_STATUS.ACTIVE) {
                    dispatch(setIsOnboardingSuccessful(true));
                    history.push(`/${locale}/onboarding/success`);
                }
                closeModal();
            }
        } catch (error) {
            const errorMessage = getUserErrorMessages(error?.response?.data?.errorCode);
            handleErrorMsg(errorMessage)
        } finally {
            setProcessing(false);
        }
    };

    const affiliateOrgWithPersonRequestBody = {
        userId: userId,
        orgId: orgId
    }

    const OrgSelection = (
        <Box flexWrap="wrap" gap="23px">
            <Typography
                fontSize="23px"
                fontWeight="700"
                lineHeight="30px"
                textAlign={"left"}
                paddingBottom={"8px"}
            >
                Are you sure this is your organization?
            </Typography>
            <Box>
                <Box gap={"8px"} display={"grid"} paddingBottom={"10px"} width={'628px'}>
                    <MessageBanner
                        type='warning'
                        messageText={`Your account will be permanently associated with the existing details for ${dbaName}.`}
                        dataTestId="associateAccountWarning"
                    />
                    <MessageBanner
                        type='warning'
                        messageText='If you require a different billing address than the one listed, click cancel and choose “None of these matches my organization."'
                        dataTestId="associateBillingAddressWarning"
                    />
                </Box>
                <Spinner processing={processing} />
                <Box display="flex" minWidth={'600px'} mb={3} alignItems="flex-start" padding={"16px 24px"}>
                    {(
                        <AddressCard address={soldToAddress} dbaName={dbaName} addressType={ADDRESS_TYPE.SOLD_TO} hasTitle={true} sx={{width: '290px'}} />
                    )}
                    {(
                        <AddressCard address={billToAddress} dbaName={dbaName} addressType={ADDRESS_TYPE.BILL_TO} hasTitle={true} sx={{width: '290px'}} />
                    )}
                </Box>
            </Box>
            <Box width="100%" display="flex" justifyContent="center" gap="8px">
                <Button
                    buttonType='mds-secondary'
                    sx={{ padding: "16px 32px", textTransform: "none", }}
                    onClick={closeModal}
                >
                    Go back
                </Button>
                <Button
                    buttonType='mds-primary'
                    sx={{ padding: "16px 32px", textTransform: "none", }}
                    onClick={handleAccountAssociation}
                    color='primary'
                    variant="contained"
                >
                    Associate my account with this organization
                </Button>
            </Box>
        </Box>
    )

    return (
        <ModalComponent
            openModal={openModal}
            closeModalHandler={closeModal}
            style={{
                width: '675px',
                padding: '24px',
                display: 'flex',
                alignItems: 'flex-start',
                gap: '10px',
                overflowX: 'hidden',
                textAlign: 'left',
                color:"#191F2A",
                [theme.breakpoints.down('md')]: {
                    width: '400px',
                },
            }}
            modalContent={OrgSelection}
        />
    )
}

export default AffiliateOrgConfirmationModal
