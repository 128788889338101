import { createGlobalStyle } from "styled-components";
import reset from "styled-reset-advanced";

export default createGlobalStyle`
  ${reset};

  body {
    font-weight: normal;
    font-family: "Aeonik Regular", "Arial";
    color: #1E2846;
  }
  a {
    font-family: 'Aeonik Regular', 'Arial', 'Nunito', 'Helvetica Neue', 'Helvetica', sans-serif;
    color: #177EAB;
  }

  p {
      margin: 5px 0px;
  }

  ul, li {
    line-height: normal
  }
  
  div, span {
    font-family: 'Aeonik Regular', 'Nunito', 'Arial';
  }
  *, *::before, *::after {
    box-sizing: border-box;
  }
`;
