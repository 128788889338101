import { styled } from '@mui/material/styles';
import { Box, Typography } from "@mui/material";
import { EmailVerificationSucceedConstants } from "../../assets/constants/EmailVerificationConstants";
import { useHistory } from "react-router-dom";
import { Button } from "../Button";

const CustomContainer = styled('div')(({ theme: { breakpoints } }) => `
  margin-top: 48px;
  width: 640px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${[breakpoints.down('sm')]} {
    width: 90%;
    margin-top: 10px;
  }
`);

export const EmailVerificationSucceed = () => {
  const history = useHistory();
  const { headlineIcon: HeadlineIcon } = EmailVerificationSucceedConstants;

  return (
    <CustomContainer data-testid='emailVerificationSucceed'>
      <Box display='flex' alignItems='flex-start' gap='8px' >
        <img src={HeadlineIcon} alt={'check badge icon'} style={{ paddingTop: '5px' }} />
        <Typography variant='h3' color='#079AE0' >
          { EmailVerificationSucceedConstants.headline }
        </Typography>
      </Box>
      <Typography fontFamily="Aeonik Regular" fontSize="16px" fontWeight="400">
        { EmailVerificationSucceedConstants.message }
      </Typography>
      <Box display="flex" justifyContent="flex-start">
        <Button
          sx={{textTransform: 'none', padding: '16px 32px' }}
          buttonType='mds-primary'
          onClick={() => { history.push(`/home`); }}
        >
          <Typography fontFamily="Aeonik Regular" fontSize="16px" fontWeight="700">
            { EmailVerificationSucceedConstants.callToActionButtonLabel }
          </Typography>
        </Button>
      </Box>
    </CustomContainer>
  );
};
