import axios from "axios";
import { OktaAuth, urlParamsToObject } from "@okta/okta-auth-js";
import { oidcConfig } from "../config";

let expiryTime;
const baseUrl = process.env.COMMERCE_BASE_URL;

const oktaAuthEnabled = process.env.IS_SAP_OKTA_AUTH_ENABLED === "true";
const shouldUseOktaAuth = (params) => {
  let isEmployee = localStorage.getItem("employeeUser") || (params && params["asmuser"]);
  return oktaAuthEnabled && !isEmployee;
}

const getBaseUrl = (params) => {
  const baseSiteId = window.location.pathname.includes("en-DE") ? process.env.COMMERCE_BASE_SITE_ID_DE : process.env.COMMERCE_BASE_SITE_ID;
  let conditionalBaseUrl = window.location.hostname === "localhost"
                      ? `${process.env.COMMERCE_API_URL}/${baseSiteId}` 
                      : `/modernawebservices/v2/${baseSiteId}/`;

  if (shouldUseOktaAuth(params)) {
    conditionalBaseUrl = window.location.hostname === "localhost"
      ? `${process.env.COMMERCE_NEW_AUTH_API_URL}/${baseSiteId}`
      : `/modernawebservices/v2-new-auth/${baseSiteId}/`
  }

  return conditionalBaseUrl;
}

const commerceAuthAxiosInstance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "application/json"
  }
});

const getOktaAuth = (() => {
  const { state } = urlParamsToObject(window.location.search);
  return new OktaAuth(
    Object.assign({}, oidcConfig, {
      state,
    })
  );
});

commerceAuthAxiosInstance.interceptors.request.use(async req => {
  if (shouldUseOktaAuth(req.params)) {
    // if using okta auth, simply add okta auth header and direct to new endpoint
    let oktaAccessToken = getOktaAuth().getAccessToken();
    req.headers["Authorization"] = `Bearer ${oktaAccessToken}`;
    req.baseURL = getBaseUrl();
    return req;
  }

  let conditionalBaseUrl = getBaseUrl(req.params);
  let token = JSON.parse(localStorage.getItem("commerceNewAuthToken"));
  let oktaAccessTokenObj = JSON.parse(localStorage.getItem("okta-token-storage"));
  let userId = req.params?.userId === undefined ? '' : req.params?.userId.toLowerCase();
  req.params = req.params || {};
  const empInfo = localStorage.getItem("employeeUser") ? JSON.parse(localStorage.getItem("employeeUser")) : '';
  const empEmail = empInfo?.uid;
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    auth:{
      username: process.env.COMMERCE_OAUTH_ID,
      password: process.env.COMMERCE_OAUTH_SECRET
    },
    data: `grant_type=custom&clientId=${userId}&accessToken=${oktaAccessTokenObj?.accessToken?.accessToken}`,
    url: window.location.hostname === "localhost" ? `${baseUrl}/authorizationserver/oauth/token` : `/authorizationserver/oauth/token`,
  }
  if(empEmail) {
    req.params['asmuser'] = `${empEmail}`; //pass employee ID for emp APIs
  }
  const now = new Date();
  const commerceAuthTokenExpiry = localStorage.getItem("commerceAuthTokenExpiry")
  const isExpired = new Date(commerceAuthTokenExpiry).getTime() < now.getTime();

  if(!token || isExpired){
    if(token && isExpired) {
      localStorage.clear();
      if (window.location.pathname.includes("en-DE")) {
        window.location.href = "/en-DE"
      } else {
        window.location.href = "/";
      }
    }
    const rs = await axios.request(options); 
    const commerceNewAuthToken = rs.data;
    expiryTime = new Date();
    expiryTime.setSeconds(expiryTime.getSeconds() + commerceNewAuthToken?.expires_in);
    localStorage.setItem("commerceAuthTokenExpiry", expiryTime);
    localStorage.setItem("commerceNewAuthToken", JSON.stringify(commerceNewAuthToken));
    req.headers["Authorization"] = `Bearer ${commerceNewAuthToken?.access_token}`;
    req.baseURL = conditionalBaseUrl;
    return req;
  }  
  
  if(token && !isExpired) {
    req.headers["Authorization"] = `Bearer ${token?.access_token}`;
    req.baseURL = conditionalBaseUrl;
    return req;
  }
});

export default commerceAuthAxiosInstance;
