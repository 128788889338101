import React from "react";
import { styled } from '@mui/material/styles';
import { Button } from "../";
import { BackComponent } from "../";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, useTheme } from "@mui/material";

const PreBookStickyFooterContainer = styled('div')(({ theme: { palette: { primary }, breakpoints }}) => `
    position: fixed;
    padding:8px 140px;
    bottom: 0px;
    height: 64px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    background-color: ${primary.main};
    z-index: 1000000;
    align-items:center;
    ${[breakpoints.down('sm')]} {
        padding:8px 24px;
        justify-content: space-between;
    };
`);

export const PreBookStickyFooter = ({
    buttonLabel,
    buttonStyle,
    buttonType,
    buttonDisabled = false,
    onClickHandler,
    totalDoses,
    backClickHandler,
    processing,
    backButtonDataHref
}) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const clickHandler = () => {
        onClickHandler();
      };
    return (
        <PreBookStickyFooterContainer>
            {mobile && <BackComponent id="backBtn" data-testid="backBtn" onClickHandler={backClickHandler} {...backButtonDataHref && {"data-link-href":backButtonDataHref}} /> }
            <Box variant={'p'} textAlign={'center'} id="stickyFooter" px={2} sx={{color: "neutral.white"}}>{totalDoses} Added</Box>
            { buttonLabel && <Button
                id="customBtn"
                data-testid="customBtn"
                buttonType={buttonType ? buttonType : "primary"}
                disabled={buttonDisabled}
                onClick={clickHandler}
                icon={false}
                sx={buttonStyle}
                loading={processing}
              >
                  {buttonLabel}
            </Button>}
            
        </PreBookStickyFooterContainer>

    )
}