import HomePage from "../../pages/Homepage";
import NewHomePage from "../../pages/NewHomepage";
import { ModalProvider } from "../../providers/ModalProvider";
import { useSelector } from "react-redux";

export const FallbackHomePage = () => {
    const locale = useSelector((state) => state.globalStates.locale);
    const isNewHomepageEnabled = process.env.IS_NEW_HOMEPAGE_ENABLED === 'true' && locale === 'en-US';
  
    return isNewHomepageEnabled ? (
      <ModalProvider>
        <NewHomePage />
      </ModalProvider>
    ) : (
      <HomePage />
    );
  };
