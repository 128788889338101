import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { Button, TextInput } from "..";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { validateFields } from "../../utils/Helper";
import { BLOCKS } from "@contentful/rich-text-types";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";
import { HandlerBarTemplateHandler } from "../../utils/Helper";
import { loadNewPasswordStaticContent } from '../../features/contentful/contentfulThunkApi';
import { updateUserApiNoAuth } from "../../utils/commerceUsersApi";
import { Typography } from "@mui/material";

const StyledTextInput = styled(TextInput)`
  height: 70px;
  width: 100%;
  margin-top: 10% !important;
`;

const ButtonContainer = styled('div')(({ theme: { breakpoints } }) => `
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  margin: 15% auto 70px auto;
  ${[breakpoints.down('sm')]} {
    flex-direction: column-reverse;
    height: 96px;
    justify-content: space-between;
    width: 50%;
    margin-bottom: 120px;
    margin-top: 70px;
  }
`);

const PasswordList = styled('ul')(({ theme: { palette: { primary }, breakpoints }}) => `
  list-style-type: initial;
  color: ${primary.main};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-family: 'Aeonik Regular';
  ${[breakpoints.down('sm')]} {
    margin-top: 24px;
    margin-bottom: 30px;
  }
`);

export const NewPassword = ({ setComp, userEmail, isASMuser }) => {
  const [password, setPassword] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [showpassword, setShowpassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmpassword] = useState(false);
  const { newPasswordContent } = useSelector((store) => store?.contentful);
  const { staticContent = [] } = newPasswordContent;
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const history = useHistory();
  const { oktaAuth } = useOktaAuth() || '';
  const locale = useSelector((state) => state.globalStates.locale);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const recoveryToken = params.get("token");

  const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
  const invalidPasswordFormatErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "INVALID_PASSWORD_FORMAT");
  const invalidPasswordFormatError = invalidPasswordFormatErrorData && invalidPasswordFormatErrorData[0]?.fields?.message;

  const mismatchPasswordFormatErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "PASSWORD_MISMATCH");
  const mismatchPasswordFormatError = mismatchPasswordFormatErrorData && mismatchPasswordFormatErrorData[0]?.fields?.message;


  useEffect(() => {
    if (Object.keys(newPasswordContent).length === 0) {
      dispatch(loadNewPasswordStaticContent({title: isASMuser ? "Set Password Component" : "Reset Password Component"}));
    }
  },[]);

  const hbrHtmlEl = HandlerBarTemplateHandler({ email: userEmail}, staticContent?.message?.content[0]) || null;

  const verifyTokenAndSetNewPassword = () => {
    setProcessing(true);
    if(recoveryToken) {
        oktaAuth.verifyRecoveryToken({
        recoveryToken: recoveryToken
      })
      .then(transaction => {
        if (transaction.status === 'PASSWORD_RESET') {
          return transaction.resetPassword({
            newPassword: password
          });
        }
      }).then((transaction) => {
        if (transaction.status === 'SUCCESS') {
          if(isASMuser){
            const activateUser = async () => {
              try {
                const data = { "isASMuser": true };
                const resp = await updateUserApiNoAuth(transaction?.user?.id, data);
                if(resp?.status === 200) {
                  setProcessing(false);
                  setComp("setPasswordResetSuccess");
                }
              }
              catch (err) {
                setProcessing(false);
              } finally {
                setProcessing(false);
              }
            }
            activateUser();
          } else {
            setProcessing(false);
            setComp("resetSuccess");
          }
        } else {
          setProcessing(false);
          const error = 'We cannot handle the ' + transaction.status + ' status';
          throw error;
        }
      }).catch((error) => {
        setProcessing(false);
        if (error?.errorCode === "E0000080") {
          setInvalidPassword(error?.errorSummary)
        } else if (error?.errorCode === "E0000105"){
          setComp("tokenExpired");
        } else {
          setInvalidPassword(error?.errorSummary)
        }
      });
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    history.push(`/${locale}/`);
  };

  const handleReset = (e) => {
    e.preventDefault();
    if(validate()) {
      verifyTokenAndSetNewPassword();
    }
  };
  const handlePassword = (event) => {
    setPassword(event.target.value);
    setInvalidPassword("");
  };

  const handleClickShowPassword = () => {
    setShowpassword(!showpassword);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    setInvalidConfirmPassword("");
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmpassword(!showconfirmpassword);
  };

  const validate = () => {
    let isValid = true;
    let passwordError;
    let confPasswordError;

    if (!password) {
      isValid = false;
      passwordError = "Please enter your password.";
      setInvalidPassword(passwordError);
    }

    if (!validateFields("password", password)) {
      isValid = false;
      passwordError = invalidPasswordFormatError;
      setInvalidPassword(passwordError);
    }

    if (!confirmPassword) {
      isValid = false;
      confPasswordError = "Please enter confirm password.";
      setInvalidConfirmPassword(confPasswordError);
    }

    if (confirmPassword !== "undefined") {
      if(password !== confirmPassword){
        isValid = false;
        confPasswordError = mismatchPasswordFormatError;
        setInvalidConfirmPassword(confPasswordError);
      }
    }

    return isValid;
  };

  const options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => <div>{children}</div>
    }
  };
  return (
    <div data-testid="NewPasswordTest">
      <Typography variant={'h1'} textAlign='center' color='primary.main' id="headline" data-testid="HeadlineTest">{ isASMuser ? staticContent?.headline : 'Reset your Password' }</Typography>
      <Typography variant={'p1'} component={'span'} textAlign='center' mt='20px' id="resetPassText" data-testid="ResetPassTextTest">{ isASMuser ? documentToReactComponents(staticContent?.message?.content[0]) : hbrHtmlEl }</Typography>
      <PasswordList id="passwordList" data-testid="PasswordListTest">
        {documentToReactComponents(staticContent?.message?.content[1], options)}
      </PasswordList>
      <StyledTextInput
        required
        focused
        placeholder="Enter password"
        error={!!invalidPassword}
        value={password}
        inputType={showpassword ? 'text' : 'password'}
        id="password"
        data-testid="PasswordTest"
        label="Password"
        helperText={invalidPassword}
        variant="standard"
        showpassword={showpassword}
        handleClickShowPassword={handleClickShowPassword}
        onChange={handlePassword}
        fieldType="password"
      />
      <StyledTextInput
        required
        focused
        placeholder="Re-enter password"
        error={!!invalidConfirmPassword}
        value={confirmPassword}
        inputType={showconfirmpassword ? 'text' : 'password'}
        id="confirmPassword"
        data-testid="ConfirmPasswordTest"
        label="Confirm Password"
        helperText={invalidConfirmPassword}
        variant="standard"
        showpassword={showconfirmpassword}
        handleClickShowPassword={handleClickShowConfirmPassword}
        onChange={handleConfirmPassword}
        fieldType="password"
      />
      { isASMuser ? 
        <ButtonContainer>
          <Button 
            buttonType="primary" 
            id="submitButton"
            data-testid="SubmitButtonTest"
            onClick={handleReset}
            loading={processing}
            disabled={processing}>
              {staticContent?.resetButtonLabel}
            </Button>
        </ButtonContainer> : 
        <ButtonContainer>
          <Button 
            buttonType="secondary" 
            id="cancelButton"
            data-testid="CancelButtonTest"
            onClick={handleCancel}>
              {staticContent?.cancelButtonLabel}
          </Button>
          <Button 
            buttonType="primary" 
            id="resetButton"
            data-testid="ResetButtonTest"
            onClick={handleReset}
            loading={processing}
            disabled={processing}>
              {staticContent?.resetButtonLabel}
          </Button>
        </ButtonContainer>
      }
    </div>
  )
};
