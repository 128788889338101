import React, { useState , useRef , useEffect} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material/styles';
import { Modal } from "../Modal";
import { Button } from "../Button"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from "react-redux";
import { loadSessionHeaderStaticContent } from "../../features/contentful/contentfulThunkApi";  
import { useHistory } from 'react-router-dom';
import { setSessionHeaderOpen } from "../../features/globalStates/globalStatesSlice";
import { endImpersonationSession } from "../../utils/Helper"
import { useIdleTimer } from "react-idle-timer";
import { Box, Typography, useTheme } from "@mui/material";

const LeftContainer = styled('div')(({ theme: { breakpoints } }) => `
${[breakpoints.down('sm')]} {
    width: 100%;
  }
`);

export const SessionHeader = () =>{
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const [openModal, setOpenModal] = useState(false);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { loading, sessionHeaderContent } = useSelector((store) => store?.contentful)
    const { staticContent } = sessionHeaderContent
    const dispatch = useDispatch();
    const history = useHistory();
    const locale = useSelector((state) => state.globalStates.locale);
    const openSessionHeader = useSelector((state) => state.globalStates.sessionHeaderOpen)
    const onIdle = () => {
        endImpersonationSession()
        history.push(`/${locale}/admin`)
    }
    
    useIdleTimer({
      onIdle,
      timeout: 3600_000,
      throttle: 500,
      crossTab: true,
      syncTimers:200
    })
  
  
    useEffect(() => {
        if (Object.keys(sessionHeaderContent).length === 0) {
          dispatch(loadSessionHeaderStaticContent())
        }
      }, [])


    const endSessionHandler = () =>{
        setOpenModal(!openModal)
    }

    const cancelModal = () =>{
        setOpenModal(!openModal)
    }

    const condirmEndSession = () => {
        endImpersonationSession();
        history.push(`/${locale}/admin`)
    }


    return(
        <>
        {openModal &&
        <Modal
            title={staticContent?.endSessionPromptHeader}
            primaryButtonLabel={staticContent?.endSessionPromptProceedButtonLabel}
            secondaryButtonLabel={staticContent?.endSessionPromptCancelButtonLabel}
            closeModalHandler={ cancelModal }
            openModal={ openModal } 
            confirmationHandler={ condirmEndSession }
        />}
        <div style={{"position":"relative"}}>
        <Box zIndex='100000000' position='fixed' top='0px' display='flex' justifyContent='space-between' width='100vw' padding={{xs:'24px', sm:'24px 40px'}} backgroundColor='neutral.main'>
            <LeftContainer>
                <Box display='flex' color='neutral.white' justifyContent={{xs:'space-between',sm: 'start'}}>
                    <Typography variant={'p1'} alignItems={'center'} lineheight='24px' color='neutral.white'>{staticContent?.sessionMessage}&nbsp;<Typography variant={'h5'} component={"span"}>{user?.name || ""}&nbsp;</Typography></Typography>
                    <div onClick={() =>{dispatch(setSessionHeaderOpen())}}>{openSessionHeader ? <ExpandLessIcon fontSize="medium"/> : <ExpandMoreIcon fontSize="medium"/>}</div>
                </Box>
                { openSessionHeader && <Box display='flex' color='neutral.white' pt='25px' flexDirection={{xs:'column', sm:'unset'}}>
                    <Box display={'flex'} alignItems={'center'} mr={'40px'}>
                        <Typography variant={'h5'} color='neutral.white'>{staticContent?.emailLabel} &nbsp;<Typography variant={'p1'}>{user?.email}</Typography></Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} mr={'40px'}>
                        <Typography variant={'h5'} color='neutral.white'>{staticContent?.accountNameLabel} &nbsp;<Typography variant={'p1'}>{user?.orgUnit.legalAccountName}</Typography></Typography>
                    </Box>
                    {mobile && 
                    <div style={{"paddingTop":"30px" , "alignSelf":"center"}}>
                    <Button onClick={endSessionHandler}>
                        {staticContent?.endSessionButtonLabel}
                    </Button>
                    </div>
                    }
                </Box>}
            </LeftContainer>
            {mobile ? "" :
            <div>
                <Button onClick={endSessionHandler}>
                    {staticContent?.endSessionButtonLabel}
                </Button>
            </div>}
        </Box>
        </div>
        </>
    )
}