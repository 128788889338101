import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import dayjs from "dayjs";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import {ArrowDropDownOutlined, ArrowDropUpOutlined, KeyboardArrowRightOutlined } from "@mui/icons-material";
import { StatusIndicators} from '..';
import { NoRowsOverlay } from "../NoRowsOverlay";
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip'
import { useDispatch, useSelector } from "react-redux";
import { DisclaimerMessageFooter } from "../DisclaimerMessageFooter";
import { fetchOrders } from "../../features/commerce/commerceThunkApi";
import { loadOrderHistoryTableComponent } from "../../features/contentful/contentfulThunkApi";
import DataGridPagination from "../Pagination/DataGridPagination";

const DataGrid = lazy(() => import('../DataGrid'));

const mapStatus = (() => {
    const statuses = {
        'DELIVERED': 'Delivered',
        'PENDING': 'Pending'
    };

    return (value) => statuses[value] ?? ''
})();

export const HistoricOrderHistoryList = ({ userId, handleErrorMsg, setLoading }) => {
    const { orderHistoryTableComponent } = useSelector((store) => store?.contentful);
    const locale = useSelector((state) => state.globalStates.locale);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [orderTableData, setOrderTableData] = useState([]);
    const isNewOrderingEnabled = process.env.IS_NEW_ORDER_EXPERIENCE_ENABLED === 'true';

    useEffect(() => {
    if (orderHistoryTableComponent && Object.keys(orderHistoryTableComponent).length === 0)
            dispatch(loadOrderHistoryTableComponent());
    }, [orderHistoryTableComponent, dispatch]);

    useEffect(() => {
        setLoading(true)
        dispatch(fetchOrders({ userId, statuses:'PENDING,DELIVERED' })).unwrap()
            .then((data) => {
                const tabledata = data?.map((item, index) => {
                    return {
                        id: index + 1,
                        modernaId: item?.code,
                        referenceId: item?.salesOrderNumber ? item?.salesOrderNumber : '-',
                        datePlaced: item?.placed?.split('T')[0],
                        poNumber: item?.purchaseOrderNumber ? item?.purchaseOrderNumber : '-',
                        subtotal: item?.total?.formattedValue,
                        subtotalValue: item?.total?.value,
                        deliveryLocation: `${item?.deliveryLocation?.companyName} - ${item?.deliveryLocation?.formattedAddress}`,
                        status: item?.status
                    }
                })
                setLoading(false)
                setOrderTableData(tabledata)
            })
            .catch((error) => {
                handleErrorMsg(error);
            })
    }, []);

    const mobileColumns = [
        {
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: isNewOrderingEnabled?"Order Id":`${orderHistoryTableComponent?.modernaIdColumnLabel?.toUpperCase()}`,
            field: 'modernaId',
            renderCell: ({value}) => {
                return <Link
                    to={`/${locale}/order/${value}`}
                    underline={'none'}
                    sx={{
                        color: 'primary.main',
                        fontFamily: 'Aeonik Bold',
                        '&:hover':{
                            color: 'primary.darker',
                        }
                    }}
                    display={'flex'}
                    variant={'link'}>
                    {value}
                </Link>;
            }
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: true,
            headerName: `${orderHistoryTableComponent?.orderStatusColumnLabel?.toUpperCase()}`,
            field: 'status',
            flex: 1,
            renderCell: ({value}) => {
                return <StatusIndicators status={value} children={mapStatus(value)}></StatusIndicators>
            }
        },
        {
            field: 'null',
            headerName: '',
            disableColumnMenu: true,
            sortable: false,
            width: 32,
            renderCell: ({row}) => {
                return <Link
                    to={`/${locale}/order/${row.modernaId}`}
                    underline={'none'}
                    sx={{
                        svg: {
                            color: 'secondary.main',
                            '&:hover':{
                                color: 'primary.darker',
                            }
                        }
                    }}
                    display={'flex'}
                    variant={'link'}>
                    <KeyboardArrowRightOutlined/>
                </Link>;
            }
        }
    ]

    const desktopColumns = [
        {
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: isNewOrderingEnabled?"Order Id":`${orderHistoryTableComponent?.modernaIdColumnLabel?.toUpperCase()}`,
            field: 'modernaId',
            renderCell: ({value}) => {
                return <Link
                    to={`/${locale}/order/${value}`}
                    underline={'none'}
                    sx={{
                        color: 'primary.main',
                        fontFamily: 'Aeonik Bold',
                        '&:hover':{
                            color: 'primary.darker',
                        }
                    }}
                    display={'flex'}
                    variant={'link'}>
                    {value}
                </Link>;
            }
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: false,
            headerName: `${orderHistoryTableComponent?.referenceIdColumnLabel?.toUpperCase()}`,
            field: 'referenceId',
            flex: .75
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            headerName: `${orderHistoryTableComponent?.subtotalColumnLabel?.toUpperCase()}`,
            field: 'subtotalValue',
            renderCell: ({row}) => (row.subtotal),
            flex: 1
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: false,
            headerName: `${orderHistoryTableComponent?.poNumberColumnLabel?.toUpperCase()}`,
            field: 'poNumber',
            flex: 1
        },
        {
            disableColumnMenu: true,
            headerName: `${orderHistoryTableComponent?.datePlacedColumnLabel?.toUpperCase()}`,
            field: 'datePlaced',
            flex: 1,
            renderCell: ({value}) => dayjs(value, 'YYYY-MM-DD').format('MMMM D, YYYY')
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: false,
            headerName: `${orderHistoryTableComponent?.deliveryLocationColumnLabel?.toUpperCase()}`,
            field: 'deliveryLocation',
            flex: 1,
            renderCell: ({ value }) => (
                <Tooltip title={value}>
                      <span style={{display:'block', overflow: "hidden", textOverflow: "ellipsis", whiteSpace: 'nowrap' }}>
                          {value}
                      </span>
                </Tooltip>)
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: true,
            headerName: `${orderHistoryTableComponent?.orderStatusColumnLabel?.toUpperCase()}`,
            field: 'status',
            flex: 1,
            renderCell: ({value}) => {
                return <StatusIndicators status={value} children={mapStatus(value)}></StatusIndicators>
            }
        },
        {
            field: 'null',
            headerName: '',
            disableColumnMenu: true,
            sortable: false,
            width: 32,
            renderCell: ({row}) => {
                return <Link
                    to={`/${locale}/order/${row.modernaId}`}
                    underline={'none'}
                    sx={{
                        svg: {
                            color: 'secondary.main',
                            '&:hover':{
                                color: 'primary.darker',
                            }
                        }
                    }}
                    display={'flex'}
                    variant={'link'}>
                    <KeyboardArrowRightOutlined/>
                </Link>;
            }
        }
    ]

    const [apiRef, setApiRef] = useState(null);

    return (
        <>
            <Box mx={{xs:'32px', lg:'100px'}} my={'60px'}>
                <Typography variant={'h2'} mb={'10px'}>{isNewOrderingEnabled?"2023-2024 Spikevax Orders":orderHistoryTableComponent?.headerLabel}</Typography>
                <Suspense>
                    <DataGrid
                        onApiRef={(ref) => setApiRef(ref)}
                        getRowId={(rows) => rows.id}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick={true}
                        sx={{
                            '.MuiDataGrid-iconButtonContainer': {
                                visibility: 'visible',
                            },
                            '.MuiDataGrid-sortIcon': {
                                opacity: 'inherit !important',
                            },
                            '.MuiDataGrid-virtualScroller': {
                                overflow: 'hidden !important',
                            },
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: "normal",
                                lineHeight: "20px"
                            }
                        }}
                        slots={{
                            pagination: DataGridPagination,
                            noRowsOverlay: NoRowsOverlay,
                            columnSortedAscendingIcon: ArrowDropUpOutlined,
                            columnSortedDescendingIcon: ArrowDropDownOutlined
                        }}
                        slotProps={{
                            noRowsOverlay: {
                                orderTableData
                            },
                            pagination: {
                                disabled: false
                            }
                        }}
                        getCellClassName={({field}) => ['confirmationNumber', 'date', 'poNumber', 'frieghtAmt', 'total'].includes(field) ? 'nonSelectable' : ''}
                        hideFooterSelectedRowCount={true}
                        checkboxSelection={false}
                        autoHeight={true}
                        columns={mobile ? mobileColumns : desktopColumns}
                        sortingOrder={['desc', 'asc']}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'datePlaced', sort: 'desc' }],
                            },
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        rows={orderTableData}/>
                </Suspense>
            </Box>
            <DisclaimerMessageFooter
                disclaimerMessage={orderHistoryTableComponent?.disclaimerMessage}
        />
    </>
    );
};
