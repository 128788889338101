import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import { loadOrderSummaryComponentContent } from "../../features/contentful/contentfulThunkApi";
import { Button, Checkbox } from "..";
import CustomCard from "../CustomCardComponent/CustomCard";
import { formattedPrice } from "../../utils/Helper";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
    setCanAccessOrderConfirmationPage,
    setReservationConversionID
} from "../../features/globalStates/globalStatesSlice";
import { createOrder } from "../../utils/OrderService/OrderApi";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { PAYMENT_OPTIONS, UNAVAILABLE } from "../../constants";
import { PayLater, PaymentOptions } from "../Payments/paymentOptions";
import { TypographyList, TypographyH2 } from "../../assets/fonts/typographyTheme";
import { TAX_PENDING } from "../../constants";

export const SummaryItem = ({ label, value, sx }) => (
    <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mb="8px"
        sx={sx}
    >
        <TypographyList id="label">{label}</TypographyList>
        <TypographyList id="value">
            {value}
        </TypographyList>
    </Box>
);

const isPaymentsEnabled = process.env.IS_PAYMENTS_ENABLED === 'true';
const isPromptPayEnabled = process.env.IS_PROMPT_PAY_ENABLED === 'true';

export const InvoiceSummary = ({summaryData, isOrderDetailsPage=false}) => {
    const showContractPrice = useSelector((state) => state.globalStates.showContractPrice);
    const { totalDoses, listPrice, contractPrice, taxes, totalPrice, promptPayEligible, promptPayTerms, totalContractPriceWithPromptPay } = summaryData;
    const paymentType = summaryData?.paymentType;
    const totalCarton = (totalDoses / 10);
    const isTaxPending = summaryData?.isTaxPending ?? true;
    const isEligibleForPromptPay = isPromptPayEnabled && promptPayEligible;

    const getPromptPayText = () => {
        if(isOrderDetailsPage && paymentType === PAYMENT_OPTIONS?.PAY_NOW){
            return `Total with prompt pay applied`
        }
        return promptPayTerms?.timePeriod ? `If paid within ${promptPayTerms?.timePeriod} days of invoicing**` : "If paid within your payment terms**"
    }

    return (
      <Box data-testid={'invoice-summary'}>
        <SummaryItem
          label="Total Doses"
          value={totalDoses?.toLocaleString()}
          sx={{ marginBottom: "0px" }}
        />
        <SummaryItem
          label="Cartons"
          value={`${totalCarton.toLocaleString()} carton${totalCarton !== 1 ? 's' : ''}`}
          sx={{
            "#label": { visibility: "hidden" },
            "#value": { fontSize: "14px" },
          }}
        />
        <SummaryItem
          label="List Price"
          value={formattedPrice(listPrice)}
          sx={{ "#value": { textDecoration: showContractPrice ? "line-through" : "none" } }}
        />
        <SummaryItem
          label="Contract Price"
          value={showContractPrice ? formattedPrice(contractPrice) : UNAVAILABLE}
        />
        <SummaryItem label="Taxes*" value={isTaxPending ? TAX_PENDING : formattedPrice(taxes)} />
        <SummaryItem label="Shipping" value="Free" />
        <Divider
          orientation="horizontal"
          variant="middle"
          sx={{ marginBottom: 2, marginTop: 1 }}
        />
        <SummaryItem
          label="Total"
          value={formattedPrice(totalPrice)}
          sx={{
            "#value, #label": {
              fontWeight: 700,
              lineHeight: "28px",
              fontSize: "20px",
            },
          }}
        />
          {
              isEligibleForPromptPay &&
              <SummaryItem
                  label={getPromptPayText()}
                  value={formattedPrice(totalContractPriceWithPromptPay)}
                  sx={{
                      "#value, #label": {
                          fontWeight: 400,
                          lineHeight: "21px",
                          fontSize: "16px",
                      },
                  }}
              />
          }
      </Box>
    );
}

export const OrderSummaryCard = ({ 
    userId,
    handleErrorMsg,
    setProcessing,
    summaryData,
    paymentOption,
    setPaymentOption,
    paymentMethodsList,
    setPaymentMethodsList,
    setIsSnackbarOpen,
    setSnackbarMessage
}) => {
    const [checked, setChecked] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const locale = useSelector((state) => state.globalStates.locale);
    const checkoutCartData = useSelector((state) => state?.commerce?.checkoutCartData);
    const cartId = checkoutCartData?.cart?.cartId;
    const { orderSummaryComponentContent } = useSelector((store) => store.contentful);
    const selectedPaymentCardInfo = useSelector((store) => store?.commerce?.selectedPaymentMethod);
    const reservationID = useSelector(store => store?.globalStates?.reservationConversionID);

    const handlePaymentSelection = (event) => {
        setPaymentOption(event.target.value);
    };

    const mapCartLineItemsToSnapshots = (checkoutCartData) => {
        const taxSnapshotMap = {};
        const priceSnapshotMap = {};
    
        checkoutCartData?.cart?.cartLineItems?.forEach((item) => {
            taxSnapshotMap[item.cartLineItemId] = item.taxSnapshotId;
            priceSnapshotMap[item.cartLineItemId] = item.priceSnapshotId;
        });
    
        return {
            cartLineItemIdToTaxSnapshotId: taxSnapshotMap,
            cartLineItemIdToPriceSnapshotId: priceSnapshotMap
        };
    };

    const priceAndTaxSnapshots = mapCartLineItemsToSnapshots(checkoutCartData);

    const createOrderRequestBody = {
        userId: userId,
        cartId: cartId,
        cartLineItemIdToTaxSnapshotId: priceAndTaxSnapshots.cartLineItemIdToTaxSnapshotId,
        cartLineItemIdToPriceSnapshotId: priceAndTaxSnapshots.cartLineItemIdToPriceSnapshotId,
        ...(isPaymentsEnabled && {
            paymentType: paymentOption,
            paymentMethodId: selectedPaymentCardInfo?.paymentMethodId,
        }),
    }

    const handlePlaceOrder = async () => {
        if (!checked) {
            setErrorMsg("Please accept the agreements above to place this order");
            return;
        }

        setProcessing(true);
        setErrorMsg("");
        try {
            const response = await createOrder(createOrderRequestBody);
            if (response?.status === 201) {
                setProcessing(false);
                dispatch(setCanAccessOrderConfirmationPage(true));
                if(!!reservationID){
                    dispatch(setReservationConversionID(""));
                }
                history.push(`/${locale}/order-confirm`);
            }
        } catch (error) {
            setProcessing(false);
            const response = error?.response?.data;
            const errorMessage = getErrorMessage(response?.errorCode);
            handleErrorMsg(errorMessage);
        } finally {
            setProcessing(false);
        }
    };

    useEffect(() => {
        if (
            orderSummaryComponentContent &&
            Object.keys(orderSummaryComponentContent).length === 0
        ) {
            dispatch(loadOrderSummaryComponentContent());
        }
    }, [orderSummaryComponentContent]);

    const linkMap = {
        "Terms of Use Internal Link": "/terms-of-use",
        "Privacy Policy Internal Link": "/privacy-policy",
        "Terms and Conditions of Sale Internal Link":
            "/terms-and-conditions-of-sale",
    };

    const options = {
        renderNode: {
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                const title = node.data.target.fields.title;
                // Use the title to find the correct URL
                const url = linkMap[title] || "";

                return (
                    <Box component={"span"}>
                        <Link to={`/${locale}${url}`} target="_blank">
                            <Typography variant={"link"} fontSize="16px">
                                {children}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <Typography variant={"link"} fontSize="16px">
                    {children}
                </Typography>
            ),
        },
    };

    return (
        <Box minWidth={"350px"} maxWidth={"486px"}>
            <CustomCard
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px",
                }}
            >
                <TypographyH2 mb="24px" textTransform="capitalize">Summary</TypographyH2>
                <InvoiceSummary summaryData={summaryData}/>
                <Box gap="16px" data-testid={'payment-method'} mt={2} mb={isPaymentsEnabled ? 0 : 2}>
                    <TypographyH2>Payment Method</TypographyH2>
                </Box>
                {
                    isPaymentsEnabled ?
                        <PaymentOptions
                            handlePaymentSelection={handlePaymentSelection}
                            paymentOption={paymentOption}
                            handleErrorMsg={handleErrorMsg}
                            paymentMethodsList={paymentMethodsList}
                            setProcessing={setProcessing}
                            setPaymentMethodsList={setPaymentMethodsList}
                            setIsSnackbarOpen={setIsSnackbarOpen}
                            setSnackbarMessage={setSnackbarMessage}
                        />
                        :
                        <PayLater />
                }

                <Box>
                    <Checkbox
                        sx={{ alignItems: "flex-start", marginBottom: "24px" }}
                        name="privacyPolicyConsent"
                        label={documentToReactComponents(
                            orderSummaryComponentContent?.reservationPrivacyPolicyConsentMessage,
                            options
                        )}
                        value={checked}
                        data-testid="privacyPolicyConsentCheckbox"
                        onChange={() => {
                            setChecked((prev) => !prev);
                            setErrorMsg("");
                        }}
                    />
                </Box>
                {errorMsg && (
                    <Typography 
                        color="#E22929" 
                        fontFamily="Aeonik Regular" 
                        fontSize="16px" 
                        fontWeight="400" >
                    {errorMsg}
                    </Typography>
                )}
                <Button
                    buttonType="mds-primary"
                    sx={{ textTransform: "none", marginTop: "24px" }}
                    onClick={handlePlaceOrder}
                >
                    Place Order
                </Button>
            </CustomCard>
        </Box>
    );
};
