import React, { useState , useRef , useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import MenuIcon from '@mui/icons-material/Menu';
import { Button as SignoutButton } from "../Button";
import { useOktaAuth } from '@okta/okta-react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from "react-redux";
import { loadHeaderStaticContent } from "../../features/contentful/contentfulThunkApi";
import { Avatar, Box, useTheme } from "@mui/material";
import germanyFlag from '../../assets/images/germany-flag.svg';
import usaFlag from '../../assets/images/usa-flag.svg';

const LinkContainer = styled("div")(
  ({ linksCount, theme: { palette: { neutral }, breakpoints } }) => `
    display: grid;
    grid-template-columns: repeat(${linksCount}, 110px) auto;
    justify-content: flex-end;
    align-items: center;
    color: ${neutral.main};
    ${[breakpoints.down('sm')]} {
      grid-template-columns:1fr;
      grid-template-rows:repeat(${linksCount}, 70px) auto;
      justify-content: flex-start;
    }
  `
);

const StyledBox = styled(Box)(({ theme: { breakpoints } }) => `
  ${[breakpoints.down('sm')]} {
    position: relative;
    left: 113px;
  }
`);

const Menu = styled('ul')(
  ({ show, theme: { palette: { primary, neutral, secondary }, breakpoints } }) => `
  position: absolute;
  top: 66px;
  right: 0;
  background-color: ${secondary.main};
  border-top: 3px solid ${primary.blue};
  border-radius: 0px 0px 16px 16px;
  width: 230px;
  display: ${show ? "block" : "none"};
  box-shadow: 0px 3px 16px #00000029;
  padding: 0 15px;
  z-index: 100000000;
  ${[breakpoints.down('sm')]} {
    background-color: ${neutral.white};
    border-top:0px;
    top:80px;
    position:fixed;
    padding-bottom:80px;
    left:0px;
    width:100vw;
    height:100vh;
    display: ${show ? "flex" : "none"};
    flex-direction:column;
    flex-wrap:wrap;
    justify-content:space-between;
  } 
`)


const Header = ({ role }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { oktaAuth, authState } = useOktaAuth() || '';
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef()
  const locale = useSelector((state) => state.globalStates.locale);
  const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);
  
  const { headerContent: headerData } = useSelector(store => store?.contentful)
  const dispatch = useDispatch()

  useEffect(() => {
  if (Object.keys(headerData).length === 0)
    dispatch(loadHeaderStaticContent({ title: `Header - ${localeISOCode}`}));
  }, [])


  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showMenu && ref.current && !ref.current.contains(e.target)) {
        setShowMenu(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showMenu])
  
  const wordCount = role?.match(/(\w+)/g).length;
  let roleContent;
  if (wordCount > 1) {
    roleContent = role?.match(/\b(\w)/g).join("");
  } else {
    roleContent = role?.charAt(0) + role?.slice(-1);
  }

  const logOut = async() => {
    localStorage.clear(); //for localStorage
    sessionStorage.clear(); //for sessionStorage
    await oktaAuth.signOut();
  };

  const toggleMenu = () => setShowMenu(prev => !prev)

  return (
    <Box position={{xs:'fixed', sm:'relative'}} zIndex='1000' width={{xs:'100vw', sm:'100%'}} p={{xs:'0px', sm: '20px 72px'}} boxShadow='0px 3px 16px #00000029' backgroundColor='neutral.white' height={{xs:'80px', sm:'90px'}} display='grid' gridTemplateColumns={{xs: '1fr auto', sm: 'auto 1fr auto auto'}} alignItems='center'>
      <Link to={authState?.isAuthenticated ? `/${locale}/admin` : `/${locale}/`}>
        <StyledBox pr='30px' sx={{cursor:'pointer'}}>
          {mobile ?
          <img
            id="headerLogo"
            src={headerData?.logo?.fields.file?.url}
            alt="Moderna Logo"
            width="164px"
            onClick={() => {setShowMenu(false)}}
          />
          :
          <img
            id="headerLogo"
            src={headerData?.logo?.fields.file?.url}
            alt="Moderna Logo"
            width="158px"
          />}
        </StyledBox>
      </Link>
      {desktop &&
      <LinkContainer linksCount={headerData?.navigationItems?.length}>
        {/* {headerData?.navigationItems && headerData?.navigationItems.map((item, index) => {
          return (
            <Link id={`headerLink-${index}`} to={`/${item?.fields?.link?.fields?.pageReference?.fields.slug}`} key={index}>
              <Typography variant={'h5'} color='primary.main'>{item?.fields?.label?.toUpperCase()}</Typography>
            </Link>);
        })} */}
      </LinkContainer>}
      <Avatar sx={{backgroundColor: 'neutral.white', color: 'secondary.main', border: '1px solid', borderColor:'primary.main', height:"32px", width:"32px" }}>
      {locale === 'en-DE' ? 
        <Box component={'img'} sx={{objectFit: 'cover'}} alt='germany' height="32px" width="32px" src={germanyFlag}/> :
        <Box component={'img'} sx={{objectFit: 'cover'}} alt='usa' height="32px" width="32px" src={usaFlag}/>
      }
      </Avatar>
      <div style={{ position: "relative", marginLeft: 50 }} ref={ref}>
        {mobile ?
        <IconButton
          id="profileMenu"
          aria-label="user account"
          onClick={toggleMenu}
          sx={{ padding: 0, color:"primary.blue" }}>
          <MenuIcon style={{height:30 , width:30 , marginRight:13, marginLeft:0}} fontSize="small"/>
        </IconButton> :
        <IconButton
          id="profileMenu"
          aria-label="user account"
          onClick={toggleMenu}
          sx={{ padding: 0, color:"primary.blue" }}>
         <Avatar sx={{ fontFamily: 'Aeonik Bold', fontSize:'16px', backgroundColor: 'neutral.white', color: 'primary.blue', border: '2px solid', borderColor:'primary.main', '&:hover': {backgroundColor: 'secondary.main'} }}>{roleContent ? roleContent : 'GT'}</Avatar>
        </IconButton>}
        {<Menu show={showMenu}>
          {mobile &&
          <LinkContainer linksCount={headerData?.navigationItems?.length}>
            {headerData?.navigationItems && headerData?.navigationItems.map((item, index) => {
            return (
              <div key={index}>
                {index === 0 ? <div> </div> : <div style={{borderTop:"1px solid #c0c0c0"}}></div>}
              {/* <Link id={`headerLink-${index}`} to={`/${item?.fields?.link?.fields?.pageReference?.fields.slug}`} onClick={toggleMenu} key={index}>
                <Typography variant={'h5'} color='primary.main'>{item?.fields?.label}</Typography>
                <ArrowForwardIosIcon sx={{color:"primary.blue"}}/>
              </Link> */}
              </div>);
            })}
          </LinkContainer>}
          <div>
            <Box component={'li'} display='flex' justifyContent='center' alignItems= 'center' height='120px'>
              <SignoutButton id="signOutBtn" onClick={logOut}>
                SIGN OUT
              </SignoutButton>
            </Box>
          </div>
        </Menu>
        }
      </div>
    </Box>
  )
};

export { Header as EmployeeHeader };

Header.propTypes = {
  role: PropTypes.string,
  headerData: PropTypes.object,
};
