import React from "react";
import { Radio } from "../Radio";
import { Accordion } from "../Accordion";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const Label = styled(Typography)(({ theme: { palette: { primary }, breakpoints }}) => `
  color: ${primary.main};
  margin-top: 5px;
  margin-Bottom: 5px;
  text-transform: uppercase;
  ${[breakpoints.down('sm')]} {
    line-height: 16.5px;
  }
`);

const Value = styled(Typography)(({ theme: { breakpoints } }) => `
  ${[breakpoints.down('sm')]} {
    line-height: 24px;
  }
`);

const StyledBox = styled(Box)(({ theme: { palette: { neutral, secondary }, breakpoints }}) => `
  margin: 0 !important;
  width: 100% !important;
  &&.border {
    border-bottom: solid 2px ${neutral.gray2};
  }
  &&.edit {
    background-color: ${secondary.main};
    && div {
      background-color: ${secondary.main};
    }
    &&.false {
        background-color: ${neutral.gray5};
        && div {
          background-color: ${neutral.gray5};
        }
    }
  }
  ${[breakpoints.down('sm')]} {
    margin: 0;
  }
`);

const SingleAccount = styled('div')(({ theme: { palette: { neutral, secondary } }}) => `
  border-radius: 0px !important;
  box-shadow: none;
  border: none !important;
  margin: 0px !important;
  padding-top: 16px;
  padding-bottom: 16px;

  &&.edit {
    background-color: ${secondary.main};
    && div {
      background-color: ${secondary.main};
    }
    &&.false {
        background-color: ${neutral.gray5};
        && div {
          background-color: ${neutral.gray5};
        }
    }
  }
`);

const RadioTitleWrapper = styled('div')`
  margin: 17px 0 20px 0;
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled(Box)(({ theme: { palette: { secondary, neutral } }}) => `
  display: flex;
  background-color: ${secondary.main};
  gap: 3px;

  &&.edit {
    background-color: ${secondary.main};
    &&.false {
        background-color: ${neutral.gray5};
    }
  }
`);

const accordionCustomStyle = {
    "&.gpoSectionAccordion": {
      borderRadius: "0px !important",
      boxShadow: "none",
      border: "none !important",
      margin: "0px !important",
      backgroundColor: "secondary.main",
      "& .MuiTypography-h5": {
          color:"primary.main",
          display:"flex"
      },
      "& .Mui-expanded": {
          backgroundColor: "transparent",
      },
      "& .MuiAccordionSummary-expandIconWrapper": {
          marginRight:"0px"
      },
      "& .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded": {
          marginLeft:"0px"
      }
    }
};

export const GroupAffiliations = ({ staticContent, userInfo, businessType, selected, border, onChange, pageType, isDefault, singleAccount, isExpanded, id="", isEnabled }) => {
  return(
        <StyledBox id={singleAccount ? `GpoAffiliationsContainer` : `GpoAffiliationsContainer_${id}`} className={`${border ? 'border' : ''} ${pageType} ${isEnabled}`} sx={{ flexGrow: 1 }}>
            { singleAccount ? 
            <SingleAccount id={`gpoDetailsContainer`} data-testid='gpoDetailsContainer'>
                <RadioTitleWrapper id={`radioTitleWrapper`} data-testid='radioTitleWrapper'>
                    <Typography variant={'h5'} color='primary.main' id="gpoName"  data-testid='gpoName'>{userInfo?.gpoName}</Typography>
                    {isEnabled === 'false' && <Typography variant={'p2'} ml='5px' color='primary.main' id="default" data-testid='default'>{isDefault ? '(default)' : ''}</Typography>}
                </RadioTitleWrapper>
                <Grid container spacing={2} id={`gpoDetailsWrapper`} data-testid='gpoDetailsContainer'>
                    <Grid item xs={12} md={4} id={`gpoNameContainer`} data-testid='gpoNameContainer'>
                        <Label variant={'h6'} id="gpoNameLabel" data-testid='gpoNameLabel'>{staticContent?.gpoNameLabel}</Label>
                        <Value variant={'p1'} id="gpoNameVal" data-testid='gpoNameVal'>{userInfo?.gpoName}</Value>
                    </Grid>
                    <Grid item xs={12} md={4} id={`gpoBusinessTypeContainer`} data-testid='gpoBusinessTypeContainer'>
                        <Label variant={'h6'} id="gpoBusinessTypeLabel" data-testid='gpoBusinessTypeLabel'>{staticContent?.gpoBusinessTypeLabel}</Label>
                        <Value variant={'p1'} id="businessType" data-testid='businessType'>{businessType}</Value>
                    </Grid>
                </Grid>
            </SingleAccount> : 
            <Accordion
                className='gpoSectionAccordion'
                id={`gpoDetailsContainer_${id}`}
                data-testid='gpoSectionAccordion'
                defaultExpanded={isExpanded}
                accordionCustomStyle={accordionCustomStyle}
                heading={
                    <TitleWrapper id={`gpoTitleWrapper_${id}`}>
                        <Radio
                          id={`gpo_${id}`}
                          checked={selected}
                          label={
                              <RadioTitleWrapper id={`radioTitleWrapper_${id}`}>
                                  <Typography variant={'h5'} color='primary.main' id={`gpoName_${id}`}>{userInfo?.gpoName}</Typography>
                                  {isEnabled === 'false' && <Typography variant={'p2'} ml='5px' color='primary.main' id={`default_${id}`}>{isDefault ? '(default)' : ''}</Typography>}
                              </RadioTitleWrapper>
                          }
                          onChange={onChange}
                          size="large"
                          className={pageType === "readonly" || isEnabled === "false" ? "readonly" : ""} />
                    </TitleWrapper>
                }
                description={
                <Grid container spacing={2} id={`gpoDetailsWrapper_${id}`}>
                    <Grid item xs={12} md={4} id={`gpoNameContainer_${id}`}>
                        <Label variant={'h6'} id={`gpoNameLabel_${id}`}>{staticContent?.gpoNameLabel}</Label>
                        <Value variant={'p1'} id={`gpoNameVal_${id}`}>{userInfo?.gpoName}</Value>
                    </Grid>
                    <Grid item xs={12} md={4} id={`gpoBusinessTypeContainer_${id}`}>
                        <Label variant={'h6'} id={`gpoBusinessTypeLabel_${id}`}>{staticContent?.gpoBusinessTypeLabel}</Label>
                        <Value variant={'p1'} id={`businessType_${id}`}>{businessType}</Value>
                    </Grid>
                </Grid>
                }
            />}
        </StyledBox>
    )
}