import { useEffect, useState, useRef } from "react";
import { BenefitCardComponent, AlertMessage, Spinner } from "../components";
import { Redirect } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from './../components/OktaSignIn';
import { activateOktaUserApi } from "../utils/commerceOktaApi";
import { updateUserApiNoAuth } from "../utils/commerceUsersApi";
import { useDispatch, useSelector } from "react-redux";
import { loadHomeStaticContent } from "../features/contentful/contentfulThunkApi";
import { useLocation } from "react-router-dom";
import { useTracking } from '@modernatx/tracking';

const HomePage = () => {
  const location = useLocation();
  const [errorMsg, setErrorMsg] = useState('');
  const [activationMsg, setActivationMsg] = useState('');
  const { authState } = useOktaAuth() || '';
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const userId = token;
  const [loader, setloader] = useState(false);
  const [invalidUser, setInvalidUser] = useState(Boolean(localStorage.getItem("invalidUser")));
  const { globalErrorMessage, globalSuccessMessage } = useSelector(store=>store?.globalMessages);
  const globalInvalidUserMsgData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "OKTA_USER_NOT_ONBOARDED");
  const globalInvalidUserMsg = globalInvalidUserMsgData && globalInvalidUserMsgData[0]?.fields?.message;
  const { loading, homePageContent } = useSelector((store) => store?.contentful)
  const accountAlreadyActivatedErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "ACCOUNT_ALREADY_ACTIVATED");
  const accountAlreadyActivatedError = accountAlreadyActivatedErrorData && accountAlreadyActivatedErrorData[0]?.fields?.message;
  const locale = useSelector((state) => state.globalStates.locale);
  const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);
 
  const successAlertRef = useRef(null)
  const alertRef = useRef(null)
  const { aboveFoldBGImage, signInItems, benefitCardItems } = homePageContent

  const {
    trackCustom
  } = useTracking();

  const dispatch = useDispatch()
  useEffect(() => {
    if (invalidUser === true && globalInvalidUserMsg) {
      setErrorMsg(globalInvalidUserMsg);
      setInvalidUser(false);
      alertRef.current?.openAlert()
      localStorage.removeItem("invalidUser");
    }
  }, [invalidUser, globalInvalidUserMsg]);

  useEffect(() => {
    if (localeISOCode && homePageContent?.signInItems?.fields?.country?.fields?.isocode !== localeISOCode) {
      dispatch(loadHomeStaticContent({
        title1: `Sign In Component - ${localeISOCode}`,
        title2: `Benefits Card Carousel Component - ${localeISOCode}`
      }))
    }
  }, [])

  useEffect(() => {
    const activateUser = async () => {
      try {
        setloader(true);
        const res = await activateOktaUserApi(token);
        if (res?.status === 200) {
          const data = { "active": true };
          const resp = await updateUserApiNoAuth(userId, data); //activate user in commerce
          if (resp?.status === 200) {
            setloader(false);
            const accountActivatedMsgData = globalSuccessMessage.length && globalSuccessMessage.filter((data) => data?.fields?.code === "ACCOUNT_ACTIVATED");
            const accountActivatedSuccessMsg = accountActivatedMsgData && accountActivatedMsgData[0]?.fields?.message;          
            setActivationMsg(accountActivatedSuccessMsg)
            trackCustom("Activated New User", {"OktaID" : userId, "Data": data})
            successAlertRef.current?.openAlert()
          }
        }
      } catch (err) {
        setErrorMsg(accountAlreadyActivatedError);
        setloader(false);
        alertRef.current?.openAlert()
      } finally {
        setloader(false);
      }
    }
    if (token && globalSuccessMessage?.length > 0)
      activateUser();
  }, [token, userId, globalSuccessMessage, accountAlreadyActivatedError]);

  return (
    <div>
      <Spinner processing={loader} />
      {
        localStorage.getItem("currentUser") && localStorage.getItem("currentUser") !== "undefined" && authState?.isAuthenticated 
        && (location?.pathname === '/' || location?.pathname === '/en-US' || location?.pathname === '/en-US/' || location?.pathname === '/en-DE' || location?.pathname === '/en-DE/') ?
          <Redirect to={{ pathname:`/${locale}/home` }} /> : 
          localStorage.getItem("currentUser") && localStorage.getItem("currentUser") !== "undefined" && authState?.isAuthenticated ?
            <Redirect to={{ pathname:`/${locale}${location?.pathname}` }} /> : 
            localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined" && authState?.isAuthenticated ?
              <Redirect to={{ pathname: `/${locale}/admin` }} /> : 
            (<div>
              <AlertMessage variant={"filled"} type={"error"} message={errorMsg} ref={alertRef} />
              <AlertMessage variant={"filled"} type={"success"} color={"info"} message={activationMsg} ref={successAlertRef} />
              <OktaSignIn
                aboveFoldBGImage={aboveFoldBGImage}
                content={signInItems} />
              {benefitCardItems && (<BenefitCardComponent
                content={benefitCardItems}
              ></BenefitCardComponent>
              )}
            </div>
            )
      }
    </div>
  );
};

export default HomePage
