import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";

const StyledBadge = styled(Chip)(({ theme: { palette: { neutral, secondary } }}) => ({
    "& .MuiChip-label": {
      overflow: "inherit",
      padding: `0px 16px`,
    },
    "&&:hover": {
      borderColor: secondary.graphical,
    },
    "&&:focus": {
      backgroundColor: `${secondary.graphical} !important`,
      color: neutral.white,
      borderColor: secondary.graphical,
      boxShadow: "none",
    }
}));

export const BasicBadge = ({ label, variant, onClick, ...props }) => {
  return (
    <StyledBadge
      label={label}
      variant={variant}
      onClick={onClick}
      sx={props.sx}
    />
  );
};

BasicBadge.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};
