import React, { useEffect,useState,useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderSummaryComponent } from '../components/OrderSummary'
import {Box, Grid, Typography} from "@mui/material";
import CartEntryComponent from "../components/CartEntryComponent";
import { loadCartComponentContent, loadCheckoutPageComponentContent } from '../features/contentful/contentfulThunkApi';
import { HandlerBarTemplateHandler } from "../utils/Helper";
import { useUserInfoContext } from '../contexts';
import { useHistory } from 'react-router-dom';
import { AlertMessage, Spinner } from '../components';
import { getOrderCartApi } from '../features/commerce/commerceThunkApi';
import dayjs from 'dayjs';
import { DatePicker } from '../components/DatePicker';
import { DisclaimerMessageFooter } from "../components/DisclaimerMessageFooter";
import { setCheckoutBtnClicked } from "../features/globalStates/globalStatesSlice";
import { Link } from 'react-router-dom';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const { userInfo } = useUserInfoContext();
  const { uid : userId } = userInfo;
  const [errorMsg, setErrorMsg] = useState('');
  const alertRef = useRef(null);
  const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
  const { orderCartData } = useSelector(store => store?.commerce);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const[processing, setProcessing] = useState(false);
  const locale = useSelector((state) => state.globalStates.locale);
  const history = useHistory();
  const { cartContent } = useSelector(store => store.contentful);
  const { checkoutPageContent } = useSelector(store => store.contentful);
  const [requestDate, setRequestDate] = useState(dayjs('', "MM/DD/YYYY"));
  const [datePickerError, setDatePickerError] = useState(null);

  const isCheckoutBtnClicked = useSelector(store => store?.globalStates?.isCheckoutBtnClicked);
  useEffect(() => {
    window.scrollTo(0,0);
    if (cartContent && Object.keys(cartContent).length === 0){
      dispatch(loadCartComponentContent());
    }
  },[dispatch, cartContent]);

  useEffect(() => {
    window.scrollTo(0,0);
    if (checkoutPageContent && Object.keys(checkoutPageContent).length === 0){
      dispatch(loadCheckoutPageComponentContent());
    }
  },[dispatch, checkoutPageContent]);

  useEffect(() => {

    setProcessing(true);
    dispatch(getOrderCartApi({ userId })).unwrap()
      .then((data) => {
        setProcessing(false);
        if(data?.entries.length === 0)
          history.push(`/${locale}/products`)
      })
      .catch((error) => {
        setErrorMsg(generalApiError);
        alertRef.current?.openAlert(error);
        setProcessing(false);
      })
  }, [generalApiError, userId, dispatch])

  const onDatePickerRequestDateChange = useCallback((value) => {
    setRequestDate(dayjs(value).format('YYYY-MM-DD'));
  }, [])

  const areDatesEqual = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate(); 
  }

  useEffect(() => {
    if(!isCheckoutBtnClicked) {
      history?.push(`/${locale}/cart`);
    } else {
      dispatch(setCheckoutBtnClicked(false));
    }
  }, [history]);

  const onErrorHandler = (err) => {
    setDatePickerError(err);
  }

  const shouldDisableDate = (date) => {
    const dateToCheck = new Date(date);
    const dayOfWeek = dateToCheck.getDay();

    const SUNDAY = 0;
    const MONDAY = 1;
    const SATURDAY = 6;
    
    if (dayOfWeek === SUNDAY || dayOfWeek === MONDAY || dayOfWeek === SATURDAY) {
      return true;
    }

    const today = new Date();
    if (areDatesEqual(dateToCheck, today)) {
      return true;
    }

    const usaTime = today.toLocaleTimeString("en-US", {
      timeZone: "America/New_York",
      hour: 'numeric',
      hour12: false
    });

    if (usaTime <= 12) {
      return false;
    }

    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + (today.getDay() === 5 ? 4 : 1));
    // After noon, disable next day
    return areDatesEqual(dateToCheck, nextDay);
  }

  return (
    <>
      <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
      <Spinner processing={processing} />
      <Grid data-testid="mainContainer" container spacing={{ xs: 1, lg: 3}} padding={{ xs: 2, md: 3, lg: 12}}>
        <Grid item xs={12} sm={8}>
        <Link
          to={`/${locale}/cart`}
          variant={'link'}
          data-testid='backToCartLink'
        >
          <Box display='flex' alignItems='center' mb={"10px"}>
            <KeyboardArrowLeft />
            <Typography variant='link'>Back to cart</Typography>
          </Box>
        </Link>
          <Typography variant='h2' sx={{paddingBottom: {sm:"30px", xs:'15px'}}}>{checkoutPageContent?.headerLabel}</Typography>
          <Box mb={3}>
            <Typography variant='h4' data-testid='deliveryTextMessage0'>{checkoutPageContent?.deliveryTextMessage?.[0]}</Typography>
            <Typography fontSize='18px' data-testid='deliveryTextMessage1'>{checkoutPageContent?.deliveryTextMessage?.[1]}</Typography>
          </Box>
          <Typography data-testid="labelField" variant='h3'>{HandlerBarTemplateHandler({cartEntries: orderCartData?.totalItems }, cartContent?.checkoutHeaderLabel)}</Typography>
          { orderCartData?.entries?.map((entry, index) => {
            return (
              <CartEntryComponent key={index} isCheckout={true} entryData={entry} cartContent={cartContent}/>
            )})
          }
        </Grid>
        <Grid item xs={12} sm={4}>
            <OrderSummaryComponent orderCartData={orderCartData} isCheckout={true} requestedDeliveryDate={requestDate} datePickerError={datePickerError}/>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant={'p'} sx={{ fontSize: '14px', lineHeight: '20px' }}>
            {documentToReactComponents(checkoutPageContent?.customerCareMessage)}
          </Typography>
        </Grid>
      </Grid>
      <DisclaimerMessageFooter
          disclaimerMessage={checkoutPageContent?.disclaimerMessage}
      />
    </>
  )
}

export default CheckoutPage
