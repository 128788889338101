import React from "react";
import { Button } from "../";

export const getCardStyles = (
  variant,
  buttonLink,
  buttonLabel,
  onButtonClick,
  loading = false,
) => {
  let cardStyle,
    cardContentStyle,
    cardActionsStyle = {},
    cardImageStyle = {},
    subTitleStyle,
    titleStyle,
    descriptionStyle,
    subDescStyle = {},
    imageDropshadowColor,
    imageHeight,
    imageWidth,
    ButtonComponent;
    
  switch (variant) {
    case "Wide": {
      cardStyle = {
        maxWidth: "524px",
      };
      cardContentStyle = {
        padding: "40px",
        ":last-child": {
          paddingBottom: "40px",
        },
      };
      cardImageStyle = {
        objectFit: "contain",
        filter:
          "opacity(0.5) drop-shadow(0px 0px 0px #D8362C) drop-shadow(0px 0px 0px #D8362C) drop-shadow(0px 0px 0px #D8362C)",
      };
      subTitleStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "11px",
        fontWeight: "900",
        textTransform: "UpperCase",
        letterSpacing: "0px",
        marginBottom: "8px",
        color: "#D8362C",
      };
      titleStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "24px",
        marginBottom: "16px",
        color: "neutral.main",
      };
      descriptionStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "16px",
        color: "neutral.main",
        marginBottom: "32px",
      };
      ButtonComponent = null;
      imageWidth = "524px";
      imageHeight = "198px";
      break;
    }
    case "Long": {
      cardStyle = {
        width: "416px",
      };
      cardContentStyle = {
        padding: "40px",
        ":last-child": {
          paddingBottom: "40px",
        },
      };
      cardImageStyle = {
        objectFit: "contain",
        filter:
          "opacity(0.5) drop-shadow(0px 0px 0px #38BAE8) drop-shadow(0px 0px 0px #38BAE8) drop-shadow(0px 0px 0px #38BAE8)",
      };
      subTitleStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "11px",
        fontWeight: "900",
        textTransform: "UpperCase",
        letterSpacing: "0px",
        marginBottom: "8px",
        color: "#38BAE8",
      };
      titleStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "24px",
        marginBottom: "16px",
        color: "neutral.main",
      };
      descriptionStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "16px",
        color: "neutral.main",
        marginBottom: "24px",
      };
      subDescStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "14px",
        opacity: "0.38",
        color: "neutral.main",
      };
      ButtonComponent = (
        <Button buttonType="primary" onClick={onButtonClick} icon={false}>
          Lorem
        </Button>
      );
      imageWidth = "416px";
      imageHeight = "160px";
      break;
    }
    case "Secondary": {
      cardStyle = {
        width: "526px",
      };
      cardContentStyle = {
        padding: "40px",
        ":last-child": {
          paddingBottom: "40px",
        },
      };
      cardImageStyle = {};
      subTitleStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "11px",
        fontWeight: "900",
        textTransform: "UpperCase",
        letterSpacing: "0px",
        marginBottom: "8px",
        color: "#38Bae8",
      };
      titleStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "24px",
        marginBottom: "16px",
        color: "neutral.main",
      };
      descriptionStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "16px",
        color:"neutral.main",
        marginBottom: "32px",
      };
      ButtonComponent = (
        <Button buttonType="secondary" onClick={onButtonClick} icon={false}>
          Lorem
        </Button>
      );
      imageWidth = "";
      imageHeight = "";
      break;
    }
    case "Info": {
      cardStyle = {
        width: "416px",
      };
      cardContentStyle = {
        padding: "40px",
        ":last-child": {
          paddingBottom: "40px",
        },
      };
      cardImageStyle = {};
      subTitleStyle = {};
      titleStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "24px",
        marginBottom: "16px",
        color: "neutral.main",
      };
      descriptionStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "16px",
        color: "neutral.main",
        marginBottom: "32px",
      };
      ButtonComponent = (
        <Button buttonType="primary" onClick={onButtonClick} icon={false}>
          Lorem
        </Button>
      );
      imageWidth = "";
      imageHeight = "";
      break;
    }
    case "Request": {
      cardStyle = {
        maxWidth: "524px",
      };
      cardContentStyle = {
        height: "467px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "Column",
        alignItems: "center",
        padding: "60px 40px",
        ":last-child": {
          paddingBottom: "60px",
        },
      };
      cardImageStyle = {};
      subTitleStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "11px",
        fontWeight: "900",
        textTransform: "UpperCase",
        letterSpacing: "0px",
        marginBottom: "8px",
        color: "#2F688B",
        textAlign: "center",
      };
      titleStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        marginBottom: "24px",
        color: "neutral.main",
        textAlign: "center",
      };
      descriptionStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        fontSize: "16px",
        color: "neutral.main",
        marginBottom: "40px",
        textAlign: "center",
      };
      ButtonComponent = buttonLabel && buttonLink && (
        <Button
          buttonType="primary"
          onClick={() => (window.location.href = buttonLink)}
          icon={false}
        >
          {buttonLabel}
        </Button>
      );
      imageWidth = "";
      imageHeight = "";
      break;
    }
    case "Product": {
      cardActionsStyle = {
        justifyContent: "space-between",
        height: "38px",
        marginTop: "45px",
        '@media screen and (max-width: 500px)': {
          justifyContent:"start",
          alignItems:"end",
          height : '100px',
          marginTop:"0px",
          marginBottom:"26px",
        },
      };
      cardImageStyle = {
        height:'auto',
        minHeight:"120px",
        '@media screen and (max-width: 500px)': {
          height : '140px',
        },
      };
      descriptionStyle = {
        fontFamily: "Aeonik Regular",
        color: "primary.main",
        fontSize: "11px",
        '@media screen and (max-width: 500px)': {
          fontSize : '14px',
        },
      };
      cardContentStyle = {
        display: "flex",
        justifyContent: "center",
        flexDirection: "Column",
        padding: "20px !important",
      };
      titleStyle = {
        fontFamily: "Aeonik Regular, Nunito",
        color: "primary.main",
        textAlign: "left",
        fontSize: "15px",
        fontWeight: "600",
        '@media screen and (max-width: 500px)': {
          fontSize : '16px',
        }
      };
      ButtonComponent = buttonLabel && (
        <Button
          className="addBtn"
          disabled={loading}
          size="small"
          buttonType="secondary"
          onClick={onButtonClick}
          icon={false}
          sx={{ right: "0px", height: "37px" ,'@media screen and (max-width: 500px)': {
            height: "48px"}}}
        >
          {buttonLabel}
        </Button>
      );
      break;
    }
    default:
  }

  return {
    cardStyle,
    cardContentStyle,
    cardActionsStyle,
    cardImageStyle,
    subTitleStyle,
    titleStyle,
    descriptionStyle,
    subDescStyle,
    imageDropshadowColor,
    imageHeight,
    imageWidth,
    ButtonComponent,
  };
};
