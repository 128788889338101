import React, { useState } from 'react'
import { Box, Typography, Link } from "@mui/material";
import { SelectDropdown } from "../SelectDropdown";
import { ComboSelect } from '../ComboSelect';
import { DeliveryIntructionModal } from '../DeliveryInstructionModal';
import { AddressesForm } from '../AddressesForm/index';
import { ModalComponent } from '../ModalComponent';

export const DeliveryLocation = ({ 
    index,
    location,
    addresses,
    modifiedLocationsAsArray,
    doseQuantity,
    deliveryInstructions,
    cartContent,
    entryData,
    dosesPerCarton,
    handleLocationSelection,
    handleDeliveryInstructions,
    handleDoseQuantity,
    setUpdateCartBtnDisabled,
    setCheckoutBtnDisabled,
    comboSelectError,
    setComboSelectError,
    addressDropdownError,
    setAddressDropdownError,
    userId
}) => {
    const [showDeliveryInstructionModal, setShowDeliveryInstructionModal] = useState(false)
    const isAddAddressEnabled = process.env.IS_ADD_ADDRESS_ENABLED;

    const getDosesOptions = (dosesPerCarton) => {
    let minDoses = { "label": dosesPerCarton?.toString(), "value": dosesPerCarton };
    let doses = cartContent?.quantityIncrements?.quantityIncrements?.map(item => {
        return (
            {
            "label": item.toString(),
            "value": item,
            }
        )
        });
        doses?.unshift(minDoses);
        return doses;
    };

    let availableAddresses = addresses?.filter(addr => modifiedLocationsAsArray.findIndex(modifiedLoc => modifiedLoc.uid === addr.uid) === -1);

    const DOSES_PER_CARTON = parseInt(entryData?.product?.dosesPerCarton);

    const selectDoseEntry = (doses) => {
        setComboSelectError('false')
        let dropDownValue = (Math.ceil(parseInt(doses) / DOSES_PER_CARTON)) * parseInt(dosesPerCarton);
        handleDoseQuantity(dropDownValue.toString(), index);
    }

    const selectDoseBlurHandler = (event) => {
        if(doseQuantity !== event.target.value) {
            selectDoseEntry(event.target.value);
        }
    }

    const selectDoseKeyUpHandler = (event) => {
        if (event.key === "Enter" && doseQuantity !== event.target.value) {
            selectDoseEntry(event.target.value);
        }
    }

    const [showAddNewAddress, setShowAddNewAddress] = useState(false);
    const openNewAddressModal = () => {
      setShowAddNewAddress(true)
    }

    const addNewAddressLink = [{
        label: <Link component="button" color={'secondary.textLinks'} sx={{ textDecoration: 'none', cursor: 'pointer', opacity: '1' }}>
                    + Add a new address
                </Link>,
        value: "add-address-uid"
    }]

    const formatAddresses = (addresses) => {
        return (
            addresses?.map((address) => {
                return (
                    {
                        label:`${address?.companyName} - ${address?.line1 ? address?.line1 + ',' : ''} ${address?.line2 ? address?.line2 + ',' : ''} ${address?.line3 ? address?.line3 + ',' : ''} ${address?.town || ''} ${address?.region?.isocode || ''}, ${address?.postalCode || ''}`, 
                        value: `${address?.uid}`
                    }
                )
            })
        )
    }

    const selectLocationChange = (value) => {
        if (value === 'add-address-uid') {
            openNewAddressModal()
            return
        }
        setAddressDropdownError(false)
        setUpdateCartBtnDisabled(false);
        setCheckoutBtnDisabled(true);

        handleLocationSelection(value, index);
    }

    const selectDeliveryInstructions = (value) => {
        handleDeliveryInstructions(value, index);
    }

    const handleNewAddresses = (newAddresses) => {
        let userAddedAddresses = newAddresses?.filter((newAddress) => 
            !addresses.some((address) => address.uid === newAddress.uid)
        )
        if (userAddedAddresses.length) {
            handleLocationSelection(userAddedAddresses[0].uid, index)
        }
    }

    return (
        <>
        <DeliveryIntructionModal id="editDeliveryInsrructions" 
            title={`${cartContent?.addDeliveryInstructionsModalLabel} - ${location?.companyName}`} 
            primaryButtonLabel="SAVE" openModal={showDeliveryInstructionModal} 
            closeModalHandler={() => setShowDeliveryInstructionModal(false)} 
            setDeliveryInstructions={selectDeliveryInstructions} 
            cartContent={cartContent} deliveryInstructions={deliveryInstructions} 
        />
        <ModalComponent 
            title='Add a new address'
            openModal={showAddNewAddress}
            closeModalHandler={() => setShowAddNewAddress(false)} 
            modalContent={<AddressesForm userId={userId} closeModal={() => setShowAddNewAddress(false)} handleNewAddresses={handleNewAddresses} />}
        />
        <Box data-testid="DeliveryContainer" display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }} gap={1}>
                <Box width='100%' maxWidth={{xs: '300px', md: '250px', lg: '450px'}}>
                <SelectDropdown
                    className={'cart'}
                    required={true}
                    label={cartContent?.deliveryLocationLabel?.toUpperCase()}
                    value={location?.uid || ''}
                    error={addressDropdownError}
                    disabledOptions={formatAddresses(modifiedLocationsAsArray)}
                    options={(formatAddresses(availableAddresses)?.concat(isAddAddressEnabled === 'true' ? addNewAddressLink : []))}
                    onChangeHandler={(value) => selectLocationChange(value)}
                />
                </Box>
                <ComboSelect 
                    className={'cart'}
                    variant={'square'}
                    label={cartContent?.dosesLabel?.toUpperCase()}
                    sx={{position: 'relative', top: '4px'}}
                    options={getDosesOptions(entryData?.product?.dosesPerCarton) || []}
                    defaultValue={doseQuantity || ''}
                    error={comboSelectError}
                    onChangeHandler={(event, newValue) => {
                        if(doseQuantity !== newValue?.value?.toString()){
                            setComboSelectError(''); 
                            setUpdateCartBtnDisabled(false); 
                            setCheckoutBtnDisabled(true);
                        }
                    }}
                    onBlurHandler={(event) => selectDoseBlurHandler(event, entryData?.product?.dosesPerCarton)}
                    onKeyUpHandler={(event) => selectDoseKeyUpHandler(event, entryData?.product?.dosesPerCarton)}
                />
            </Box>
            { deliveryInstructions && <Box display={'flex'} alignItems={'center'} mb={2}>
                <Typography variant={'p2'} lineHeight={1} mr={1}>{`${cartContent?.deliveryInstructionsText}: ${deliveryInstructions}`}</Typography>
                <Link onClick={() => setShowDeliveryInstructionModal(true)} fontSize={'14px'} color={'secondary.textLinks'} sx={{ textDecoration: 'none', cursor: 'pointer' }}>{cartContent?.editLinkText}</Link>
            </Box>}
        </Box>
    </>
    )
}