import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";

const CustomAlert = styled(Alert)(() => (({ theme: { palette: { neutral } }}) => ({
  backgroundColor: neutral.white,
  "& .MuiAlert-icon": {
    marginRight: 8,
    svg: {
      width: 11,
      height: 11,
    },
  },
  "& .MuiAlert-message": {
    fontSize: 14,
    textAlign: "left",
    color: "#222222",
    opacity: 1,
    padding: `3px 0`,
  },
})));

const renderProgressStatus = (s, content) => {
  switch (s.toLowerCase()) {
    case "delayed":
      return (
        <CustomAlert
        icon={<CircleIcon sx={{ color: 'primary.red'}}/>}
        >
          {content}
        </CustomAlert>
      );
    case "pending":
      return (
        <CustomAlert
          icon={<CircleIcon sx={{ color: '#FFCE00'}}/>}
        >
          {content}
        </CustomAlert>
      );
    default:
      return (
        <CustomAlert
          icon={<CircleIcon sx={{ color: '#49B705'}}/>}
        >
          {content}
        </CustomAlert>
      );
  }
};

export const ProgressIndicators = ({ status, children }) => {
  return <Box sx={{ m: 1 }}>{renderProgressStatus(status, children)}</Box>;
};

ProgressIndicators.propTypes = {
  status: PropTypes.oneOf(["delivered", "pending", "delayed"]),
  children: PropTypes.string,
};
