export const UserErrorCodes = {
    LOCATIONS: {
        USER_NOT_FOUND: 'USER_NOT_FOUND',
        AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
        UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
    },
    AFFILIATE: {
        USER_NOT_FOUND: 'USER_NOT_FOUND',
        AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
        UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
    },
    CONTACT: {
        USER_NOT_FOUND: 'USER_NOT_FOUND',
        AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
        UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
    },
    SEARCH: {
        UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
        THIRD_PARTY_SERVICE_ERROR: 'THIRD_PARTY_SERVICE_ERROR',
    },
    UPDATE: {
        INVALID_ADDRESS: 'INVALID_ADDRESS',
        THIRD_PARTY_SERVICE_INVALID_RESPONSE: 'THIRD_PARTY_SERVICE_INVALID_RESPONSE',
        ORG_CREATION_FAILED: 'ORG_CREATION_FAILED',
    }
};