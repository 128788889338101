import { useState } from 'react';
import { Box } from '@mui/material';
import { Button } from '../../components';
import { ModalComponent } from '../ModalComponent';
import { PaymentMethodsModal } from './PaymentMethodsModal';
import useAddPaymentMethod from '../../hooks/useAddPaymentMethod';
import { PaymentMethodBasicInfo } from './PaymentMethodBasicInfo';
import { deletePaymentMethodApi, updatePaymentMethodApi } from '../../utils/PaymentsService/PaymentMethodApis';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { v4 as uuidv4 } from 'uuid';
import { checkIsExpired, getPaymentMethodLists, snackbarMessages } from "./Helper";
import { PAYMENT_METHOD_STATUS } from "../../constants";

const PaymentMethodSelection = ({ handleErrorMsg,
                                    paymentMethodsList,
                                    setPaymentMethodAdded,
                                    paymentMethodAdded,
                                    handlePaymentMethodSelection,
                                    setProcessing,
                                    setPaymentMethodsList,
                                    setIsSnackbarOpen,
                                    setSnackbarMessage
                                }) => {
    const {
        showPaymentForm,
        processing,
        onAddPaymentMethodClick,
        handleClosePaymentModal,
        StripeForm
    } = useAddPaymentMethod(handleErrorMsg, setPaymentMethodAdded);

    const [openPaymentMethodsModal, setOpenPaymentMethodsModal] = useState(false);
    const defaultPayment = paymentMethodsList?.find((listItem) => listItem?.isDefault === true);
    const isCardExpired = defaultPayment && checkIsExpired(defaultPayment.paymentMethodInfo.expirationDate);
    const hasDefaultPayment = !!defaultPayment && !isCardExpired;
    const hasPaymentMethods = paymentMethodsList?.length > 0;

    const handleSelection = async () => {
        if (hasPaymentMethods) {
            setOpenPaymentMethodsModal(true);
        }
        else {
            await onAddPaymentMethodClick();
        }
    }

    const handleAddNewPaymentMethod = async () => {
        setOpenPaymentMethodsModal(false);
        await onAddPaymentMethodClick();
    }

    const PaymentButton = ({buttonLabel}) => {
        return <Button buttonType="mds-primary" onClick={handleSelection} sx={{textTransform: 'none', width: '100%'}} loading={processing}>
                    {buttonLabel}
                </Button>
    }

    const PaymentSelectionDisplay = ({ handleSetAsDefaultPayment, handleDeletePaymentMethod }) => {
        if (hasDefaultPayment || paymentMethodAdded) {
            return (
                <PaymentMethodBasicInfo
                    handleErrorMsg={handleErrorMsg}
                    handlePaymentMethodSelection={handlePaymentMethodSelection}
                    setPaymentMethodAdded={setPaymentMethodAdded}
                    handleSetAsDefaultPayment={handleSetAsDefaultPayment}
                    handleDeletePaymentMethod={handleDeletePaymentMethod}
                />
            )
        }
        else if (hasPaymentMethods) {
            return <PaymentButton buttonLabel={'Select a payment method'} />
        } else {
            return <PaymentButton buttonLabel={'Add a payment method'} />
        }
    }

    const handleSetAsDefaultPayment = async (paymentMethodId) => {
        try {
            setProcessing(true);
            const response = await updatePaymentMethodApi({
                idempotencyToken: uuidv4(),
                setDefault: true,
            }, paymentMethodId);
            if (response.status === 200) {
                await getPaymentMethodLists(setProcessing, handleErrorMsg, setPaymentMethodsList, PAYMENT_METHOD_STATUS.ACTIVE)
                setSnackbarMessage(snackbarMessages?.setDefaultPaymentMethod);
                setIsSnackbarOpen(true);
            }
        } catch (error) {
            const errorMessage = getErrorMessage(error?.response?.data?.errorCode);
            handleErrorMsg(errorMessage);
        } 
        finally {
            setProcessing(false);
        }
    }

    const handleDeletePaymentMethod = async (paymentMethodId) => {
        try {
            const response = await deletePaymentMethodApi(paymentMethodId);
            if (response.status === 200) {
                await getPaymentMethodLists(setProcessing, handleErrorMsg, setPaymentMethodsList, PAYMENT_METHOD_STATUS.ACTIVE )
                setSnackbarMessage(snackbarMessages?.deletePaymentMethod);
                setIsSnackbarOpen(true);
            }
        } catch (error) {
            const errorMessage = getErrorMessage(error?.response?.data?.errorCode);
            handleErrorMsg(errorMessage);
        } finally {
            setProcessing(false);
        }
    };

    return (
        <Box>
            {openPaymentMethodsModal &&
                <PaymentMethodsModal
                    openModal={openPaymentMethodsModal}
                    closeModal={()=>setOpenPaymentMethodsModal(false)}
                    paymentList={paymentMethodsList}
                    handleAddNewPaymentMethod={handleAddNewPaymentMethod}
                    handlePaymentMethodSelection={handlePaymentMethodSelection}
                    handleSetAsDefaultPayment={handleSetAsDefaultPayment}
                    handleDeletePaymentMethod={handleDeletePaymentMethod}
                />
            }
            <PaymentSelectionDisplay handleSetAsDefaultPayment={handleSetAsDefaultPayment}
                                     handleDeletePaymentMethod={handleDeletePaymentMethod}/>
            {showPaymentForm && <ModalComponent
                openModal={showPaymentForm}
                closeModalHandler={handleClosePaymentModal}
                modalContent={<StripeForm />}
                style={{ 
                    maxWidth: '521px',
                    width: 'auto',
                    padding: '24px'
                }}
            />}
        </Box>
    );
};

export default PaymentMethodSelection;
