import { Box, Typography } from "@mui/material";
import { useOktaAuth} from "@okta/okta-react";
import { Link } from "react-router-dom";
import GPOComponent from "../../components/Ordering/GPOComponent";
import { useHistory } from "react-router-dom";
import { AlertMessage, Button, Spinner, Checkbox} from "../../components";
import CustomCard from "../../components/CustomCardComponent/CustomCard";
import { useEffect, useMemo, useRef, useState } from "react";
import {formattedPrice, getOrderableProducts, HandlerBarTemplateHandler, sortAddresses} from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { mapProductNames } from "../../utils/HelperFunctions/OrderAndReservationHelper";
import OrderDetailsCard from "../../components/Ordering/OrderDetailsCard";
import { setCartData } from "../../features/commerce/commerceSlice";
import { setShowContractPrice } from "../../features/globalStates/globalStatesSlice";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddressSelectionModal from "../../components/Reservation/AddressSelectionModal";
import { loadOrderPage } from "../../features/contentful/contentfulThunkApi";
import { UserAddressFormModal } from "../../components/AddressesForm/UserAddressForm";
import { getPricingApi } from "../../utils/PricingService/PricingApi";
import { createCartApi, updateCartApi } from "../../utils/OrderService/OrderApi";
import { getUserByEmail, updateUserPreferencesApi } from "../../utils/UserService/UserApis";
import { UNAVAILABLE } from "../../constants";
import {
    createPricingRequestPayload,
    getCartLineItems, getPrices, getShipToOrganizationId,
    initializeEmptyCartLineItems,
    initializeExistingMResviaCartLineItems
} from "./OrderHelper";
import { getOrderableProductsApi } from "../../utils/CatalogService/CatalogAPI";
import GlobalBanner from "../../components/GlobalBanner/GlobalBanner";
import MessageBanner from "../../components/MessageBanner/MessageBanner";
import { TypographyP1 } from "../../assets/typographyTheme";

const MODALS = {
    ADDRESS_SELECTION: 'addressSelection',
    ADDRESS_FORM: 'addressForm',
    USER_ADDRESS_FORM: 'userAddressForm',
}

const OrderFormPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { authState } = useOktaAuth() || '';
    const isAuthenticated = authState?.isAuthenticated;
    const alertRef = useRef(null);
    const reservationConversionID = useSelector(store => store?.globalStates?.reservationConversionID);
    const { orderPage: orderPageContent } = useSelector((store) => store?.contentful);
    const cart  = useSelector((store) => store?.commerce?.cart?.cart);
    const {cartData} = useSelector((store) => store?.commerce)
    const pricingData = useSelector((store) => store?.commerce?.pricingData);
    const isCatalogServiceEnabled = process.env.IS_CATALOG_SERVICE_ENABLED === 'true';
    const orderableProducts = getOrderableProducts();
    const userData = useSelector((store) => store?.commerce?.userData);
    const defaultShipToAddress = userData?.addresses?.filter(address => address.addressType === 'ship_to')[0];
    const defaultBillToAddress = userData?.addresses?.filter(address => address.addressType === 'bill_to')[0];
    const soldToAddress = userData?.addresses?.filter(address => address.addressType === 'sold_to')[0];
    const [totalDosesQty, setTotalDoses] = useState(0);
    const [openAddressModal, setOpenAddressModal] = useState(false);
    const [showUserAddressFormModal, setShowUserAddressFormModal] = useState(false);
    const [processing, setProcessing] = useState(false);
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const userId = userData?.userId;
    const selectedContract = userData?.selectedContract;
    const showContractPrice = useSelector((state) => state.globalStates.showContractPrice);
    const [alertMsg, setAlertMsg] = useState({ type:'info', text:'' });
    const invalidErrorMsg = {
        medicalLicense: 'A medical license is required for ordering.',
        quantity: 'Number of doses is a required field.',
        stateMatchMedicalLicenseWithShipTo: 'Your medical license must be in the same state as your ship to address.',
    }

    const [isTaxExemptSelected, setIsTaxExemptSelected] = useState(false);
    const isTaxPhase3Enabled = process.env.IS_TAX_PHASE_3_ENABLED === 'true';
    const isSpikevaxMresvia2024ProductEnabled = process.env.IS_SPIKEVAX_MRESVIA_2024_PRODUCTS_ENABLED === 'true';
    const canShowTaxExemptCheckbox = isTaxPhase3Enabled && !userData?.isCustomerTaxExemptionSelfSelectionPastTenDays;

    const handleErrorMsg = (errorMsg) => {
        setAlertMsg({ type: 'error', text: errorMsg || generalApiError })
        alertRef.current?.openAlert('')
    }

    const isConvertingReservation = window.location.href.includes('complete-reservation');

    const shipToAddresses = useMemo(() => {
        const addresses = userData?.addresses || [];
        return addresses
            .filter(address => address.addressType === "ship_to")
            .map(address => ({
                ...address,
                defaultAddress: address.id === defaultShipToAddress?.id,
            }))
            .sort(sortAddresses);
    }, [defaultShipToAddress]);

    useEffect(() => {
        const createCart = async () => {
            try {
                setProcessing(true);
                if (isAuthenticated && userId) {
                    let response = {};
                    if (isConvertingReservation) {
                        response = await safeDispatch(createCartApi({ userId: userId, reservationId: reservationConversionID }));
                    }
                    else {
                        response = await safeDispatch(createCartApi({ userId: userId }));
                    }
                    isTaxPhase3Enabled && setIsTaxExemptSelected(response?.cart?.isTaxExemptSelectedByCustomer ?? false);
                }
                let res = await safeDispatch(getOrderableProductsApi({locale: 'US'}));
                res = res?.data?.productList
                await safeDispatch(getPricingApi({medicalIdentifier: getMedicalIdentifier(), payload: createPricingRequestPayload(res)}));
            } finally {
                setProcessing(false);
            }
        };
        createCart();
    }, [dispatch, isAuthenticated, isCatalogServiceEnabled]);

    useEffect(() => {
        if (orderPageContent && Object.keys(orderPageContent).length === 0){
            dispatch(loadOrderPage());
        }
    }, [orderPageContent, dispatch, isCatalogServiceEnabled]);

    useEffect(() => {
        if (isSpikevaxMresvia2024ProductEnabled) {
            let cartLineItems = getCartLineItems(orderableProducts, cart, shipToAddresses, defaultBillToAddress);
            dispatch(setCartData({ cartLineItems: cartLineItems}));
        }
        else {
            // if there is cart data, map through cart's cartLineItems,
            // else map through products to create default product info
            if (cart?.cartLineItems?.length) {
                let cartLineItems = initializeExistingMResviaCartLineItems(cart)
                dispatch(setCartData({ cartLineItems: cartLineItems}));
            }
            else {
                let cartLineItems = initializeEmptyCartLineItems(orderableProducts, cart, shipToAddresses, defaultBillToAddress);
                dispatch(setCartData({cartLineItems: cartLineItems}));
            }
        }
    }, [orderableProducts, isCatalogServiceEnabled])

    const safeDispatch = async (action) =>{
        try{
            return await dispatch(action).unwrap();
        } catch(error) {
            const apiErrorMessage = error?.message || generalApiError;
            setAlertMsg({ type: 'error', text: apiErrorMessage });
            alertRef.current?.openAlert('');
        }
    }
    const createDisplayEntry = (cartLineItem) => {
        //TBD: Implement logic when no address is selected
        if (!cartLineItem) return null;
        const { healthcareLicense, productId, shipToAddress, purchaseOrderNumber } = cartLineItem;
        const shipToAddressId = shipToAddress?.addressId;
        const pricing = getPrices(selectedContract, pricingData)?.[productId] || {};
        const address = shipToAddresses?.find(({ id }) => String(id) === String(shipToAddressId));
        const productMap = mapProductNames(orderableProducts);
        const productName = productMap[productId];
        return {
            id: cartLineItem.internalCartLineItemId,
            doses: parseInt(cartLineItem?.quantity, 10) || 0,
            productId,
            productName: productName,
            listPriceValue: pricing?.listPrice,
            listPrice: formattedPrice(pricing?.listPrice),
            contractPriceValue: pricing?.contractPrice,
            contractPrice: showContractPrice ? formattedPrice(pricing?.contractPrice) : UNAVAILABLE,
            address,
            healthcareLicense,
            purchaseOrderNumber
        };
    };

    const handleContractChange = async (selectedPriceContract) => {
        const { id: priceContractId, displayName, medicalIdentifier, contractType } = selectedPriceContract;
        const updateUserPreferencesRequestBody = {
            userId: userData?.userId,
            priceContract: {
                id: priceContractId,
                displayName: displayName,
                medicalIdentifier: medicalIdentifier,
                priceReferenceType: contractType,
            },
        };
        try {
            setProcessing(true);
            const response = await updateUserPreferencesApi(updateUserPreferencesRequestBody);
            if (response.status === 200) {
                dispatch(setShowContractPrice(true));
                dispatch(getUserByEmail({ emailAddress: response?.data?.email }));

                await safeDispatch(getPricingApi({ medicalIdentifier: selectedPriceContract.medicalIdentifier, payload: createPricingRequestPayload(orderableProducts) }));
            }
        } catch (error) {
            setAlertMsg({ type: "error", text: error.message || generalApiError });
            alertRef.current?.openAlert("");
        } finally {
            setProcessing(false);
        }
    };

    useEffect(() => {
        if (selectedContract) {
            dispatch(setShowContractPrice(true));
        } else {
            dispatch(setShowContractPrice(false));
        }
    }, [selectedContract, dispatch]);

    const getMedicalIdentifier = () => {
        return selectedContract?.medicalIdentifier || userData?.licenses?.find(license => license.issuer === "HIN" || license.issuer === "DEA")?.number || 'anonymous';
    }

    // TBD: Connecting to orderAPI
    const cartLineItemsModel = cartData?.cartLineItems || [];
    const cartLineItemsDisplay = cartLineItemsModel.map(createDisplayEntry).filter(entry => entry !== null);

    const orderPricingInfo = (data) => {
        return data.reduce(({totalDoses, totalPrice}, product) => {
            const pricePerDose = (product?.contractPriceValue) ? product.contractPriceValue : product.listPriceValue;
            return {
                totalDoses: totalDoses + product.doses,
                totalPrice: totalPrice + product.doses * pricePerDose
            };
        }, {totalDoses: 0, totalPrice: 0})
    }
    const updateDosesRoundedUpMsg = (val) => {
        return HandlerBarTemplateHandler({enteredDoses: val}, orderPageContent?.dosesRoundedUpMessage?.content[0])
    }

    const handleDosesChange = (internalCartLineItemId, doses) => {
        const cartLineItemIndex = cartLineItemsModel.findIndex(entry => entry?.internalCartLineItemId === internalCartLineItemId);
        // If the entry is not found, exit the function
        if (cartLineItemIndex === -1) return;

        // Retrieve the entry to be updated
        const entryToUpdate = cartLineItemsModel[cartLineItemIndex];
        // If it's the entry we want to update, return a new object with the updated doses
        const roundOffQty = (Math.ceil(parseInt(doses) / 10)) * 10;

        if (roundOffQty !== 0 && doses !== roundOffQty) {
            setAlertMsg({type: 'info', text: updateDosesRoundedUpMsg(roundOffQty)})
            alertRef.current?.openAlert('');
        }

        // Create a new entry with the updated doses
        const updatedLineItem = {...entryToUpdate, quantity: roundOffQty};

        // Create a new array with the updated entry
        const updatedCartLineItems = [
            ...cartLineItemsModel.slice(0, cartLineItemIndex),
            updatedLineItem,
            ...cartLineItemsModel.slice(cartLineItemIndex + 1)
        ];

        const { totalDoses } = orderPricingInfo(updatedCartLineItems)
        setTotalDoses(totalDoses);

        dispatch(setCartData({cartLineItems: updatedCartLineItems}));
    };

    const handlePoNumberChange = async (cartLineItems, purchaseOrderNumber) => {
        const updatedCartLineItems = cartLineItemsModel?.map(item => {
            const matchingCartLineItem = cartLineItems.find(cartLineItem => item?.internalCartLineItemId === cartLineItem?.id);
            if (matchingCartLineItem) {
                return { ...item, purchaseOrderNumber: purchaseOrderNumber };
            }
            return item;
        });
        await dispatch(setCartData({ cartLineItems: updatedCartLineItems }));
    };

    const closeModal = (modalName) => {
        modalName === MODALS.ADDRESS_SELECTION && setOpenAddressModal(false);
        modalName === MODALS.USER_ADDRESS_FORM && setShowUserAddressFormModal(false);
    }

    const handleDeleteAddressRow =  (cartAddressId) => {
        const updatedCartLineItems = cartData?.cartLineItems?.filter((entry) => {
            return entry?.shipToAddress?.addressId !== cartAddressId
        });
        const { totalDoses } = orderPricingInfo(updatedCartLineItems)
        setTotalDoses(totalDoses);
        dispatch(setCartData({ cartLineItems: updatedCartLineItems }));
    }

    const isUniqueAddressId = (addressId, index, self) => {
        return self.findIndex(entry => entry.shipToAddress?.addressId === addressId) === index;
    };

    const cartAddressIds = cartLineItemsModel
        .filter(({ shipToAddress }, index, self) => shipToAddress?.addressId && isUniqueAddressId(shipToAddress?.addressId, index, self))
        .map(({ shipToAddress }) => shipToAddress?.addressId);

    const getOrderDetailCards = cartAddressIds.map((cartAddressId, i) => {
        const cartLineItemForThisAddress = cartLineItemsDisplay?.filter(({address}) => String(address?.id) === String(cartAddressId));

        return <OrderDetailsCard
            key={cartAddressId}
            entries={cartLineItemForThisAddress}
            handleDosesChange={handleDosesChange}
            deleteAddressRow={() => handleDeleteAddressRow(cartAddressId)}
            updateDosesQty={(count) => setTotalDoses(count)}
            orderPageContent={orderPageContent}
            billingAddress={defaultBillToAddress}
            handlePoNumberChange={handlePoNumberChange}
            setExternalProcessing={setProcessing}
            errorMsgHandler={handleErrorMsg}
        />
    })

    const getAllSelectedAddress = () => {
        return cartAddressIds.map((orderAddressId, i) => {
            return cartLineItemsDisplay?.filter(({address}) => String(address?.id) === String(orderAddressId))[0]?.address
        });
    }
    const handleAddNewAddress = () => {
        closeModal(MODALS.ADDRESS_SELECTION);
        setShowUserAddressFormModal(true);
    }
    const updateCartLineItem = () => {
        return cartData?.cartLineItems
            .filter(cartLineItem => cartLineItem.quantity !== 0)
            .map(cartLineItem => ({
            ...cartLineItem,
            ...(isConvertingReservation && {shipToOrganizationId: getShipToOrganizationId(shipToAddresses, cartLineItem?.shipToAddress?.addressId)} ),
            priceSnapshotId: pricingData?.productIdToPriceSnapshotId?.[cartLineItem?.productId],
            billToAddress: {addressId: String(defaultBillToAddress?.id)},
            billToOrganizationId: String(defaultBillToAddress?.organizationId),
        }));
    }
    const checkValidation = (cartDetails) => {
        const showAlert = (messageKey) => {
            setAlertMsg({ type: 'error', text: invalidErrorMsg[messageKey] });
            alertRef.current?.openAlert('');
        };

        let totalDosePerCart = 0;
        for (const cartLineItem of cartDetails?.cart?.cartLineItems || []) {
            totalDosePerCart += cartLineItem?.quantity || 0;
            if (!cartLineItem?.healthcareLicense?.licenseNumber) {
                showAlert('medicalLicense');
                return true;
            }
            let shipToAddressId = cartLineItem?.shipToAddress?.addressId;
            let shipToAddress = shipToAddresses?.find(address => String(address.id) === String(shipToAddressId));
            if (cartLineItem?.healthcareLicense?.state?.toLowerCase() !== shipToAddress?.state?.toLowerCase()) {
                showAlert('stateMatchMedicalLicenseWithShipTo');
                return true;
            }
        }

        if (totalDosePerCart === 0) {
            showAlert('quantity');
            return true;
        }

        return false;
    };

    const handleContinue = async () => {
        const cartDetails = {
            cart:{
                userId: userId,
                cartId: cart?.cartId,
                soldToOrganizationId: String(soldToAddress?.organizationId),
                soldToAddress: {addressId: String(soldToAddress?.id)},
                contractInfo: {
                    contractId: selectedContract?.id,
                    contractDisplayName: selectedContract?.displayName,
                },
                fein: '12-3456789',
                cartLineItems: updateCartLineItem(),
                isTaxExemptSelectedByCustomer: isTaxExemptSelected,
                ...(isConvertingReservation && {
                    reservationId: cart?.reservationId,
                    reservationNumber: cart?.reservationNumber,
                })
            }
        };
        try {
            setProcessing(true);
            if(!checkValidation(cartDetails)){
                const response = await safeDispatch(updateCartApi({
                    payload: cartDetails,
                    userId: userId,
                    cartId: cart?.cartId,
                }));
                if (response?.status === 200) {
                    history.push('/order-summary');
                }
            }
        } catch (error) {
            setAlertMsg({ type: 'error', text: error?.message || generalApiError })
            alertRef.current?.openAlert('')
        } finally {
            setProcessing(false);
        }
    }

    const TaxExemptSelection = () => {
        const checkboxLabel = (
            <Typography fontFamily={'Aeonik Regular'}>
                <span style={{ fontWeight: '700' }}>This order is tax-exempt. </span>
                I hereby attest that my purchase is exempt from sales tax and purchaser
                holds a valid exemption certificate that has been provided or will be promptly provided to Moderna.
            </Typography>
        )
        
        return (
            <Box flexGrow={2} width="auto" mt="15px" data-testid="taxExemptCheckBox">
                <CustomCard sx={{alignContent: 'center', justifyContent: 'start', display: 'flex', padding: '16px 32px'}} >
                    <Checkbox
                        checked={isTaxExemptSelected}
                        label={checkboxLabel}
                        onChange={() => setIsTaxExemptSelected(!isTaxExemptSelected)}
                        labelSx={{fontSize: '16px', fontWeight: 400, lineHeight: '24px'}}
                    /> 
                </CustomCard>
            </Box>
        )
    }

    const getOrderableProductCodes = (orderableProducts?.length && orderableProducts?.map(product =>
        (isCatalogServiceEnabled ? product?.materialMasterId : product?.code)
    )) || [];

    return(
        <>
            <Spinner processing={processing}/>
            { <AlertMessage data-testid='errorAlert' variant={"filled"} type={alertMsg.type} message={alertMsg.text} sx={{ top: 120}} ref={alertRef} />}
            {openAddressModal && <AddressSelectionModal
                openModal={openAddressModal}
                closeModal={() => closeModal(MODALS.ADDRESS_SELECTION)}
                allSelectedAddresses={getAllSelectedAddress()}
                handleAddNewAddress={handleAddNewAddress}
                isOrdering={true}
            />
            }
            {showUserAddressFormModal &&
                <UserAddressFormModal
                    openModal={showUserAddressFormModal}
                    closeModal={() => closeModal(MODALS.USER_ADDRESS_FORM)}
                    addressType={"ship_to"}
                    isOrdering={true}
                    isAddingAdditionalLocation={true}
                    setExternalProcessing={setProcessing}
                    errorMsgHandler={handleErrorMsg}
                />
            }
            <Box display="flex" flexDirection="column" m={4}>
                <GlobalBanner />
                {!defaultBillToAddress && <MessageBanner
                    dataTestId="mising-billing-banner"
                    type="warning"
                    messageText={
                        <TypographyP1>
                            There was a problem accessing your <span style={{ fontWeight: 700 }}>billing address, </span>
                            which is required to proceed. Please try logging out and logging back in. If the issue persists,
                            please <Link to="/contact-us" >Contact us</Link> for further assistance.
                        </TypographyP1>
                    }
                />}
                <Typography style={{ fontSize: '30px', fontWeight:500, marginBottom: '16px', fontFamily: 'Aeonik Regular' }}>Quick order form</Typography>
                <Box display="flex" 
                    width="100%" 
                    alignItems='center'
                    gap='19px'
                    sx={{
                        flexDirection: {
                          xs: 'column', 
                          sm: 'row'     
                        }}}
                >
                    <Box flexGrow={3} width="80%" minHeight={'70px'}>
                        {isAuthenticated && <GPOComponent productsForPricing={getOrderableProductCodes} onContractChange={handleContractChange} />}
                    </Box>
                </Box>
                <Box display="flex" 
                    width="100%" 
                    alignItems='center'
                >
                    <Box flexGrow={3} width="80%">
                        {canShowTaxExemptCheckbox && <TaxExemptSelection/>}
                    </Box>
                </Box>

                {isAuthenticated && getOrderDetailCards}
                <Box flex={1} minWidth={'300px'} mb={3} mt={3}>
                    <Button
                        buttonType={'mds-secondary'}
                        data-testid='deliverToAddressBtn'
                        muiIconLeft={AddCircleOutlineIcon}
                        disabled={!isAuthenticated}
                        onClick={() => {setOpenAddressModal(true)}}
                    >
                        Deliver to additional address
                    </Button>
                </Box>
                {
                    isAuthenticated &&
                    <Box display="flex" justifyContent="center" mt={4}>
                        <Button
                            data-testid='orderContinueBtn'
                            buttonType={'mds-primary'}
                            muiIconRight={ArrowForwardIcon}
                            onClick={handleContinue}
                        >
                            Continue
                        </Button>
                    </Box>
                }
            </Box>

        </>
    )
}

export default OrderFormPage
