import React from "react";
import Modal from "@mui/material/Modal";
import LoadingIcon from "../../assets/images/Loading.gif";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%"
};

export const Spinner = ({
  processing
}) => {
  return (
    <Modal open={processing} sx={style}>
      <img width="50" height="50" src={LoadingIcon} alt="Loader"></img>
    </Modal>
  );
};