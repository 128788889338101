import React from 'react'
import { useHistory } from "react-router-dom";
import { Button } from "../";
import { styled } from '@mui/material/styles';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const StyledButton = styled(Button)(({ theme: { palette: { primary, secondary, neutral }, breakpoints }}) => ({
  border: `1px solid ${primary.main} !important`,
  background: neutral.white,
  color: ` ${primary.main} !important`,
  padding: "0px !important",
  width: "35px",
  height: "35px",
  minWidth: "0px !important",
  borderRadius: "50% !important",
  left: "90px",
  top: "75px",
  "&:hover": {
    background: primary.blue,
    color: `${neutral.white} !important`,
    border: `1px solid ${primary.blue} !important`,
  },
  [breakpoints.down('sm')]: {
    top: "unset",
    left: "unset",
    width: "48px",
    height: "48px"
  }
}));

const StyledKeyboardBackspaceIcon = styled(KeyboardBackspaceIcon)`
  top: 1px;
  position: relative;
`;

export const BackComponent = ({onClickHandler, ...props}) => {
  const history = useHistory();
  const backButtonClicked = () => {
    if(onClickHandler){
      onClickHandler()
    }else{
      history.goBack()
    }
  }
  return (
    <StyledButton
        id="backBtn"
        buttonType="primary"
        icon={false}
        onClick={backButtonClicked}
        {...props}
      >
        <StyledKeyboardBackspaceIcon />
    </StyledButton>
  );
};
