import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Button } from "../Button";
import { useHistory } from "react-router-dom";
import { loadTokenExpiredStaticContent } from "../../features/contentful/contentfulThunkApi";
import { Box, Typography } from "@mui/material";

const ButtonContainer = styled('div')(({ theme: { breakpoints } }) => `
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  margin: 50px auto 40% auto;
  ${[breakpoints.down('sm')]} {
    width: 60%;
    margin-top: 130px;
    margin-bottom: 150px;
  }
`);

export const TokenExpired = () => {
  const { tokenExpiredContent } = useSelector((store) => store?.contentful);
  const { staticContent = [] } = tokenExpiredContent;
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector((state) => state.globalStates.locale);
  
  useEffect(() => {
    window.scrollTo(0,0);
    if (Object.keys(tokenExpiredContent).length === 0) {
      dispatch(loadTokenExpiredStaticContent());
    }
  },[]);

  const resetPasswordHandler = () => {
    history.push(`/${locale}/request-reset-password`);
  }

  return (
      <div>
        <Box component={'img'} margin={{xs:'50px auto 20px auto',sm:'20px auto'}} display='flex' id="tokenExpiredImg" data-testid='tokenExpiredImg' src={staticContent?.icon?.fields?.file?.url} width="90px" height="90px"></Box>
        <Typography variant={'h1'} textAlign='center' color='primary.main' id="headline" data-testid='headline'>{staticContent?.headline}</Typography>
        <Typography variant={'p1'} textAlign='center' mt='20px' id="tokenExpiredMessage" data-testid='tokenExpiredMessage'>{documentToReactComponents(staticContent?.message)}</Typography>
        <ButtonContainer>
          <Button
            id="resetButton"
            buttonType="primary"
            onClick={resetPasswordHandler}
          >{staticContent?.resetButtonLabel}
          </Button>
      </ButtonContainer>
    </div>
  )
};