import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Spinner } from "../Spinner";
import { NewPassword } from "./NewPassword";
import { TokenExpired } from "./TokenExpired";
import { useOktaAuth } from '@okta/okta-react';
import { ResetSuccess } from "./ResetSuccess";
import { useHistory } from "react-router-dom";
import { SetPasswordResetSuccess } from './SetPasswordResetSuccess';
import { useSelector } from "react-redux";

export const ResetPassword = ({ isASMuser, comp, setComp }) => {
  
  const [userEmail, setUserEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const { oktaAuth } = useOktaAuth() || '';
  const [processing, setProcessing] = useState(false);
  const history = useHistory();
  const locale = useSelector((state) => state.globalStates.locale);
  
  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const recoveryToken = params.get("token");
    if(recoveryToken) {
      setComp('');
      setProcessing(true);
      oktaAuth.verifyRecoveryToken({
        recoveryToken: recoveryToken
      })
      .then(transaction => {
        if (transaction.status === 'PASSWORD_RESET') {
          setUserEmail(transaction?.user?.profile?.login);
          setFirstName(transaction?.user?.profile?.firstName);
          setProcessing(false);
          setComp('newPassword');
        } else {
          setProcessing(false);
          history.push(`/${locale}/request-reset-password`);
          const error = 'We cannot handle the ' + transaction.status + ' status';
          throw error;
        }
      })
      .catch(err => {
        setProcessing(false);
        setComp('tokenExpired');
      });
    }
  },[history, oktaAuth]);

  return (
    <>
    <Spinner processing={processing} />
    <Box sx={{ flexGrow: 1 }}>
      { 
      comp === 'newPassword' ? <NewPassword setComp={setComp} userEmail={userEmail} isASMuser={isASMuser} /> : 
      ( comp === 'setPasswordResetSuccess' ) ? <SetPasswordResetSuccess firstName={firstName} /> : 
      ( comp === 'resetSuccess' ) ? <ResetSuccess /> : 
      comp === 'tokenExpired' ? <TokenExpired userEmail={userEmail}/> : ''
      }
    </Box>
    </>
  );
};
