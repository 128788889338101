import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme: { palette: { neutral, primary }} }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: `linear-gradient(180deg, ${neutral.gray2} , white)`,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "50px 50px",
    background: `linear-gradient(90deg, #C6E5F4 , ${primary.blue})`,
  },
}));

const CustomProgressBar = ({ progressValue, sx }) => {
  return (
    <Box sx={sx}>
      <BorderLinearProgress variant="determinate" value={progressValue} />
    </Box>
  );
};

export { CustomProgressBar as ProgressBar };
