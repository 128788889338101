import { useState, useRef } from "react";
import { Box, Typography, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Dropdown from "../Dropdown";
import {US_STATES, USER_STATUS} from "../../../constants";
import { AlertMessage, Button, Radio, Spinner } from "../../../components"
import {
    formatStateOptions,
    FormLabel,
    helperTextStyles,
    textFieldStyles,
    validateCity,
    validateEmail,
    validateRequiredField,
    validateShippingPhoneNumber,
    validateZipcode
} from "../Helper";
import { getUserByEmail, updateUserDetailsApi } from "../../../utils/UserService/UserApis";
import {setIsOnboardingSuccessful, setUserStatus} from "../../../features/globalStates/globalStatesSlice";
import {useHistory} from "react-router-dom";
import {getUserErrorMessages} from "../../../utils/getErrorMessage";


const typographyTextStyle = {
    fontFamily: 'Aeonik Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
};

const billingAddressText = {
    headerText: "Your billing address is where we will send your invoice. ",
    subHeader: "My organization’s billing address is:",
    sameAsShippingText: "Usually the same as my Shipping address:",
    differentAddressText: "A different address:"
}

const RadioTitleWrapper = styled('div')`
  margin-left: 12px;
  display: flex;
  align-items: center;
`;

const billingInputFieldText = {
    companyName: 'Company (Doing business as) name',
    contactName: 'Billing department or contact person',
    contactEmail: 'Billing contact email',
    phoneNumber: 'Billing contact phone',
    extension: 'Ext',
    addressLine1: 'Address line 1',
    addressLine2: 'Suite Number',
    city: 'City',
    state: 'State',
    zipcode: 'ZIP code',
    zipcodeExt: 'ZIP +4',
}
const billingInputFieldPlaceholder = {
    companyName: 'e.g., Riverside Hospital, Greenleaf Community Pharmacy',
    contactName: 'e.g., Johnathan Doe, Pharmacy',
    contactEmail: 'Optional',
    phoneNumber: 'Optional',
    extension: '',
    addressLine1: 'e.g., 123 Main St',
    addressLine2: 'e.g., Dock 5, Ste 301',
    city: '',
    state: '',
    zipcode: '',
    zipcodeExt: '',
}

export const BillingAddressForm = ({ tabIndex, onTabChange = () => {}, errorMsgHandler }) => {
    const defaultFields = {
        companyName: '',
        contactName: '',
        contactEmail: '',
        phoneNumber: '',
        extension: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipcode: '',
        zipcodeExt: '',
    }

    const [inputFields, setInputFields] = useState(defaultFields);
    const [errors, setErrors] = useState(defaultFields);
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    // Need to add user service API and error handling - ECOM-1097
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const [errorMsg, setErrorMsg] = useState('');
    const alertRef = useRef(null);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const locale = useSelector((state) => state.globalStates.locale);
    // const [billingAddressOption, setBillingAddressOption] = useState('sameAsShipping'); Commenting for ECOM-1317 
    const USStates = formatStateOptions(US_STATES);
    const requiredFields = ['companyName', 'contactName', 'addressLine1', 'city', 'state', 'zipcode'];
    const { userId, userData } = useSelector((store) => ({
        userId: store?.commerce?.userData?.userId || '',
        userData: store?.commerce?.userData,
    }));
    const validateField = (name, value) => {
        let errorMsg = "";

        if (requiredFields.includes(name) && value.trim() === '') {
            errorMsg = validateRequiredField(value);
        } else if (name === "contactEmail" && value) {
            errorMsg = validateEmail(value);
        } else if (name === "phoneNumber" && value) {
            errorMsg = validateShippingPhoneNumber(value);
        } else if (name === "city") {
            errorMsg = validateCity(value);
        } else if (name === "zipcode") {
            errorMsg = validateZipcode(value);
        }

        return errorMsg;
    };

    const handleShippingOptionChange = (event) => {
        const optionSelected = event.target.value;
        if(optionSelected === 'sameAsShipping'){
            setErrors(defaultFields)
        }
        setBillingAddressOption(optionSelected);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newInputFields = { ...inputFields, [name]: value };
        setInputFields(newInputFields);
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
        const errorMsg = validateField(name, value);
        setErrors({ ...errors, [name]: errorMsg });
    };

    const handleStateSelection = (event) => {
        const { value } = event.target
        const newInputFields = { ...inputFields, state: value }
        setInputFields(newInputFields)

        const newErrors = { ...errors, state: "" }
        setErrors(newErrors);
    };

    let updateUserRequestBody = {
        userId: userId,
        updateType: "billing",
        organization: {
            orgType: "bill_to",
            dbaName: inputFields?.companyName,
            contactEmail: inputFields?.contactEmail,
            contactPhone: inputFields?.phoneNumber,
            address: {
                addressType: "bill_to",
                attention: inputFields?.contactName,
                line1: inputFields?.addressLine1,
                line2: inputFields?.addressLine2,
                city: inputFields?.city,
                state: inputFields?.state,
                zip: inputFields?.zipcode,
                zipExtension: inputFields?.zipcodeExt,
            }
        }
    }

    const handleUpdateBilling = async (sameAsShipping = false) => {
        // if (sameAsShipping) {
        //     const shippingAddress = userData?.addresses?.filter(address => address.addressType === 'ship_to')[0];
        //     updateUserRequestBody = {
        //         userId: userId,
        //         updateType: "billing",
        //         organization: {
        //             orgType: "bill_to",
        //             dbaName: shippingAddress?.dba,
        //             contactEmail: shippingAddress?.contactEmail,
        //             contactPhone: shippingAddress?.contactPhone,
        //             address: {
        //                 addressType: "bill_to",
        //                 attention: shippingAddress?.contactName,
        //                 line1: shippingAddress?.line1,
        //                 line2: shippingAddress?.line2,
        //                 city: shippingAddress?.city,
        //                 state: shippingAddress?.state,
        //                 zip: shippingAddress?.zip,
        //                 zipExtension: shippingAddress?.zipExtension,
        //             }
        //         }
        //     }
        // }
        try {
            setIsLoading(true);
            const response = await updateUserDetailsApi(updateUserRequestBody);
            if (response?.status === 200) {
                dispatch(getUserByEmail({emailAddress: response?.data?.email}))
                dispatch(setUserStatus(response?.data?.userStatus));
                setInputFields(defaultFields);
                if (response?.data?.userStatus == USER_STATUS.ONBOARDING_BILL_COMPLETE) {
                    onTabChange(tabIndex + 1);
                } else if (response?.data?.userStatus == USER_STATUS.ACTIVE){
                    dispatch(setIsOnboardingSuccessful(true));
                    history.push(`/${locale}/onboarding/success`);
                }

            }
        } catch (error) {
            const errorMessage = getUserErrorMessages(error?.response?.data?.errorCode)
            errorMsgHandler(errorMessage)
        } finally {
            setIsLoading(false);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // Commenting for ECOM-1317 
        // if(billingAddressOption === 'sameAsShipping'){
        //     handleUpdateBilling(true);
        // }
        // else{
            const validationErrors = {};
            Object.keys(inputFields).forEach((fieldName) => {
                const errorMsg = validateField(fieldName, inputFields[fieldName]);
                if (errorMsg) validationErrors[fieldName] = errorMsg;
            });

            if (Object.keys(validationErrors).length === 0) {
                handleUpdateBilling();
            } else {
                setErrors(validationErrors);
            }
        // }
    };

    const createTextField = (name, value, onChange, onBlur, isRequired) => {
        return (
            <>
                <FormLabel label={billingInputFieldText[name]} isRequired={isRequired}/>
                <TextField
                    id={name}
                    data-testid={name}
                    name={name}
                    type="text"
                    value={value}
                    placeholder={billingInputFieldPlaceholder[name]}
                    onChange={onChange}
                    onBlur={onBlur}
                    focused
                    variant="outlined"
                    fullWidth
                    sx={textFieldStyles(errors[name])}
                    error={Boolean(errors[name])}
                    helperText={errors[name]}
                    FormHelperTextProps={helperTextStyles}
                />
            </>
        )
    }

    return (
        <>
            <Spinner processing={isLoading} />
            <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
            <Box flexDirection='column' width='100%' maxWidth='700px'>
                <Box mt={4}>
                    <Typography sx={typographyTextStyle}>{billingAddressText?.headerText}</Typography>
                </Box>
                <Box mt={2}>
                    <Typography sx={typographyTextStyle} paddingBottom={'10px'}><span style={{ color: 'red' }}>*</span>Required</Typography>
                </Box>
                
                {/* Commenting for ECOM-1317 
                <Box mt={2} mb={3}>
                    <Typography variant={'h4'}>{billingAddressText?.subHeader}</Typography>
                </Box>
                <Box display="flex" alignItems={"center"}>
                    <Radio
                        checked={billingAddressOption === 'sameAsShipping'}
                        size="medium"
                        name="sameAsShipping"
                        value="sameAsShipping"
                        onChange={handleShippingOptionChange}
                        radiosx={{
                            '&.Mui-checked': {
                                color: '#545F71',
                            },
                            padding: '0px'
                        }}
                    />
                    <RadioTitleWrapper>
                        <Typography data-testid='sameAsShippingText' sx={typographyTextStyle}>
                            Usually the same as my <span style={{ fontWeight: '700' }}>Shipping address</span>.
                        </Typography>
                    </RadioTitleWrapper>
                </Box>
                <Box mt={3} mb={3} display="flex" alignItems={"center"}>
                    <Radio
                        checked={billingAddressOption === 'differentAddress'}
                        size="medium"
                        name="differentAddress"
                        value="differentAddress"
                        onChange={handleShippingOptionChange}
                        radiosx={{
                            '&.Mui-checked': {
                                color: '#545F71',
                            },
                            padding: '0px'
                        }}
                    />
                    <RadioTitleWrapper>
                        <Typography data-testid='sameAsShippingText' sx={{...typographyTextStyle, fontWeight: '700'}}>
                            A different address:
                        </Typography>
                    </RadioTitleWrapper>
                </Box> */}
                <Box>
                    <Grid container data-testid='facilityInfoSection' rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" marginBottom={'48px'}>
                        <Grid item xs={12} paddingBottom={'1px'}>
                            {createTextField("companyName",  inputFields.companyName, handleChange, handleBlur, true)}
                        </Grid>
                        <Grid item xs={12} paddingBottom={'1px'}>
                            {createTextField("contactName",  inputFields.contactName, handleChange, handleBlur, true)}
                        </Grid>
                        <Grid item xs={12} sm={5} paddingBottom={'1px'}>
                            {createTextField("contactEmail",  inputFields.contactEmail, handleChange, handleBlur, false)}
                        </Grid>
                        <Grid item xs={12} sm={5} paddingBottom={'1px'}>
                            {createTextField("phoneNumber",  inputFields.phoneNumber, handleChange, handleBlur, false)}
                        </Grid>
                        <Grid item xs={12} sm={2} paddingBottom={'1px'}>
                            {createTextField("extension",  inputFields.extension, handleChange, handleBlur, false)}
                        </Grid>
                    </Grid>
                    <Grid container data-testid='billingAddressInfoSection' rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" marginBottom={'48px'}>
                        <Grid item xs={12} paddingBottom={'1px'}>
                            {createTextField("addressLine1",  inputFields.addressLine1, handleChange, handleBlur, true)}
                            <Typography fontFamily={'Aeonik Regular'} fontWeight={'400'} letterSpacing={'-0.28px'}>Note: Orders cannot be delivered to P.O. Boxes</Typography>
                        </Grid>
                        <Grid item xs={12} paddingBottom={'1px'}>
                            {createTextField("addressLine2",  inputFields.addressLine2, handleChange, handleBlur, false)}
                        </Grid>
                        <Grid item xs={12} sm={4} paddingBottom={'1px'}>
                            {createTextField("city",  inputFields.city, handleChange, handleBlur, true)}
                        </Grid>
                        <Grid item xs={12} sm={4} paddingBottom={'1px'}>
                            <Dropdown
                                dataTestId={"stateDropdownSelection"}
                                name={"state"}
                                label={"State"}
                                options={USStates}
                                value={inputFields.state}
                                onChangeHandler={handleStateSelection}
                                isRequired={true}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} paddingBottom={'1px'}>
                            {createTextField("zipcode",  inputFields.zipcode, handleChange, handleBlur, true)}
                        </Grid>
                        <Grid item xs={12} sm={2} paddingBottom={'1px'}>
                            {createTextField("zipcodeExt",  inputFields.zipcodeExt, handleChange, handleBlur, false)}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Button
                type="submit"
                id="submit"
                sx={{ padding: "16px 24px", textTransform: "none" }}
                buttonType="mds-primary"
                data-testid='continueBtn'
                onMouseDown={handleSubmit}
            >
                Continue
            </Button>
        </>
    )
}

export default BillingAddressForm