const isSpikevaxMresvia2024ProductEnabled = process.env.IS_SPIKEVAX_MRESVIA_2024_PRODUCTS_ENABLED === 'true';
const isCatalogServiceEnabled = process.env.IS_CATALOG_SERVICE_ENABLED === 'true';

const unitMapping = (unit) => {
    const unitSymbol = {
        'milliliter' : 'mL',
        'liter' : 'L'
    }
    return unitSymbol?.[unit] || "";
}
export const mapProductNames = (orderProducts) => {
    if (isCatalogServiceEnabled) {
        return orderProducts?.length
            ? orderProducts.reduce((all, item) => {
                const displayProductInfo  = item?.displayInfo
                all[item.materialMasterId] = `${displayProductInfo?.name} - ${displayProductInfo?.description} - ${displayProductInfo?.displayAge}`;
                return all;
            }, {})
            : {};
    }
    if (isSpikevaxMresvia2024ProductEnabled) {
        return orderProducts?.length
            ? orderProducts.reduce((all, item) => {
                const ageGroupText = item?.ageGroup ? ` - ${item?.ageGroup}` : '';
                all[item.code] = `${item?.name} - ${item?.doseVolume} ${unitMapping(item?.doseVolumeUOM)} ${item?.description}${ageGroupText}`;
                return all;
            }, {})
            : {};
    }
    else{
        return orderProducts?.length ? orderProducts.reduce((all, item) => ({...all,
            [item.code]: item?.ageGroup ? `${item?.description}, ${item?.ageGroup}`: `${item?.description}`
        }), {}) : {};
    }
}

export const getPricingInfo = (allPrices) => {
    let productPricingMap = allPrices?.productPrices.reduce((all, productPrice) => {
        return {
            ...all,
            [productPrice?.productId]: {
                listPrice: productPrice?.listPriceFormatted,
                contractPrice: productPrice?.finalPriceFormatted
            }
        }
    }, {}) || {};
    return productPricingMap;
}

export const convertUserDataAddressToCartData = (userDataAddress) => {
    return {
        "addressId": String(userDataAddress?.id),
        "street1": userDataAddress?.line1 ,
        "street2": userDataAddress?.line2 ,
        "city": userDataAddress?.city ,
        "state": userDataAddress?.state ,
        "zipCode": userDataAddress?.zip,
        "zipExtraFour": userDataAddress?.zipExtension ,
        "name": userDataAddress?.dba ,
        "contactInfo": {
            "name": userDataAddress?.contactName,
            "email": userDataAddress?.contactEmail,
            "phone": userDataAddress?.contactPhone,
        }
    };
};

export const convertCartDataAddressToUserData = (cartDataAddress) => {
    return {
        id: cartDataAddress?.addressId,
        line1: cartDataAddress?.street1,
        line2: cartDataAddress?.street2,
        city: cartDataAddress?.city,
        state: cartDataAddress?.state,
        zip: cartDataAddress?.zipCode,
        zipExtension: cartDataAddress?.zipExtraFour,
        dba: cartDataAddress?.name,
        contactName: cartDataAddress?.contactInfo?.name,
        contactEmail: cartDataAddress?.contactInfo?.email,
        contactPhone: cartDataAddress?.contactInfo?.phone,
    };
};

export const getLicenseName = (license) => {
    let licenseName = license?.licenseType === 'medical' ? 
    `${license?.firstName} ${license?.lastName}` : license?.name;
    return licenseName
}

export const getMedicalLicense = (licenseSelection) => {
    return {
        name: getLicenseName(licenseSelection),
        state: licenseSelection?.state,
        licenseNumber: licenseSelection?.number,
        expirationDate: licenseSelection?.expiration,
        licenseType: "STATE",
    }
}