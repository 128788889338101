import {createContext, useContext} from 'react';

export const IdxTransaction = createContext({});
export const useIdxTransaction = () => useContext(IdxTransaction);

export const MyAccountContext = createContext({});
export const useMyAccountContext = () => useContext(MyAccountContext);

export const UserInfoContext = createContext(null)
export const useUserInfoContext = () => useContext(UserInfoContext)

export const ModalContext = createContext();
export default ModalContext;
