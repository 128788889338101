import { Box, CircularProgress, Typography } from '@mui/material';

const CustomSpinner = ({ message, sx={}, messageSx={} }) => {
    return (
        <Box 
            sx={sx}
            data-testid="spinnerContainer"
        >
            <Box sx={{ pointerEvents: 'all', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress />
                <Typography 
                    paddingTop={'16px'}
                    textAlign={'center'}
                    fontFamily={'Aeonik Regular'}
                    sx={messageSx}
                >
                    {message}
                </Typography>
            </Box>
        </Box>
    );
}

export default CustomSpinner;
