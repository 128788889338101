import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Box, TextField, IconButton, InputAdornment, FormControl, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button } from '../Button';
import { useModal } from '../../hooks/useModal';
import { useAuth } from '../../hooks/useAuth';
import { setEmailAddress, setIsLoggingIn } from '../../features/globalStates/globalStatesSlice';

export const LoginForm = () => {
  const { handleCloseModal, postLoginRedirectPath, setPostLoginRedirectPath } = useModal();
  const history = useHistory();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState("");
  const [invalidPassword, setInvalidPassword] = useState("");
  const locale = useSelector((state) => state.globalStates.locale);

  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const { signIn } = useAuth({
    setInvalidEmail,
    setInvalidPassword,
    setProcessing,
    locale
  });


  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setInvalidEmail("");
    setInvalidPassword("");
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    dispatch(setEmailAddress(values.email));
    dispatch(setIsLoggingIn(true));
    const onSuccess = () => {
      if (postLoginRedirectPath) {
        setPostLoginRedirectPath(null);
      } else {
        closeLoginModal();
      }
    };

    await signIn(values.email, values.password, onSuccess, postLoginRedirectPath);
    dispatch(setIsLoggingIn(false));
  };

  const closeLoginModal = () => {
    handleCloseModal();
  };

  return (
    <Box width="408px" display="flex" flexDirection={'column'}>
      <Typography 
        fontSize={'24px'} 
        fontFamily={'Aeonik Regular'}
        fontWeight={700}
        lineHeight={'30px'}
        paddingBottom={'16px'}
        display={'flex'}
      >
        Log In
      </Typography>
      <form onSubmit={handleLoginSubmit}>
        <FormControl fullWidth>
          <Typography display="flex" paddingBottom={'8px'}>Email</Typography>
          <TextField
            id="email"
            placeholder="Enter your email address"
            value={values.email}
            onChange={handleChange('email')}
            helperText={invalidEmail}
            error={!!invalidEmail}
            variant="outlined"
            margin="dense"
            display="flex"
            focused
          />
        </FormControl>
        <FormControl fullWidth>
        <Typography display="flex" paddingBottom={'8px'}>Password</Typography>
          <TextField
            id="password"
            placeholder="Enter your password"
            type={values.showPassword ? 'text' : 'password'}
            error={!!invalidPassword}
            value={values.password}
            onChange={handleChange('password')}
            helperText={invalidPassword}
            variant="outlined"
            margin="dense"
            display="flex"
            focused
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Link 
          id="forgotPassword"
          data-testid="forgotPasswordLink" 
          to={`/${locale}/request-reset-password`}
          onClick={closeLoginModal}
        >
          <Typography 
            variant={'link'}
            component={'div'}
            color={'secondary.textLinks'}
            display={'flex'} padding={'10px 0px'}
          >
            Reset your password
          </Typography>
        </Link>
        <Box paddingTop="20px">
          <Button sx={{display:"flex", textTransform: "none"}} onClick={handleLoginSubmit} loading={processing} buttonType='mds-primary'>Log In</Button>
        </Box>
      </form>
    </Box>
  );
};
