import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, useTheme } from "@mui/material";
import { setCartData, setReservationData } from '../../features/commerce/commerceSlice';
import { Button } from '../Button';
import { ModalComponent } from '../ModalComponent';
import MapIcon from '../../assets/images/mapIcon.svg'
import CheckIcon from '../../assets/images/check.svg'
import PlusIcon from '../../assets/images/plusIcon.svg'
import { sortAddresses, getOrderableProducts } from '../../utils/Helper';
import { generateInternalCartLineItemId } from "../../pages/Ordering/OrderHelper";

const AddressCardStyles = {
    display: 'flex',
    width: '348px',
    height: '207px',
    padding: '16px 24px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '16px',
    borderRadius: '16px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    "&:hover": {
        boxShadow: "0px 3px 16px #00000029",
        cursor: 'pointer'
    },
};

const AddressSelectionModal = ({closeModal, openModal, currentAddress, allSelectedAddresses, handleAddNewAddress, isOrdering=false}) => {
    const userData = useSelector((store) => store?.commerce?.userData);
    const defaultAddress = userData?.addresses?.filter(address => address.addressType === 'ship_to')[0];
    const { reservationData } = useSelector((store) => store?.commerce);
    const { reservationProducts } = useSelector((store) => store?.commerce);
    const isCatalogServiceEnabled = process.env.IS_CATALOG_SERVICE_ENABLED === 'true';
    const orderableProducts = getOrderableProducts();
    const [addressSelection, setAddressSelection] = useState(currentAddress);
    const {cartData}  = useSelector((store) => store?.commerce);
    const dispatch = useDispatch()
    const theme = useTheme();

    const shipToAddresses = useMemo(() => {
        const addresses = userData?.addresses || [];
        return addresses
            .filter(address => address.addressType === "ship_to")
            .map(address => ({
                ...address,
                defaultAddress: address.id === defaultAddress?.id,
            }))
            .sort(sortAddresses);
    }, [defaultAddress]);

    const handleSubmit = () => {
        if (!addressSelection) {
            closeModal();
            return;
        }

        let currentAddressId = String(currentAddress?.id)
        
        //allSelectedAddresses is the list of all selected addresses in reservation page & we pass it as prop only in case of adding a new delivery location
        if (allSelectedAddresses) {
            return handleAdditionalAddress();
        }
        if(isOrdering){
            const updatedCartListItems = cartData?.cartLineItems.map((entry) => {
                if (String(entry?.shipToAddress?.addressId) === currentAddressId) {
                    return {
                        ...entry,
                        shipToAddress: {
                            addressId: String(addressSelection?.id)
                        },
                        shipToOrganizationId: String(addressSelection?.organizationId),
                    }
                }
                return entry;
            });
            dispatch(setCartData({cartLineItems: updatedCartListItems}));
        }
        else{
            const updatedEntries = reservationData?.reservationEntries.map((entry) => {
                if (String(entry.deliveryAddressId) === currentAddressId) {
                    return { ...entry, deliveryAddressId: addressSelection.id };
                }
                return entry;
            });
            dispatch(setReservationData({ reservationEntries: updatedEntries }));
        }
        closeModal()
    }
   // The following submit function will be for adding additional shipping location
    const handleAdditionalAddress = () => {
        //TBD: Needs refactoring
        if(isOrdering){
            const newCartLineItems = orderableProducts?.map(product => {
                const internalCartLineItemId = generateInternalCartLineItemId();
                return {
                    internalCartLineItemId: internalCartLineItemId,
                    cartId: cartData.cartId,
                    shipToAddress: {addressId: String(addressSelection?.id)},
                    shipToOrganizationId: String(addressSelection?.organizationId),
                    productId: isCatalogServiceEnabled ? product?.materialMasterId : product?.code,
                    quantity: 0,
                    purchaseOrderNumber: "",
                    healthcareLicense:{},
                    priceSnapshotId:"",
                };
            });

            // Combine the existing reservation entries with the new entries for the additional address
            const updatedCartLineItems = cartData?.cartLineItems? [...cartData?.cartLineItems, ...newCartLineItems] : [...newCartLineItems];
            // Dispatch an action to update the reservation entries in the Redux store
            dispatch(setCartData({
                cartLineItems: updatedCartLineItems
            }));
        }
        else{
            const newReservationEntries = reservationProducts?.map(product => {
                const uniqueSuffix = Math.random().toString(36).substr(2, 9);
                const reservationEntryId = `${product.code}-${uniqueSuffix}`;
                return {
                    reservationEntryId: reservationEntryId,
                    reservationId: reservationData.reservation.reservationId,
                    deliveryAddressId: addressSelection.id,
                    productId: product.code,
                    quantity: 0 // Start with a quantity of 0 for the new address
                };
            });

            // Combine the existing reservation entries with the new entries for the additional address
            const updatedReservationEntries = [
                ...reservationData.reservationEntries,
                ...newReservationEntries
            ];

            // Dispatch an action to update the reservation entries in the Redux store
            dispatch(setReservationData({
                ...reservationData,
                reservationEntries: updatedReservationEntries
            }));
        }
        closeModal()
    }

    const isAddressSelected = (addressId) => {
        return allSelectedAddresses?.filter(address => address?.id === addressId).length
    }

    const AddressSelection = (
        <Box display="inline-flex" flexDirection="column" alignItems="flex-start" gap="23px">
            <Typography fontSize="23px" fontWeight="700" lineHeight="30px">Choose a shipping address</Typography>
            <Box display="flex" alignItems="flex-start" gap="24px" flexWrap="wrap">
                { shipToAddresses.map((address,i) => {
                    const isSelected = String(address?.id) === String(addressSelection?.id)
                    if (isAddressSelected(address?.id)) {
                        return null
                    }
                return (
                    <Box
                        key={address?.id}
                        sx={{
                            ...AddressCardStyles,
                            border: isSelected ? '1px solid #079AE0' : '1px solid transparent'
                        }}
                        data-testid={`addressTile-${i}`}
                        tabIndex="0"
                        onClick={() => {setAddressSelection(address)}}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setAddressSelection(address);
                            }
                        }}
                    >
                        <Box display="flex" width="300px" gap="10px" data-testid="addressBlock" alignItems="flex-start">
                            <Box padding="4px 0px"><img src={MapIcon} width="24" height="22.15" /></Box>
                            <Box display="flex" flexDirection="column" paddingTop="3px" alignItems="flex-start" flex="1 0 0" data-testid="addressDetails">
                                <Typography fontWeight="700" fontFamily="Aeonik Regular">{address?.dba}</Typography>
                                <Typography fontWeight="400" fontFamily="Aeonik Regular">{address?.line1}</Typography>
                                <Typography fontWeight="400" fontFamily="Aeonik Regular">{address?.line2}</Typography>
                                <Typography fontWeight="400" fontFamily="Aeonik Regular">
                                    {`${address?.city}, ${address?.state}, ${address?.zip}`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" height="32px" justifyContent="center" alignItems="center" gap="8px">
                            {
                            isSelected ?
                            <>
                                <img src={CheckIcon} alt="check icon"  width="32" height="32"/>
                                <Typography fontWeight="700" fontFamily="Aeonik Regular" color="#0379B2">Selected</Typography>
                            </> :
                                <Typography fontWeight="400" fontFamily="Aeonik Regular" color="#0379B2">Select this address</Typography>

                            }
                        </Box>
                    </Box>
                )
            })}
            <Box 
                data-testid="addAddressTile"
                sx={{
                    ...AddressCardStyles,
                    justifyContent: 'center'
                }}
                onClick={handleAddNewAddress}
            >
                <Box display="flex" justifyContent="center" alignItems="center" gap="8px">
                    <img src={PlusIcon} alt="plus icon" width="32" height="32"/>
                    <Typography fontWeight="700" fontFamily="Aeonik Regular" color="#0379B2">Add new address</Typography>
                </Box>
            </Box>
            </Box>
            <Box width="100%" display="flex" justifyContent="center">
                <Button
                    buttonType='mds-primary'
                    sx={{ padding: "16px 32px", textTransform: "none", }}
                    onClick={handleSubmit}
                >
                    Submit
                </Button> 
            </Box>
        </Box>
    )
    
    
    return (
        <ModalComponent
            openModal={openModal}
            closeModalHandler={closeModal}
            style={{ 
                maxWidth: '1155px',
                width: 'fit-content',
                padding: '24px',
                display: 'flex',
                alignItems: 'flex-start',
                gap: '10px',
                overflowX: 'hidden',
                [theme.breakpoints.down('lg')]: {
                    width: '800px',
                },
                [theme.breakpoints.down('md')]: {
                    width: '400px',
                },
        }}
        modalContent={AddressSelection}
        />
      )
}

export default AddressSelectionModal
