import React, {useState, useEffect} from 'react'
import { styled } from '@mui/material/styles';
import {Container, Typography, Box} from '@mui/material';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import printIcon from '../assets/images/printIcon.svg';
import { changeDateFormat, isExternalURL } from "../utils/Helper";
import { Button } from "../components";
import { useOktaAuth } from '@okta/okta-react';
import { contentfulClient } from "../sevices/useContentful";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

const BannerContainer = styled('div')(
    ({ aboveFoldBGImage,theme: { palette: { primary }, breakpoints } }) => `
    background-image: url(${aboveFoldBGImage});
    background-size: cover;
    background-position: right;
    background-color: ${primary.main};
    height: 200px;
    ${[breakpoints.down('sm')]} {
        background-image: unset;
        height: 180px;
    }
    `
);

const BannerContentContainer = styled('div')(({ theme: { breakpoints } }) => `
    display: flex;
    font-family: 'Aeonik Regular', 'Arial';
    color: #FFF;
    justify-content: space-between;
    align-items: center;
    max-width: 1050px;
    height: 100%;
    margin: 0 auto;
    ${[breakpoints.down('sm')]} {
        justify-content: space-around;
        flex-direction: column;
        padding: 36px;
    }
`);

const CustomParagraph = styled('p')`
   line-height:22px;
   padding-top: 15px;
`;

const CustomBold = styled('span')`
    font-family: 'Aeonik Bold', 'Arial';
`;

const CustomTable = styled('table')`
    border: 1px solid black;
    border-collapse: collapse;
    & th {
        border: 1px solid black;
    }
    & td {
      border: 1px solid black;
      text-align: center;
    }
`;

const CustomList = styled('ul')(({ theme: { breakpoints } }) => `
  list-style-type: initial;
  margin-left: 20px;
  font-family: 'Aeonik Regular';
  ${[breakpoints.down('sm')]} {
    margin-top: 24px;
    margin-bottom: 30px;
  }
`);

const ComposePage = ({relativePathname, ...props}) => {
  const { authState } = useOktaAuth() || '';
  const [staticContent, setStaticContent] = useState([]);
  const [staticBannerContent, setStaticBannerContent] = useState([]);
  const [dataExternalModal, setDataExternalModal] = useState(true);
  const [dataExternalSocialModal, setDataExternalSocialModal] = useState(true);
  const remindMeExternal = localStorage.getItem("dontRemindMeExternal");
  const remindMeSocial = localStorage.getItem("dontRemindMe");
  const [isLinkOutsideModrna, setIsLinkOutsideModrna] = useState(true);
  const pathName = window.location.pathname;
  const locale = useSelector((state) => {
    return state.globalStates.locale
  });
  const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);

  useEffect(() => {
    if(pathName === "/privacy-policy" || pathName === "/return-goods-policy" ||
    pathName === `/${locale}/privacy-policy` || pathName === `/${locale}/return-goods-policy`){
      setIsLinkOutsideModrna(true);
      if(remindMeSocial === "true") {
        setDataExternalSocialModal(false);
      }
    }else{
      setIsLinkOutsideModrna(false);
      if(remindMeExternal === "true") {
        setDataExternalModal(false);
      }
    }
    
  }, [remindMeExternal, remindMeSocial, pathName, locale])

  const getContenfulSlugValue = () => {
    return relativePathname.includes(`${locale}`) ?  relativePathname.replace(`/${locale}/`,"") : relativePathname.replace('/',"");
  }

  useEffect(() => {
      const query = {
          content_type: "page",
          "fields.slug": getContenfulSlugValue(),
          skip: 0,
          include: 5,
          select: "fields"
      };

      if(relativePathname !== "/"){
        contentfulClient.getEntries(query).then(response => {
          let bannerContent = response?.items[0]?.fields?.content?.fields?.modules.filter((item, index) => {
            return item?.fields?.country?.fields?.isocode === localeISOCode && item?.fields?.title.includes("Banner");
          })
          bannerContent && setStaticBannerContent(bannerContent[0]);

          let richTextContent = response?.items[0]?.fields?.content?.fields?.modules.filter((item, index) => {
            return item?.fields?.country?.fields?.isocode === localeISOCode && !item?.fields?.title.includes("Banner");
          })
          richTextContent && setStaticContent(richTextContent[0]);
        });
      } 
  },[relativePathname]);

  const openExternalLinkHandler = (e, url, type) => {
    props?.setExternalLinkModalType(type);
    if(type === "extrenalLink"){
      if(isExternalURL(url) && !localStorage.getItem("dontRemindMeExternal")){
        props?.setShowNavigationAlert(true);
        props?.setExternalLink(url);
        e.preventDefault();
      }else{
        props?.setShowNavigationAlert(false);
        props?.setExternalLink("");
      }
    }else{
      if(isExternalURL(url) && !localStorage.getItem("dontRemindMe")){
        props?.setShowNavigationAlert(true);
        props?.setExternalLink(url);
        e.preventDefault();
      }else{
        props?.setShowNavigationAlert(false);
        props?.setExternalLink("");
      }
    }
  }
  const options = {
      renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => <CustomParagraph>{children}</CustomParagraph>,
          [BLOCKS.UL_LIST]: (node, children) => <CustomList>{children}</CustomList>,
          [BLOCKS.HEADING_3]: (node, children) => <Typography variant={'h5'} pt='80px'>{children}</Typography>,
          [INLINES.HYPERLINK]: (node, children) => {
            let website_url = window.location.protocol + "//" + window.location.host;
            return <Link to={{ pathname: `${node.data.uri}` }} 
                      target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`}
                      rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}
                      onClick={(e) => {
                        isLinkOutsideModrna ? openExternalLinkHandler(e, node.data.uri, "socialLink") : openExternalLinkHandler(e, node.data.uri, "extrenalLink")
                      }}
                      data-external-modal={isLinkOutsideModrna ? dataExternalSocialModal : dataExternalModal}
                    >
                      {node.content[0].value}
                    </Link>;
          },
          [BLOCKS.TABLE]: (node, children) => (
            <CustomTable> {children}</CustomTable>
          ),
          [BLOCKS.EMBEDDED_ASSET]: (node) => {
              const { title, file } = node?.data?.target?.fields;
              const imageUrl = file?.url
              return <img style={{ maxWidth: '100%' }} src={imageUrl} alt={title} />
          },
      },
      renderText: text => {
        return text.split('\n').reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
      },
      renderMark: {
          [MARKS.BOLD]: (text) => <CustomBold>{text}</CustomBold>
      }
  };

  return (
    <div style={!authState?.isAuthenticated ? {position: "relative", margin: "0 auto"} : {}}>
         <BannerContainer aboveFoldBGImage={staticBannerContent?.fields?.backgroundImage?.fields?.file?.url}>
            <BannerContentContainer>
                <Typography variant={'h1'}>{staticBannerContent?.fields?.headline}</Typography>
            </BannerContentContainer>
        </BannerContainer>
        
        <Container maxWidth="md" sx={{paddingTop:"50px" , '@media screen and (max-width: 500px)': {padding: "50px 36px"}}}>
            <Box display='flex' justifyContent='space-between'>
                {staticContent?.fields?.effectiveDate && <Typography variant="body2" sx={{'@media screen and (max-width: 500px)': {fontSize: "16px",}}} gutterBottom>
                    {staticContent?.fields?.effectiveDateLabel} {changeDateFormat(staticContent?.fields?.effectiveDate)}
                </Typography>}
                <Box display='flex' sx={{cursor: 'pointer'}} onClick={() => window.print()}>
                  <img src={printIcon} height="20" width="20" alt="PRINT"/><span>{staticContent?.fields?.printButtonLabel}</span>
                </Box>
            </Box>
            <Typography variant="subtitle2" gutterBottom sx={{fontWeight:"bold" , '@media screen and (max-width: 500px)': {fontSize: "16px",}}}>
                {staticContent?.fields?.disclaimerMessage}
            </Typography>
            <Box sx={{ height: "auto", color: "primary.main",fontFamily: "Aeonik Regular, Arial" }} >
                {documentToReactComponents(staticContent?.fields?.content, options)}
            </Box>
            <Box display='flex' justifyContent='space-evenly' width={{xs:'60%', sm:'40%'}} margin='50px auto'>
              { staticContent?.fields?.backToTopButtonLabel &&
                <Button
                buttonType="secondary" 
                onClick={() => window.scrollTo({top:0, behavior: 'smooth'})}>
                    {staticContent?.fields?.backToTopButtonLabel}
                </Button>}
            </Box>
        </Container>
    </div>
  )
}

export default ComposePage
