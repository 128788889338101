import React, { useState, useEffect, useRef } from "react";
import { styled } from '@mui/material/styles';
import { Button, TextInput, AlertMessage } from "..";
import { EmployeeHeader } from "../Header/EmployeeHeader";
import { useUserInfoContext } from '../../contexts';
import { fetchUserApi } from "../../features/commerce/commerceThunkApi";
import { searchCustomerApi } from "../../utils/commerceEmpUsersApi";
import { useHistory, Link } from 'react-router-dom';
import { validateFields, endImpersonationSession } from "../../utils/Helper";
import { loadImpersonationLookupContent } from "../../features/contentful/contentfulThunkApi";
import { useDispatch, useSelector } from "react-redux";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { Box, Typography } from "@mui/material";
import { setCanOrder2023Products } from "../../features/globalStates/globalStatesSlice";

const StyledTextInput = styled(TextInput)(({ theme: { breakpoints } }) => `
  width: "100%",
  height: "70px",
  margin-top: "25px !important",
  ${[breakpoints.up('md')]} {
    width: "80%"
  },
  ${[breakpoints.down('sm')]} {
    margin: "20px 0px !important",
  }
`);

const CustomBold = styled('b')`
    font-weight:700;
    font-family: 'Aeonik Bold', 'Arial';
`;

export const ImpersonationLookup = ({ ...props }) => {
  const { loading, impersonationLookupContent } = useSelector((store) => store?.contentful)
  const { customerImpersonation, welcomeBanner } = impersonationLookupContent;
  const { setUserInfo } = useUserInfoContext() || '';
  const isImpersonationEnabled = process.env.IS_IMPERSONATION_ENABLED;

  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0,0);
    if (Object.keys(impersonationLookupContent).length === 0) {
      dispatch(loadImpersonationLookupContent())
    }
  },[]);

  const [startSessionIsDisabled, setStartSessionIsDisabled] = useState(false);

  const [email, setEmail] = useState("");
  const [hinNumber, setHinNumber] = useState("");
  const [deaNumber, setDeaNumber] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const [invalidHIN, setInvalidHIN] = useState(false);
  const [invalidDEA, setInvalidDEA] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [processing, setProcessing] = useState(false);
  const history = useHistory();
  const alertRef = useRef(null)
  const empUserInfo = localStorage.getItem("employeeUser") ? JSON.parse(localStorage.getItem("employeeUser")) : '';
  const { globalErrorMessage } = useSelector(store=>store?.globalMessages);

  const missingEmailErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "MISSING_EMAIL");
  const missingEmailError = missingEmailErrorData && missingEmailErrorData[0]?.fields?.message;

  const invalidEmailFormatData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "INVALID_EMAIL_FORMAT");
  const invalidEmailFormatError = invalidEmailFormatData && invalidEmailFormatData[0]?.fields?.message;

  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;

  const noCustomerFoundErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "NO_CUSTOMERS_FOUND");
  const noCustomerFoundError = noCustomerFoundErrorData && noCustomerFoundErrorData[0]?.fields?.message;

  const missingHinErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "MISSING_HIN_DEA");
  const missingHinError = missingHinErrorData && missingHinErrorData[0]?.fields?.message;

  const missingDeaErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "MISSING_DEA_HIN");
  const missingDeaError = missingDeaErrorData && missingDeaErrorData[0]?.fields?.message;


  const locale = useSelector((state) => state.globalStates.locale);
  const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Typography variant={'p2'} display={{lg:'flex', sm:"block"}} marginTop={{sm:"0px", xs:"15px"}} fontWeight={"bold"}>{children}</Typography>,
    },
    renderMark: {
        [MARKS.BOLD]: (text) => <CustomBold>&nbsp;{text}&nbsp;</CustomBold>
    }
  };

  const enableHinDeaFeature = false;

  useEffect(() => {
    if(!email || (enableHinDeaFeature && (!hinNumber && !deaNumber ))){
      setStartSessionIsDisabled(true); 
    }else{
      setStartSessionIsDisabled(false); 
    }
  }, [email, hinNumber, deaNumber, enableHinDeaFeature]);

  const validate = () => {
    let isValid = true;

    if (!email) {
      isValid = false;
      setInvalidEmail(missingEmailError);
    }

    if (email && !validateFields("email", email)) {
      isValid = false;
      setInvalidEmail(invalidEmailFormatError);
    }

    if (enableHinDeaFeature) {
      if (!hinNumber && !deaNumber) {
        isValid = false;
        setInvalidHIN(missingHinError);
        setInvalidDEA(missingDeaError);
      }
  
      if (hinNumber && !validateFields("hin", hinNumber)) {
        isValid = false;
        setInvalidHIN("Please enter a valid HIN");
      }
  
      if (deaNumber && !validateFields("dea", deaNumber)) {
        isValid = false;
        setInvalidDEA("Please enter a valid DEA Number");
      }

    }
    return isValid;

  };

  const startSessionHandler = async () => {
    if(validate()){
      try{
        setProcessing(true);
        const customerSearch = await searchCustomerApi({employeeEmail: empUserInfo?.uid, email, hin: hinNumber, dea: deaNumber })
        if (customerSearch.status === 200) {
          dispatch(fetchUserApi({ userId: customerSearch?.data?.uid }))
          .unwrap()
          .then((user) => {
            if (!user?.data) {
              setErrorMsg(noCustomerFoundError);
              alertRef.current?.openAlert()
            }else{
              localStorage.setItem("currentUser", JSON.stringify(user?.data));
              dispatch(setCanOrder2023Products(true));
              setUserInfo(user.data);
              history.push("/home")
            }
          })
        }
      }catch(err){
        setProcessing(false);
        setErrorMsg(noCustomerFoundError);
        alertRef.current?.openAlert()
      }
    }
  }

  const handleHIN = (event) => {
    setHinNumber(event.target.value.trim())
    setInvalidHIN('');
  };

  const handleDEA = (event) => {
    setDeaNumber(event.target.value.trim());
    setInvalidDEA('');
  };

  useEffect(() => {
    if(localStorage.getItem("currentUser") && localStorage.getItem("currentUser") !== "undefined" && localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined"){
      endImpersonationSession();
    }
  }, []);

  const handleMessageData = (message) => {
    const hyperlinkMessage = message?.content[0]?.content?.filter(item => item?.nodeType === "hyperlink")?.map(item => `/${locale}${item?.data?.uri}`)[0];
    const modifyUri = (message) => {
      if (message?.nodeType === "hyperlink") {
          return {
              ...message,
              data: {
                  ...message?.data,
                  uri: hyperlinkMessage
              }
          };
      }
      if (message?.content && Array.isArray(message?.content)) {
          return {
              ...message,
              content: message?.content?.map(item => modifyUri(item))
          };
      }
      return message;
    };
  
    const modifiedObj = modifyUri(message);
    return modifiedObj;
  }

  return (
    <>
      <EmployeeHeader role={empUserInfo?.name}/>
      <Box pt={{xs:'80px', sm:'0'}}>
      {
        isImpersonationEnabled === "true" && <Box backgroundColor='neutral.gray4' p={{xs:'50px 32px', sm:'50px 94px'}}>
          <AlertMessage variant={"filled"} type={"warning"} color={"error"} message={errorMsg} ref={alertRef} sx={{top:120}}/>
          <Typography variant={'h1'} fontFamily='Aeonik Bold' color='primary.main'>{welcomeBanner?.introMessage} {empUserInfo?.name?.split(" ")[0]}</Typography>
          <Box pt={{xs:'28px', sm:'35px'}} width={{lg:"70%", sm:"100%"}}>
            <Typography variant={'h2'} color='primary.main'>{ customerImpersonation?.headline }</Typography>
            <Typography variant={'p1'} color='primary.main' lineHeight='22px' mt={1}>
              Providing customer information will enable impersonation and modification of another user’s account.
            </Typography>
          </Box>
          <Box display='flex' justifyContent='flex-start' flexDirection={{xs:'column', sm:'row'}} boxShadow='0px 3px 6px #00000029' borderRadius='16px' opacity='1' padding='35px' mt='35px' backgroundColor='neutral.white'>
            <Box width={{xs:'100%', sm:'33%'}} display='grid' gap='30px' pr={{xs:'0', sm:'30px'}}>
              <Typography variant={'p2'} fontWeight={"bold"}>
                {customerImpersonation?.requiredFieldsLabel }
              </Typography>
              <StyledTextInput
                  required
                  label={ customerImpersonation?.emailLabel }
                  sublabel= { customerImpersonation?.emailHelpMessage }
                  inputType="text"
                  varient="standard"
                  name="emailField"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={"Enter email"}
                  focused
                  helperText={invalidEmail}
                  error={!!invalidEmail}
              />
            </Box>
            {
              enableHinDeaFeature ? 
            <Box width={{xs:'100%', sm:'33%'}} display='grid' gap='30px'>
              {/* This label needs to be either removed or updated accordingly */}
              {documentToReactComponents(customerImpersonation?.licenseFieldsLabel, options)}
              <StyledTextInput
                  label={ customerImpersonation?.hinNumberLabel }
                  sublabel = { customerImpersonation?.hinNumberHelpMessage }
                  inputType="text"
                  varient="standard"
                  name="HinNumber"
                  onChange={handleHIN}
                  placeholder={"Enter HIN"}
                  focused
                  helperText={invalidHIN}
                  error={!!invalidHIN}
                  maxLength="9"
              />  

              <StyledTextInput
                  label={ customerImpersonation?.deaNumberLabel }
                  sublabel = {  customerImpersonation?.deaNumberHelpMessage }
                  inputType="text"
                  varient="standard"
                  name="DeaNumber"
                  onChange={handleDEA}
                  placeholder={"Enter DEA"}
                  focused
                  helperText={invalidDEA}
                  error={!!invalidDEA}

              />
            </Box>
            :
            <></>
            }

            <Box display='flex' flexDirection='column' alignItems={{xs:'center'}} alignContent={{sm: 'flex-end'}} justifyContent='center' flexWrap='wrap' 
              width={{xs:'100%', sm:'33%'}} mt={{xs:'50px'}} paddingTop='30px'>
              <Button 
                disabled={startSessionIsDisabled}
                onClick={startSessionHandler}
                loading={processing}
                sx={{width:"52%", textAlign:{xs: 'center'}}}
                margin={{xs:'0 0 0 50px'}}
              >
                  { customerImpersonation?.startSessionButtonLabel }
              </Button>
              <Typography variant={'p2'} mt={2}>Don't have a customer account?&nbsp;
                <Link to={`/${locale}/sign-up`} id="manageLink">
                  <Typography variant={'link'} fontSize="14px" color='secondary.textLinks'>
                    Create One
                  </Typography>
                </Link>
              </Typography>

            </Box>
          </Box>
        </Box>
      }
    </Box>
  </>
  );
};
