import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { loadGlobalBannerComponent } from "../../features/contentful/contentfulThunkApi";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import MessageBanner from "../MessageBanner/MessageBanner";

const GlobalBanner = () =>  {
    const { globalBannerComponent: globalBannerContent } = useSelector((store) => store?.contentful);
    const dispatch = useDispatch();

    useEffect(() => {
        if (globalBannerContent && Object.keys(globalBannerContent)?.length === 0) {
            dispatch(loadGlobalBannerComponent());
        }
    },[dispatch, globalBannerContent]);

    return (
        globalBannerContent?.message &&
        <Box paddingBottom={"8px"}>
            <MessageBanner
                messageText={documentToReactComponents(globalBannerContent?.message)}
                type="warning"
            />
        </Box>
    )
}

export default GlobalBanner