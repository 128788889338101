import React, { useEffect, useState, useRef } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { Button } from "../Button";
import BadgeIcon from "../../assets/images/badgeIcon.png";
import { TextInput } from "../TextInput"
import { Checkbox } from "../Checkbox"
import { useDispatch, useSelector } from "react-redux";
import { loadOrderSummaryComponentContent } from "../../features/contentful/contentfulThunkApi";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from '@contentful/rich-text-types';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { placeOrderAPI } from '../../features/commerce/commerceThunkApi';
import { useUserInfoContext } from "../../contexts";
import { AlertMessage } from "../AlertMessage";
import { setPlaceOrderClicked } from "../../features/globalStates/globalStatesSlice";
import { setOrderCartData } from "../../features/commerce/commerceSlice";
import { styled } from '@mui/material/styles';

const SavingValue = styled('b')(
    ({ theme: { breakpoints } }) => `
      font-family: Aeonik Bold;
      margin-left:5px;
    `
);  

const OrderSummaryComponent = ({ sx, isCheckout = false, orderCartData, buttonClickHandler, btnDisabled, requestedDeliveryDate, datePickerError }) => {
    const dispatch = useDispatch()
    const [checked, setChecked] = useState(false);
    const [poNumberValue, setPONumberValue] = useState("");
    const [emailConsent, setEmailConsent] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const { orderSummaryComponentContent } = useSelector(store => store.contentful);
    const locale = useSelector((state) => state.globalStates.locale);
    const history = useHistory();
    const { userInfo } = useUserInfoContext();
    const { uid: userId } = userInfo;
    const alertRef = useRef(null);
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;

    const getOrderDataTag = (placedOrderData) => {
        let total_doses = 0;
        const productListTag = orderCartData.entries?.map((data) => {
            total_doses += (data.quantity * data.product?.dosesPerCarton);
            return {
                product_name: data.product?.name,
                product_detail: `${data.product?.summary}:${data.product?.doseVolume} ${data.product?.doseVolumeUOM}:${data.product?.ageGroup}`,
                product_id: `${data.product?.code}`,
                price_per_dose: `${data?.basePrice?.value / data?.product?.dosesPerCarton}`,
                product_price: `${data.totalPrice?.value}`,
                ndc_number: `${data.product?.ndc}`,
                product_doses: `${(data.quantity * data.product?.dosesPerCarton)}`,
            };});
        return {
            confirmationCode: placedOrderData?.code,
            products: productListTag,
            total_price: orderCartData?.totalPrice?.value,
            total_doses: total_doses,
        }

    }

    const handlePlaceOrder = () => {
        const requestJSON = {
            requestedDeliveryDate: requestedDeliveryDate || "",
            purchaseOrderNumber: poNumberValue || "",
            orderEmailConsent: emailConsent || false
        }
        setLoading(true);
        dispatch(placeOrderAPI({ userId, orderData: requestJSON })).unwrap()
            .then((placedOrderData) => {
                const ecomDataTag = getOrderDataTag(placedOrderData);
                dispatch(setPlaceOrderClicked(true));
                dispatch(setOrderCartData({}))
                history.push(`/${locale}/order-placed`, { dataTag: ecomDataTag });
            })
            .catch((error) => {
                setErrorMsg(generalApiError);
                alertRef.current?.openAlert(error);
                setLoading(false);
            })
    }

    useEffect(() => {
        if (orderSummaryComponentContent && Object.keys(orderSummaryComponentContent).length === 0) {
            dispatch(loadOrderSummaryComponentContent())
        }
    }, [orderSummaryComponentContent]);

    const options = {
        renderNode: {
        [INLINES.ENTRY_HYPERLINK]: (_, children) => <Box component={'span'}><Link to={`/${locale}/terms-and-conditions-of-sale`} target="_blank"><Typography variant={'link'}>{children}</Typography><Typography variant={'p2'} color={'status.error'}>*</Typography></Link></Box>,
        },
    };

    return (
        <>
            <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
            <Box display='flex' flexDirection='column'
                sx={{ borderRadius: '16px', boxShadow: '0px 3px 6px #33242429', boxSizing: 'border-box', justifySelf: 'end', ...sx }}>

                <Box sx={{ backgroundColor: "primary.blueFaded", minHeight: '60px', backgroundSize: '100%', backgroundImage: `url(${BadgeIcon})`, backgroundRepeat: "no-repeat", borderTopLeftRadius: "inherit", borderTopRightRadius: "inherit" }} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant='p1' display={"flex"} alignItems={"center"} flexDirection={{md:'column', lg:'row'}}>{documentToReactComponents(orderSummaryComponentContent?.savingsOnOrderMessage)}<SavingValue> {orderCartData?.totalDiscounts?.formattedValue}</SavingValue></Typography>
                </Box>
                <Box padding={{xs: '15px', lg: '40px'}}>
                    <Typography mb='24px' variant='h3' textTransform='capitalize'>{orderSummaryComponentContent?.headerLabel}</Typography>
                    <Box display='flex' justifyContent='space-between' alignItems={'center'} width='100%' mb='8px'>
                        <Typography variant='h5'>{orderSummaryComponentContent?.subtotalLabel}</Typography>
                        <Typography variant='p1'>{orderCartData?.subTotal?.formattedValue}</Typography>
                    </Box>

                    <Box display='flex' justifyContent='space-between' alignItems={'center'} width='100%' mb='8px'>
                        <Typography variant='h5'>{orderSummaryComponentContent?.savingsLabel}</Typography>
                        <Typography variant='p1'>{orderCartData?.totalDiscounts?.formattedValue}</Typography>
                    </Box>

                    <Box display='flex' justifyContent='space-between' alignItems={'center'} width='100%' mb='8px'>
                        <Typography variant='h5'>{orderSummaryComponentContent?.shippingLabel}</Typography>
                        <Typography variant='p1' textAlign={'right'}>{orderSummaryComponentContent?.shippingMessage}</Typography>
                    </Box>

                    {isCheckout && <Box display='flex' justifyContent='space-between' alignItems={'center'} width='100%' mb='8px'>
                        <Typography variant='h5'>{orderSummaryComponentContent?.shippingTermsLabel}</Typography>
                        <Typography variant='p1' textAlign={'right'}>{orderCartData?.deliveryMode?.description}</Typography>
                    </Box>}

                    <Divider orientation="horizontal" variant="middle" sx={{ marginBottom: 3, marginTop: 2 }} />
                    <Box display='flex' justifyContent='space-between' alignItems={'center'} width='100%'>
                        <Typography variant='h4'>{orderSummaryComponentContent?.totalLabel}</Typography>
                        <Typography variant='h4'>{orderCartData?.totalPrice?.formattedValue}</Typography>
                    </Box>

                    {isCheckout ? <>
                        <Divider orientation="horizontal" variant="middle" sx={{ marginBottom: 3, marginTop: 3 }} />
                        <Box mt={3}>
                            <TextInput
                                sx={{ width: '100%' }}
                                focused
                                placeholder={orderSummaryComponentContent?.poNumberPlaceholderText}
                                label={orderSummaryComponentContent?.poNumberLabel}
                                sublabel={orderSummaryComponentContent?.optionalLabel}
                                inputType="text"
                                varient="standard"
                                stylevariant={"border"}
                                name="poNumber"
                                data-testid="poNumber"
                                value={poNumberValue}
                                onChange={(e) =>
                                    {
                                        const regex = /^([a-z0-9]){0,35}$/i;
                                        if (e.target.value === '' || regex.test(e.target.value)) {
                                            setPONumberValue(e.target.value);
                                        }
                                    }}
                                    maxLength={35}
                            />
                            <Checkbox
                                sx={{ alignItems: "flex-start", marginTop: '32px' }}
                                name="marketingConsent"
                                label={orderSummaryComponentContent?.marketingConsentMessage}
                                data-testid="marketingConsentCheckbox"
                                value={emailConsent}
                                onChange={() => setEmailConsent(prev => !prev)}
                            />
                            <Checkbox
                                sx={{ alignItems: "flex-start", marginTop: '24px' }}
                                name="privacyPolicyConsent"
                                label={documentToReactComponents(orderSummaryComponentContent?.privacyPolicyConsentRichTextMessage, options)}
                                value={checked}
                                data-testid="privacyPolicyConsentCheckbox"
                                onChange={() => setChecked(prev => !prev)}
                            />
                        </Box>

                        <Box display='flex' justifyContent='center' width='100%' mt={4}>
                            <Button disabled={!checked || datePickerError} onClick={handlePlaceOrder} loading={loading}>{orderSummaryComponentContent?.placeOrderButtonLabel}</Button>
                        </Box>
                    </>
                        :
                        <Box display='flex' justifyContent='center' width='100%' mt={5}>
                            <Button onClick={(e) => buttonClickHandler(e)} disabled={btnDisabled}>{orderSummaryComponentContent?.checkoutButtonLabel}</Button>
                        </Box>
                    }

                </Box>

            </Box>
        </>

    );
};

export { OrderSummaryComponent };