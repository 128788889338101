import React, { useState } from 'react';
import ModalContext from '../contexts';
import { LoginModal } from '../components/ModalComponent/LoginModal';
import { CreateAccountModal } from '../components/ModalComponent/CreateAccountModal';

export const ModalProvider = ({ children }) => {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = useState(false);
  const [postLoginRedirectPath, setPostLoginRedirectPath] = useState(null);

  const handleOpenModal = (redirectPath = null) => {
    setPostLoginRedirectPath(redirectPath);
    setLoginModalOpen(true);
  };

  const handleCloseModal = () => {
    setLoginModalOpen(false);
    setPostLoginRedirectPath(null);
  };

  const handleOpenCreateAccountModal = () => {
    setLoginModalOpen(false);
    setIsCreateAccountModalOpen(true);
  };

  const handleCloseCreateAccountModal = () => {
    setIsCreateAccountModalOpen(false);
  };

  return (
    <ModalContext.Provider value={{ isLoginModalOpen, handleOpenModal, handleCloseModal, postLoginRedirectPath, setPostLoginRedirectPath }}>
      {children}
      {isLoginModalOpen && 
        <LoginModal 
          isLoginModalOpen={isLoginModalOpen} 
          handleCloseModal={handleCloseModal} 
          postLoginRedirectPath={postLoginRedirectPath}
          setPostLoginRedirectPath={setPostLoginRedirectPath}
          handleOpenCreateAccountModal={handleOpenCreateAccountModal}
        />}
      {isCreateAccountModalOpen && 
        <CreateAccountModal 
          isCreateAccountModalOpen={isCreateAccountModalOpen} 
          handleCloseModal={handleCloseCreateAccountModal}
        />}
    </ModalContext.Provider>
  );
};
