import React, { useState, useEffect, Fragment } from "react"
import { styled } from '@mui/material/styles';
import { HomePageBanner } from "../components/HomePageBanner"
import { ReservationPendingCard } from "../components/ReservationPendingCard"
import { Spinner } from "./../components/Spinner";
import { useUserInfoContext } from '../contexts';
import { getCartsApi } from "../utils/ReservationApis";
import { LandingPageInfograpic } from "../components/LandingPageInfograpic";
import { AlertMessage } from "../components/AlertMessage";
import { NewsContent } from "../components/NewsContent";
import { ExternalRouterPrompt } from '../components/ExternalRouterPrompt';
import { useRef } from "react";
import { loadLandingStaticContent } from "../features/contentful/contentfulThunkApi";
import { useDispatch, useSelector } from "react-redux";

const Container = styled('div')(({ theme: { palette: { primary, secondary }, breakpoints }}) => `
width: 100%;
background-color: ${secondary.main};
${[breakpoints.down('sm')]} {
    width:100vw;
    background-color: ${primary.main};
}
`);

const LandingPage = ({ externalModalData }) => {
    const [loader, setLoader] = useState(false);
    const [openReservations, setOpenReservations] = useState(null)
    const [showNavigationAlert, setShowNavigationAlert] = useState(false);
    const [externalLink, setExternalLink] = useState("");
    const [externalLinkModalType, setExternalLinkModalType] = useState("socialLink");
    const [errorMsg, setErrorMsg] = useState("");
    const { userInfo, setUserInfo } = useUserInfoContext() || '';
    const alertRef = useRef(null)
    const { landingPageContent } = useSelector((store) => store?.contentful)
    const closeNavigationAlert = (type) => {
        setShowNavigationAlert(false)
    }
    const dispatch = useDispatch()
    const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);
    const { baseStoreData } = useSelector((store) => store?.commerce);

    useEffect(() => {
        if (Object.keys(landingPageContent).length === 0)
            dispatch(loadLandingStaticContent({ 
                title1: 'Prebooking Card Component - ' + localeISOCode, 
                title2: 'Home Page Visualization Banner Component - ' + localeISOCode, 
                title3:  `Welcome Home News Component - ${localeISOCode}`,
                title4: 'Home Page Welcome Banner - ' + localeISOCode
            }));
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const loadReservations = async () => {
            setLoader(true);
            try {
                const { carts } = await getCartsApi(userInfo?.uid)
                const openReservations = carts?.filter((cart) => cart?.status === "PreBook")
                setOpenReservations(openReservations?.length > 0 ? openReservations[0] : "")
            } catch (error) {
                setErrorMsg(error?.message);
                alertRef.current?.openAlert()
            } finally {
                setLoader(false);
            }
        }

        if (!userInfo)
            localStorage.getItem("currentUser") !== "undefined" && setUserInfo(JSON.parse(localStorage.getItem("currentUser")))
        else if (userInfo && !baseStoreData?.commerceEnabled)
            loadReservations()
    }, [userInfo, setUserInfo, baseStoreData])


    return (
        <Fragment>
            <AlertMessage variant={"filled"} type={"error"} message={errorMsg} ref={alertRef} />
            <Spinner processing={loader} />
            <Container>
                <HomePageBanner>
                    <ReservationPendingCard openReservations={openReservations} />
                </HomePageBanner>
            </Container>
            <LandingPageInfograpic />
            {
                externalLinkModalType === "extrenalLink" && showNavigationAlert && !localStorage.getItem('dontRemindMeExternal') && <ExternalRouterPrompt
                    isOpen={showNavigationAlert}
                    closeModalHandler={() => closeNavigationAlert("extrenalLink")}
                    externalLink={externalLink}
                    onOpenLink={setShowNavigationAlert}
                    type={"extrenalLink"}
                    heading={externalModalData?.header}
                    headingStyle={{
                        fontFamily: "Aeonik Bold",
                        fontSize: "30px",
                        '@media screen and (max-width: 500px)': {
                            fontSize: '24px',
                            paddingBottom: "24px"
                        }
                    }}
                    content={externalModalData?.subHeader}
                    checkBoxLabel={externalModalData?.remindMeText}
                    buttonLabel={externalModalData?.buttonLabel}
                />
            }
            <NewsContent setShowNavigationAlert={setShowNavigationAlert} setExternalLink={setExternalLink} setExternalLinkModalType={setExternalLinkModalType} />
        </Fragment>
    )
}

export default LandingPage
