import React from "react";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import { Accordion } from "../Accordion";
import { Radio } from "../Radio";
import { AccountDetails } from "./AccountDetails";
import { Typography } from "@mui/material";

const accordionCustomStyle = {
  borderRadius: "0px !important",
  boxShadow: "none",
  border: "none !important",
  margin: "0px !important"
}

export const MultipleAccountFound = ({ staticContent, accountDetails, selected, onChange }) => {
  return (
    <Box m={{xs:'0px', sm:'30px'}} sx={{ flexGrow: 1 }}>
      <Accordion
        defaultExpanded={selected}
        onChange={onChange}
        accordionCustomStyle={accordionCustomStyle}
        heading={
          <Box display='flex' gap='3px'>
            <Radio checked={selected} onChange={onChange}/>
            <Typography variant={'h5'} color='primary.main' id="accountTitle">{accountDetails?.legalAccountName} - {accountDetails?.officeAddress?.town}, {accountDetails?.officeAddress?.region?.isocodeShort}</Typography>
          </Box>
        }
        description={
          <Box justifyContent='space-between' backgroundColor='secondary.main'>
            <AccountDetails staticContent={staticContent} accountDetails={accountDetails} singleAccount={false}/>
          </Box>
        }
      />
    </Box>
  )
};