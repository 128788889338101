import React from "react"
import { styled } from '@mui/material/styles';
import { LeftCard } from "./LeftCard";
import { useUserInfoContext } from "../../contexts";
import { useSelector } from "react-redux";

const Container = styled('div')(
    ({ aboveFoldBGImage, theme: { palette: { primary }, breakpoints } }) => `
    background-color: ${primary.main};
    font-family: 'Aeonik Regular', 'Arial';
    background-image: url(${aboveFoldBGImage});
    background-size: cover;
    height: 380px;  
    background-repeat: no-repeat;
    ${[breakpoints.down('sm')]} {
        background-size: contain;
        background-position: center;
    }
    `
);

const ContentContainer = styled('div')(({ theme: { breakpoints } }) => `
    display: grid;
    grid-column-gap: 10%;
    grid-template-columns: 7fr 5fr;
    margin: 0 90px;
    margin-top: -300px;
    ${[breakpoints.down('sm')]} {
        grid-template-columns:unset;
        grid-template-rows:auto auto;
        height: 100%;  
        margin:-300px 36px 0 36px;
        padding-bottom:60px;   
    }
`);

export const HomePageBanner = ({ children }) => {
    const { userInfo } = useUserInfoContext() || '';
    const { loading, landingPageContent: { welcomeBanner: bannerContent } } = useSelector((store) => store?.contentful)
    const { baseStoreData } = useSelector((store) => store?.commerce);
    const isOrderingEnabled = baseStoreData?.commerceEnabled;

    return (
        <>
            <Container aboveFoldBGImage={bannerContent?.backgroundImage?.fields?.file?.url}>
            </Container>
            <ContentContainer>
                <LeftCard
                    username={userInfo?.firstName}
                    leftCardContent={bannerContent}
                    isOrderingEnabled={isOrderingEnabled}
                />
                {children}
            </ContentContainer>
        </>
    )
}