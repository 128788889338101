import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, BackComponent } from "..";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { SingleAccountFound } from "./SingleAccountFound";
import { MultipleAccountFound } from "./MultipleAccountFound";
import { NoAccountMatch } from "./NoAccountMatch";
import useMediaQuery from '@mui/material/useMediaQuery';
import { loadUserRegistrationAccountStaticContent } from "../../features/contentful/contentfulThunkApi";
import { Box, useTheme } from "@mui/material";
import { AddressDetails } from "./AddressDetails";
import ProfileIcon from "../../assets/images/profile.svg";

const GridContainer = styled(Grid)(({ theme: { breakpoints } }) => `
  justify-content: space-between;
  ${[breakpoints.down('sm')]} {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`);

const ResetContainer = styled('div')(({ theme: { breakpoints } }) => `
  position: relative;
  margin: 30px auto 0 auto;
  width: 60%;
  ${[breakpoints.down('sm')]} {
    width: 90%;
    margin: 30px 20px;
    padding: 0px 15px;
  }
`);

const StyledBox = styled(Box)(({ theme: { palette: { secondary }, breakpoints }}) => `
margin: 0 30px;
display: flex;
background-color: ${secondary.main};
${[breakpoints.down('sm')]} {
  margin: 0px;
  flex-direction: column;
};
border-radius: 13px;
`);

const ProfileIconImg = styled('img')`
filter: invert(.5) sepia(1) saturate(5) hue-rotate(180deg);
`;

export const AccountFound = ({ setComp, setProgressValue, accounts, setAccountStatus, shipToAddresses, soldToAddresses, ...props }) => {
  const isASMuser = props?.isASMuser;
  const { userRegistrationAccountContent } = useSelector((store) => store?.contentful);
  const { staticContent = [] } = userRegistrationAccountContent;
  const { fields: addressContent = [] } = staticContent.registrationAddressDisplayComponent || {fields: {}};
  const dispatch = useDispatch();
  const [selectedAccount, setSelectedAccount] = useState(0);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isUnvalidatedRegistrationEnabled = process.env.IS_UNVALIDATED_REGISTRATION_ENABLED;


  useEffect(() => {
    dispatch(loadUserRegistrationAccountStaticContent({ title : isUnvalidatedRegistrationEnabled === 'true' ? "Registration Account Component" : "Registration Account Component Disabled" }));
    window.scrollTo(0,0);
  }, []);

  const handleNext = async (e) => {
    e.preventDefault();
    localStorage.setItem('accountInfo', JSON.stringify(accounts[selectedAccount]));
    if(accounts?.length === 1 && !accounts[0]?.legalAccountName) setAccountStatus('Unvalidated')
    setComp('contactInfo');
    isASMuser ? setProgressValue(60) : setProgressValue(50);
  }

  const backBtnHandler = () => {
    setComp('licenseInfo');
    isASMuser ? setProgressValue(20) : setProgressValue(16);
  };

  const handleSelectAccount = (index) => {
    setSelectedAccount(index)
  }

  const renderAddressDetails = (addresses, label) => {
    return(
      <> 
          <Box display='flex' alignItems='center' m={{xs:'20px 0', sm:'20px 30px'}}>
            <ProfileIconImg src={ProfileIcon} height="30" width="30" alt="user profile" />
            <Typography variant="h5" marginLeft="10px">{label}</Typography>
          </Box>
          <StyledBox sx={{ flexGrow: 1 }}>
            <AddressDetails staticContent={addressContent} addresses={addresses}/>
          </StyledBox>
      </>
    )
  }

  return (
    <>
      {desktop && <BackComponent id="backBtn" sx={{ top: "110px" }} onClickHandler={backBtnHandler} />}
      <ResetContainer>
        {mobile && <BackComponent id="backBtn" sx={{ top: "110px" }} onClickHandler={backBtnHandler} />}
        <Typography variant={'h1'} textAlign='center' color='primary.main' mt={{xs:'20px', sm:'0'}} id="headline">{(accounts?.length === 1 && accounts[0]?.legalAccountName) ? staticContent?.singleAccountHeadline : (accounts?.length > 1 && staticContent?.multipleAccountsHeadline)}</Typography>
        <Typography variant={'p1'} component={'div'} textAlign='center' mt='20px' mb='60px' id="subtitle">{documentToReactComponents((accounts?.length === 1 && accounts[0]?.legalAccountName) ? staticContent?.singleAccountMessage : (accounts?.length > 1 && staticContent?.multipleAccountsMessage))}</Typography>
          {accounts?.length > 1 ? accounts?.map((el, index) => <MultipleAccountFound selected={selectedAccount === index} onChange={() => handleSelectAccount(index)} key={index} staticContent={staticContent} accountDetails={el} />) :
          accounts?.length === 1 && accounts[0]?.legalAccountName ? <SingleAccountFound staticContent={staticContent} accountDetails={accounts[0]}/> :
          accounts?.length === 1 ? <NoAccountMatch handleNext={handleNext} staticContent={staticContent} /> : ''}

        {soldToAddresses?.length > 0 && renderAddressDetails(soldToAddresses, addressContent?.soldToLabel)}
        {shipToAddresses?.length > 0 && renderAddressDetails(shipToAddresses, addressContent?.shipToLabel)}

        {accounts && accounts?.length !== 0 && accounts[0]?.legalAccountName && <Box display='flex' justifyContent='space-evenly' width='40%' margin='40px auto'>
          <Button buttonType="primary" onClick={handleNext}>{staticContent?.nextButtonLabel}</Button>
        </Box>}
        {!isASMuser && <Box p={{xs:'30px 0px',sm:'30px'}} display='flex' borderTop='1px solid' borderColor='neutral.gray2' sx={{ flexGrow: 1 }} data-testid='callCustomer'>
          <GridContainer container gap={{xs:0 , sm:2}} pl={5} pr={5}>
            {accounts && accounts?.length !== 0 && accounts[0]?.legalAccountName && (<Grid item xs={12}>
              <Typography id="notYouText" variant="h5" sx={{ fontFamily: "Aeonik Bold", textAlign: "center" , '@media screen and (max-width: 500px)': {paddingBottom: '16px'}}}>{staticContent?.notYouLabel}</Typography>
            </Grid>)}
            {accounts && accounts?.length !== 0 && accounts[0]?.legalAccountName && (<Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography id="callCustomerText" variant="subtitle2" sx={{ fontFamily: "Aeonik Regular" ,'@media screen and (max-width: 500px)': {paddingBottom: '40px'}}}>{documentToReactComponents(staticContent?.notYouMessage)}</Typography>
            </Grid>)}
            <Grid item xs={12}>
              <Typography id="subtitle" variant="subtitle2" sx={{ fontFamily: "Aeonik Bold" }}>{staticContent?.careTeamHeader}</Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography id="phonenumber" variant="subtitle1" sx={{ fontFamily: "Aeonik Regular", color: "primary.main" }}>{staticContent?.careTeamPhoneNumber}</Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography id="time" variant="subtitle1" sx={{ fontFamily: "Aeonik Regular", color: "primary.main" }}>{staticContent?.careTeamHoursOfOperation}</Typography>
            </Grid>
          </GridContainer>
        </Box>}
      </ResetContainer>
    </>
  )
};