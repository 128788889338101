import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Link, useHistory } from "react-router-dom";
import { Button } from "../Button";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { HandlerBarTemplateHandler } from "../../utils/Helper";
import { getBaseStoreDataApi } from "../../features/commerce/commerceThunkApi";
import { changeDateFormat } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { loadPreBookConfirmationStaticContent } from "../../features/contentful/contentfulThunkApi";
import { Box, Typography } from "@mui/material";


const Disclaimer = styled('span')(({ theme: { palette: { status, neutral }, breakpoints }}) => `
  width: 50%;
  margin: auto;
  text-align: center;
  margin-top: 40px;
  text-align: center;
  ${[breakpoints.down('sm')]} {
    width: 99%;
    text-align: center;
  }

  && > h3 {
    color: ${status.error};
    font-size: 12px;
    font-family: "Aeonik Regular";
    line-height: 20px;
  }
   && > h4 {
    color: ${neutral.gray1};
    margin-top: 10px;
    font-size: 11px;
    font-family: "Aeonik Regular";
    line-height: 20px;
  }
`);

const CustomParagraph = styled('p')(({ theme: { palette: { neutral } }}) => `
  color: ${neutral.gray1},
  "font-size":"10px",
  "font-family":"Aeonik Regular",
  opacity:"60%"
`);
const CustomBold = styled('b')`
    font-family: 'Aeonik Bold', 'Arial';
`;

export const PreBookConfirmation = ({confirmationNumber, productsData, totalPrice, totalDoses}) => {
  const { preBookConfirmationContent: staticContent} = useSelector(store => store?.contentful)
  const { baseStoreData } = useSelector((store) => store?.commerce);
  const [cutOffMsg, setCutoffMsg] = useState('');
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.globalStates.locale);
  const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);
  useEffect(() => {
      window.scrollTo(0,0);
      if (Object.keys(staticContent).length === 0)
            dispatch(loadPreBookConfirmationStaticContent({ title: 'Reservation Confirmation - ' + localeISOCode }))
  },[]);

  useEffect(() => {
    const cutOff = async () => {
      try {
        const cutoffDate = baseStoreData?.cutoffDate;
        const remainingDaysMsg = HandlerBarTemplateHandler({reservationCutoffDate: changeDateFormat(cutoffDate)+'.' }, staticContent?.reservationCutOffMessage) || null;
        setCutoffMsg(remainingDaysMsg);
      } catch(error) {
        console.error(error);
      }
    };
    cutOff();
  });

  useEffect(() => {
    if (Object.keys(baseStoreData).length === 0) {
      dispatch(getBaseStoreDataApi({ baseSiteId: locale === 'en-DE' ? 'modernaDirect-DE' : 'modernaDirect-US' }));
    }
  }, [baseStoreData])

  const  history = useHistory()

  const returnToHome = () => history.push("/home")

  const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <CustomParagraph>{children}</CustomParagraph>,
    },
    renderMark: {
        [MARKS.BOLD]: (text) => <CustomBold>{text}</CustomBold>
    }
  };

  return (
    <Box m={{xs: '40px 36px 30px 36px', sm:'70px 0 30px 0'}} display='flex' flexDirection='column' alignItems='center'>
      <Box
        component={'img'}
        id="prebookNotion"
        data-testid="prebookNotion"
        src={staticContent?.icon?.fields?.file?.url}
        alt="prebook notion"
        maxWidth="70px"
        maxHeight="70px"
      />
      <Typography variant={'h2'} mt='30px' color='primary.main' padding='0px 36px' textAlign='center' id="gratitudeText" data-testid="gratitudeText">{staticContent?.headline}</Typography>
      <Typography variant={'p2'} mt='20px' color='primary.main' textAlign='center' lineHeight='25px' maxWidth='660px' width='100%' id="infoText" data-testid="infoText">
        {cutOffMsg}
      </Typography>
      <Box mt='30px' maxWidth='500px' pt='30px' width='100%' display='flex' justifyContent='space-between' alignItems='start' borderTop='1px solid #D1D1D1'>
        <Box display='grid'>
          <Typography variant='h6' color='primary.main' textTransform='uppercase' id="contactTitle" data-testid="contactTitle">
            {staticContent?.confirmationNumberLabel}
          </Typography>
          <Typography variant={'p1'} mt="12px" color='primary.main' id="contactText" data-testid="contactText">{confirmationNumber}</Typography>
        </Box>
        <Link to={`/${locale}/manage-reservations`} id="manageLink" data-testid="manageLink">
          <Typography variant={'link'} color='secondary.textLinks'>
            {staticContent?.manageReservationsLink?.fields?.label}
          </Typography>
        </Link>
      </Box>
      <Button id="returnToHome" data-testid="returnToHome" sx={{ marginTop: "130px", fontSize: "20px" , '@media screen and (max-width: 500px)': {marginTop: '80px',}}} onClick={returnToHome}
              data-link-href={"/home"}>
        {staticContent?.accountLink?.fields?.label}
      </Button>
      <Disclaimer id="disclaimer" data-testid="disclaimer">
        {
          documentToReactComponents(staticContent?.disclaimerMessage, options)
        }
      </Disclaimer>
    </Box>
  );
};
