import React, { useState, useEffect } from "react";
import { addToCartApi, fetchCartApi } from "../../utils/ReservationApis";
import { styled, useTheme } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import { PreBookPageHeader, PreBookPageFooter, Card, BasicBadge, BackComponent } from "../";
import CheckIconImage from "../../assets/images/checkIcon.svg";
import { PreBookStickyFooter } from "../PreBookStickyFooter";
import useMediaQuery from '@mui/material/useMediaQuery';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useDispatch, useSelector } from "react-redux";
import { loadProductsComponentStaticContent } from "../../features/contentful/contentfulThunkApi";
import { getBaseStoreDataApi, getProductsApi } from "../../features/commerce/commerceThunkApi";

const GridContainer = styled('div')(({ theme: { breakpoints } }) => `
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  padding: 10px;
  margin: 0 auto;
  width: 85%;
  margin-top: 19px;
  ${[breakpoints.down('sm')]} {
    display: block;
    width:90%;
  }
`);

const GridItem = styled('div')(({ theme: { breakpoints } }) => `
  padding: 8px;
  font-size: 30px;
  text-align: center;
  ${[breakpoints.down('sm')]} {
    padding:20px 0px;
  }
`);

const Header = styled('div')(({ theme: { breakpoints } }) => `
 ${[breakpoints.down('sm')]} {
    padding: 0px 36px;
  }
`);

const FlashMessage = styled('div')(
  ({ display, theme: { palette: { primary, neutral } }}) => `
  margin-top: -15px;
  height: ${display === "true" ? "46px" : "0px"};
  line-height: 58px;
  border-radius: 0px 0px 16px 16px;
  background-color: ${primary.main};
  color: ${neutral.white};
  font-size: 11px;
  overflow: hidden;
  transition: height 0.5s ease;
`);

const CheckIcon = styled('img')`
  top: 6px;
  position: relative;
  width: 20px;
`;

export const ProductsComponent = ({ updateProgressValue, cartId, userId, cartDetails, ...props }) => {
  const history = useHistory();
  const { products: productsData } = useSelector((store) => store?.commerce);
  const [selectedDoses, setSelectedDoses] = useState([]);
  // const [enteredDoses, setEnteredDoses] = useState([]);
  const [totalDoses,setTotalDoses] = useState(0);
  const [productAddedMsgData, setProductIdAddedMsgData] = useState(null);
  const { productsComponentContent: staticContent } = useSelector(store => store?.contentful)
  const { baseStoreData } = useSelector((store) => store?.commerce);
  const [isReviewDisable, setIsReviewDisable] = useState(true);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const locale = useSelector((state) => state.globalStates.locale);
  const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const dispatch = useDispatch();
  const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);
  // ECOM-36: Quick fix to hide product 70900 from customer view
  const excludedProductCodes = ['70900'];

  useEffect(() => {
    if (Object.keys(staticContent).length === 0)
            dispatch(loadProductsComponentStaticContent({ title: 'Reservation Products Selection - ' + localeISOCode }))
  }, []);

  useEffect(() => {
    if (Object.keys(baseStoreData).length === 0) {
      dispatch(getBaseStoreDataApi({ baseSiteId: locale === 'en-DE' ? 'modernaDirect-DE' : 'modernaDirect-US' }));
    }
  }, [baseStoreData])

  const fetchCartData = async (userId, cartId) => {
    try{
      const res = await fetchCartApi(userId, cartId);
      if(res?.status === 200){
        const { entries } = res?.data;
        let totalQuantityDoses = 0;
        entries.forEach(el => {
          totalQuantityDoses += el.product.dosesPerCarton * el.quantity;
        });
        setIsReviewDisable(entries.length === 0);
        setTotalDoses(totalQuantityDoses)
      }
    }catch(error){
      if(props?.setErrorMsg){
        props?.setErrorMsg(generalApiError);
      }
    }
  }

  useEffect(() => {
    fetchCartData(userId, cartId);
  }, [cartId, userId])

  useEffect(() => {
    dispatch(getProductsApi({ catalogVersion: localeISOCode && localeISOCode === "US" ? `modernaProductCatalog`: `modernaProductCatalog-${localeISOCode}`, userId, excludedProductCodes  }))
    .unwrap()
    .then((originalPromiseResult) => {
    })
    .catch((error) => {
      if(props?.setErrorMsg){
        props?.setErrorMsg(generalApiError);
      }
    })
  }, [dispatch, localeISOCode, generalApiError, userId])


  const cartReview = () => {
    if(props?.pageType === "edit"){
        props?.openProductSelection(false);
    }else{
      updateProgressValue(66);
    }
  };

  const getDosesOptions = (dosesPerCarton) => {
    let doses = [
      { label: "0", value: 0 },
      { label: "1", value: 10 },
      { label: "2", value: 20 },
      { label: "3", value: 30 },
      { label: "4", value: 40 },
      { label: "5", value: 50 },
    ];
    let dosesOptions = doses.map((dose, i) => {
      return {
        label: (dose.value * dosesPerCarton).toString(),
        value: dose.value * dosesPerCarton,
      };
    });
    return dosesOptions;
  };

  const addToCart = async (product, dosesPerCarton) => {
    try{
      getDosesOptions(dosesPerCarton);
      let foundIndex = selectedDoses.findIndex((item) => item.productId === product.code);
      if (foundIndex !== -1 && selectedDoses[foundIndex]?.dosesQuantity > 0) {
      // let foundIndex = enteredDoses.findIndex((item) => item.productId === product.code);
      // if (foundIndex !== -1 && enteredDoses[foundIndex]?.dosesQuantity > 0) {
        if (cartId) {
          let addToCartBody = {
            product: {
              code: product?.code,
            },
            quantity: selectedDoses[foundIndex]?.dosesQuantity / dosesPerCarton,
            // quantity: enteredDoses[foundIndex]?.dosesQuantity / dosesPerCarton,
          };
          
          const response = await addToCartApi(userId, cartId, addToCartBody);
          if (response?.status === 200) {

            let totaldoses=totalDoses + (response?.data?.quantityAdded * dosesPerCarton);
            setTotalDoses(totaldoses);
            setProductIdAddedMsgData({
              productId: product.code,
              msg: `${selectedDoses[foundIndex]?.dosesQuantity} doses added`,
              // msg: `${enteredDoses[foundIndex]?.dosesQuantity} doses added`,
              display: true
            });
            setTimeout(() => setProductIdAddedMsgData((prev)=>({...prev, display: false})),2000)
            setIsReviewDisable(false);
            if(props?.pageType === "edit"){
              localStorage.setItem("saveChangeBtn", false);
            }
            selectedDoses.splice(foundIndex, 1);
            setSelectedDoses(selectedDoses);
            // enteredDoses.splice(foundIndex, 1);
            // setEnteredDoses(enteredDoses);
          }
        }
      } else {
        let selectedData = {
          productId: product?.code,
          dosesQuantity: 0,
        };
        if (foundIndex === -1)
        setSelectedDoses([...selectedDoses, selectedData])
          // setEnteredDoses([...enteredDoses, selectedData])
      }

      if (foundIndex !== -1 && selectedDoses[foundIndex]?.dosesQuantity === 0) {
      // if (foundIndex !== -1 && enteredDoses[foundIndex]?.dosesQuantity === 0) {
        setProductIdAddedMsgData({});
      }
    }catch(error){
      if(props?.setErrorMsg){
        props?.setErrorMsg(generalApiError);
      }
    }
    
  };

  const onSelectDose = (e, productId, dosesPerCarton, value) => {
    let foundIndex = selectedDoses.findIndex((item) => item.productId === productId);
    let selectedData = {
      productId: productId,
      dosesQuantity: value.value,
    };
    foundIndex === -1 ? selectedDoses.push(selectedData) : (selectedDoses[foundIndex] = selectedData);
    setSelectedDoses(selectedDoses);
  };
  // const getSelectOptionError = (productId) => {
  //   let foundIndex = selectedDoses.findIndex((item) => item.productId === productId);
  //   return foundIndex !== -1 ? selectedDoses[foundIndex]?.dosesQuantity === 0 : false;
  // };
  const getDefaultValue = (productId) => {
    let defaultValue = productAddedMsgData !== null && productAddedMsgData.productId === productId ? { label: "0", value: 0 } : "false";
    return defaultValue;
  };

  // const getInputFieldError = (productId) => {
  //   let foundIndex = enteredDoses.findIndex((item) => item.productId === productId);
  //   return foundIndex !== -1 ? enteredDoses[foundIndex]?.dosesQuantity === 0 : false;
  // };

  const selectBlurHandler = (event, dosesPerCarton, productId) => {
    let dropDownValue = event.target.value ? (Math.ceil(parseInt(event.target.value) / parseInt(dosesPerCarton))) * parseInt(dosesPerCarton) : 0;
    onSelectDose(event, productId, dosesPerCarton, { "label": dropDownValue.toString(), "value": dropDownValue })
  }
  const selectKeyUpHandler = (event, dosesPerCarton, productId) => {
    let dropDownValue = event.target.value ? (Math.ceil(parseInt(event.target.value) / parseInt(dosesPerCarton))) * parseInt(dosesPerCarton) : 0;
    onSelectDose(event, productId, dosesPerCarton, { "label": dropDownValue.toString(), "value": dropDownValue })
  }

  const backButtonAction = () => {
    if(props?.pageType === "edit"){
      props?.openProductSelection(false);
    }else{
      history.push(`/${locale}`)
    }
  }

  /**
   * UPDATE THIS IF YOU CHANGE backButtonAction to keep them consistent please. 
   */
  const backButtonHref = props?.pageType !== "edit" ? `/${locale}` : null;

  // const dosesInputBlurHandler = (event, productId, dosesPerCarton) => {
  //   let inputFieldValue = event.target.value ? (Math.ceil(parseInt(event.target.value) / parseInt(dosesPerCarton))) * parseInt(dosesPerCarton) : 0;
  //   let foundIndex = enteredDoses.findIndex((item) => item.productId === productId);
  //   let selectedData = {
  //     productId: productId,
  //     dosesQuantity: inputFieldValue,
  //   };
  //   foundIndex === -1 ? enteredDoses.push(selectedData) : (enteredDoses[foundIndex] = selectedData);
  //   setEnteredDoses(enteredDoses);
  // }

  const getImageUrl = (imagesData) => {
    if(!imagesData || imagesData.length === 0) return false;
    const imageFilteredData = imagesData.filter((data) => data?.imageType === "PRIMARY");
    return imageFilteredData[0]?.url;
  }

  return (
    <>
      {mobile ? "" : <BackComponent id="backBtn" onClickHandler={ backButtonAction } {...(backButtonHref && {"data-link-href": backButtonHref})}/>}
      <Header>
      <PreBookPageHeader 
          hoverText = {staticContent?.gpoAffiliationHelpMessage}
          pageInfoHeading = {staticContent?.headline}
          pageInfoDescription = {<>{documentToReactComponents(staticContent?.subHeaderMessage)}</>}
          backButtonAction = {backButtonAction}
          gpoAffiliationMessage = { staticContent?.gpoAffiliationMessage }
          gpoName = { cartDetails?.gpoDetail !== null ? cartDetails?.gpoDetail?.gpoName : null}
          // gpoName = { cartDetails?.gpoDetail !== undefined ? cartDetails?.gpoDetail?.gpoName : null}
       />
       </Header>
      <GridContainer>
        {productsData?.products?.map((data, index) => {
          return (
            <GridItem key={data?.code}>
              <Card
                className="prodDetail"
                sx={{
                  textAlign: "left",
                  boxShadow: "0px 3px 16px #00000029",
                  borderRadius: "16px",
                }}
                index={index}
                variant={"Product"}
                comboselectvariant={localeISOCode === "DE" ? "square" : "round"}
                cardImage={{
                  src: getImageUrl(data?.images)
                }}
                cardId={data?.code}
                cardTitle={data?.name}
                cardDescription={data?.summary}
                buttonLabel={"Add"}
                cardAgeGroup={data?.ageGroup}
                CardBadge={
                  <BasicBadge
                    label={data?.ageGroup}
                    sx={{
                      color: "primary.main",
                      backgroundColor: "primary.blueFaded",
                      height: "22px",
                      fontSize: "10px",
                    }}
                  />
                }
                pricedata={{
                  price: `$${(data?.price?.value / data?.dosesPerCarton).toFixed(2)}`,
                  defaultPrice: data?.discountedPrice ? `$${(data?.defaultPrice?.value / data?.dosesPerCarton).toFixed(2)}` : null,
                }}
                hidePrice = {baseStoreData?.hidePrice || false}
                data={data}
                quantitylabel={"DOSES"}
                quantitydata={getDosesOptions(data?.dosesPerCarton)}
                dosespercarton={data?.dosesPerCarton}
                onSelectQuantity={(e, value) => onSelectDose(e, data?.code, data?.dosesPerCarton, value)}
                // selectOptionError={getSelectOptionError(data?.code)}
                quantitydefaultvalue={getDefaultValue(data?.code)}
                onButtonClick={addToCart}
                onSelectBlurHandler={(event) => selectBlurHandler(event, data?.dosesPerCarton, data?.code)}
                onSelectKeyUpHandler={(event) => selectKeyUpHandler(event, data?.dosesPerCarton, data?.code)}
                />
                 {/* inputFieldError={getInputFieldError(data?.code)}
                 onButtonClick={() => addToCart(data, data?.dosesPerCarton)}
                 onInputBlurHandler = {(event) => dosesInputBlurHandler(event, data.code, data?.dosesPerCarton)}
              /> */}
              {productAddedMsgData && productAddedMsgData?.productId === data.code && (
                <FlashMessage id="flashMsg" display={productAddedMsgData?.display?.toString()}>
                  <CheckIcon src={CheckIconImage} /> {productAddedMsgData?.msg}
                </FlashMessage>
              )}
            </GridItem>
          );
        })}
      </GridContainer>

      <PreBookPageFooter footerText={staticContent?.disclaimerMessage} />
      <PreBookStickyFooter totalDoses={totalDoses} buttonLabel={staticContent?.reviewButtonLabel} buttonType={"primary"} buttonDisabled={isReviewDisable} onClickHandler={cartReview} />
    </>
  );
};
