import axios from "axios";

let expiryTime;
const baseUrl = process.env.COMMERCE_BASE_URL; 

const getBaseUrl = () => {
    let conditionalBaseUrl = process.env.ORCHESTRATOR_BASE_URL;
    return conditionalBaseUrl;
}

const noAuthAxiosInstance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "application/json"
  }
});

noAuthAxiosInstance.interceptors.request.use(async req => {
  let conditionalBaseUrl = getBaseUrl();
  let token = JSON.parse(localStorage.getItem("commerceNoAuthToken"));
  const empInfo = localStorage.getItem("employeeUser") ? JSON.parse(localStorage.getItem("employeeUser")) : '';
  const empEmail = empInfo?.uid;
  req.params = req.params || {};
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    auth:{
      username: process.env.COMMERCE_OAUTH_ID,
      password: process.env.COMMERCE_OAUTH_SECRET
    },
    data: 'grant_type=client_credentials',
    url: window.location.hostname === "localhost" ? `${baseUrl}/authorizationserver/oauth/token` : `/authorizationserver/oauth/token`,
  }

  if(empEmail && req?.params) {
    req.params['asmuser'] = `${empEmail}`; //pass employee ID for emp APIs
  }
  const now = new Date();
  const commerceNoAuthTokenExpiry = localStorage.getItem("commerceNoAuthTokenExpiry")
  const isExpired = new Date(commerceNoAuthTokenExpiry).getTime() < now.getTime();
  
  if(!token || isExpired) {
    const rs = await axios.request(options);
    const commerceNoAuthToken = rs.data;
    expiryTime = new Date();
    expiryTime.setSeconds(expiryTime.getSeconds() + commerceNoAuthToken?.expires_in);
    localStorage.setItem("commerceNoAuthTokenExpiry", expiryTime);
    localStorage.setItem("commerceNoAuthToken", JSON.stringify(commerceNoAuthToken));
    req.headers["SAP-Commerce-Authorization"] = `Bearer ${commerceNoAuthToken?.access_token}`;
    return req;
  }  
  
  if(token && !isExpired) {
    req.headers["SAP-Commerce-Authorization"] = `Bearer ${token?.access_token}`;
    req.baseURL = conditionalBaseUrl;
    return req;
  }
});

export default noAuthAxiosInstance;
