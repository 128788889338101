import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { PRODUCT_IMAGE_TYPES } from "../constants";
import Handlebars from 'handlebars/dist/cjs/handlebars';
import { useSelector } from "react-redux";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import React from "react";
import { TypographyH1, TypographyH2, TypographyP1 } from "../assets/typographyTheme";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from 'uuid';

const isCatalogServiceEnabled = process.env.IS_CATALOG_SERVICE_ENABLED === 'true';

export const validateFields = ( fieldType, value ) => {
  let isValid = true;
  switch(fieldType) {
    case "email": {
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(value)) {
        isValid = false;
      }
      break;
    }
    case "password": {
     const pattern = new RegExp(
        "^(?=.{12,}$)(?=.*[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).+$"
      );
      if (!pattern.test(value)) {
        isValid = false;
      }
      break;
    }
    case "hin": {
      const pattern = new RegExp( 
        "^[a-zA-Z0-9]{9,9}$"
      );
      if (!pattern.test(value)) {
        isValid = false;
      }
      break;
    }
    case "dea": {
      const pattern = new RegExp( 
        "^[a-zA-Z][a-zA-Z0-9][0-9]{7}$"
      );
      if (!pattern.test(value)) {
        isValid = false;
      }
      break;
    }
    case "phone": {
      const pattern = new RegExp(
        /^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/
      )
      if(!pattern.test(value)) isValid = false
      break
    }
    case "zipcode": {
      const pattern = new RegExp(
        /^\d{5}(-\d{4})?$/
      )
      if(!pattern.test(value)) isValid = false
      break;
    }
    default: {
      console.log("Invalid choice");
      break;
    }
  }
  return isValid;
};

export const changeDateFormat = (date) => {
  let outputDate = new Date(date?.replace(/-/g, '/').replace(/T.+/, '')).toLocaleDateString('en-us', {year:"numeric", month:"long", day:"numeric"});
  return outputDate;
}

export const HandlerBarTemplateHandler = (valuesObject, data) => {
  let hbrHtmlEl = ''; 
  const hbr = typeof data == "string" ? data : `${documentToHtmlString(data)}`;
  const template = Handlebars.compile(hbr);
  hbrHtmlEl = template(valuesObject);
  return hbrHtmlEl;
}

// for check string is url or slug
export const isUrl = (str) => {
  let checkStr = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
  return checkStr !== null;
}

export const isExternalURL = (url) => new URL(url).origin !== window.location.origin;

export const getAnalyticsProductDataAttributes = (product) => {
  return product && {
    "product_name": `${product.name}`,
    "product_id": product.code,
    "ndc_number": product.ndc,
    "product_detail": `${product.summary}:${product.doseVolume} ${product.doseVolumeUOM}:${product.ageGroup}`,
    "data-product-allocated-quantity": product.customerInventory?.allocatedQuantity,
    "data-product-remaining-allocated-quantity": product.customerInventory?.remainingAllocatedQuantity,
    "data-product-doses-per-carton": product.dosesPerCarton,
    "data-product-gpo-name": product.gpoDetails?.gpoName,
    "data-product-gpo-member-id": product.gpoDetails?.memberId,
    "data-product-dose-price": `${product.dosePrice?.value} ${product.dosePrice?.currencyIso}`,
    "data-product-default-dose-price": `${product.defaultDosePrice?.value} ${product.defaultDosePrice?.currencyIso}`
  }
}

export const endImpersonationSession = () => {
  localStorage.removeItem("currentUser");
  localStorage.removeItem("commerceAuthTokenExpiry");
  localStorage.removeItem("commerceNewAuthToken");
} 

export const extractState = (region) => {
  const parts = region?.isocode.split('-')
  return parts?.[1].trim()
}

export const sortAddresses = (a, b) => {
  if (a?.defaultAddress !== b?.defaultAddress) {
    return b?.defaultAddress - a?.defaultAddress
  }
  const nameComparison = a?.companyName?.localeCompare(b?.companyName)
  if (nameComparison === 0) {
    return a?.line1?.localeCompare(b?.line1)
  }
  return nameComparison
}

export const formattedPrice = (price) => {
  if (price === null || price === undefined || isNaN(price)) {
    return 'Unavailable';
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price);
};


export const sortArrayByField = (targetArray, sortOrder, field) => {

  if (!Array.isArray(targetArray)) {
    throw new TypeError(`Expected an array, but received type: ${Object.prototype.toString.call(targetArray)}`);
  }

  targetArray.forEach((element, index) => {
    if (!(field in element)) {
      throw new Error(`Field "${field}" not found in element: ${index}`);
    }
  });

  const orderMap = new Map(sortOrder.map((item, index) => [item, index]));
  //elements whose field value is not in sortOrder will be sorted to the end
  targetArray?.sort((a, b) => {
    const indexA = orderMap.has(a[field]) ? orderMap.get(a[field]) : sortOrder.length;
    const indexB = orderMap.has(b[field]) ? orderMap.get(b[field]) : sortOrder.length;

    return indexA - indexB;
  });

  return targetArray;
}

export const displayNDC = (ndcValue) => {
  if (!ndcValue) {
    return '';
  }
  if (ndcValue === '80777-102-93') {
    return '80777-102-96';
  }
  return ndcValue;
}

// Setting priority of display for each product code
const productPriority = {
  70506: 1,
  70840: 2,
  70545: 3
};

export const sortProductsByPriority = (array, key) => {
  const DEFAULT_PRIORITY = Number.MAX_SAFE_INTEGER;
  
  return array?.sort((a, b) => {
    const priorityA = productPriority[a[key]] ?? DEFAULT_PRIORITY;
    const priorityB = productPriority[b[key]] ?? DEFAULT_PRIORITY;

    return priorityA - priorityB;
  });
};

export const sortProductImages = (imageInfo) => {
  return imageInfo && [...imageInfo]?.sort((a, b) => {
    const aIndex = Object.values(PRODUCT_IMAGE_TYPES).indexOf(a.imageType);
    const bIndex = Object.values(PRODUCT_IMAGE_TYPES).indexOf(b.imageType);

    return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
  });
}


export const getOrderableProducts = () => {
  return useSelector((store) =>
          store?.commerce?.orderableProducts
  ) || []
}

const CustomBold = styled('span')`
    font-family: 'Aeonik Bold', 'Arial';
`;
const CustomParagraph = styled('p')`
   line-height:22px;
`;

export const contentfulRichTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <CustomBold>
      {text}
    </CustomBold>
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
        <CustomParagraph>
          {/*If the contentful return paragraph is empty, meaning it's a line break*/}
          {(node?.content?.[0]?.value === '' && node?.content?.[0]?.nodeType === 'text') && <br></br>}
          {children}
        </CustomParagraph>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
        <TypographyH1>
          {children}
        </TypographyH1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
        <TypographyH2>
          {children}
        </TypographyH2>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
        <ul>
          {children}
        </ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
        <ol>
          {children}
        </ol>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
        <li>{children}</li>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, file } = node.data.target.fields;
      const imageUrl = file.url;
      return <img src={imageUrl} alt={title} style={{ maxWidth: '100%' }} />;
    },
  },
};

export const generateIdempotencyToken = () => uuidv4();

export const usdToCents = (value) => {
  const dollar = parseFloat(value.replace(/[^0-9.]/g, ""));
  if (isNaN(dollar)) return null;
  
  // Convert the dollar to cents (multiply by 100 and round)
  const cents = Math.round(dollar * 100);
  
  return cents;
};

export const centsToUSD = (value) => {
  const number = parseFloat(value) / 100;
  
  if (isNaN(number)) return "";
  
  // Format number to USD style with two decimal places
  return `$${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};
