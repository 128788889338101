import { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography, Button } from '@mui/material';
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined, TaskAlt } from "@mui/icons-material";
import { updateDefaultShipmentAddressAPI } from "../../utils/AddressesApi";
import { fetchUserApi } from "../../features/commerce/commerceThunkApi";
import { useSelector, useDispatch } from "react-redux";
import { extractState } from "../../utils/Helper";

const GridContainer = styled(Grid)(({ theme: { palette: { neutral, secondary  }, breakpoints }}) => `
  background-color: ${secondary.main};
  border-radius: 16px;
  padding: 26px;
  margin: 0 !important;
  width: 350px;
  height: 200px;
`);

const Value = styled(Typography)( `
  font-size: 15px;
  text-align: left;
`);

const ValueBold = styled(Value)(`
  font-family: Aeonik Bold;
`);

const DefaultValue = styled(ValueBold)(`
  font-size: 15px;
  color: #2aace2;
`);

const AddressType = styled(Typography)(`
  font-family: Aeonik Light;
  font-size: 22px;
  text-align: left;
  color: #1e2846;
  margin-bottom: 15px;
  margin-top: 30px;
`);

const SetDefault = styled(Typography)(`
  font-family: Aeonik Regular;
  font-size: 15px;
  color: #2aace2;
`);

const AddressBox = styled(Box)(`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`)

export const AddressesDetails = ({ staticContent, addresses, pageType, setLoader, onDefaultAddressApiError }) => {
  if (!Array.isArray(addresses)) {
    addresses = [];
  }
  let modifiedAddresses = [...addresses];
  const isAddAddressEnabled = process.env.IS_ADD_ADDRESS_ENABLED;
  const isSetDefaultAddressEnabled = process.env.IS_SET_DEFAULT_ADDRESS_ENABLED === 'true';
  const soldToAddresses = modifiedAddresses.filter(address => address?.partnerFunction === "SOLD_TO");
  const soldToAddress = soldToAddresses[0];
  const [shipToAddresses, setShipToAddresses] = useState(modifiedAddresses.filter(address => address?.partnerFunction === "SHIP_TO"));
  const dispatch = useDispatch();
  const { commerceUser } = useSelector((store) => store?.commerce);
  const defaultAddress = commerceUser?.data?.defaultAddress;

  const sortAddresses = (a, b) => {
    if (a?.defaultAddress !== b?.defaultAddress) {
    return b?.defaultAddress - a?.defaultAddress
  }
    const nameComparison = a?.companyName?.localeCompare(b?.companyName)
    if (nameComparison === 0) {
      return a?.line1?.localeCompare(b?.line1)
    }
    return nameComparison
  }

  const getDefaultShippingAddress = () => {
    if (!isSetDefaultAddressEnabled) {
      return
    }
    const modifiedShippingAddresses = shipToAddresses.map((address) => ({
      ...address,
      defaultAddress: address?.uid === defaultAddress?.uid
    }))
    modifiedShippingAddresses.sort(sortAddresses)
    setShipToAddresses(modifiedShippingAddresses)
  }

  useEffect(getDefaultShippingAddress, [])

  const setAsDefaultShippingAddress = async (addressId) => {
    setLoader(true)
    try {
      const updateResponse = await dispatch(updateDefaultShipmentAddressAPI({ userId: commerceUser?.data?.uid, addressId: addressId })).unwrap()
      dispatch(fetchUserApi({userId: commerceUser?.data?.uid}))
    } catch (error) {
      onDefaultAddressApiError()
    } finally {
      setLoader(false)
    }
  }

  const [page, setPage] = useState(1);
  const CELL_PER_PAGE = 9;
  let paginateStart = (page - 1) * CELL_PER_PAGE;
  let paginateEnd = paginateStart + CELL_PER_PAGE;
  const pageCount = Math.ceil(modifiedAddresses.length / CELL_PER_PAGE);

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const CustomPagination = (props) => {
    const { page, pageCount} = props;
  
    const handleBackButtonClick = (event) => {
      if (page > 1) {
        handlePageChange(event, page - 1);
      }
    };
  
    const handleNextButtonClick = (event) => {
      if (page < pageCount) {
        handlePageChange(event, page + 1);
      }
    };
  
    return (
      <>
      {<Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center', gap: 10 }} id="Pagination" data-testid="pagination">
        <KeyboardArrowLeftOutlined onClick={handleBackButtonClick} disabled={page === 1} fontSize="large" sx={{color: 'secondary.graphical', opacity: page === 1 ? 0.5 : 1}}/>
        <Typography variant={'p1'}>{page} of {pageCount}</Typography>
        <KeyboardArrowRightOutlined onClick={handleNextButtonClick} disabled={page === pageCount} fontSize="large" sx={{color:'secondary.graphical', opacity: page === pageCount ? 0.5 : 1}}/>
      </Box>
      }
      </>
    );
  }

  return(
      <Box margin='30px auto 0 auto' width='80%' id="AddressesDetails" data-testid="addressesDetails">
        <AddressType id="SoldToAddressLabel" data-testid="soldToAddressLabel">{staticContent?.addressSoldToLabel}</AddressType>
        {isAddAddressEnabled === 'true' ? 
          (
          <Grid container gap={2} display={'flex'} justifyContent={'left'} gridTemplateColumns={'repeat(3, 1fr)'} id="SoldToAddress" data-testid="soldToAddressContainer">
            { soldToAddresses.slice(paginateStart, paginateEnd).map((address, index) => {
              return (
                <GridContainer key={index}>
                  <Grid container spacing={1} className={pageType} index={index} id={`BillingAddress-${index}`}
                        data-testid={`billingAddress-${index}`}>
                    {address ?
                      <Box sx={{alignContent: 'left'}}>
                        <ValueBold variant={'p1'} id="billingCompanyName">{address.companyName}</ValueBold>
                        <Box sx={{alignContent: 'left'}}>
                          <Value variant={'p1'} id={`billingAddress-${index}`}>{address.formattedAddress}</Value>
                        </Box>
                      </Box> : <ValueBold variant={'p1'}>{staticContent?.addressUnavailableLabel}</ValueBold>
                    }
                  </Grid>
                </GridContainer>
              )
              })}
          </Grid>
          )
          :
          (<GridContainer id="SoldToAddress" data-testid="soldToAddressContainer">
          { soldToAddress ? 
            <AddressBox>
              <Box width={'100%'}>
                <ValueBold variant={'p1'} id="companyNameVal">{soldToAddress.companyName}</ValueBold>
                <Box display={'flex'} flexDirection={'column'}>
                  <Value variant={'p1'} data-testid={`billingAddressLine1`}>{soldToAddress.line1}</Value>
                  <Value variant={'p1'} data-testid={`billingAddressLine2`}>{soldToAddress.line2}</Value>
                  <Value variant={'p1'} data-testid={`billingAddressTownRegionZip`}>{soldToAddress.town}, {extractState(soldToAddress.region)}, {soldToAddress.postalCode}</Value>
                </Box>
              </Box>
            </AddressBox> : <ValueBold variant={'p1'}>{staticContent?.addressUnavailableLabel}</ValueBold>}
          </GridContainer>
          )
        }
        <AddressType id="ShipToAddressLabel" data-testid="shipToAddressLabel">{staticContent?.addressShippingLabel}</AddressType>
        <Grid container gap={2} display={'flex'} justifyContent={'left'} gridTemplateColumns={'repeat(3, 1fr)'} id="ShipToAddresses" data-testid="shipToAddressesContainer">
          { shipToAddresses.slice(paginateStart, paginateEnd).map((address, index) => {
            return (
              <GridContainer key={index}>
                <Box height={'100%'} className={pageType} index={index} id={`ShippingAddress-${index}`} data-testid={`shippingAddress-${index}`}>
                  {address ?
                  <AddressBox>
                    <Box width={'100%'}>
                      <ValueBold variant={'p1'} id="companyNameVal">{address.companyName}</ValueBold>
                      <Box display={'flex'} flexDirection={'column'}>
                        <Value variant={'p1'} data-testid={`shippingAddressLine1-${index}`}>{address.line1}</Value>
                        <Value variant={'p1'} data-testid={`shippingAddressLine2-${index}`}>{address.line2}</Value>
                        <Value variant={'p1'} data-testid={`shippingAddressTownRegionZip-${index}`}>{address.town}, {extractState(address.region)}, {address.postalCode}</Value>
                      </Box>
                    </Box>
                    { isSetDefaultAddressEnabled &&
                      <Box display={'flex'} justifyContent={'flex-end'}>
                        {address.defaultAddress ?
                        <Box display={'flex'} alignItems={'center'} gap={'1px'}>
                          <TaskAlt sx={{ color: '#2aace2'}} fontSize='14px'/>
                          <DefaultValue data-testid="defaultAddressLabel">Default address</DefaultValue>
                        </Box> :
                        <Button sx={{padding: '0px', textTransform: 'none' }} onClick={() => {setAsDefaultShippingAddress(address.id)}}>
                          <SetDefault data-testid="setAsDefaultButton">Set as default address</SetDefault>
                        </Button> }
                    </Box>
                    }
                  </AddressBox> : <ValueBold variant={'p1'}>{staticContent?.addressUnavailableLabel}</ValueBold>}
                </Box>
            </GridContainer>
            )
            })
          }
        </Grid>
        <Box mx={{xs:'32px', sm:'400px'}} my={'60px'} display={'flex'} justifyContent={'center'} flexGrow={1} >   
          <CustomPagination page={page} pageCount={pageCount} />
        </Box>
      </Box>
  )
}
