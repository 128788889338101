import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sessionHeaderOpen: false,
    invalidPasswordMsg: "",
    locale: "en-US",
    localeISOCode: "US",
    isCheckoutBtnClicked: false,
    isPlaceOrderClicked: false,
    isConfirmationPageAccessible: false,
    isOnboardingSuccessful: false,
    canOrder2023Products: false,
    emailAddress: "",
    userStatus: "",
    canAccessOrderConfirmationPage: false,
    canAccessInvoicePayConfirmationPage: false,
    isUserValidated: true,
    showContractPrice: false,
    showContractPriceForReservation: false,
    isLoggingIn: false,
    reservationConversionID: "",
}

export const globalStatesSlice = createSlice({
    name: "globalStates",
    initialState,
    reducers: {
        setSessionHeaderOpen: (state, action) => {
            if(state.sessionHeaderOpen===false)
                state.sessionHeaderOpen = true
            else
                state.sessionHeaderOpen = false
        },
        setInvalidPasswordMsg: (state, action) => {
            const { payload } = action;
            state.invalidPasswordMsg = payload;
        },
        setLocale: (state, action) => {
            const { payload } = action;
            state.locale = payload;
        },
        setLocaleISOCode: (state, action) => {
            const { payload } = action;
            state.localeISOCode = payload;
        },
        setCheckoutBtnClicked: (state, action) => {
            const { payload } = action;
            state.isCheckoutBtnClicked = payload;
        },
        setPlaceOrderClicked: (state, action) => {
            const { payload } = action;
            state.isPlaceOrderClicked = payload;
        },
        setIsConfirmationPageAccessible: (state, action) => {
            const { payload } = action;
            state.isConfirmationPageAccessible = payload;
        },
        setIsOnboardingSuccessful:(state, action) => {
            const {payload} = action;
            state.isOnboardingSuccessful = payload;
        },
        setCanOrder2023Products: (state, action) => {
            const { payload } = action;
            state.canOrder2023Products = payload;
        },
        setUserStatus:(state, action) => {
            const { payload } = action;
            state.userStatus = payload;
        },
        setEmailAddress:(state, action) => {
            const {payload} = action;
            state.emailAddress = payload;
        },
        setCanAccessOrderConfirmationPage: (state, action) => {
            const { payload } = action;
            state.canAccessOrderConfirmationPage = payload;
        },
        setCanAccessInvoicePayConfirmationPage: (state, action) => {
            const { payload } = action;
            state.canAccessInvoicePayConfirmationPage = payload;
        },
        setIsUserValidated: (state, action) => {
            const { payload } = action;
            state.isUserValidated = payload;
        },
        setShowContractPrice: (state, action) => {
            const { payload } = action;
            state.showContractPrice = payload;
        },
        setShowContractPriceForReservation: (state, action) => {
            const { payload } = action;
            state.showContractPriceForReservation = payload;
        },
        setIsLoggingIn: (state, action) => {
            const { payload } = action;
            state.isLoggingIn = payload;
        },
        setReservationConversionID: (state, action) => {
            const { payload } = action;
            state.reservationConversionID = payload;
        }
    }
}) 

export const {setSessionHeaderOpen, setInvalidPasswordMsg, setLocale, setLocaleISOCode, setCheckoutBtnClicked, setPlaceOrderClicked,
    setIsConfirmationPageAccessible, setIsOnboardingSuccessful, setCanOrder2023Products, setEmailAddress, setUserStatus,
    setCanAccessOrderConfirmationPage, setCanAccessInvoicePayConfirmationPage, setIsUserValidated, setReservationConversionID, setShowContractPrice, setShowContractPriceForReservation, setIsLoggingIn
} = globalStatesSlice.actions

export default globalStatesSlice.reducer