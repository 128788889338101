import React, { useEffect, useRef, useState } from 'react'
import { Grid, Typography, Box } from "@mui/material";
import { OrderSummaryComponent } from '../components/OrderSummary';
import CartEntryComponent from '../components/CartEntryComponent';
import { Button } from '../components/Button';
import { getAddressesApi, getOrderCartApi } from '../features/commerce/commerceThunkApi';
import { useDispatch, useSelector } from 'react-redux';
import { useUserInfoContext } from '../contexts';
import { AlertMessage, Spinner } from '../components';
import { useHistory } from 'react-router-dom';
import { loadCartComponentContent } from '../features/contentful/contentfulThunkApi';
import { HandlerBarTemplateHandler } from "../utils/Helper";
import { DisclaimerMessageFooter } from "../components/DisclaimerMessageFooter";
import { setCheckoutBtnClicked } from "../features/globalStates/globalStatesSlice";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getErrorMessage } from '../utils/commonUtils';
import { errorMessages } from '../constants';
import { useTracking } from "@modernatx/tracking";

const CartPage = () => {
  const dispatch = useDispatch();
  const { userInfo } = useUserInfoContext();
  const { uid : userId } = userInfo;
  const [errorMsg, setErrorMsg] = useState('');
  const [customErrorMsg, setCustomErrorMsg] = useState(null);
  const alertRef = useRef(null);
  const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
  const { orderCartData } = useSelector(store => store?.commerce);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const minOrderQuantityNotMetError = getErrorMessage(globalErrorMessage, errorMessages.minOrderQuantityNotMet, true);
  const [processing, setProcessing] = useState(false);
  const [addressProcessing, setAddressProcessing] = useState(false)
  const locale = useSelector((state) => state.globalStates.locale);
  const history = useHistory();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const { cartContent } = useSelector(store => store.contentful);
  const { minOrderQuantity: minCartLevelOrderQuantity } = useSelector(store => store?.commerce?.baseStoreData);
  const [localeError, setLocaleError] = useState(false);

    const {trackCustom} = useTracking();

  useEffect(() => {
    window.scrollTo(0,0);
    if (cartContent && Object.keys(cartContent).length === 0){
      dispatch(loadCartComponentContent());
    }
  },[dispatch, cartContent]);
  const [checkoutBtnDisabled, setCheckoutBtnDisabled] = useState(false);
  const [minQuantityNotMet, setMinQuantityNotMet] = useState(false);

  useEffect(() => {
      if (orderCartData?.totalUnitCount) {
          if (orderCartData.totalUnitCount < minCartLevelOrderQuantity){
              setMinQuantityNotMet(true);
              setCustomErrorMsg(<CustomMessage />)
              alertRef.current?.openAlert(errorMsg);
          } else {
              setMinQuantityNotMet(false);
              setCustomErrorMsg(null);
          }
      }
  }, [orderCartData, minCartLevelOrderQuantity]);
  const CustomMessage = () => {

      return (
          <>
              <Typography variant={"h4"} color={'neutral.white'}>
                  {minOrderQuantityNotMetError?.messageTitle}
              </Typography>
              <Typography variant={"p1"} color={'neutral.white'}>
                  {`${(minOrderQuantityNotMetError?.message || '').replace("${minOrderQuantity}", minCartLevelOrderQuantity)}`}
              </Typography>
          </>
      )
  }
  
  useEffect(() => { 
    setProcessing(true);
    dispatch(getOrderCartApi({ userId })).unwrap() // calling the API to get the order cart data and update in redux
      .then((data) => {
        setProcessing(false);
        if(data?.entries?.length !== 0) {
          const selectedLocations = data?.entries?.map(item => {
            return item?.configurationInfos
          })
          setSelectedLocations(selectedLocations);
          trackCustom("Cart Entries",{"CartEntries": data?.entries});
        }
        if(data?.entries.length === 0)
          history.push(`/${locale}/products`)
      })
      .catch((error) => {
        setErrorMsg(generalApiError);
        alertRef.current?.openAlert(error);
        setProcessing(false);
      })
  }, [generalApiError, userId, dispatch, history, locale])

  useEffect(() => { //this is called to set selectedLocations when we do update cart and we have cart data in redux 
    if (orderCartData) {
      const selectedLocations = orderCartData?.entries?.map(item => {
        return item?.configurationInfos
      })
      setSelectedLocations(selectedLocations);
    }
  },[orderCartData]);

  useEffect(() => {
    setProcessing(true);
    setAddressProcessing(true)
    dispatch(getAddressesApi({ userId })).unwrap() // getting all the available address for the logged in user
      .then((data) => {
        setProcessing(false);
        setAddressProcessing(false)
      })
      .catch((error) => {
        setErrorMsg(generalApiError);
        alertRef.current?.openAlert(error);
        setProcessing(false);
        setAddressProcessing(false)
      })
  }, [generalApiError, userId, dispatch])

  const checkoutHandler = (e) => {
    dispatch(setCheckoutBtnClicked(true));
    history.push(`/${locale}/checkout`);
  }

  const handleLocaleErrorChange = (errorState) => {
    setLocaleError(errorState);
  };

  return (
    <>
      <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} customMessage={customErrorMsg} />
      <Spinner processing={processing} />
      <Grid container spacing={{ xs: 1, lg: 3}} padding={{ xs: 2, md: 3, lg: 12}} justifyContent={'space-between'}>
        <Grid data-testid="cartContainer" item xs={12} sm={8} padding={{lg: "24px !important"}}>
          <Typography variant='h2'>{HandlerBarTemplateHandler({cartEntries: orderCartData?.totalItems }, cartContent?.headerLabel)}</Typography>
            { !addressProcessing && orderCartData?.entries?.map((entry, index) => {
              return (
                <CartEntryComponent key={index} index={index} entryData={entry} cartContent={cartContent} selectedLocations={selectedLocations} setCheckoutBtnDisabled={setCheckoutBtnDisabled} onLocaleErrorChange={handleLocaleErrorChange}/>
              )
            })}
        </Grid>
        <Grid data-testid="orderSummaryContainer" item xs={12} sm={4}>
            <OrderSummaryComponent orderCartData={orderCartData} sx={{position:'sticky', top:50}} buttonClickHandler={checkoutHandler} btnDisabled={localeError || checkoutBtnDisabled || minQuantityNotMet} />
        </Grid>
        <Box width="100%" display="flex" justifyContent={{ xs: "center", md: "flex-start" }} ml={{ md: "88px", lg: "124px" }} mt={{ xs: 8, md: "-10px" }}>
          <Button buttonType="secondary" data-testid='continueShoppingBtn' onClick={() => history.push(`/${locale}/products`)}>{cartContent?.continueShoppingButtonText}</Button>
        </Box>
          <Box
              width="100%"
              maxWidth={{ xs: '100%', sm: '600px' }} // Set your desired max-width here
              mt={2}
              display="flex"
              justifyContent={{ xs: "center", md: "flex-start" }}
              ml={{ md: "88px", lg: "124px" }}
          >
              <Typography variant={'p'} sx={{ fontSize: '14px' }}>
                  {documentToReactComponents(cartContent?.customerCareMessage)}
              </Typography>
          </Box>
      </Grid>
      <DisclaimerMessageFooter
          disclaimerMessage={cartContent?.disclaimerMessage}
      />
    </>
  )
}

export default CartPage
