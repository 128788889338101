import { CONTRACT_TYPES } from "../../constants";

export const isCommitmentPricing = (contract) => contract?.contractType === CONTRACT_TYPES.LETTER_OF_COMMITMENT;

export const filterContracts = (contracts) => {
    // Remove unknown and customer price list contracts
    return contracts?.filter(contract => contract.contractType !== CONTRACT_TYPES.UNKNOWN && 
        contract.contractType !== CONTRACT_TYPES.CUSTOMER_PRICE_LIST);
}

const formatContractDisplayName = (contracts) => {
    let filteredContracts = filterContracts(contracts);
    return filteredContracts?.map(contract => ({
        ...contract,
        displayName: isCommitmentPricing(contract) ? `${contract?.displayName} (Commitment pricing)` : contract?.displayName
    }));
}

export const getContractsToRender = (contracts) => {
    // Search for letter of commitment pricing contracts and put it in the front
    let sortedContracts = [...contracts]?.sort((a, b) => {
        if (isCommitmentPricing(a) && !isCommitmentPricing(b)) {
            return -1;
        } else if (!isCommitmentPricing(a) && isCommitmentPricing(b)) {
            return 1;
        }
        return 0;
    });

    return formatContractDisplayName(sortedContracts);
  }