import React from "react"
import { styled } from '@mui/material/styles';
import { Button } from "../Button"
import { useHistory } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock'
import { changeDateFormat } from "../../utils/Helper";
import { useUserInfoContext } from "../../contexts";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

const CardContainer = styled('div')(({ theme: { palette: { primary, neutral }, breakpoints }}) => `
    border-radius: 7px;
    border-top: 7px solid ${primary.blue};
    background-color: ${neutral.white};
    box-shadow: 0px 3px 16px #00000029;
    padding: 40px;
    max-width: fit-content;
    position: relative;
    overflow: hidden;
    ${[breakpoints.down('lg')]} {
        padding: 42px;
    }
    ${[breakpoints.down('md')]} {
        padding: 42px 25px;
    }
    ${[breakpoints.down('sm')]} {
        padding: 50px 34px;
    }
`);

const StyledButton = styled(Button)({
    marginTop: "42px !important",
    "& .ButtonText": {
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)': {
            fontSize: '10px !important',
        }
    },
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)': {
        padding: "10px 5px !important"
    }
});


export const ReservationPendingCard = ({ openReservations }) => {
    const history = useHistory();
    const locale = useSelector((state) => state.globalStates.locale);
    const { baseStoreData } = useSelector((store) => store?.commerce);
    const reserveBtnHandler = () => {
        history.push(`/${locale}/reservations/create`);
    };
    const { cutOffDaysRemaining } = useUserInfoContext() || '';
    const reservationOpen = useMemo(() => cutOffDaysRemaining > 0 ?? false, [cutOffDaysRemaining])
    const reservationClose = useMemo(() => cutOffDaysRemaining <= 0 ?? false, [cutOffDaysRemaining])
    const handleManageReserve = () => {
        history.push(`/${locale}/manage-reservations`);
    }

    const handleManageOrder = () => {
        history.push(`/${locale}/products`);
    }

    const handleViewReservation = () => {
        history.push(`/${locale}/view-reservations/${openReservations?.code}`)
    }

    const { loading, landingPageContent: { prebookingCard: staticContent, orderingCard: staticOrderContent  } } = useSelector(store => store?.contentful)

    return (
        <CardContainer>
            <Box component={"img"} width='140px' height='140px' position='absolute' transform='translateY(-50%)' right='-48px' top='30%' sx={{opacity:'0.2'}} display={{xs:'none', sm:'block'}} src={staticContent?.icon?.fields?.file?.url} alt="bg-image" />
            {reservationOpen && openReservations && !baseStoreData?.commerceEnabled ?
                (<>
                    <Typography variant={'h1'} color='primary.main' mt='5px' data-testid='pendingcardTitle'>{staticContent?.pendingReservationHeadline}</Typography>
                    <Box mt='14px'>
                    <Typography variant={'p1'} color='primary.main'>{staticContent?.confirmationNumberLabel}</Typography>
                        <Typography variant={'p1'} color='secondary.textLinks' ml='8px' textDecoration='underline' sx={{cursor:'pointer'}} onClick={handleViewReservation} data-testid='pendingResNumber'>{openReservations?.code}</Typography>
                    </Box>
                    {openReservations?.modified && <Typography component={"label"} variant={'p2'} display='block' mt='14px' color='primary.main' data-testid='dateModifiedLabel'>{staticContent?.dateModifiedLabel} {changeDateFormat(openReservations.modifiedDate)}</Typography>}
                    <StyledButton id="manageReservation" onClick={handleManageReserve} data-testid='pendingcardBtn' data-link-href={`/${locale}/manage-reservations`}>{staticContent?.manageReservationsButtonLabel}</StyledButton>
                </>) :
                reservationOpen && openReservations==="" && !baseStoreData?.commerceEnabled ?
                    (<>
                        <Typography variant={'h1'} color='primary.main' mt='5px' data-testid='pendingcardTitle'>{staticContent?.reserveNowHeadline}</Typography>
                        <Box mt='14px'>
                            <Typography variant={'p1'} color='primary.main' data-testid='pendingcardMessage'>{staticContent?.reserveNowMessage}</Typography>
                        </Box>
                        <Button id="pendingcardBtn" sx={{ marginTop: "42px", fontSize: "18px" }} onClick={reserveBtnHandler} data-testid='pendingcardBtn' data-link-href={`/${locale}/reservations/create`}>{staticContent?.reserveNowButtonLabel}</Button>
                    </>) :
                    reservationClose && !baseStoreData?.commerceEnabled ?
                        <>
                            <Typography variant={'h1'} color='primary.main' mt='5px' data-testid='pendingcardTitle'>{staticContent?.reservationsClosedHeadline}</Typography>
                            <Box mt='14px'>
                                <Typography variant={'p1'} color='primary.main' data-testid='pendingcardMsg'>{staticContent?.reservationsClosedMessage}</Typography>
                            </Box>
                            <Button id="reserveBtn" sx={{ marginTop: "28px", fontSize: "15px" }} onClick={reserveBtnHandler} disabled data-testid='pendingcardBtn'><div style={{ display: "flex", alignItems: "center" }}><LockIcon style={{ marginRight: "4px" }} />{staticContent?.reserveNowButtonLabel}</div></Button>
                        </> : 
                    baseStoreData?.commerceEnabled ?
                        <>
                            <Typography variant={'h1'} color='primary.main' mt='5px' data-testid='pendingcardTitle'>{staticOrderContent?.orderNowHeadline}</Typography>
                            <Box mt='14px'>
                                <Typography variant={'p1'} color='primary.main' data-testid='pendingcardMsg'>{staticOrderContent?.orderNowMessage}</Typography>
                            </Box>
                            <StyledButton id="manageReservationOrder" onClick={handleManageOrder} data-testid='pendingcardBtn' data-link-href={`/${locale}/products`}>{staticOrderContent?.orderNowButtonLabel}</StyledButton>
                        </> : ""
            }
        </CardContainer>
    )
}

