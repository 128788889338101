import { UserErrorCodes } from "./errorCodes";

export const UserErrorMessages = {
    [UserErrorCodes.LOCATIONS.USER_NOT_FOUND]: "Your session has expired. Please log out and log back in.",
    [UserErrorCodes.LOCATIONS.AUTHENTICATION_FAILED]: "Your session has expired. Please log out and log back in.",
    [UserErrorCodes.LOCATIONS.UNEXPECTED_ERROR]: "An unexpected error occurred. Please refresh the page and call 1-866-MODERNA if the issue persists.",

    [UserErrorCodes.AFFILIATE.USER_NOT_FOUND]: "Your session has expired. Please log out and log back in.",
    [UserErrorCodes.AFFILIATE.AUTHENTICATION_FAILED]: "Your session has expired. Please log out and log back in.",
    [UserErrorCodes.AFFILIATE.UNEXPECTED_ERROR]: "An unexpected error occurred. Please refresh the page and call 1-866-MODERNA if the issue persists.",

    [UserErrorCodes.CONTACT.USER_NOT_FOUND]: "Your session has expired. Please log out and log back in.",
    [UserErrorCodes.CONTACT.AUTHENTICATION_FAILED]: "Your session has expired. Please log out and log back in.",
    [UserErrorCodes.CONTACT.UNEXPECTED_ERROR]: "An unexpected error occurred. Please refresh the page and call 1-866-MODERNA if the issue persists.",

    [UserErrorCodes.SEARCH.UNEXPECTED_ERROR]: "An unexpected error occurred. Please refresh the page and call 1-866-MODERNA if the issue persists.",
    [UserErrorCodes.SEARCH.THIRD_PARTY_SERVICE_ERROR]: "Something went wrong on our end. Please try again or call 1-866-MODERNA.",

    [UserErrorCodes.UPDATE.INVALID_ADDRESS]: "We are unable to validate this address. Please try again or call 1-866-MODERNA if the issue persists.",
    [UserErrorCodes.UPDATE.THIRD_PARTY_SERVICE_INVALID_RESPONSE]: "Something went wrong on our end. Please try again or call 1-866-MODERNA.",
    [UserErrorCodes.UPDATE.ORG_CREATION_FAILED]: "We're unable to add this address to your account. Please enter a different address, or contact us at 1-866-MODERNA (1-866-663-3762) for assistance.",
};
