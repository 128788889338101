import React from "react";
import { Checkbox } from "../Checkbox";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";

const GridContainer = styled(Grid)(({ theme: { palette: { secondary } }}) => `
  background-color: ${secondary.main};
  border-radius: 16px;
  padding: 10px 10px 26px 10px;
  margin: 0 !important;
  width: 100% !important;
  &&.edit {
    box-shadow: 0px 3px 6px #22222229;
    }
`);

export const CommunicationPreferences = ({ staticContent, userInfo, pageType, checked, onChange }) => {
    return(
        <Box margin='30px auto 50px auto' width='80%' id="CommunicationPreferences" data-testid='CommunicationPreferences'>
            <Typography variant={'h3'} fontFamily={'Aeonik Light'} color='primary.main' textAlign='left' mb='25px' mt='30px' data-testid='label'>{staticContent?.communicationPreferencesSectionLabel}</Typography>
            <GridContainer container spacing={2} className={pageType}>
                <Grid item xs={12} md={12}>
                    <Checkbox id="consentMessage" data-testid="consentMessage"  label={staticContent?.consentMessage} checked={checked} disabled={pageType === 'edit' ? false : true} onChange={onChange} />
                </Grid>
            </GridContainer>
        </Box>
    )
}