import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextInput, BackComponent, SelectDropdown, AlertMessage } from "..";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import { MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { accountsList } from "../../data/accountsList";
import { validateFields } from "../../utils/Helper";
import useMediaQuery from '@mui/material/useMediaQuery';
import { getReltioDataApi } from "../../utils/commerceReltioApi";
import { loadUserRegistrationLicenseStaticContent } from '../../features/contentful/contentfulThunkApi';
import { Box, Typography, useTheme } from "@mui/material";

const StyledTextInput = styled(TextInput)(({ theme: { breakpoints } }) => `
  height: 70px;
  width: 100%;
  margin-top: 30px !important;
  ${[breakpoints.down('sm')]} {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
`);

const ResetContainer = styled('div')(({ theme: { breakpoints } }) => `
  position: relative;
  margin: 30px auto 0 auto;
  width: 70%;
  ${[breakpoints.down('sm')]} {
    width: 90%;
    margin: 30px 20px;
  }
`);

const GridContainer1 = styled('div')(({ theme: { palette: { neutral }, breakpoints }}) => `
 width: 100%;
 margin-top: 40px;
 padding-left: 10%;
 border: 1px solid ${neutral.gray2};
 border-radius: 5px;
 padding-right: 10%;
 ${[breakpoints.down('sm')]} {
  padding: 0 15px;
 }
`);

const GridContainer2 = styled('div')(({ theme: { breakpoints } }) => `
 width: 100%;
 margin-top: 40px;
 padding-left: 10%;
 padding-right: 10% ;
 ${[breakpoints.down('sm')]} {
  padding: 0 15px;
 }
`);
const CustomBold = styled('span')`
    font-family: 'Aeonik Bold', 'Arial';
`

export const LicenseInfo = ({ setComp, setProgressValue, setAccounts, setAddresses, ...props }) => {
  const isASMuser = props?.isASMuser;
  const [hinNum, setHinNum] = useState("");
  const [invalidHIN, setInvalidHIN] = useState(false);
  const [deaNum, setDeaNum] = useState("");
  const [invalidDEA, setInvalidDEA] = useState(false);
  const [medicalLicense, setMedicalLicense] = useState("");
  const history = useHistory();
  const { userRegistrationLicenseContent } = useSelector((store) => store?.contentful);
  const { staticContent = [] } = userRegistrationLicenseContent;
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [states, setStates] = useState("");
  const statesOptions = staticContent?.medicalLicenseStates || [];
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [errorMsg, setErrorMsg] = useState('');
  const alertRef = useRef(null);

  const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
  const missingHinErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "MISSING_HIN_DEA");
  const missingHinError = missingHinErrorData && missingHinErrorData[0]?.fields?.message;

  const missingDeaErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "MISSING_DEA_HIN");
  const missingDeaError = missingDeaErrorData && missingDeaErrorData[0]?.fields?.message;

  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;

  const statesArr = [];
  statesArr.push({"label": "", "value": ""});
  statesOptions.forEach((element) => {
    const obj = {};
    obj['label'] = element;
    obj['value'] = element;
    statesArr.push(obj);
  });

  useEffect(() => {
    window.scrollTo(0,0);
    if (Object.keys(userRegistrationLicenseContent).length === 0) {
      dispatch(loadUserRegistrationLicenseStaticContent({title: isASMuser ? "Admin Registration License Component" : "Registration License Component"}));
    }
  },[]);

  const handleHIN = (event) => {
    setHinNum(event.target.value.trim());
    setInvalidHIN('');
  };

  const handleDEA = (event) => {
    setDeaNum(event.target.value.trim());
    setInvalidDEA('');
  };

  const handleMedicalLicense = (event) => {
    setMedicalLicense(event.target.value);
  };

  const validate = () => {
    let isValid = true;
    if (!hinNum && !deaNum) {
      isValid = false;
      setInvalidHIN(missingHinError);
      setInvalidDEA(missingDeaError);
    }

    if (hinNum && !validateFields("hin", hinNum)) {
      isValid = false;
      setInvalidHIN("Please enter a valid HIN");
    }

    if (deaNum && !validateFields("dea", deaNum)) {
      isValid = false;
      setInvalidDEA("Please enter a valid DEA Number");
    }

    return isValid;
  };

  const handleNext = async (e) => {
    e.preventDefault();
    localStorage.setItem('licenseInfo', JSON.stringify({"medicalLicense": medicalLicense, "licenseState": states}));
    if(validate()) {
      setProcessing(true);
      try {
        let mdmData = await getReltioDataApi({"hin": hinNum, "dea": deaNum});
        if (mdmData.status === 200) {
          const formattedData = mdmData?.data?.map((obj) => {
            let data = {
              "hin": "",
              "deaLicense": "",
              "legalAccountName": "",
              "medicalLicense": "",
              "dbaName": "",
              "officeAddress": { 
                "billingAddress": false,
                "shippingAddress": false,
                "email": "",
                "phone": "",
                "line1": "",
                "postalCode": "",
                "country": {
                  "isocode": ""
                },
                "region":{
                    "countryIso":"",
                    "isocode":"",
                    "isocodeShort":"",
                    "name":""
                  },
                "town": ""
              }
            }
            const hinArr = obj?.attributes?.identifiers?.filter(identifier => {
              if(identifier?.value?.type[0]?.value === 'Health Industry Number') {
                return identifier
              }
            });

            if(hinArr?.length === 0) {
              data.hin = ''
            } else if (hinArr?.length === 1) {
              data.hin = hinArr[0].value?.id[0]?.value?.substring(0,9) || ''
            } else if (hinArr?.length > 1) {
              const hin = hinArr?.filter(hinIdentifier => hinIdentifier?.value?.id[0]?.value === hinNum);
              data.hin = hin?.length > 0 ? hin[0].value?.id[0]?.value?.substring(0,9) : hinArr[0].value?.id[0]?.value?.substring(0,9);
            }

            const dea = obj?.attributes?.dea?.[0]?.value?.number?.[0]?.value?.substring(0,9) || '';
            data.deaLicense = dea;

            const address = obj?.attributes?.addresses && obj?.attributes?.addresses[0];
            const phone = obj?.attributes?.communication?.[0]?.value?.modCommVal?.[0]?.value || '';
            const email = obj?.attributes?.modPrimaryContactEmail?.[0]?.value || '';
            const officeAddress = address && {
              "billingAddress": false,
              "shippingAddress": false,
              "line1": address.value?.addressLine1?.[0]?.value,
              "postalCode": address.value?.zip?.[0]?.value?.zip5?.[0]?.value,
              "phone": phone,
              "email": email,
              "country": {
                "isocode": address.value?.country?.[0]?.value
              },
              "region":{
                "countryIso": address.value?.country?.[0]?.value,
                "isocode": `${address.value?.country?.[0]?.value}-${address.value?.stateProvince?.[0]?.value}`,
                "isocodeShort": address.value?.stateProvince?.[0]?.value,
                "name": address.value.stateProvince[0].value
              },
              "town": address.value?.city?.[0]?.value
            };
            data.legalAccountName = obj?.label
            data.officeAddress = officeAddress || {};
            return data
          });
          setProcessing(false);
          setAccounts(formattedData);
          setAddresses(mdmData.data[0]?.customerMasterAddresses);
          setComp('accountFound');
          isASMuser ? setProgressValue(40) : setProgressValue(33);
        }
      } catch (error) {
        setProcessing(false);
        setErrorMsg(generalApiError);
        alertRef.current?.openAlert();
      }
    }
  };

  const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <CustomBold>{text}</CustomBold>
    }
  };

  const backBtnHandler = () => {
    history.push("/")
  };

  return (
    <>
    {desktop && <BackComponent sx={{top: "110px"}} onClickHandler={backBtnHandler} data-link-href="/" />}
    <ResetContainer>
      <div style={{paddingLeft: "15px"}}>{mobile && <BackComponent sx={{top: "110px"}} onClickHandler={backBtnHandler} data-link-href="/" />}</div>
      <AlertMessage variant={"filled"} type={"error"} message={errorMsg} ref={alertRef}/>
      <Typography variant={'h1'} textAlign='center' color='primary.main' mt={{xs:'20px', sm:'0'}} id="headline">{staticContent?.headline}</Typography>
      <Typography variant={'p1'} component={'div'} textAlign='center' mt='20px' mb='60px' id="subtitle">{documentToReactComponents(staticContent?.subHeader)}</Typography>
      <GridContainer1>
      <Typography variant={'p1'} component={'div'} display='flex' mt='20px' mb='20px' textAlign='center'><span>{documentToReactComponents(staticContent?.message, options)}</span><Typography variant={'span'} sx={{ color: "primary.red" }}>*</Typography></Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}> 
            <StyledTextInput
              focused
              placeholder={staticContent?.hinNumberPlaceholder}
              label={staticContent?.hinNumberLabel}
              inputType="text"
              variant="standard"
              value={hinNum}
              sx={{ marginBottom: "20px" }}
              helperText={invalidHIN}
              error={!!invalidHIN}
              onChange={handleHIN}
              maxLength="9"
            /> 
        </Grid>
        <Grid item xs={12} sm={6}>
            <StyledTextInput
              label={staticContent?.deaNumberLabel}
              inputType="text"
              variant="standard"
              value={deaNum}
              focused
              placeholder={staticContent?.deaNumberPlaceholder}
              sx={{ marginBottom: "20px" }}
              helperText={invalidDEA}
              error={!!invalidDEA}
              onChange={handleDEA}
            />
        </Grid>
      </Grid>
      </GridContainer1>
      <GridContainer2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StyledTextInput
            label={staticContent?.medicalLicenseLabel}
            inputType="text"
            variant="standard"
            focused
              placeholder={staticContent?.medicalLicensePlaceholder}
            value={medicalLicense}
            sx={{ marginBottom: "20px" }}
            onChange={handleMedicalLicense}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectDropdown
            sx={{minWidth:'254px', maxHeight:'46px'}}
            label={"Registered State"}
            options={statesArr}
            value={states}
            onChangeHandler={(value) => setStates(value)}
          />
        </Grid>
      </Grid>
      </GridContainer2>
      <Box display='flex' justifyContent='space-evenly' width='40%' margin='70px auto 90px auto'>
        <Button buttonType="primary" onClick={handleNext} loading={processing}>{staticContent?.nextButtonLabel}</Button>
      </Box>
    </ResetContainer>
    </>
  )
};
