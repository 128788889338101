export const ORDERED_PRODUCT_BY_ID_OR_CODE = ['80291', '80289', '80588'];

export function formatAddressesList(addresses) {
    return addresses && addresses?.map(address => {
        const { line1, line2, city, state, zip } = address;
        const formattedAddress = [line1, line2, city, state, zip].filter(part => part && typeof part === 'string' && part.trim() !== '').join(', ');
        return { ...address, formattedAddress };
    });
}

export function formatAddresses(address) {
    const { line1, line2, city, state, zip } = address;
    return [line1, line2, city, state, zip].filter(part => part && typeof part === 'string' && part.trim() !== '').join(', ');
}