import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { Button, Spinner } from "../";
import { useUserInfoContext } from "../../contexts";
import { getCartsApi } from "../../utils/ReservationApis";
import { useSelector, useDispatch } from "react-redux";
import { loadManageReservationBannerStaticContent } from "../../features/contentful/contentfulThunkApi";
import { Box, Typography } from "@mui/material";

const BannerContainer = styled('div')(
    ({ aboveFoldBGImage, theme: { palette: { primary }, breakpoints }}) => `
    background-color: ${primary.main};
    background-image: url(${aboveFoldBGImage});
    background-size: cover;
    height: 200px;
    ${[breakpoints.down('sm')]} {
        background-image: unset;
    }
    `
);

const bannerContainerSxStyle = {
    flexDirection: {xs: 'column', sm: 'row'},
    padding: {xs: '36px', sm: '0px'},
    alignItems: {xs: 'unset', sm: 'center'},
    justifyContent:{xs:'space-evenly', sm:'space-between'}
}

export const ManageReservationBanner = () => {
    const { manageReservationBannerContent: staticContent } = useSelector(store => store?.contentful)
    const [processing, setProcessing] = useState(false)
    const { userInfo, cutOffDaysRemaining } = useUserInfoContext() || '';
    const [reservation, setReservation] = useState({});
    const dispatch = useDispatch()
    const history = useHistory();
    const locale = useSelector((state) => state.globalStates.locale);
    const reserveBtnHandler = () => {
        history.push(`/${locale}/reservations/create`);
    };
    const buttonHref = `/${locale}/reservations/create`

    useEffect(() => {
        if (Object.keys(staticContent).length === 0)
            dispatch(loadManageReservationBannerStaticContent())
    }, [])

    useEffect(() => {
        const getReservations = async () => {
            setProcessing(true)
            try {
                const userId = userInfo?.uid
                const { carts } = await getCartsApi(userId)
                const openReservations = carts?.filter((cart) => cart?.status === "PreBook")
                const canceledReservation = carts?.filter(cart => cart?.status === "CANCELLED")
                const reservations = {
                    openReservations,
                    canceledReservation,
                    reservationAvailable: openReservations?.length > 0 ?? false
                }
                setReservation(reservations)
                setProcessing(false)
            } catch (error) {
                console.log("error", error)
                setProcessing(false)
            }
        }
        getReservations()
    }, [userInfo])

    return (
        <>
            <Spinner processing={processing} />
            <BannerContainer aboveFoldBGImage={staticContent?.backgroundImage?.fields?.file?.url}>
                <Box display={'flex'} maxWidth={'1050px'} m={'0 auto'} fontFamily={'Aeonik Regular'} color='neutral.white' height={'100%'} sx={bannerContainerSxStyle}>
                    <Typography variant={'h1'}>{staticContent?.headline}</Typography>
                    { Object.keys(reservation).length !== 0 && <>
                            {reservation?.reservationAvailable ? (
                                <Typography py={1} variant={'h6'} whiteSpace={'pre-line'} lineHeight={'16px'} sx={{textAlign: {xs: 'start', sm: 'end'} }}>{documentToReactComponents(staticContent?.reserveLimitMessage)}</Typography>
                            ) :
                                <Button onClick={reserveBtnHandler} disabled={cutOffDaysRemaining <= 0} data-link-href={buttonHref}>{staticContent?.reserveButtonLabel}</Button>
                            }
                        </>
                    }
                </Box>
            </BannerContainer>
        </>
    )
}