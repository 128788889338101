import { AmexCardIcon, DiscoverCardIcon, GenericCardIcon, MastercardIcon, VisaCardIcon } from "../../assets/logos/cardLogo";
import { listPaymentMethodsApi } from "../../utils/PaymentsService/PaymentMethodApis";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { Typography } from "@mui/material";
import CheckIcon from "../../assets/images/checkIconWhite.svg"

export const CREDIT_CARD_MAPPING = {
    mastercard: { name: 'Mastercard', logo: <MastercardIcon /> },
    amex: { name: 'American Express', logo: <AmexCardIcon /> },
    visa: { name: 'Visa', logo: <VisaCardIcon /> },
    discover: { name: 'Discover', logo: <DiscoverCardIcon /> },
    diners: { name: 'Diners', logo: <GenericCardIcon /> },
    jcb: { name: 'JCB', logo: <GenericCardIcon /> },
    unionpay: { name: 'UnionPay', logo: <GenericCardIcon /> },
    elo: { name: 'Elo', logo: <GenericCardIcon /> }
}

export const snackbarStyles = {
    justifyContent: 'center',
    borderRadius: '8px',
    backgroundColor: '#079AE0',
    padding: '10px 32px',
    boxShadow: '0px 3px 12px 0px rgba(0, 0, 0, 0.16)',
    '.MuiSnackbarContent-message': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '13px'
    }
}

export const snackbarMessages = {
    setDefaultPaymentMethod: 'Your default payment method has been updated.',
    deletePaymentMethod: 'Your payment method has been deleted.'
}

export const sortPaymentCardList = (paymentList, selectedPaymentId=false) => {
    return paymentList.sort((a, b) => {
        if (a.paymentMethodId === selectedPaymentId) return -1;
        if (b.paymentMethodId === selectedPaymentId) return 1;
        if (a.isDefault && !b.isDefault) return -1;
        if (!a.isDefault && b.isDefault) return 1;
        return 0;
    });
}

export const getPaymentMethodLists = async (setProcessing, handleErrorMsg, setPaymentMethodsList, paymentMethodStatus ) => {
    try {
        setProcessing(true);
        const response = await listPaymentMethodsApi({ status: paymentMethodStatus });
        if (response.status === 200) {
            setPaymentMethodsList(sortPaymentCardList(response?.data?.paymentMethodsList))
        }
    } catch (error) {
        setProcessing(false);
        const errorMessage = getErrorMessage(error?.response?.data?.errorCode);
        handleErrorMsg(errorMessage);
    } finally {
        setProcessing(false);
    }
};

export const checkIsExpired = (cardExpirationData) => {
    if (!cardExpirationData) {
        return
    }
    const [month, year] = cardExpirationData.split('/');
    const expirationDate = new Date(year, month - 1, 1);
    const currentDate = new Date();
    return expirationDate < currentDate;
}

export const getSnackbarMessage = (message) => {
    return (
      <>
        <img src={CheckIcon} alt="success icon" width={'32px'} height={'32px'}/>
        <Typography fontFamily={'Aeonik Regular'} fontSize={'20px'} fontWeight={'700'}>
          {message}
        </Typography>
      </>
    )
  }

export const validatePaymentAmount = (value) => {
    if (!value.trim()) return "Required";

    // Regex that allows numbers with or without commas and optional dollar signs
    const regex = /^\$?(\d+|\d{1,3}(,\d{3})*)(\.\d{2})?$/;

    // Remove any dollar signs or commas to properly validate the numeric value
    const numericValue = value.replace(/[$,]/g, '');

    if (!regex.test(value) || parseFloat(numericValue) <= 0 || parseFloat(numericValue) > 9999999.99) {
        return "Please enter a valid amount greater than 0 and up to $9,999,999.99";
    }

    return "";
};

export const validateInvoiceNumber = (value) => {
    if (!value.trim()) return "Required";
    
    if (!/^\d{8,10}$/.test(value)) return "Please enter a valid invoice number.";
    return "";
};