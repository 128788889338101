import {styled, Typography} from "@mui/material";

export const TypographyH1 = styled(Typography)`
    font-family: Aeonik Regular;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
`
export const TypographyH2 = styled(Typography)`
    font-family: Aeonik Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`

export const TypographyH3 = styled(Typography)`
    font-family: Aeonik Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`

export const TypographyP1 = styled(Typography)`
    font-family: Aeonik Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`
export const TypographyList = styled(Typography)`
    font-family: Aeonik Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
`

