import { useEffect, useState } from "react";
import { Box, Typography, Snackbar, SnackbarContent } from "@mui/material";
import { PaymentMethods } from "../Payments/PaymentMethods";
import { deletePaymentMethodApi, updatePaymentMethodApi } from "../../utils/PaymentsService/PaymentMethodApis";
import { PAYMENT_METHOD_STATUS } from "../../constants";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { v4 as uuidv4 } from 'uuid';
import { getPaymentMethodLists, getSnackbarMessage, snackbarMessages, snackbarStyles } from "../Payments/Helper";
import useAddPaymentMethod from "../../hooks/useAddPaymentMethod";
import { ModalComponent } from "../ModalComponent";

const PaymentMethodsSection = ({ setProcessing, handleErrorMsg }) => {
    const [listPayments, setListPayments] = useState([]);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [paymentMethodAdded, setPaymentMethodAdded] = useState(false);

    const {
        showPaymentForm,
        onAddPaymentMethodClick,
        handleClosePaymentModal,
        StripeForm
    } = useAddPaymentMethod(handleErrorMsg, setPaymentMethodAdded);

    const handleAddNewPaymentMethod = async () => {
        await onAddPaymentMethodClick();
    }

    const handleSetAsDefaultPayment = async (paymentMethodId) => {
        try {
            setProcessing(true);
            const response = await updatePaymentMethodApi({
                idempotencyToken: uuidv4(),
                setDefault: true,
            }, paymentMethodId);
            if (response.status === 200) {
                await getPaymentMethodLists(setProcessing, handleErrorMsg, setListPayments, PAYMENT_METHOD_STATUS.ACTIVE)
                setSnackbarMessage(getSnackbarMessage(snackbarMessages?.setDefaultPaymentMethod));
                setIsSnackbarOpen(true);
            }
        } catch (error) {
            setProcessing(false);
            const errorMessage = getErrorMessage(error?.response?.data?.errorCode);
            handleErrorMsg(errorMessage);
        } 
        finally {
            setProcessing(false);
        }
    }

    const handleDeletePaymentMethod = async (paymentMethodId) => {
        try {
            const response = await deletePaymentMethodApi(paymentMethodId);
            if (response.status === 200) {
                await getPaymentMethodLists(setProcessing, handleErrorMsg, setListPayments, PAYMENT_METHOD_STATUS.ACTIVE )
                setSnackbarMessage(getSnackbarMessage(snackbarMessages?.deletePaymentMethod));
                setIsSnackbarOpen(true);
            }
        } catch (error) {
            const errorMessage = getErrorMessage(error?.response?.data?.errorCode);
            handleErrorMsg(errorMessage);
        } finally {
            setProcessing(false);
        }
    };

    useEffect(() => {
        getPaymentMethodLists(setProcessing, handleErrorMsg, setListPayments, PAYMENT_METHOD_STATUS.ACTIVE);
        setPaymentMethodAdded(false);
    }, [paymentMethodAdded])

    return (
        <Box display='flex' width='80%' margin='60px auto 0 auto' flexDirection={{xs:'column', sm:'row'}} justifyContent='space-between'>
            <Snackbar 
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}
                open={isSnackbarOpen}
                onClose={() => {setIsSnackbarOpen(false)}}
            >
                <SnackbarContent
                    message={snackbarMessage}
                    sx={snackbarStyles}
                />
            </Snackbar>
            <Box width={{xs:'100%'}} pb={{xs:'24px', sm:'48px'}}>
                <Typography data-testid='paymentMethodHeadline' variant={'h2'} color='primary.main' textAlign='left' lineHeight='35px' id="headline">Payment methods</Typography>
                <PaymentMethods
                    paymentList={listPayments}
                    isProfilePage={true}
                    handleSetAsDefaultPayment={handleSetAsDefaultPayment}
                    handleAddNewPaymentMethod={handleAddNewPaymentMethod}
                    handleDeletePaymentMethod={handleDeletePaymentMethod}
                />
            </Box>
            {showPaymentForm && <ModalComponent
                openModal={showPaymentForm}
                closeModalHandler={handleClosePaymentModal}
                modalContent={<StripeForm />}
                style={{
                    maxWidth: '521px',
                    width: 'auto',
                    padding: '24px'
                }}
            />}
        </Box>
    )
}

export default PaymentMethodsSection;
