import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { TypographyH2 } from "../assets/typographyTheme";
import invoicePayInfographic from "../assets/images/invoicePayInfographic.png";
import { InvoicePayForm } from "../components/Payments/InvoicePay/InvoicePayForm";
import { AlertMessage } from "../components";
import { CALCULATION_INSTRUCTIONS_URL } from "../constants";
import CustomSpinner from "../components/Spinner/CustomSpinner";

const InfoTextBlock = ({ children }) => {
    return (
      <Box display="block" mb={1} sx={{ userSelect: 'text' }}>
        {children}
      </Box>
    );
};

const spinnerStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    backgroundColor: '#F9F9FA',
    pointerEvents: 'none',
    flexDirection: 'column'
}

const spinnerMessageStyle = {
    fontSize: '24px',
    fontWeight: 400,
}

const ImportantInformationBlock = () => {
    const history = useHistory();

    return (
        <Box display='flex' flexDirection='column' gap={2} maxWidth={'450px'} paddingTop={'16px'}>
            <Typography>Here’s where you can find the requested invoice information.</Typography>
            <img src={invoicePayInfographic} width={'450px'} height={'334px'}></img>
            <TypographyH2>Important information</TypographyH2>
            <Typography textAlign="left">
                <InfoTextBlock>
                    If you are entitled to a prompt pay discount, please calculate your new total using 
                    <a href={CALCULATION_INSTRUCTIONS_URL} target="_blank" rel="noopener noreferrer"> these calculation instructions. </a> 
                    See your invoice for your payment terms.
                </InfoTextBlock>
                <InfoTextBlock>
                    Transactions over $9,999,999.99 cannot be processed through this portal. Please refer to our
                    <Link 
                        color={'#0379B2'}
                        sx={{ textDecoration: 'none', cursor: 'pointer' }}
                        onClick={() => history.push(`/invoicepay`)}> payment options </Link> page for Bank Wire Transfer details.  
                </InfoTextBlock>
                <InfoTextBlock>
                    If you encounter any difficulties with payment processing or have further questions,
                    our dedicated Customer Care Team is available via email at 
                    <Box component="span" sx={{ color: '#0379B2', userSelect: 'text' }}> WeCare@modernatx.com</Box> or by calling
                    <Box component="span" sx={{ display: 'block', userSelect: 'text' }}>
                        1-866-MODERNA (1-866-663-3762) (8:00AM - 8:00PM ET, Monday-Friday, excluding holidays).
                    </Box>
                </InfoTextBlock>
            </Typography>
        </Box>
    )
}

const InvoicePayPage = () => {
    const [errorMsg, setErrorMsg] = useState('');
    const alertRef = useRef(null);
    const [showSpinner, setShowSpinner] = useState(false);

    const errorMsgHandler = (errorMsg) => {
        setErrorMsg(errorMsg);
        alertRef.current?.openAlert(errorMsg);
    }

    return (
        <Box backgroundColor='#F9F9FA'>
            {showSpinner && 
                <CustomSpinner
                    message="Processing payment. Please do not refresh or leave this page."
                    sx={spinnerStyles}
                    messageSx={spinnerMessageStyle}
                />
            }
            <AlertMessage
                variant={"filled"}
                type={"error"}
                message={errorMsg}
                sx={{ top: 120 }}
                ref={alertRef}
            />
            <Box display='flex' gap={4} justifyContent={'center'} paddingTop={'88px'} paddingBottom={'88px'}>
                <InvoicePayForm handleErrorMsg={errorMsgHandler} setShowSpinner={setShowSpinner} />
                <ImportantInformationBlock />
            </Box>
        </Box>
    )
}

export default InvoicePayPage
