import React from "react";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";

const CustomRadio = styled(Radio)(({ theme, size, customcheckedcolor }) => {
    const sizeToFontSizeMap = {
        small: 17,
        medium: 20,
        large: 25,
    };

    return {
        "&:hover": {
            backgroundColor: "transparent",
            opacity: 0.4,
        },
        "&.Mui-checked": {
            color: customcheckedcolor || theme.palette.secondary.graphical,
        },
        "&.readonly": {
            color: theme.palette.neutral.gray2,
        },
        '& .MuiSvgIcon-root': {
            fontSize: sizeToFontSizeMap[size] || sizeToFontSizeMap.small,
        },
    };
});

const CustomFormControlLabel = styled(FormControlLabel)(({controlformsx, labelsx}) => ({
    ".MuiFormControlLabel-label": {
        fontSize: "14px",
        paddingLeft: "4px",
        ...labelsx,
    },
    margin: 0,
    ...controlformsx,
}));

const RadioButton = ({ label, value, checked, onChange, name, className, id, radiosx, labelsx, controlformsx, size, disabled, ...props }) => {
    return (
        <CustomFormControlLabel
            id={`radio_label_${id}`}
            disabled={disabled || className === 'readonly'}
            value={value}
            className={className}
            labelsx={labelsx}
            controlformsx={controlformsx}
            control={
                <CustomRadio
                    id={`radio_field_${id}`}
                    checked={checked}
                    onChange={onChange}
                    name={name}
                    size= {size? size: "small"}
                    className={className}
                    customcheckedcolor={props.customCheckedColor}
                    sx={{
                        padding: "8px",
                        ...radiosx,
                    }}
                />
            }
            label={label}
        />
    );
};

export { RadioButton as Radio };