import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from '@mui/material/styles';

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 !important;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
      padding: 8px 5px 16px !important;
`;


const AccordionComponent = ({ heading, description, accordionCustomStyle, expanded, defaultExpanded, id="panel1a-header", className }) => {
  return (
    <Accordion sx={{...accordionCustomStyle}} expanded={expanded} defaultExpanded={defaultExpanded} className={className}>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'primary.blue' }} />}
        aria-controls="panel1a-content"
        id={id}
      >
        <Typography variant={'h5'} sx={{color: "primary.main"}} component={'div'}>{heading}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails sx={{backgroundColor: className === 'gpoSectionAccordion' ? 'transparent' : 'neutral.white' }} >
        <Typography variant={'p2'} color="primary.main">{description}</Typography>
      </StyledAccordionDetails>
    </Accordion>
  );
};

export { AccordionComponent as Accordion };
