import { useEffect, useState } from 'react';

export default function useAddressSorting(addresses) {
  const SHIP_TO = 'SHIP_TO';
  const SOLD_TO = 'SOLD_TO';
  const [shipToAddresses, setShipToAddresses] = useState([]);
  const [soldToAddresses, setSoldToAddresses] = useState([]);

  useEffect(() => {
    if (addresses && addresses.length > 0) {
      const filterAddress = (targetFunction) => {
        let filteredResult = addresses.filter((address) => address?.partnerFunction === targetFunction).map(item => {
          let newItem = {...item,
            country: item?.country?.isocode,
            region: item?.region?.isocodeShort,
          };
          return newItem;
        });
        return filteredResult;
      };

      let temp = filterAddress(SHIP_TO);
      setShipToAddresses(temp);

      temp = filterAddress(SOLD_TO);
      setSoldToAddresses(temp);
    }
    else{
      setSoldToAddresses([])
      setShipToAddresses([])
    }
  }, [addresses]);

  return [ shipToAddresses, soldToAddresses ];
}