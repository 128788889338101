import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import ViewIcon from "../../assets/images/MyProfile/View_Icon.svg";
import { Box, Typography } from "@mui/material";

const GridContainer = styled(Grid)(({ theme: { palette: { secondary, neutral } }}) => `
  background-color: ${secondary.main};
  border-radius: 16px;
  padding: 10px 10px 26px 10px;
  margin: 0 !important;
  width: 100% !important;
  &&.edit {
    background-color: ${neutral.gray5};
}
`);

const ViewContainer = styled('div')(({ theme: { breakpoints } }) => `
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    ${[breakpoints.down('sm')]} {
        width: 100%;
        padding-right: 10px;
    }
`);

const Label = styled(Typography)(({ theme: { palette: { primary }, breakpoints }}) => `
  color: ${primary.main};
  margin-top: 5px;
  margin-Bottom: 5px;
  text-transform: uppercase;
  ${[breakpoints.down('sm')]} {
    line-height: 16.5px;
  }
`);

const Value = styled(Typography)(({ theme: { breakpoints } }) => `
  user-select: text;
  ${[breakpoints.down('sm')]} {
    line-height: 24px;
  }
`);

const NotFoundValue = styled('p')(({ theme: { palette: { neutral } }}) => `
  color: ${neutral.gray2};
`);

export const LicenseInfo = ({ staticContent, userInfo, pageType }) => {
    const [showDea, setShowDea] = useState(false);
    const [showHin, setShowHin] = useState(false);
    const toggleDea = () => {
        setShowDea(prev => !prev)
    }
    const toggleHin = () => {
        setShowHin(prev => !prev)
    }
    return(
        <Box margin='30px auto 0 auto' width='80%' id="License">
            <Typography variant={'h3'} fontFamily={'Aeonik Light'} color='primary.main' textAlign='left' mb='25px' mt='30px' id="title">{staticContent?.licensesSectionLabel}</Typography>
            <GridContainer container spacing={2} className={pageType}>
                <Grid item xs={12} md={6}>
                    <Label variant={'h6'} id="deaNumberLabel">{staticContent?.deaNumberLabel}</Label>
                    <ViewContainer className={pageType}>
                        {userInfo?.orgUnit?.deaLicense ? <><Value variant={'p1'} id="deaNumber">{showDea ? userInfo?.orgUnit?.deaLicense : '**********'}</Value>
                        <img src={ViewIcon} alt="view icon" height="30" width="30" onClick={toggleDea} /></> : 
                        <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>}
                    </ViewContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label variant={'h6'} id="hinNumberLabel">{staticContent?.hinNumberLabel}</Label>
                    <ViewContainer className={pageType}>
                        {userInfo?.orgUnit?.hin ? <><Value variant={'p1'} id="hinNumber">{showHin ? userInfo?.orgUnit?.hin : '**********'}</Value>
                        <img src={ViewIcon} alt="view icon" height="30" width="30" onClick={toggleHin} /></> : 
                        <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>}
                    </ViewContainer>
                </Grid>
            </GridContainer>
        </Box>
    )
}