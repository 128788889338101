import { useEffect, useRef, useState } from "react";
import { Box, Divider, Link, Typography } from "@mui/material";
import { DeliveryLocation } from "../DeliveryLocationEntryComponent";
import { AlertMessage } from "../AlertMessage";
import { Button } from "../Button";
import { ExternalLinkModal } from "../ExternalLinkModal";
import { DeliveryIntructionModal } from '../DeliveryInstructionModal';
import { useDispatch, useSelector } from "react-redux";
import { getOrderCartApi, updateOrderCartApi } from "../../features/commerce/commerceThunkApi";
import { cartValidationApi } from "../../utils/CartApi";
import { useUserInfoContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import { STATE_MAPPING, RESTRICTED_STATES } from '../../constants';
import { isExternalURL } from "../../utils/Helper";

const isOrderableAddress = (address) => {
  const invalidStatuses = ["UNVALIDATED", "NOT_VALID"];
  const isValid = !invalidStatuses.includes(address.validationStatus);
  
  return address.partnerFunction === "SHIP_TO" && isValid;
}

const CartDeliveryComponent = ({isCheckout, entryData, cartContent, selectedLocations, index, dosesPerCarton, setCheckoutBtnDisabled, onLocaleErrorChange}) => {
  const history = useHistory();
  const { userInfo } = useUserInfoContext();
  const { uid : userId } = userInfo;
  const alertRef = useRef(null);

  /** Base object prototype for tracking a dropdown component's current selections. */
  const DropdownModifiedDetailsBase = Object.freeze({locationUid: undefined, doses: undefined, deliveryInstruction: undefined});
  const emptyDropdownDetails = () => {return {...DropdownModifiedDetailsBase}};

  // Store
  const dispatch = useDispatch();
  const { addresses } = useSelector(store => store.commerce);
  const { orderCartData } = useSelector(store => store?.commerce);
  const locale = useSelector((state) => state.globalStates.locale);
  const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
  const isRestrictedLocationErrorEnabled = process.env.IS_RESTRICTED_LOCATION_ERROR_ENABLED;

  const getDropdownValues = (locations) => {
    if (locations && locations.length) {
      let initialLocations = {}
      locations.forEach((location, i) => {
        initialLocations[i] = {
          locationUid: location.deliveryAddress?.uid,
          doses: parseInt(location.quantity * dosesPerCarton).toString(),
          deliveryInstruction: location.deliveryInstruction
        }
      })
      return initialLocations;
    }
    return {0: emptyDropdownDetails()};
  }

  // State
  const [shipToAddresses, setShipToAddresses] = useState([]);
  const [modifiedLocationSelections, setModifiedLocationSelections] = useState(getDropdownValues(selectedLocations && selectedLocations[index]));
  const [showDeliveryInstructionModal, setShowDeliveryInstructionModal] = useState(false);
  const [updateCartBtnDisabled, setUpdateCartBtnDisabled] = useState(false);
  const [addressDropdownError, setAddressDropdownError] = useState(false);
  const [comboSelectError, setComboSelectError] = useState('false');
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const [showLocaleSpecificError, setShowLocaleSpecificError] = useState(false);
  const [restrictedLocation, setRestrictedLocation] = useState('');

  const modifiedLocationsAsArray = Object.values(modifiedLocationSelections).map(
    ({locationUid: uid}) => shipToAddresses.find(addr => addr.uid === uid)
  ).filter(addr => addr !== undefined);

  const companyNameLastEntry = modifiedLocationsAsArray[modifiedLocationsAsArray.length - 1]?.companyName;
  const currentDeliveryLocationSelection = modifiedLocationSelections[Object.keys(modifiedLocationSelections).length - 1];

  const isAddLocationEnabled = currentDeliveryLocationSelection?.locationUid;

  const isDeliveryInstructionEnabled = currentDeliveryLocationSelection?.locationUid 
    && (currentDeliveryLocationSelection?.deliveryInstruction === '' || !currentDeliveryLocationSelection?.deliveryInstruction);

  useEffect (() => {
    if(addresses && addresses?.data?.addresses?.length > 0) {
      setShipToAddresses(addresses?.data?.addresses.filter((address) => isOrderableAddress(address)))
    }
  }, [addresses]);
 
  /**
   * TODO: This needs refactoring.
   * This useEffect is being used incorrectly, that needs to be refactored so that child components
   * do not control how parent renders the UI.
   */
  useEffect(() => {
    let savedSelections = []; // persisted selected locations in the backend.
    let currentSelections = Object.values(modifiedLocationSelections).filter(val => val.locationUid !== undefined);

    if (selectedLocations && selectedLocations[index]?.length) {
      savedSelections = selectedLocations[index]
    }

    if (!currentSelections.length && !savedSelections.length ) {
        currentSelections.push(emptyDropdownDetails())
    } else if (!currentSelections.length) {
      currentSelections = getDropdownValues(savedSelections);
      setModifiedLocationSelections(currentSelections)
    } else {
        let savedSelectionUids = savedSelections.map(location => location.deliveryAddress?.uid);
        let mismatch = currentSelections.some(location => 
          !savedSelectionUids.includes(location.locationUid)
        )
        // if currently selected locations length are same as backend saved lengths and there are no mismatches
        if (Object.values(modifiedLocationSelections).length === savedSelections.length && !mismatch) {
          setUpdateCartBtnDisabled(true);
        } else {
          setUpdateCartBtnDisabled(false);
        }
    }
  }, [selectedLocations, index, cartContent, dosesPerCarton, entryData, shipToAddresses, setCheckoutBtnDisabled ]) // do not add 'modifiedLocationSelections' attr in dependency
    
 /**
   * TODO: This can be a state rather than useEffect. 
   * 11-07-23: Do not change the order of this useEffect for now.
   */
  useEffect(()=> {
    let totalShippingQty = 0;
    selectedLocations && selectedLocations[index]?.forEach(item => totalShippingQty += item.quantity)
    if(totalShippingQty !== entryData?.quantity){
      setUpdateCartBtnDisabled(false);
      setCheckoutBtnDisabled(true);
    } else {
      setUpdateCartBtnDisabled(true);
      setComboSelectError('false');
      const dosesTotalMismatch = selectedLocations?.map((item, i) => {
        let totalShippingQty = 0;
        item.forEach(item =>  totalShippingQty += item.quantity)
        if(totalShippingQty !== orderCartData?.entries[i]?.quantity) {
          return false
        } else {
          return true
        }
      })
      setCheckoutBtnDisabled( dosesTotalMismatch.every(val => val === true) ? false : true )
    }
  }, [entryData?.quantity, index, selectedLocations, setCheckoutBtnDisabled, orderCartData?.entries])

  const addLocation = () => {
    setModifiedLocationSelections({...modifiedLocationSelections, [Object.keys(modifiedLocationSelections).length]: emptyDropdownDetails()})
    setUpdateCartBtnDisabled(false)
    setCheckoutBtnDisabled(true)
  }

  const removeLocation = () => {
    let locationToRemove = Object.keys(modifiedLocationSelections).length - 1;
    let copyLocations = {...modifiedLocationSelections}

    if (showLocaleSpecificError) {
      setShowLocaleSpecificError(false);
      setRestrictedLocation('');
      setUpdateCartBtnDisabled(false);
    }

    delete copyLocations[locationToRemove]

    setModifiedLocationSelections({...copyLocations})
    setUpdateCartBtnDisabled(false)
    setCheckoutBtnDisabled(true)
  }

  /** Filter the locations from the addresses available for the logged in user */
  const findSelectedLocation = (locationUid) => {
    const filteredLocation = shipToAddresses?.find(address => {
        return locationUid === address?.uid
    })
    return filteredLocation;
  }

  /** 
   * Handle instruction change - from initial instruction button or edit instruction button.
   * @param {int} index If no index provided, assume we want to update last dropdown entry. 
   * Currently we can only update last dropdown entry.
   */
  const handleDeliveryInstructions = (value, index = undefined) => {
    let i = index || Object.keys(modifiedLocationSelections).length - 1;
    let updatedDeliveryLocation = {...modifiedLocationSelections[i]}
    updatedDeliveryLocation.deliveryInstruction = value;

    setModifiedLocationSelections({...modifiedLocationSelections, [i]: updatedDeliveryLocation});
    setUpdateCartBtnDisabled(false);
    setCheckoutBtnDisabled(true);
  }

  const handleDoseQuantity = (value, i) => {
    let updateDoseQuantity = {...modifiedLocationSelections[i]}
    updateDoseQuantity.doses = value;

    setModifiedLocationSelections({...modifiedLocationSelections, [i]: updateDoseQuantity});
    setCheckoutBtnDisabled(true);
  }

  const handleLocationSelection = async (locationUid, i) => {
    let updatedLocation = {...modifiedLocationSelections[i]}
    updatedLocation.locationUid = locationUid;

    if (isRestrictedLocationErrorEnabled === 'true') {
      const { isRestricted, shippingState } = await isRestrictedLocation(locationUid);
      if (isRestricted) {
        updateLocaleSpecificError(shippingState);
      } else {
        setShowLocaleSpecificError(false);
        setRestrictedLocation('');
        onLocaleErrorChange(false);
      }
    }

    setModifiedLocationSelections({...modifiedLocationSelections, [i]: updatedLocation});
  }

  const validateCartEntries = async (cartEntries) => {
    let validationDetailsBody = { validationCart: { cartEntries } };
    let isRestricted;
    let shippingState = null;
    const restrictedEntry = cartEntries?.find(entry => RESTRICTED_STATES?.includes(entry?.deliveryAddress?.state));
    if (restrictedEntry) {
      shippingState = STATE_MAPPING[restrictedEntry?.deliveryAddress?.state];
    }

    try {
      const validationResponse = await dispatch(cartValidationApi({ payload: validationDetailsBody, cartId: 'current' })).unwrap();
      isRestricted = !(validationResponse?.validationResult?.result?.validationSummary?.valid);

      return { isRestricted, shippingState };
    } catch (error) {
      if (restrictedEntry) {
        isRestricted = true;
        updateLocaleSpecificError(shippingState);
      } else {
        isRestricted = false;
        setShowLocaleSpecificError(false);
      }
      return { isRestricted, shippingState };
    }
  };

  const isRestrictedLocation = async (locationUid) => {
    const selectedLocation = shipToAddresses?.find(address => address.uid === locationUid)?.region?.isocode?.split("-")?.[1];
    const cartEntry = {
      cartEntryId: (entryData?.entryNumber).toString(),
      deliveryAddress: {
        id: locationUid,
        state: selectedLocation
      }
    };
  
    let { isRestricted, shippingState } = await validateCartEntries([cartEntry]);
    if (isRestricted) {
      updateLocaleSpecificError(shippingState);
    }
    
    return { isRestricted, shippingState };
  };

  const handleRestrictedLocation = (state) => {
    setShowLocaleSpecificError(true);
    setRestrictedLocation(state);
    setUpdateCartBtnDisabled(true);
    setCheckoutBtnDisabled(true);
  };

  const updateLocaleSpecificError = (value) => {
    handleRestrictedLocation(value);
    onLocaleErrorChange(true);
  };

  const validateDropdowns = () => { // validate the values of locations doses drop downs where the quantity should be matched with the item's quantity
    let totalShippingQty = 0;
    let dosePresent = true;
    Object.values(modifiedLocationSelections)?.forEach(item =>  {
      if(item.doses === 0 || item.doses === undefined) dosePresent = false;
      let itemQuantity = item.doses/dosesPerCarton
      totalShippingQty += itemQuantity
    });

    if(totalShippingQty !== entryData?.quantity || !dosePresent) {
      setComboSelectError('true')
    }
    if(Object.keys(modifiedLocationSelections).length !== modifiedLocationsAsArray.length) {
      setAddressDropdownError(true)
    }
    if(totalShippingQty === entryData?.quantity && dosePresent) {
      handleUpdateCart()
    }
  }

  const handleUpdateCart = async () => {
    let configurationInfos = Object.keys(modifiedLocationSelections).map(i => {
      let dropdownEntry = modifiedLocationSelections[i];
      return {
        deliveryAddress: { uid: dropdownEntry.locationUid },
        deliveryInstruction: dropdownEntry.deliveryInstruction || null,
        quantity: dropdownEntry.doses/dosesPerCarton || null
      };
    });

    if (isRestrictedLocationErrorEnabled === 'true') {
      const cartEntries = configurationInfos.map(info => ({
        cartEntryId: info.deliveryAddress.uid.toString(),
        deliveryAddress: {
          id: info.deliveryAddress.uid,
          state: shipToAddresses?.find(address => address.uid === info.deliveryAddress.uid)?.region?.isocode?.split("-")?.[1]
        }
      }));
    
      setLoading(true);
    
      const { isRestricted, shippingState } = await validateCartEntries(cartEntries);
    
      if (!isRestricted) {
        try {
          await dispatch(updateOrderCartApi({ userId, entryNumber: entryData?.entryNumber, updatedData: { configurationInfos } })).unwrap();
          const data = await dispatch(getOrderCartApi({ userId })).unwrap();
          if (data?.entries?.length === 0) {
            history.push(`/${locale}/products`);
          }
          setModifiedLocationSelections({});
          setUpdateCartBtnDisabled(true);
        } catch (error) {
          setErrorMsg(generalApiError);
          alertRef.current?.openAlert(error);
        }
      } else {
        updateLocaleSpecificError(shippingState);
      }
      setLoading(false);
    } else {
      setLoading(true)
      const updatedData = {
        configurationInfos
      };
      dispatch(updateOrderCartApi({ userId, entryNumber: entryData?.entryNumber, updatedData})).unwrap()
      .then(() => {
        dispatch(getOrderCartApi({ userId })).unwrap()
        .then((data) => {
          if(data?.entries?.length === 0)
            history.push(`/${locale}/products`)
        
          setModifiedLocationSelections({}) // Triggers the useEffect
          setLoading(false);
          setUpdateCartBtnDisabled(true);
        })
        .catch((error) => {
          setErrorMsg(generalApiError);
          alertRef.current?.openAlert(error);
          setLoading(false);
        })
      })
      .catch((error) => {
        setErrorMsg(generalApiError);
        alertRef.current?.openAlert(error);
        setLoading(false);
      })
    }
  };

  const [externalLink, setExternalLink] = useState(null);
  const [key, setKey] = useState(0);
  const [externalLinkModalType, setExternalLinkModalType] = useState("extrenalLink");

  const handleClick = (url) => {
    if (isExternalURL(url) && !localStorage.getItem("dontRemindMeExternal")) {
      setExternalLink(url);
      setExternalLinkModalType("extrenalLink");
    } else {
        window.open(url, '_blank');
        setExternalLink("");
    }
    setKey(prevKey => prevKey + 1);
  };

  const renderRestrictedLocationError = (
    (
      <>
        <Typography data-testid='restrictedLocationErrorMsg' color='error.main'>{`${cartContent?.localeSpecificErrorMessage1} ${restrictedLocation}. `}
        <br/> {`${cartContent?.localeSpecificErrorMessage2}`}
          <Link
              data-link-href={cartContent?.authorizedDistributorsLink}
              onClick={() => handleClick(cartContent?.authorizedDistributorsLink)}
              color='error.main' 
              sx={{textDecoration:'underline', cursor: 'pointer'}}
              target="_blank" 
              rel="noopener noreferrer">
          {`${cartContent?.authorizedDistributors}`}
          </Link>.
        </Typography>
        {Object.keys(modifiedLocationSelections).length > 1 &&
          <>
            <Link data-testid='removeButton' onClick={() => removeLocation()} color={'secondary.textLinks'} sx={{ textDecoration: 'none', cursor: 'pointer' }} width='fit-content'>{cartContent?.removeLinkText}</Link>
          </>
        }
      </>
    )
  )

  return (
    <>
    {externalLink && externalLinkModalType === "extrenalLink" && !localStorage.getItem('dontRemindMeExternal') && <ExternalLinkModal
      key={key}
      redirectUrl={cartContent?.authorizedDistributorsLink}
    />}
    <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
    <DeliveryIntructionModal 
      id="addDeliveryInsrructions" 
      title={`${cartContent?.addDeliveryInstructionsModalLabel} - ${companyNameLastEntry}`} 
      primaryButtonLabel="SAVE" 
      openModal={showDeliveryInstructionModal} 
      closeModalHandler={() => setShowDeliveryInstructionModal(false)} 
      handleDeliveryInstructions={handleDeliveryInstructions} 
      cartContent={cartContent} 
      deliveryInstructions={currentDeliveryLocationSelection?.deliveryInstruction}
      setDeliveryInstructions={handleDeliveryInstructions}
    />
    <Box id={"deliveryLocationContainer"} width='100%' data-testid="CartDeliveryContainer">
      { Object.keys(modifiedLocationSelections)?.map(i => {
        let location = findSelectedLocation(modifiedLocationSelections[i].locationUid);
        return(
          <DeliveryLocation 
            key={i} 
            index={i} 
            location={location}
            addresses={shipToAddresses}
            modifiedLocationsAsArray={modifiedLocationsAsArray} // To prevent selecting already selected location
            deliveryInstructions={modifiedLocationSelections[i].deliveryInstruction || ''}
            doseQuantity={modifiedLocationSelections[i].doses}
            cartContent={cartContent}
            entryData={entryData}
            handleLocationSelection={handleLocationSelection}
            handleDeliveryInstructions={handleDeliveryInstructions}
            handleDoseQuantity={handleDoseQuantity}
            dosesPerCarton={dosesPerCarton}
            setUpdateCartBtnDisabled={setUpdateCartBtnDisabled}
            setCheckoutBtnDisabled={setCheckoutBtnDisabled}
            addressDropdownError={addressDropdownError}
            setAddressDropdownError={setAddressDropdownError}
            comboSelectError={comboSelectError}
            setComboSelectError={setComboSelectError}
            userId={userId}
          ></DeliveryLocation>
      )}) }
    </Box>
      {showLocaleSpecificError ?
        renderRestrictedLocationError
        :
        <Box display={'flex'} alignItems={'center'} mb={1}>
          <Link onClick={() => isDeliveryInstructionEnabled && setShowDeliveryInstructionModal(true) } color={'secondary.textLinks'} sx={{ textDecoration: 'none', cursor: isDeliveryInstructionEnabled ? 'pointer' : 'auto', opacity: isDeliveryInstructionEnabled ? '1' : '0.6' }}>
              {cartContent?.addDeliveryInstructionsLinkText}
          </Link>
          {Object.keys(modifiedLocationSelections).length > 1 &&
          <>
            <Divider orientation="vertical" variant="middle" sx={{ display: 'flex', height: '15px', margin: '0 15px', border: '1px solid', borderColor: 'neutral.gray2' }} />
            <Link onClick={() => removeLocation()} color={'secondary.textLinks'} sx={{ textDecoration: 'none', cursor: 'pointer' }}>{cartContent?.removeLinkText}</Link>
          </>
          }
        </Box>
      }
    
    {!showLocaleSpecificError && isAddLocationEnabled &&
      <Link data-testid='addLocationButton' onClick={() => addLocation()} color={'secondary.textLinks'} sx={{ textDecoration: 'none', cursor: 'pointer', width: '100px' }}>{cartContent?.addLocationLinkText}</Link>
    }
    
    {!showLocaleSpecificError && !updateCartBtnDisabled && <Box width={'fit-content'} mt={2}>
      <Button className='updateCart' data-testid='updateCartButton' onClick={() => validateDropdowns()} loading={loading} disabled={updateCartBtnDisabled}>{cartContent?.updateButtonText}</Button>
    </Box>}
  </>
  );
};

export default CartDeliveryComponent;