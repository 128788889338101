import React, {useState, useEffect} from "react";
import {styled} from "@mui/material/styles";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import { Typography } from "@mui/material";
import {KeyboardArrowRightOutlined, KeyboardArrowLeftOutlined} from "@mui/icons-material";

const CustomDatePicker = styled(DatePicker)(({theme: {palette: {neutral, primary, secondary}}}) => ({
  backgroundColor: neutral.white,
  "& .MuiInputBase-root.MuiOutlinedInput-root" :{
    fontFamily: 'Aeonik Regular',
    boxSizing:'border-box'
  },
  "& label": {
    color: neutral.gray2,
    fontSize: "16px",
    fontFamily: "Aeonik Regular"
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: neutral.gray2,
    borderRadius: 5,
    fontFamily: 'Aeonik Regular'
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${neutral.gray2}`,
    borderRadius: 5
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: neutral.gray2,
    borderRadius: 5
  },
  "& .MuiIconButton-root": {
    color: secondary.graphical
  }
}));

const CustomDatePickerField = ({
    defaultValue,
    onChange,
    onError,
    sx,
    label,
    disablePast,
    ...props
  }) => {
  const [value, setValue] = useState(defaultValue !== "false" ? defaultValue : "");

  useEffect(() => {
    defaultValue !== "false" ? setValue(defaultValue) : setValue('')
  }, [defaultValue])

  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography variant={'h6'} textTransform={'uppercase'}>{label}</Typography>
        <CustomDatePicker sx={{maxWidth: '242px', ...sx}}
          onChange={onChange}
          onError={onError}
          format="MMMM D, YYYY"
          disablePast={disablePast}
          {...props}
          slotProps={{ textField: { size: 'small', placeholder:'Select date' } }}
          views={["day"]}
          inputProps={{
            'aria-label': 'Without label'
          }}
          value={value}
          components={{
            OpenPickerIcon: CalendarTodayOutlinedIcon,
            LeftArrowIcon: KeyboardArrowLeftOutlined,
            RightArrowIcon: KeyboardArrowRightOutlined
          }}
        />
      </LocalizationProvider>
  );
};

export {CustomDatePickerField as DatePicker};
