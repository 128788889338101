import { useState, useRef } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import LogoHeader from "../Header/LogoHeader";
import { AlertMessage, Button, Spinner } from "../../components";
import { EmailVerificationPageConstants } from "../../assets/constants/EmailVerificationConstants";
import { useSelector } from "react-redux";
import { resendActivationEmailApi } from "../../utils/IdentityService/EmailVerificationApi";
import MessageBanner from "../MessageBanner/MessageBanner";


const RegistrationVerification = () => {
    const history = useHistory();
    const emailAddress = useSelector(state => state.globalStates.emailAddress);
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const alertRef = useRef(null);
    const [clickedResendEmail, setClickedResendEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const handleReturnToHome = () => {
        history.push('/')
    }

    const handleOnClick = async () => {
        try {
            setIsLoading(true);
            const response = await resendActivationEmailApi({ emailAddress: emailAddress });
            if (response?.status === 200) {
                setClickedResendEmail(true)
            }
        } catch (error) {
            setErrorMsg(generalApiError);
            alertRef.current?.openAlert(error);
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Spinner processing={isLoading} />
            <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
            <Box
                width='100%'
                height='100vh'
                display="flex"
                flexDirection='column'
                alignItems='center'
            >
                <Box maxWidth='640px' width='60%'>
                    <LogoHeader/>
                </Box>
                <Box maxWidth='640px' width={'60%'}>
                    {clickedResendEmail &&
                        <MessageBanner
                            type="info"
                            headerText={EmailVerificationPageConstants?.bannerHeader}
                            messageText={EmailVerificationPageConstants?.bannerMessage}
                            dataTestId="resendEmailNotificationBanner"
                        />
                    }
                        <Typography data-testid='registrationConfirmationHeader' variant="h3" mt={6} mb={4}>{EmailVerificationPageConstants?.heading}</Typography>
                        <div>
                            <Typography variant="p1" >{EmailVerificationPageConstants?.mainText}</Typography>
                        </div>
                        <Typography variant="p1" >{`We sent a message to ${emailAddress} with instructions.`}</Typography>
                        <Typography variant="h5" mt={2}>{EmailVerificationPageConstants?.noEmailHeading}</Typography>
                        <Typography variant="p1" >{EmailVerificationPageConstants?.noEmailText}</Typography>
                        <div>
                            <Button
                                buttonType="link"
                                onClick={handleOnClick}
                                data-testid="resendEmailBtn"
                                sx={{
                                    textTransform: 'none',
                                    padding: '0',
                                    display: 'flex',
                                    justifyContent: 'flex-start', 
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                <Typography fontFamily='Aeonik Regular'>{EmailVerificationPageConstants?.NoEmailLink}</Typography>
                            </Button>
                        </div>
                    <Box mt={15}>
                        <Typography color='#85888E' data-testid='copyrightMesssage'>© 2024 Moderna, Inc. </Typography>
                        <Button buttonType="link"
                                sx={{
                                textTransform: 'none',
                                padding: '0',
                                display: 'flex',
                                justifyContent: 'flex-start', ButtonText: '14px',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                                }}
                                onClick={handleReturnToHome}
                                data-testid='returnToHomeBtn'>
                            <Typography fontSize='14px'>Return to homepage</Typography>
                           </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default RegistrationVerification