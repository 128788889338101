export const oidcConfig = {
  issuer: process.env.OKTA_ISSUER,
  clientId: process.env.OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  scopes: [
    'openid',
    'profile',
    'email'
  ],
  pkce: true
};