import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { Box, Typography } from "@mui/material";
import { HandlerBarTemplateHandler } from "../../utils/Helper";
import { loadResetSuccessStaticContent } from "../../features/contentful/contentfulThunkApi";
import { ResourceCardComponent } from "../ResourceCardComponent";

const CardContainer = styled(Box)(({ theme: { breakpoints } }) => `
    padding: 30px 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    ${[breakpoints.up('md')]} {
        padding: 20px 94px 100px;
    }
    ${[breakpoints.down('sm')]} {
      grid-template-columns: 1fr;
      gap: 16px;
    }
`);

const GlobalImg = styled('img')(({ theme: { breakpoints } }) => `
  margin: 20px auto;
  display: flex;
  ${[breakpoints.down('sm')]} {
    margin-top: 50px;
  }
`);

export const SetPasswordResetSuccess = ({ firstName }) => {
  const { resetSuccessContent } = useSelector((store) => store?.contentful);
  const { staticContent = [] } = resetSuccessContent;
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0,0);
    if (Object.keys(resetSuccessContent).length === 0) {
      dispatch(loadResetSuccessStaticContent({ title: "Set Password Success Confirmation Component" }));
    }
  },[]);

  const hbrHtmlEl = HandlerBarTemplateHandler({ firstName: firstName}, staticContent?.headline) || null;

  return (
    <Box p={4}>
      <GlobalImg id="resetSucessImg" data-testid="resetSucessImg" alt={staticContent?.icon?.fields?.title} src={staticContent?.icon?.fields?.file?.url} width="90" height="90"></GlobalImg>
      <Typography variant='h1' color={'primary.main'} mt={4} textAlign={'center'} id="resetSuccessText" data-testid="resetSuccessText" sx={{ margin: { sm: '32px 120px 0' }}}>{hbrHtmlEl}</Typography>
      <Typography variant='h3' color={'primary.main'} mt={2} style={{ textAlign: "center", marginTop: "40px" }} data-testid="benefitsSectionTitle">{staticContent?.benefitsSectionTitle}</Typography>
      <CardContainer>
          {staticContent?.benefitCards?.map((card, index) => (
              <ResourceCardComponent cardData={card} arrow={card?.fields?.link !== ''} index={index} key={"resourceCardComponent" + index} id={"setPasswordResetSuccess" + index} />
          ))
          }
      </CardContainer>
    </Box>
  )
};