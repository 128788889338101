import { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { ContactUs } from "../components";
import { useUserInfoContext } from '../contexts';
import { useDispatch, useSelector } from "react-redux";
import { loadFaqStaticContent, loadFaqLocaleContent } from "../features/contentful/contentfulThunkApi";
import algoliasearchClient from "../sevices/algoliasearchClient";
import { InstantSearch } from 'react-instantsearch-hooks-web';
import FaqList from "../components/FaqList";
import { Typography } from "@mui/material";

const algoliaSearchIndex = process.env.ALGOLIA_INDEX_NAME ? process.env.ALGOLIA_INDEX_NAME : ''
//const isAlgoliaSearchEnabled = JSON.parse(process.env.IS_ALGOSEARCH_ENABLED)

const FaqBanner = styled('section')(
  ({ backgroundImgUrl, theme: { palette: { primary }, breakpoints } }) => `
  background: ${primary.main} 0% 0% no-repeat padding-box;
  padding: 97px 0px 70px 22px;
  background-size: 1280px;
  background-image: url(${backgroundImgUrl});
  background-size: cover;
  background-position: 0 -78px !important;
  ${[breakpoints.up('md')]} {
    padding-left: 140px;
  }
`
);

const SearchImg = styled('img')`
  padding-top: 15px;
`;

const FaqPage = () => {
  const { userInfo, setUserInfo } = useUserInfoContext() || '';
  const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);

  useEffect(() => {
    if (!userInfo)
      localStorage.getItem("currentUser") !== "undefined" && setUserInfo(JSON.parse(localStorage.getItem("currentUser")))
  }, [setUserInfo, userInfo]);

  const { faqPageContent } = useSelector(store => store?.contentful)
  const { faqBannerData = {}, contactUsData = {} } = faqPageContent
  const { faqLocaleContent } = useSelector(store => store?.contentful)

  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.keys(faqPageContent).length === 0)
      dispatch(loadFaqStaticContent())
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Object.keys(faqLocaleContent).length === 0) dispatch(loadFaqLocaleContent({ isocode: localeISOCode }))
      window.scrollTo(0, 0);
  }, [faqLocaleContent]);

  const backgroudnImageUrl =
    faqBannerData?.fields?.backgroundImage?.fields?.file?.url;
  return (
    <InstantSearch searchClient={algoliasearchClient} indexName={algoliaSearchIndex}>
      <FaqBanner id="faqBanner" backgroundImgUrl={backgroudnImageUrl} data-testid='faqBanner'>
        <Typography variant={'h1'} color='neutral.white' id="deadLine" data-testid='headline'>{faqBannerData?.fields?.title}</Typography>
        <Typography variant={'p1'} mt='15px' color='neutral.white' id="tagLineText" data-testid='tagLineText'>{faqBannerData?.fields?.headline}</Typography>
      </FaqBanner>
      <FaqList faqCategoriesData={faqLocaleContent} />
      <ContactUs id="contactUs" data={contactUsData}/>
    </InstantSearch>
  );
};

export default FaqPage;
