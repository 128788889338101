import { Route, Redirect, Switch } from "react-router-dom";
import { SecureRoute } from "@okta/okta-react";
import FaqPage from "./pages/Faqpage";
import NewHomePage from "./pages/NewHomepage";
import NotFoundPage from "./pages/Notfound";
import OrderHistoryPage from "./pages/OrderHistoryPage";
import NotAuthorized from "./pages/NotAuthorized";
import { ImpersonationLookup } from "../src/components";
import Landingpage from "./pages/Landingpage";
import ResetPasswordPage from "./pages/ResetPasswordpage";
import UserRegistrationPage from "./pages/UserRegistrationpage";
import RequestResetPasswordPage from "./pages/RequestResetPasswordpage";
import RegistrationConfirmationPage from "./pages/RegistrationConfirmationPage";
import ComposePage from "./pages/ComposePage";
import MyProfilepage from "./pages/MyProfilepage";
import ContactUs from "./pages/ContactUs";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import HistoricOrderDetailsPage from "./pages/HistoricOrderDetailsPage";
import OrderDetailsPage from "./pages/OrderDetailsPage";
import { useSelector } from "react-redux";
import OrderAcknowledgement from "./pages/OrderAcknowledgement";
import { ModalProvider } from "./providers/ModalProvider";
import { FallbackHomePage } from "./components/FallbackHomepage";
import ReservationPage from "./pages/ReservationPage";
import ReservationConfirmationPage from "./pages/ReservationConfirmationPage";
import ReservationSummaryPage from "./pages/ReservationSummaryPage";
import OnboardingPage from "./pages/Onboarding/OnboardingPage";
import RegistrationVerification from "./components/Registration/RegistrationVerification";
import OnboardSuccessPage from "./pages/Onboarding/OnboardSuccessPage";
import { USER_STATUS } from "./constants";
import OrderFormPage from "./pages/Ordering/OrderFormPage";
import OrderCheckoutPage from "./pages/OrderCheckoutPage";
import OrderConfirmationPage from "./pages/Ordering/OrderConfirmationPage";
import InvoicePayPage from "./pages/InvoicePayPage";
import InvoicePayConfirmationPage from "./components/Payments/InvoicePay/InvoicePayConfirmationPage";
import InvoicePayInfoPage from "./components/InvoicePay/InvoicePayInfoPage";

const Locale = ({ relativePathname, locale, setExternalLink, setShowNavigationAlert, setExternalLinkModalType, staticContent, isEmployeeLoggedIn, isImpersonationEnabled, isCurrentUserAvailable }) => {
    const { orderCartData } = useSelector(store => store?.commerce);
    const isReservationEnabled = process.env.IS_RESERVATIONS_ENABLED === 'true'
    const isNewHomepageEnabled = process.env.IS_NEW_HOMEPAGE_ENABLED === 'true' && locale === 'en-US';
    const isEditReservationEnabled = process.env.IS_EDIT_RESERVATIONS_ENABLED === 'true';
    const isNewOrderingEnabled = process.env.IS_NEW_ORDER_EXPERIENCE_ENABLED === 'true';
    const isReservationConversionEnabled = process.env.IS_RESERVATIONS_CONVERSION_ENABLED === 'true';

    const OrderFormCmp =
      <ModalProvider>
        <OrderFormPage />
      </ModalProvider>
    ;

    const userStatus = useSelector(store => store?.globalStates?.userStatus);
    const isEmployee = () => localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined";

    return (
        <Switch>
                {(relativePathname === `/${locale}/wac-disclosure` || relativePathname === `/wac-disclosure` ||
                relativePathname === `/${locale}/privacy-policy` || relativePathname === `/privacy-policy` ||
                relativePathname === `/${locale}/terms-of-use` || relativePathname === `/terms-of-use` ||
                relativePathname === `/${locale}/game` || relativePathname === `/game` ||
                relativePathname === `/${locale}/return-goods-policy` || relativePathname === `/return-goods-policy` ||
                relativePathname === `/${locale}/participating-physician-buying-groups` || relativePathname === `/participating-physician-buying-groups` ||
                relativePathname === `/${locale}/dscsa` || relativePathname === `/dscsa` ||
                relativePathname === `/${locale}/notice-of-seasonal-return-window-covid-19-vaccine-2023-2024` || relativePathname === `/notice-of-seasonal-return-window-covid-19-vaccine-2023-2024` ||
                relativePathname === `/${locale}/participating-physician-buying-groups` || relativePathname === `/participating-physician-buying-groups` ||
                relativePathname === `/${locale}/authorized-distributor` || relativePathname === `/authorized-distributor` ||
                relativePathname === `/${locale}/packing-slip` || relativePathname === `/packing-slip` ||
                ( locale === 'en-DE' && (relativePathname === `/${locale}/imprint` || relativePathname === `/imprint`) ) ||
                relativePathname === `/${locale}/terms-and-conditions-of-sale` || relativePathname === `/terms-and-conditions-of-sale` ||
                ( locale === 'en-DE' && (relativePathname === `/${locale}/contact-moderna` || relativePathname === `/contact-moderna`) )) &&
                (<Route path={relativePathname}> {/* IMPORTANT - THIS SHOULD ALWAYS BE IN THE END!! ADD ALL THE ROUTES ABOVE THIS. ORDER MATTERS*/}
                    <ComposePage
                    relativePathname={relativePathname}
                    setShowNavigationAlert={setShowNavigationAlert}
                    setExternalLink={setExternalLink}
                    setExternalLinkModalType={setExternalLinkModalType}
                    />
                </Route>)}
            { isReservationEnabled && isEditReservationEnabled && isCurrentUserAvailable() && !isEmployee() &&
                <SecureRoute path="/:locale?/reservation/:reservationId">
                    <ModalProvider>
                        <ReservationPage />
                    </ModalProvider>
                </SecureRoute>
            }
            { isReservationEnabled && !isEmployee() &&
                <Route path="/:locale?/reservation" >
                    <ModalProvider>
                        <ReservationPage />
                    </ModalProvider>
                </Route>
            }
            { isCurrentUserAvailable() && isNewOrderingEnabled && !isEmployee() &&
                <SecureRoute path="/:locale?/order-form">
                    {OrderFormCmp}
                </SecureRoute>
            }
            { isCurrentUserAvailable() && isReservationConversionEnabled && isNewOrderingEnabled && !isEmployee() &&
                <SecureRoute exact path="/:locale?/order-form/complete-reservation">
                    <ModalProvider>
                        <OrderFormPage />
                    </ModalProvider>
                </SecureRoute>
            }
            { !isEmployee() && (isCurrentUserAvailable() && isNewOrderingEnabled) && <SecureRoute path="/:locale?/order-confirm" component={OrderConfirmationPage}></SecureRoute>}
            <Route exact path="/en-US"><Redirect to="/en-US/home" /></Route>
            <Route path="/:locale?/contact-us">
                <ContactUs
                setShowNavigationAlert={setShowNavigationAlert}
                setExternalLink={setExternalLink}
                setExternalLinkModalType={setExternalLinkModalType}
                />
            </Route>
            {isNewHomepageEnabled && <Route path="/newhomepage">
                <ModalProvider>
                    <NewHomePage />
                </ModalProvider>
            </Route>
            }
            <Route path="/invoice/pay">
                <InvoicePayPage />
            </Route>
            
            <Route path="/invoicepay/confirmation">
                <InvoicePayConfirmationPage />
            </Route>
            
            
            <Route path="/:locale?/invoicepay" >
                <InvoicePayInfoPage />
            </Route>
            <Route exact={true} path="/:locale?/not-authorized" component={NotAuthorized} />
            <SecureRoute path="/:locale?/onboarding/success" component={OnboardSuccessPage} />
            {isCurrentUserAvailable() && userStatus !== USER_STATUS.ACTIVE && <SecureRoute path="/:locale?/onboarding/:tab?" component={OnboardingPage} />}
            <Route path="/:locale?/request-reset-password" component={RequestResetPasswordPage} />
            <Route path="/:locale?/registration/email-verification" component={RegistrationVerification} />
            <Route path="/:locale?/reset-password" component={ResetPasswordPage} />
            <Route path="/:locale?/registration/email-confirmation" component={RegistrationConfirmationPage} />
            { !isEmployee() && (isCurrentUserAvailable() && isReservationEnabled) && <SecureRoute path="/:locale?/confirmation/:reservationId?" component={ReservationConfirmationPage}></SecureRoute>}
            { !isEmployee() && (isCurrentUserAvailable() && isReservationEnabled) && <SecureRoute path="/:locale?/summary/:reservationId?"><ReservationSummaryPage /></SecureRoute>}
            { (isEmployeeLoggedIn() || isImpersonationEnabled()) && relativePathname === `/${locale}/admin` && <Route path={"/:locale/admin"} exact={true} component={ImpersonationLookup} /> }
            { !isImpersonationEnabled() && relativePathname === `/${locale}/admin` && <Redirect to={{pathname:`/${locale}/not-authorized`}} /> }
            { isCurrentUserAvailable() ?
                <SecureRoute path="/:locale?/home">
                    {isNewHomepageEnabled ?
                        <ModalProvider>
                            <NewHomePage />
                        </ModalProvider>
                    :
                        <Landingpage externalModalData={staticContent?.externalLink}/>
                    }
                </SecureRoute>
                :
                (isEmployeeLoggedIn() || isImpersonationEnabled()) ? <Redirect to={{pathname:`/${locale}/admin`}} /> : <Route path="*" exact={true} component={FallbackHomePage} /> }
            { isCurrentUserAvailable() ? <SecureRoute path="/:locale?/faqs" component={FaqPage} /> : (isEmployeeLoggedIn() || isImpersonationEnabled()) ? <Route path="/:locale/admin" exact={true} component={ImpersonationLookup} /> : <Route path="*" exact={true} component={FallbackHomePage} /> }
            { isCurrentUserAvailable() ? locale === 'en-US' && <SecureRoute path="/:locale?/my-profile/" component={MyProfilepage} /> : (isEmployeeLoggedIn() || isImpersonationEnabled()) ? <Route path="/:locale/admin" exact={true} component={ImpersonationLookup} /> : <Route path="*" exact={true} component={FallbackHomePage} /> }
            { isCurrentUserAvailable() ? locale === 'en-US' && <SecureRoute path="/:locale?/my-profile/addresses" component={MyProfilepage} /> : (isEmployeeLoggedIn() || isImpersonationEnabled()) ? <Route path="/:locale/admin" exact={true} component={ImpersonationLookup} /> : <Route path="*" exact={true} component={FallbackHomePage} /> }
            
            { isCurrentUserAvailable() ? <SecureRoute exact path="/:locale?/orders" component={OrderHistoryPage} /> : (isEmployeeLoggedIn() || isImpersonationEnabled()) ? <Route path="/:locale/admin" exact={true} component={ImpersonationLookup} /> : <Route path="*" exact={true} component={FallbackHomePage} /> }
            { isCurrentUserAvailable() ? <SecureRoute path="/:locale?/order/:id" component={HistoricOrderDetailsPage} /> : (isEmployeeLoggedIn() || isImpersonationEnabled()) ? <Route path="/:locale/admin" exact={true} component={ImpersonationLookup} /> : <Route path="*" exact={true} component={FallbackHomePage} /> }
            { isCurrentUserAvailable() && <SecureRoute path="/:locale?/orders/:id" component={OrderDetailsPage} /> }
            { !isReservationEnabled ? (isCurrentUserAvailable() ? <SecureRoute path="/:locale?/products"> {OrderFormCmp} </SecureRoute> : (isEmployeeLoggedIn() || isImpersonationEnabled()) ? <Route path="/:locale/admin" exact={true} component={ImpersonationLookup} /> : <Route path="*" exact={true} component={FallbackHomePage} />) : <Route path="/:locale?/products"exact={true} component={FallbackHomePage} /> }
            { !isReservationEnabled ? (isCurrentUserAvailable() ? <SecureRoute path="/:locale?/product/:productId"> {OrderFormCmp} </SecureRoute> : (isEmployeeLoggedIn() || isImpersonationEnabled()) ? <Route path="/:locale/admin" exact={true} component={ImpersonationLookup} /> : <Route path="*" exact={true} component={FallbackHomePage} />) : <Route path="/:locale?/product/:productId" exact={true} component={FallbackHomePage} /> }
            { isCurrentUserAvailable() ? orderCartData?.totalItems > 0 && <SecureRoute path="/:locale?/cart" component={CartPage} /> : (isEmployeeLoggedIn() || isImpersonationEnabled()) ? <Route path="/:locale/admin" exact={true} component={ImpersonationLookup} /> : <Route path="*" exact={true} component={FallbackHomePage} /> }
            { isCurrentUserAvailable() ? orderCartData?.totalItems > 0 && <SecureRoute path="/:locale?/checkout" component={CheckoutPage} /> : (isEmployeeLoggedIn() || isImpersonationEnabled()) ? <Route path="/:locale/admin" exact={true} component={ImpersonationLookup} /> : <Route path="*" exact={true} component={FallbackHomePage} /> }
            { (isCurrentUserAvailable() && isNewOrderingEnabled ) && <SecureRoute path="/order-summary" component={OrderCheckoutPage}/> }
            { isCurrentUserAvailable() ? <SecureRoute path="/:locale?/order-placed" component={OrderAcknowledgement} /> : (isEmployeeLoggedIn() || isImpersonationEnabled()) ? <Route path="/:locale/admin" exact={true} component={ImpersonationLookup} /> : <Route path="*" exact={true} component={FallbackHomePage} /> }
            { isCurrentUserAvailable() && <Route component={NotFoundPage} />}
            
            { (
                relativePathname !== `/${locale}/admin` && relativePathname !== `/admin` &&
                relativePathname !== `/${locale}home` && relativePathname !== `/home` &&
                relativePathname !== `/${locale}/faqs` && relativePathname !== `/faqs` &&
                relativePathname !== `/${locale}/reservations/create` && relativePathname !== `/reservations/create` &&
                !relativePathname.includes('/view-reservations/') &&
                !relativePathname.includes('/edit-reservations/')) &&
                <Route component={NotFoundPage} /> 
            }
        </Switch>
    )
};

export default Locale;
