import { Box, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Button } from "../../Button";
import PractitionerIcon from "../../../assets/images/practitionerIcon.svg"
import InstitutionIcon from "../../../assets/images/institutionIcon.svg"

const medicalLicenseCardStyles = {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    flex: '1 0 0',
    borderRadius: '16px',
    border: '1px solid #D1D2D5',
    boxShadow: ' 0px 2px 4px 0px rgba(0, 0, 0, 0.50);'
}

const AeonikTypography = styled(Typography)({
    fontFamily: 'Aeonik Regular',
});

const MedicalLicenseCard = ({ selectedMedicalLicense, removeLicense, style }) => {

    const LicenseDetail = ({ testId, label, value }) => {
        return (
            <Box data-testid={testId}>
                <AeonikTypography textAlign={'left'} fontWeight={700}>{label}</AeonikTypography>
                <AeonikTypography textAlign={'left'}>{value}</AeonikTypography>
            </Box>
        );
    }

    return (
        <Box sx={style ? style : medicalLicenseCardStyles} data-testid="medicalLicenseCard">
            <Box display={'flex'} alignItems={'flex-start'} gap={'8px'} width={'100%'}>
                <img 
                    src={selectedMedicalLicense?.licenseType === 'medical' ? PractitionerIcon : InstitutionIcon} 
                    alt={selectedMedicalLicense?.licenseType === 'medical' ? 'practitioner icon' : 'pharmacy icon'} 
                    width={'32px'} 
                    height={'32px'}
                />
                <Box data-testid="licenseName" display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'flex-start'}>
                    <Box>
                        <AeonikTypography textAlign={'left'} fontWeight={700}>
                            {selectedMedicalLicense?.licenseType === 'medical' ? `${selectedMedicalLicense?.firstName} ${selectedMedicalLicense?.lastName}`
                            : selectedMedicalLicense?.name}
                        </AeonikTypography>
                        <AeonikTypography>
                            {selectedMedicalLicense?.licenseType === 'medical' ? 'Medical practitioner' : 'Pharmacy or other institution'}
                        </AeonikTypography>
                        </Box>
                        {removeLicense && 
                            <Button data-testid="removeLicenseBtn" buttonType="link" sx={{ textTransform: "none" }}>
                                <AeonikTypography onClick={removeLicense}>
                                    Remove
                                </AeonikTypography>
                            </Button>
                        }
                </Box>
            </Box>
            <Box display={'flex'} paddingLeft={'40px'} alignItems={'flex-start'} gap={'16px'}>
                <LicenseDetail testId="licenseNumber" label="License number" value={selectedMedicalLicense?.number} />
                <LicenseDetail testId="licenseState" label="State" value={selectedMedicalLicense?.state} />
                <LicenseDetail testId="licenseExpiration" label="Expiration" value={selectedMedicalLicense?.expiration} />
            </Box>
        </Box>
    )
}

export default MedicalLicenseCard