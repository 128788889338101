import React, {useEffect, useState} from "react";
import { styled } from '@mui/material/styles';
import { ResetPassword } from "../components";
import aboveFoldBGImage from "../assets/images/passResetStrand.svg";
import { Box } from "@mui/material";

const BackgroundContainer = styled('div')(
  ({ aboveFoldBGImage }) => `
    background: url(${aboveFoldBGImage});
    background-repeat: no-repeat;
    background-position-x: left;
    background-size: 35%;
    background-position-y: bottom;
`);

const ResetContainer = {
  position: 'relative',
  margin: '120px auto 0 auto',
  display: 'flex',
  marginTop: {xs: '60px', sm: '120px'},
  width: {xs: '80%', sm: '55%'}
};

const ResetPasswordPage = () => {
  const [comp, setComp] = useState('');
  const isASMuser = window.location.href.includes('flow=set');

  return (
    <BackgroundContainer aboveFoldBGImage={aboveFoldBGImage}>
      <Box sx={comp === 'newPassword' || comp === 'resetSuccess' || comp === 'tokenExpired' ? ResetContainer : {}} data-testid="resetPasswordComponent">
        <ResetPassword isASMuser={isASMuser} comp={comp} setComp={setComp} />
      </Box>
    </BackgroundContainer>
  );
};


export default ResetPasswordPage
