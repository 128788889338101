import { useSelector } from "react-redux";
import { useHistory} from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react"
import { Box, Typography } from "@mui/material"
import { Button } from "../Button"
import { TypographyH3 } from "../../assets/fonts/typographyTheme"
import { useModal } from "../../hooks/useModal"

const bannerContainerStyles = {
    display: 'flex',
    width: '100%',
    minHeight: '263px',
    bgcolor: '#F2FAFD',
    padding: '16px 64px 32px 64px',
    alignItems: 'center',
    gap: '24px',
    borderBottom: '1px solid #ADDCF2',
    borderTop: '1px solid #ADDCF2',
}

const bannerDetailsStyles = {
    display: 'flex',
    paddingTop: '24px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '16px',
    flex: '1 0 0'
}

const bannerTypographyStyles = {
    fontFamily: 'Aeonik Regular',
    fontWeight: '500',
    color: '#023667',
}

const SpikevaxBanner = () => {
    const { authState } = useOktaAuth() || "";
    const isAuthenticated = authState?.isAuthenticated;
    const { handleOpenModal } = useModal();
    const history = useHistory();
    const locale = useSelector((state) => state.globalStates.locale);
    const { homePage: homePageContent } = useSelector((store) => store?.contentful);

    const navigateOrLogin = (path) => {
        if (isAuthenticated) {
          history.push(`/${locale}/${path}`);
        } else {
          handleOpenModal(`/${locale}/${path}`);
        }
      };

    const onOrderNowClick = () => {
        navigateOrLogin('order-form');
    }

    return (
        <Box sx={(bannerContainerStyles)}>
            <Box sx={bannerDetailsStyles}>
                <Box>
                    <Typography sx={{...bannerTypographyStyles, fontSize:'36px'}}>{homePageContent?.spikevaxBannerHeader}</Typography>
                    <Typography sx={{...bannerTypographyStyles, fontSize:'24px'}}>{homePageContent?.spikevaxBannerText}</Typography>
                </Box>
                <Button
                    buttonType="mds-primary"
                    sx={{textTransform: "none", padding: "12px 24px"}}
                    onClick={onOrderNowClick}
                >
                    <TypographyH3>Order now</TypographyH3>
                </Button>
            </Box>
        </Box>
    )
}

export default SpikevaxBanner