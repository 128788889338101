"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStyles = void 0;
const getStyles = () => `
.privacy__overlay {
  all: unset;
  background: #555555;
  height: 100vh;
  left: 0;
  opacity: 0.8;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;
}
.privacy__content {
  all: unset;
  background-color: #f2fafd;
  border-radius: 10px;
  box-shadow: 0 0 60px 0 rgba(0,0,0,0.3);
  box-sizing: border-box;
  left: 50%;
  max-height: 100vh;
  overflow: auto;
  padding: 32px 48px 48px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 676px;
  z-index: 9999;
}
.privacy__text {
  all: unset;
  color: #544F40;
  display: block;
  font-family: Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 1.7;
}
.privacy__text--small {
  font-size: 14px;
}
.privacy__heading {
  font-size: 28px;
  font-weight: bold;
}
.privacy__text--bold {
  font-weight: bold;
}
.privacy__groups {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
}
.privacy__group__wrapper {
  all: unset;
  display: flex;
  gap: 12px;
}
.privacy__group__inner {
  all: unset;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.privacy__group__header {
  all: unset;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
button.privacy__group__header  {
  cursor: pointer;
}
.privacy__group__heading {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  gap: 12px;
}
.privacy__group__content {
  padding-top: 12px;
}
.privacy__arrow--expanded {
  transform: rotate(-90deg);
}
.privacy__arrow--collapsed {
  transform: rotate(90deg);
}
.privacy__arrow--expanded svg,
.privacy__arrow--collapsed svg {
  display: block;
}
.privacy__link {
  color: #15717D;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}
.privacy__button {
  all: unset;
  border: 2px solid #E31836;
  border-radius: 8px;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 16px;
  text-transform: uppercase;
}
.privacy__button--primary {
  background-color: #E31836;
  color: #fff;
}
.privacy__button--secondary {
  background-color: #fff;
  color: #E31836;
}
.privacy__footer {
  all: unset;
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-top: 24px;
}
.privacy__footer__ctas {
  all: unset;
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-inline-start: auto;
}
.privacy__expand {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  flex-direction: row;
  gap: 8px;
  white-space: nowrap;
}
.privacy__checkbox {
  all: unset;
  border-radius: 2px;
  border: 1.5px solid #15717D;
  cursor: pointer;
  display: flex;
  height: 20px;
  margin-top: 4px;
  position: relative;
  width: 20px;
}
.privacy__checkbox[aria-checked="true"] {
  background-color: #15717D;
}
.privacy__checkbox[aria-disabled="true"] {
  background-color: #d1d2d5;
  border: 1.5px solid #d1d2d5;
  cursor: not-allowed;
}
.privacy__checkbox::after {
  border-color: #fff;
  border-style: solid;
  border-width: 0 2px 2px 0;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 12px;
  left: 0;
  margin: auto;
  position: absolute;
  right: -1px;
  top: 2px;      
  transform: rotate(45deg);
  width: 6px;
}
.privacy__checkbox[aria-checked="false"]::after {
  display: none;
}
.privacy__gear {
  all: unset;
  background: #fff;
  bottom: -30px;
  box-shadow: 0 0 60px 0 rgba(0,0,0,0.3);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  left: -30px;
  padding-top: 4px;
  position: fixed;
  transform: rotate(45deg);
  width: 60px;
  z-index: 9999;
}
.privacy__gear path {
  fill: #17717d;
}
.privacy__gear--visible {
  background: #17717d;
}
.privacy__gear--visible path {
  fill: #fff;
}
@media screen and (max-width: 720px) {
  .privacy__content {
    border-radius: 0;
    bottom: 0;
    left: 0;
    padding: 24px 24px 30px;
    top: auto;
    transform: none;
    width: 100%;
  }
}
`;
exports.getStyles = getStyles;
