import React from "react";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { AccountDetails } from "./AccountDetails";
import accountIcon from "../../assets/images/accountSummaryIcon.svg";

const StyledBox = styled(Box)(({ theme: { palette: { secondary }, breakpoints }}) => `
margin: 0 30px;
display: flex;
background-color: ${secondary.main};
${[breakpoints.down('sm')]} {
  margin: 0px;
  flex-direction: column;
};
border-radius: 13px;
`);

export const SingleAccountFound = ({ staticContent, accountDetails }) => {
  return (
    <>
        <Box display='flex' alignItems='center' m={{xs:'20px 0', sm:'20px 30px'}}>
          <img id="accountSummary" src={accountIcon} height="30" width="30" alt="account summary" />
          <Typography id="contactInfo" variant="h5" marginLeft="10px">{"Account Summary"}</Typography>
        </Box>
        <StyledBox sx={{ flexGrow: 1 }}>
          <AccountDetails staticContent={staticContent} accountDetails={accountDetails} singleAccount={true}/>
        </StyledBox>
    </>
  )
};