import commerceAuthAxiosInstance from "../sevices/commerceAuthAxiosInstance";

export const fetchOrderFromSAPUsingCode = async (userId, code) => {
    try {
        const res = await commerceAuthAxiosInstance({
            url: `users/${userId}/orders/${code}`,
            method: "GET",
            params: {
                pageSize: 2,
                code: code,
                userId: userId,
            }
        });
        return res?.data;
    } catch (error) {
        if (!error.response) {
            throw error
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
    }
};