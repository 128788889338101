import { createAsyncThunk } from "@reduxjs/toolkit";
import { contentfulClient } from "../../sevices/useContentful";

const contentfulRes = async (contentKey) => {
    const query = {
        skip: 0,
        content_type: contentKey,
        include: 5,
        select: "fields"
    };
    return contentfulClient.getEntries(query).then(res => res)
}

export const loadGlobalMessage = createAsyncThunk(
    "globalMessages/messages",
    async () => {
        const promises = [
            contentfulRes("globalErrorMessage"),
            contentfulRes("globalSuccessMessage")
        ]
        const [res1, res2] = await Promise.all(promises)
        const staticData = {
            errorMessages: res1?.items,
            successMessages: res2?.items
        }
        return staticData
    },
    {
        condition: (_, { getState }) => {
            const { globalMessages } = getState()
            if (globalMessages.loaded)
                return false
        }
    }
)