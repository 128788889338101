import React, { useEffect, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import InfoIconImage from "../../assets/images/infoIcon.svg";
import { Button, Modal } from "../";
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock'
import { cancelReservationApi } from "../../utils/ReservationApis";
import { useUserInfoContext } from "../../contexts";
import { useHistory } from "react-router";
import { changeDateFormat, HandlerBarTemplateHandler } from "../../utils/Helper";
import useMediaQuery from '@mui/material/useMediaQuery';
import { BackComponent } from "../";
import { StyledTooltip } from "../StyledTooltip";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const PageDetails = styled('div')(({ theme: { breakpoints } }) => `
  text-align: center;
  margin: 20px 235px;
  ${[breakpoints.down('sm')]} {
    margin: 20px 0px;
  }
`);
const PageTitle = styled('h1')(({ theme: { palette: { primary }, breakpoints }}) => `
  font-size: 35px;
  color: ${primary.main};
  font-family: Aeonik Light;
  opacity: 1;
  ${[breakpoints.down('sm')]} {
    font-size: 30px;
  }
`);
const PageDescription = styled('div')(({ theme: { palette: { primary }, breakpoints }}) => `
  margin-top: 21px;
  line-height: 24px;
  font-size: "Aeonik Regular";
  color: ${primary.main};
  ${[breakpoints.down('sm')]} {
    font-size: 16px;
  }
`);

const InfoIcon = styled('img')`
  margin-left: 5px;
  top: 2px;
  position: relative;
  width: 13px;
`;

const CustomContainer = styled('div')(({ theme: { breakpoints } }) => `
  padding: 30px 0px 30px 0px;
  display:flex;
  justify-content: space-between;
  ${[breakpoints.down('sm')]} {
    flex-direction: column;
  }
`);

const DatesTextContainer = styled('div')(({ theme: { palette: { primary }, breakpoints }}) => `
  display:flex;
  justify-content: flex-end;
  color:${primary.main};
  margin-top: 20px;

  ${[breakpoints.down('sm')]} {
    flex-direction: column;
    justify-content: unset;
    font-size:14px;
  }
`);

const ActionsContainer = styled('div')(({ theme: { breakpoints } }) => `
  display:flex;
  justify-content: flex-end;
  align-items: center;
  ${[breakpoints.down('sm')]} {
    padding-top: 20px;
    justify-content: start;
    flex-direction: column;
    align-items: start;
  }
`);

const VerticalDivider = styled('span')(({ theme: { palette: { neutral } }}) => `
  color:${neutral.gray2};
`);

const CancelLink = styled('button')(({ theme: { palette: { secondary, neutral } }}) => `
  color:${secondary.textLinks};
  cursor: pointer;
  fontFamily: "Aeonik Regular";

  &:disabled {
    cursor: none;
    color: ${neutral.gray2};
  }
`);



export const PreBookPageHeader = ({
  setShowPrompt,
  pageInfoHeading,
  pageInfoDescription,
  pageType,
  reservationDetails,
  hoverText,
  gpoAffiliationMessage,
  // gpoName,
  ...props
}) => {
  const currentUserData = JSON.parse(localStorage.getItem("currentUser"));
  const gpoName = currentUserData?.orgUnit?.defaultGPO?.gpoName || null;
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [cancelling, setCancelling] = useState(false)
  const { userInfo, cutOffDaysRemaining = 0 } = useUserInfoContext() || ""
  const history = useHistory()
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [cutOffText, setCutOffText] = useState("")
  const [isUpdateAble, setIsUpdateAble] = useState(false)
  const locale = useSelector((state) => state.globalStates.locale);

  const handleCancelReservation = async () => {
    try {
      setCancelling(true)
      await cancelReservationApi(userInfo?.uid, reservationDetails?.confirmationNumber)
     history.push(`/${locale}/manage-reservations`);
    } catch (error) {
      console.log("ERROR ", error)
    } finally {
      setShowCancelModal(false)
      setCancelling(false)
    }
  }

  useEffect(() => {
    if ((pageType === "details" || pageType === "edit") && reservationDetails?.orderStatus !== "CANCELLED") {
      const remainingDaysMsg = HandlerBarTemplateHandler({cutoffDaysRemaining: cutOffDaysRemaining > 0 ? cutOffDaysRemaining : 0 }, reservationDetails?.cutOffMsgTemplate) || null;
      setCutOffText(remainingDaysMsg);
      setIsUpdateAble(cutOffDaysRemaining > 0)
    } else if (pageType === "details" && reservationDetails?.orderStatus === "CANCELLED") {
      setCutOffText(reservationDetails?.canceledStatusMessage);
    }
  }, [reservationDetails, pageType, cutOffDaysRemaining])

  const editReservation = () => {
    props?.editReservationHandler();
  }

  const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          return <Link to={`/${locale}/my-profile`}>
                    {node.content[0].value}
                  </Link>;
        }
    },
  };

  return (
    <>
      <Modal id="cancelModal" title="Are you sure you want to cancel this reservation?" subtitle="This action cannot be undone." primaryButtonLabel="YES, CANCEL" openModal={showCancelModal} closeModalHandler={() => setShowCancelModal(false)} confirmationHandler={handleCancelReservation} />
      {
        pageType === "details" || pageType === "edit" ? <CustomContainer>
          {mobile && <div style={{marginBottom:"32px"}}>
            <BackComponent id="backBtn"/>
          </div>}
          <div id="confirmationBlock">
            <Typography id="confirmationNumberLabel" variant="h6" textTransform="uppercase" display="block" gutterBottom sx={{ color: "primary.main", letterSpacing: "1.12px !important" }} data-testid="confirmationNumberLabel">
              {reservationDetails?.confirmationNumberLabel}
            </Typography>
            <Typography id="cardId" variant="h1" gutterBottom sx={{ color: "primary.main", fontFamily: "Aeonik Light" }} data-testid="originalCartId">
              {reservationDetails?.originalCartId ? reservationDetails?.originalCartId : reservationDetails?.confirmationNumber}
            </Typography>
          </div>
          <div>
            <DatesTextContainer id="datesTextContainer">
              <Typography id="datePlacedLabel" variant="caption" display="inline" gutterBottom data-testid="datePlacedLabel">
                {`${reservationDetails?.datePlacedLabel} ${changeDateFormat(reservationDetails?.orderCreatedDate)}`}
              </Typography>
               { (reservationDetails?.orderStatus === "CANCELLED" || reservationDetails?.modified) && desktop && <>&nbsp;&nbsp;
                <VerticalDivider> | </VerticalDivider>
              &nbsp;&nbsp;</> }
              {
                reservationDetails?.orderStatus === "CANCELLED" ?
                  <Typography id="caption" variant="caption" display="inline" gutterBottom>
                    {`${reservationDetails?.dateCanceledLabel} ${changeDateFormat(reservationDetails?.orderCancelledDate)}`}
                  </Typography> : reservationDetails?.modified && <Typography id="dateModifiedLabel" variant="caption" display="inline" gutterBottom data-testid="modifiedLabel">
                    {`${reservationDetails?.dateModifiedLabel} ${changeDateFormat(reservationDetails?.orderModifiedDate)}`}
                  </Typography>
              }
            </DatesTextContainer>

            {
              reservationDetails?.orderStatus !== "CANCELLED" && <ActionsContainer>
                <CancelLink  id="cancelLink" style={{marginRight: pageType !== "edit" ? "10%" : "0", minWidth: '150px'}} onClick={() => {setShowCancelModal(true); setShowPrompt(false)}} disabled={cancelling || !isUpdateAble} data-testid="cancel">Cancel reservation</CancelLink>
                  { pageType !== "edit" && <Button buttonType="secondary" sx={{ padding: "8px" , '@media screen and (max-width: 500px)': {marginTop : '16px',}}} onClick={editReservation} disabled={!isUpdateAble}><div style={{display:"flex", alignItems:"center"}}>{!isUpdateAble && <LockIcon style={{marginRight: "4px"}}/>}EDIT</div></Button>}
              </ActionsContainer>
            }

          </div>
        </CustomContainer> : <PageDetails>
          <PageTitle id="pageInfoHeading">{pageInfoHeading}</PageTitle>
          <PageDescription id="pageInfoHeading">
            {pageInfoDescription}
            {mobile ?
              gpoName && <p style={{ fontSize: "14px" }}>
                { gpoAffiliationMessage }{" "}
                <b style={{ fontWeight: "bold" }}>{ gpoName }</b>
                <InfoIcon id="infoIcon" src={InfoIconImage} />
              </p> :
              gpoName && <p style={{ fontSize: "12px" }}>
                { gpoAffiliationMessage }{" "}
                <b style={{ fontWeight: "bold" }}>{ gpoName }</b>
                <StyledTooltip id="tooltip" placement="bottom" title={<React.Fragment>
                   {documentToReactComponents(hoverText, options)}
                  </React.Fragment>}>
                  <InfoIcon src={InfoIconImage} />
                </StyledTooltip>
              </p>
            }
          </PageDescription>
        </PageDetails>
      }

    </>
  );
};
