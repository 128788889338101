import React from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const Label = styled(Typography)(({ theme: { palette: { primary }, breakpoints }}) => `
  color: ${primary.main};
  margin-top: 5px;
  text-transform: uppercase;
`);

const CustomGrid = styled(Grid)`
    &.removePadding {
        padding-top: 0 !important;
    }
`;

export const AccountDetails = ({ staticContent, accountDetails, singleAccount }) => {
  const encryptEmail = (email) => {
    if(email){
      let emailUsername = email[0];
      let emailDomain = email.substring(email.indexOf("@"), email.length);
      return (emailUsername + '********' + emailDomain);
    }
    return;
  };

  return (
    <Grid container justifyContent='space-between' gap={3} p={3}>
      {singleAccount && <Grid item xs={11}>
        <Typography variant={'h5'} color='primary.main' id="accountTitle">{accountDetails?.legalAccountName} - {accountDetails?.officeAddress?.town}, {accountDetails?.officeAddress?.region?.isocodeShort}</Typography>
      </Grid>}
      <Grid item xs={11} md={5}> 
        <Label variant={'h6'} id="accountNameLabel">{staticContent?.accountNameLabel}</Label>
        { accountDetails?.legalAccountName === "" ? <Typography variant={'p1'} color='neutral.gray2'>{staticContent?.notFoundText}</Typography> : <Typography variant={'p1'} id="accountNameValue">{accountDetails?.legalAccountName}</Typography> }
      </Grid>
      <CustomGrid item xs={11} md={5} className={!(accountDetails?.dbaName) && 'removePadding'}>
        { accountDetails?.dbaName && <>
          <Label variant={'h6'} id="dbanameLabel">{staticContent?.dbaNameLabel || staticContent?.dbaLabel}</Label>
          <Typography variant={'p1'} id="dbanameValue">{accountDetails?.dbaName}</Typography> 
        </> }
      </CustomGrid>
      <Grid item xs={11} md={5}>
        <Label variant={'h6'} id="officeAddressLabel">{staticContent?.officeAddressLabel}</Label>
        { JSON.stringify(accountDetails?.officeAddress) === '{}' ? <Typography variant={'p1'} color='neutral.gray2'>{staticContent?.notFoundText}</Typography> : <Typography variant={'p1'} id="officeAddressValue">{accountDetails?.officeAddress?.line1}, {accountDetails?.officeAddress?.town}, {accountDetails?.officeAddress?.region?.isocodeShort} {accountDetails?.officeAddress?.postalCode}</Typography> }
      </Grid>
      <Grid item xs={11} md={5}>
        <Label variant={'h6'} id="officePhoneLabel">{staticContent?.officePhoneLabel}</Label>
        { accountDetails?.officeAddress?.phone === "" ? <Typography variant={'p1'} color='neutral.gray2'>{staticContent?.notFoundText}</Typography> : <Typography variant={'p1'} id="officePhoneValue">{accountDetails?.officeAddress?.phone}</Typography> }
      </Grid>
      <Grid item xs={11} md={5}> 
        <Label variant={'h6'} id="officeEmailLabel">{staticContent?.officeEmailLabel}</Label>
        { accountDetails?.officeAddress?.email === "" ? <Typography variant={'p1'} color='neutral.gray2'>{staticContent?.notFoundText}</Typography> : <Typography variant={'p1'} id="officeEmailValue">{encryptEmail(accountDetails?.officeAddress?.email)}</Typography> }
      </Grid>
      <Grid item md={5}>
      </Grid>
    </Grid>
  )
};