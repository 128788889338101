import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Button } from "../components";
import { loadOrderConfirmationComponent } from "../features/contentful/contentfulThunkApi";
import { useUserInfoContext } from "../contexts";
import { HandlerBarTemplateHandler } from "../utils/Helper";
import { useHistory } from "react-router-dom";
import { setPlaceOrderClicked } from "../features/globalStates/globalStatesSlice";
import { DisclaimerMessageFooter } from "../components/DisclaimerMessageFooter";

const ButtonContainer = styled("div")(
    ({ theme: { breakpoints } }) => `
  display:flex;
  justify-content: center;
  gap: 24px;
  margin-top : 40px;
  margin-bottom : 180px;
  ${[breakpoints.down("sm")]} {
    flex-direction: column;
    align-items: center;
    margin-bottom : 60px;

  }
`
);

const OrderAcknowledgement = ( props ) => {
  const dispatch = useDispatch();
  const { userInfo } = useUserInfoContext() || '';
  const history = useHistory();
  const locale = useSelector((state) => state.globalStates.locale);
  const isPlaceOrderClicked = useSelector((state) => state.globalStates.isPlaceOrderClicked)
  const { orderConfirmationComponent: componentData } = useSelector(
      (store) => store?.contentful
  );

  useEffect(() => {
    if (componentData && Object.keys(componentData).length === 0)
      dispatch(loadOrderConfirmationComponent());
  }, [dispatch, componentData]);

  useEffect(() => {
      if(!isPlaceOrderClicked) {
        history?.push(`/${locale}/cart`);
      } else {
        dispatch(setPlaceOrderClicked(false));
      }
    }, [history]);

  return (
      <Box
          display="grid"
          sx={{
            m: { xs: "32px", sm: "100px 20%" },
          }}
      >
        {/* Top Image  */}
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} mb={2}>
          <img
              alt='order place icon'
              src={componentData?.icon?.fields?.file?.url}
              style={{ width: "100px", height: "100px", borderRadius: "50%" }}
          />
        </Box>

        {/* Main Heading */}
        <Typography variant={"h2"} color="primary.main" textAlign={"center"}>
          {HandlerBarTemplateHandler({name: userInfo?.name}, componentData?.headline?.content[0])}
        </Typography>

        {/* Sub Heading */}
        <Typography variant={"h2"} color="primary.main" textAlign={"center"}>
          {documentToReactComponents(componentData?.headline?.content[1])}
        </Typography>

        {/* Buttons */}
        <ButtonContainer>
          <Button
              data-testid="secondaryButton"
              id="buttonLabel"
              buttonType={"secondary"}
              disabled={false}
              onClick={() => {
                history.push(`/${locale}/orders`)
              }}
              icon={false}
          >
            {componentData?.orderHistoryButtonLabel?.toUpperCase()}
          </Button>

          <Button
              data-testid="primaryButton"
              id="buttonLabel"
              buttonType={"primary"}
              disabled={false}
              onClick={() => {
                history.push(`/${locale}/products`)
              }}
              icon={false}
          >
            {componentData?.continueShoppingButtonLabel?.toUpperCase()}
          </Button>
        </ButtonContainer>

        <DisclaimerMessageFooter
            disclaimerMessage={componentData?.disclaimerMessage}
        />
      </Box>
  );
};

export default OrderAcknowledgement;

