import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../sevices/axiosInstance";
import authAxiosInstance from "../../sevices/authAxiosInstance";

export const createIdentity = createAsyncThunk(
    "commerce/createIdentity",
    async ({payload} , { rejectWithValue }) => {
        try {
            const res = await axiosInstance({
                url: `identityservice/v1/identity`,
                method: "POST",
                data: JSON.stringify(payload),
            });
            return res;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response);
        }
    }
);

export const registerExistingUser = async (payload) => {
    try {
        const res = await authAxiosInstance({
            url: `identityservice/v1/identity/registerExistingUser`,
            method: "POST",
            data: JSON.stringify(payload)
        });
        return res;
    } catch (error) {
        throw error;
    }
}
