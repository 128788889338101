import React from "react";
import { Box } from "@mui/material";
import CustomCard from "../CustomCardComponent/CustomCard";
import { InvoiceSummary } from "./OrderSummaryCard"
import { TypographyH2 } from "../../assets/fonts/typographyTheme";
import { PayLater } from "../Payments/paymentOptions";
import { PaymentMethodInfoCard } from "../Payments/PaymentMethodInfoCard";

export const OrderDetailSummaryCard = ({summaryData}) => {
    return (
        <Box>
            <CustomCard
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px",
                    gap: "24px",
                }}
            >
                <TypographyH2 mb="24px" textTransform="capitalize">Summary</TypographyH2>
                <InvoiceSummary summaryData={summaryData} isOrderDetailsPage={true}/>
                <TypographyH2>Payment Method</TypographyH2>
                {summaryData.paymentInfo ? <PaymentMethodInfoCard paymentMethod={summaryData.paymentInfo} isOrderDetailsPage={true}/> : <PayLater />}
            </CustomCard>
        </Box>
    );
};