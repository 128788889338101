import React, {lazy, Suspense, useEffect, useRef, useState} from 'react';
import dayjs from "dayjs";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ArrowDropDownOutlined, ArrowDropUpOutlined, KeyboardArrowRightOutlined } from "@mui/icons-material";
import { Button, StatusIndicators } from '../';
import { NoReservationRowsOverlay } from "../NoRowsOverlay";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    loadOrderHistoryTableComponent
} from "../../features/contentful/contentfulThunkApi";
import DataGridPagination from "../Pagination/DataGridPagination";
import { getAllReservations } from '../../utils/Reservation/ReservationApi';
import { setReservationConversionID } from "../../features/globalStates/globalStatesSlice";

const DataGrid = lazy(() => import('../DataGrid'));

const mapStatus = (() => {
    const statuses = {
        'DELIVERED': 'Delivered',
        'PENDING': 'Reservation Placed',
        'CANCELLED': 'Reservation Cancelled',
        'ORDERED': 'Order Placed'
    };

    return (value) => statuses[value] ?? ''
})();

const isReservationConversionEnabled = process.env.IS_RESERVATIONS_CONVERSION_ENABLED === 'true';
const statusMappingForIcon = {
    'PENDING': isReservationConversionEnabled ? 'RESERVED': 'PAID',
    'ORDERED': 'ORDERED',
}

const LOCALE_CURRENCY_NAME_MAP = {
    'en-US': 'USD',
    'en-DE' : 'EUR'
}

export const ReservationHistoryList = ({ userId, handleErrorMsg }) => {
    const { orderHistoryTableComponent } = useSelector((store) => store?.contentful);
    const locale = useSelector((state) => state.globalStates.locale);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [reservationTableData, setReservationTableData] = useState([]);
    const isNewOrderingEnabled = process.env.IS_NEW_ORDER_EXPERIENCE_ENABLED === 'true';
    const isReservationConversionEnabled = process.env.IS_RESERVATIONS_CONVERSION_ENABLED === 'true';
    const isEditReservationEnabled = process.env.IS_EDIT_RESERVATIONS_ENABLED === 'true';
    const history = useHistory();

    useEffect(() => {
        if (orderHistoryTableComponent && Object.keys(orderHistoryTableComponent).length === 0)
            dispatch(loadOrderHistoryTableComponent());

    }, [orderHistoryTableComponent, dispatch]);

    useEffect(() => {
        const fetchReservations = async () => {
            if (!userId) {
                handleErrorMsg('We are unable to fetch your reservations. Please refresh and try again.');
                return;
            }
    
            try {
                const data = await getAllReservations(userId);
                const tableData = data?.reservations
                    .map((item, index) => ({
                    id: index + 1,
                    modernaId: item?.reservationNumber,
                    subtotal: parseFloat(item?.reservationPricingInfo?.totalPrice),
                    poNumber: item?.purchaseOrderNumber ? item?.purchaseOrderNumber : '-',
                    datePlaced: item?.lastModifiedDate,
                    status: item?.reservationStatus,
                    reservationId: item?.reservationId
                }));
                setReservationTableData(tableData);
            } catch (error) {
                handleErrorMsg(error);
            }
        };
    
        fetchReservations();
    }, []);

    const mobileColumns = [
        {
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: isNewOrderingEnabled?"Order Id":`${orderHistoryTableComponent?.modernaIdColumnLabel?.toUpperCase()}`,
            field: 'modernaId',
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: true,
            headerName: `${orderHistoryTableComponent?.orderStatusColumnLabel?.toUpperCase()}`,
            field: 'status',
            flex: 1,
            renderCell: ({value}) => {
                return <StatusIndicators status={value} children={mapStatus(value)}></StatusIndicators>
            }
        },
        {
            field: 'null',
            headerName: '',
            disableColumnMenu: true,
            sortable: false,
            width: 32,
            renderCell: ({row}) => {
                return <Link
                    to={`/${locale}/order/${row.modernaId}`}
                    underline={'none'}
                    sx={{
                        svg: {
                            color: 'secondary.main',
                            '&:hover':{
                                color: 'primary.darker',
                            }
                        }
                    }}
                    display={'flex'}
                    variant={'link'}>
                    <KeyboardArrowRightOutlined/>
                </Link>;
            }
        }
    ]

    const desktopColumns = [
        {
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: isNewOrderingEnabled?"Order Id":`${orderHistoryTableComponent?.modernaIdColumnLabel?.toUpperCase()}`,
            field: 'modernaId',
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            headerName: `${orderHistoryTableComponent?.subtotalColumnLabel?.toUpperCase()}`,
            field: 'subtotal',
            renderCell: ({row}) => {
                return new Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency: LOCALE_CURRENCY_NAME_MAP[locale],
                }).format(row.subtotal)
            },
            flex: 1
        },

        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: false,
            headerName: `${orderHistoryTableComponent?.poNumberColumnLabel?.toUpperCase()}`,
            field: 'poNumber',
            flex: 1
        },
        {
            disableColumnMenu: true,
            headerName: `${orderHistoryTableComponent?.datePlacedColumnLabel?.toUpperCase()}`,
            field: 'datePlaced',
            flex: 1,
            renderCell: ({value}) => dayjs(value, 'YYYY-MM-DD').format('MMMM D, YYYY')
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: true,
            headerName: `${orderHistoryTableComponent?.orderStatusColumnLabel?.toUpperCase()}`,
            field: 'status',
            flex: 1,
            renderCell: ({value}) => {
                return <StatusIndicators status={statusMappingForIcon[value] ?? value} children={mapStatus(value)}></StatusIndicators>
            }
        },
        //Will need this for next sprint, so not removing the code
        ...(isEditReservationEnabled || isReservationConversionEnabled ? [{
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: false,
            headerName: `${orderHistoryTableComponent?.actionLabel?.toUpperCase()}`,
            field: 'action',
            flex: 1,
            renderCell: ({row}) => <ActionsComponent value={row}/>
        }] : [])
    ]

    const handleReservationConversionClick = async (reservationID) => {
        await dispatch(setReservationConversionID(reservationID));
        history.push(`/${locale}/order-form/complete-reservation`);
    }

    const ActionsComponent = ({value}) => {
        return (
            <>
                {isReservationConversionEnabled ? (value?.status === 'PENDING' && (
                    <Box>
                        <Button
                            sx={{textTransform: "none", width: '120px',
                                padding: "10px 10px",
                                "& .ButtonText": {
                                    fontSize: "14px",
                                },}}
                            data-testid='convertToOrderBtn'
                            onClick={()=> handleReservationConversionClick(value?.reservationId)}
                        >
                            Order Now
                        </Button>
                    </Box>
                )) : (
                    <Box>
                        <Link
                            to={value.status === 'CANCELLED' ? `/${locale}/summary/${value?.reservationId}` : `/${locale}/reservation/${value?.reservationId}`}
                            underline={'none'}
                            sx={{
                                color: 'primary.main',
                                fontFamily: 'Aeonik Bold',
                                '&:hover': {
                                    color: 'primary.darker',
                                }
                            }}
                            display={'flex'}
                            variant={'link'}
                        >
                            {value.status === 'CANCELLED' ? 'View' : 'View/Edit'}
                        </Link>
                    </Box>
                )}
            </>
        );
    }

    const [apiRef, setApiRef] = useState(null);

    return (
        <Box mx={{xs:'32px', lg:'100px'}} my={'60px'}>
            <Typography variant={'h2'} mb={'10px'}>{orderHistoryTableComponent?.reservationTitle}</Typography>
            <Suspense>
                <DataGrid
                    onApiRef={(ref) => setApiRef(ref)}
                    getRowId={(rows) => rows.id}
                    pageSizeOptions={[10]}
                    disableRowSelectionOnClick={true}
                    sx={{
                        '.MuiDataGrid-iconButtonContainer': {
                            visibility: 'visible',
                        },
                        '.MuiDataGrid-sortIcon': {
                            opacity: 'inherit !important',
                        },
                        '.MuiDataGrid-virtualScroller': {
                            overflow: 'hidden !important',
                        },
                        '.MuiDataGrid-columnHeaderTitle': {
                            whiteSpace: "normal",
                            lineHeight: "20px"
                        }
                    }}
                    slots={{
                        pagination: DataGridPagination,
                        noRowsOverlay: NoReservationRowsOverlay,
                        columnSortedAscendingIcon: ArrowDropUpOutlined,
                        columnSortedDescendingIcon: ArrowDropDownOutlined
                    }}
                    slotProps={{
                        noRowsOverlay: {
                            reservationTableData
                        },
                        pagination: {
                            disabled: false
                        }
                    }}
                    getCellClassName={({field}) => ['confirmationNumber', 'date', 'poNumber', 'frieghtAmt', 'total'].includes(field) ? 'nonSelectable' : ''}
                    hideFooterSelectedRowCount={true}
                    checkboxSelection={false}
                    autoHeight={true}
                    columns={mobile ? mobileColumns : desktopColumns}
                    sortingOrder={['desc', 'asc']}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'datePlaced', sort: 'desc' }],
                        },
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    rows={reservationTableData}/>
            </Suspense>
        </Box>
    );
};
