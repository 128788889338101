import React from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import {styled} from "@mui/material/styles";
import PropTypes from "prop-types";
import Heart from "../../assets/images/heart.svg";

const StyledButtonBase = styled(LoadingButton, {
  shouldForwardProp: (prop) =>
      prop !== 'buttonType' &&
      prop !== 'icon' &&
      prop !== 'leftIcon' &&
      prop !== 'rightIcon' &&
      prop !== 'muiIconLeft' &&
      prop !== 'muiIconRight',
})(({ buttonType, icon, leftIcon, rightIcon, theme, loading }) => ({
  ...(buttonType === 'primary' && {
    border: `1px solid ${theme.palette.primary.button}`,
    color: theme.palette.neutral?.white,
    background: theme.palette.primary.button,
    "&:hover": {
      border: `1px solid ${theme.palette.secondary.button}`,
      backgroundColor: theme.palette.secondary.button,
      color: theme.palette.neutral?.white,
    },
    "&:disabled": {
      pointerEvents: "none",
      color: theme.palette.neutral?.white,
      backgroundColor: theme.palette.neutral?.gray2,
      border: `1px solid ${theme.palette.neutral?.gray2}`,
    },
    "&.indicator:disabled": {
      pointerEvents: "none",
      color: theme.palette.neutral?.white,
      backgroundColor: theme.palette.secondary?.graphical,
      border: `1px solid ${theme.palette.secondary?.graphical}`,
    },
    "&.Mui-focusVisible": {
      border: `2px solid ${theme.palette.secondary.yellowLight}`,
      backgroundColor: theme.palette.secondary.button,
      color: theme.palette.neutral?.white,
    },
  }),

  ...(buttonType === 'secondary' && {
    border: `1px solid ${theme.palette.primary.button} `,
    color: theme.palette.primary.button,
    "&:hover": {
      backgroundColor: theme.palette.primary.button,
      color: theme.palette.neutral?.white,
    },
    "&:disabled": {
      pointerEvents: "none",
      border: `1px solid ${theme.palette.neutral.gray2}`,
      color: theme.palette.neutral.gray2
    },
     "&.Mui-focusVisible": {
      border: `2px solid ${theme.palette.secondary.yellowLight}`,
      backgroundColor: theme.palette.primary.button,
      color: theme.palette.neutral?.white,
    },
  }),

  ...(buttonType === 'tertiary' && {
    color: theme.palette.primary.button,
    "&:hover": {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.button,
    },
    "&:disabled": {
      pointerEvents: "none",
      color: theme.palette.neutral.gray2
    },
     "&.Mui-focusVisible": {
      border: `2px solid ${theme.palette.secondary.yellowLight}`,
      backgroundColor: theme.palette.neutral?.white,
      color: theme.palette.secondary.button,
    },
  }),
  ...(buttonType === 'primaryInverted' && {
    border: `1px solid ${theme.palette.neutral?.white}`,
    color: theme.palette.neutral?.white,
    background: theme.palette.secondary.button,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.button}`,
      backgroundColor: theme.palette.primary.button,
      color: theme.palette.neutral?.white,
    },
    "&:disabled": {
      pointerEvents: "none",
      border: `1px solid ${theme.palette.neutral.gray2}`,
      color: theme.palette.neutral.gray2
    },
    "&.Mui-focusVisible": {
      border: `2px solid ${theme.palette.secondary.yellowLight}`,
      backgroundColor: theme.palette.primary.button,
      color: theme.palette.neutral?.white,
    },
  }),

  ...(buttonType === 'mds-primary' && {
    color: "#FFFFFF",
    textTransform: "none",
    display: 'flex',
    height: "48px",
    padding: "16px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    borderRadius: "8px",
    border: "1px solid #E31837",
    background: "#E31837",
    "&:hover": {
      backgroundColor: '#92242B',
      color: "#FFFFFF",
      border: "1px solid #92242B"
    },
    "&:disabled": {
      pointerEvents: "none",
      color: theme.palette.neutral?.white,
      backgroundColor: theme.palette.neutral?.gray2,
      border: `1px solid ${theme.palette.neutral?.gray2}`,
    },
  }),

  ...(buttonType === 'mds-secondary' && {
    color: "#01283C",
    textTransform: "none",
    display: 'flex',
    height: "48px",
    padding: "16px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    borderRadius: "8px",
    border: "1px solid #01283C"
  }),

  ...(buttonType === 'mds-tertiary' && {
    color: "#FFFFFF",
    textTransform: "none",
    display: 'flex',
    height: "48px",
    padding: "16px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    borderRadius: "8px",
    border: "1px solid #079AE0",
    background: "#079AE0",
    "&:hover": {
      backgroundColor: '#0379B2',
      color: "#FFFFFF",
      border: "1px solid #0379B2"
    },
  }),

  ...(buttonType === 'mds-outline' && {
    color: "#545F71",
    textTransform: "none",
    display: 'inline-flex',
    height: "48px",
    padding: "12px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "6px",
    border: "1px solid #545F71"
  }),

  ...(buttonType === 'link' && {
    color: "#079AE0",
    textTransform: "none",
    border: "none",
    "&:hover": {
      backgroundColor: '#FFFFFF',
    },
  }),

  ...(icon && {
    padding: "7px 32px 8px",
    "& .ButtonText": {
      paddingRight: "8px",
    }
  }),

  ...(leftIcon && {
    padding: "7px 32px 8px",
    "& .ButtonText": {
      paddingRight: "8px",
    }
  }),
  textTransform: "uppercase",
  fontFamily: "Aeonik Medium",
  fontSize: "14px",
  borderRadius: "5px",
  padding: buttonType === "link" ? "0px" : "10px 30px",
  cursor: "pointer",
  [theme.breakpoints.down('lg')]: {
    fontSize: "12px"
  },
  "& .ButtonInnerContainer": {
    display: "flex",
    alignItems: "center",
    lineHeight: "normal",
    gap: "8px"
  },
  "& .ButtonText": {
    fontFamily: "Aeonik Medium",
    lineHeight: "normal",
    fontSize: "16px",
    [theme.breakpoints.down('lg')]: {
      fontSize: "12px"
    },
    visibility: loading ? 'hidden' : 'visible'
  },
}));

const CustomButton = ({
  buttonType = "primary",
  disabled = false,
  icon=false,
  leftIcon = null,
  rightIcon = null,
  iconSize="24px",
  onClick,
  children,
  sx,
  loading,
  muiIconLeft: MuiIconLeft,
  muiIconRight: MuiIconRight,
  ...props
}) => {
  return (
    <StyledButtonBase
      {...props}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      buttonType={buttonType}
      icon={icon}
      leftIcon={leftIcon}
      rightIcon={leftIcon}
      disableTouchRipple
      sx={sx}
    >
      <span className="ButtonInnerContainer">
      {icon && (
          <img
            src={Heart}
            alt="Heart"
            height={iconSize}
            width={iconSize}
            className="IconBase"
          />
        )}
        {MuiIconLeft && (
            <MuiIconLeft className="MUIIconBase"
                     height={iconSize}
                     width={iconSize}/>
        )}
        {leftIcon && (
          <img
            src={leftIcon}
            alt="leftIcon"
            height={iconSize}
            width={iconSize}
            className="IconBase"
          />
        )}
        <span className="ButtonText">{children}</span>
        {MuiIconRight && (
            <MuiIconRight className="MUIIconBase"
                     height={iconSize}
                     width={iconSize}/>
        )}
      </span>
    </StyledButtonBase>
  );
};

CustomButton.propTypes = {
  buttonType: PropTypes.oneOf(["primary", "secondary", "tertiary", "request", "primaryInverted", "mds-primary", "mds-secondary", "mds-tertiary", "link", "mds-outline"]),
  disabled: PropTypes.bool,
  icon: PropTypes.bool,
  leftIcon: PropTypes.string,
  onClick: PropTypes.func,
  muiIcon: PropTypes.elementType,
  muiIconRight: PropTypes.elementType,
};

export {CustomButton as Button};
