import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const CustomSwitch = styled(Switch)(({ theme: { palette: { primary, neutral } }}) => ({
  padding: 8,
  height: 34,
  width: 47,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: primary.blue,
    opacity: 1,
  },
  "& .MuiSwitch-switchBase": {
    padding: 7.5,
    "&.Mui-checked": {
      color: neutral.white,
      transform: "translateX(14px)",
      "& + .MuiSwitch-track": {
        backgroundColor: primary.blue,
        opacity: 1,
      },
    },
  },
  "&.Mui-checked": {
    color: neutral.white,
    opacity: 1,
    "& + .MuiSwitch-track": {
      backgroundColor: primary.blue,
      opacity: 1,
      border: 0,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: "13px",
    height: "13px",
    margin: 3,
    backgroundColor: neutral.white,
  },
}));

export const Toggle = ({
  title,
  defaultChecked = false,
  onChange,
}) => {
  const [checked, setChecked] = useState(defaultChecked ? true : false);
  const [switchLabel, setSwitchLabel] = useState(() =>
    defaultChecked ? "On" : "Off"
  );
  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setSwitchLabel("On");
    } else {
      setSwitchLabel("Off");
    }
    onChange();
  };
  const handleMouseEnter = () => {
    setSwitchLabel(title);
  };
  const handleMouseLeave = () => {
    checked ? setSwitchLabel("On") : setSwitchLabel("Off");
  };
  return (
    <div
      className="ToggleContainer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CustomSwitch checked={checked} onChange={handleSwitchChange} />
      <span className="ToggleLabel">{switchLabel}</span>
    </div>
  );
};
