import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import ArrowForward from "@mui/icons-material/ArrowForward";
import { Box, IconButton, Typography } from "@mui/material";
import { isExternalURL } from "../../utils/Helper";
import { useSelector } from "react-redux";

const BannerWrapper = styled('div')(
    ({ bgImageUrl, theme: { palette: { neutral }, breakpoints } }) => `
    background: url(${bgImageUrl}) 0% 100% / 150% no-repeat;
    display: flex;
    float: right;
    height: 500px;
    background-position: right;
    width: 50%;
    ${[breakpoints.down('sm')]} {
        float: none;
        height: 0px;
        background: none;
    }
    `
)

const Card = styled('div')(({ theme: { palette: { primary }, breakpoints } }) => `
    background-color: ${primary.white};
    border-radius: 14px;
    box-shadow: 0px 3px 6px #00000029;
    flex-grow: 1;
    padding: 22px;
    display: flex;
    width: 10px;
    justify-content: space-between;
    min-width: fit-content;
    ${[breakpoints.down('sm')]} {
        min-width: 100% !important;
        flex-direction: column;
    }
`)

export const NewsContent = ({ ...props }) => {
    const [dataExternalModal, setDataExternalModal] = useState(true);
    const remindMeExternal = localStorage.getItem("dontRemindMeExternal");
    const { landingPageContent: { news: newsStaticContent } } = useSelector(store => store?.contentful)
    useEffect(() => {
        if (remindMeExternal === "true") {
            setDataExternalModal(false);
        }
    }, [remindMeExternal])

    const handleRedirect = (url) => {

        if (isExternalURL(url) && !localStorage.getItem("dontRemindMeExternal")) {
            props?.setShowNavigationAlert(true);
            props?.setExternalLink(url);
            props?.setExternalLinkModalType("extrenalLink");
        } else {
            window.open(url, '_blank');
            props?.setShowNavigationAlert(false);
            props?.setExternalLink("");
        }
    }

    return (
        <>
            <BannerWrapper bgImageUrl={newsStaticContent?.banner?.backgroundImage?.fields?.file?.url} data-testid='bannerimg'></BannerWrapper>
            <Typography variant={'h2'} ml={{xs:'36px', sm:'92px'}} mt={{xs:'60px', sm:'48px'}} color='primary.main' data-testid='headline'>{newsStaticContent?.banner?.headline}</Typography>
            <Box backgroundColor='neutral.white' width={{xs:'100%', sm:'500px'}} p={{xs:'30px 32px 30px 36px', sm:'30px 32px 30px 92px'}} display={{xs:'grid', sm:'flex'}} gap={2} mb={'52px'}>
                {newsStaticContent?.newsCards?.map((newscard, index) => (
                    <Card key={index} id={"newsCard" + index} data-testid='newsCard'>
                        <div>
                            <img src={newscard?.icon?.fields?.file?.url} alt={newscard?.icon?.title} style={{ width: "45px", height: "45px" }} />
                            <Typography variant={'p2'} component={"div"} mt={2} mr={'20px'} lineHeight="20px" color={'primary.main'} id="newsCardTitle">{newscard?.headline}</Typography>
                        </div>
                        {newscard?.link &&
                            <Box alignSelf="end" >
                                <IconButton
                                    id={"cardBtn" + index}
                                    variant="outlined"
                                    onClick={(e) => handleRedirect(newscard?.link?.fields?.url)}
                                    data-external-modal={dataExternalModal}
                                    data-link-href={newscard?.link?.fields?.url}
                                    sx={{
                                        width: "34px", height: "34px",
                                        color: "primary.main",
                                        backgroundColor: "none",
                                        border: "1px solid",
                                        borderColor: "primary.main",
                                        '&:hover': {
                                            color: 'neutral.white',
                                            backgroundColor: 'primary.blue',
                                            borderColor: "primary.blue"
                                        },
                                        '@media screen and (max-width: 500px)': {
                                            height: '48px',
                                            width: '48px',
                                        }
                                    }}>
                                    <ArrowForward />
                                </IconButton>
                            </Box>}
                    </Card>
                ))}
            </Box>
        </>
    )
}
