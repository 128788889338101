import { createClient } from "contentful";
let contentfulClient;
if(process.env.CONTENTFUL_ACCESS_TOKEN){
  contentfulClient = createClient({
    space: process.env.CONTENTFUL_SPACE_ID,
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
    environment: process.env.CONTENTFUL_ENVIRONMENT
  });
}

export { contentfulClient };
