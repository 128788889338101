import React from "react";
import Box from "@mui/material/Box";
import { Button } from "../";
import Modal from "@mui/material/Modal";
import crossIcon from "../../assets/images/crossIcon.svg";
import { Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  zIndex: 10,
  borderRadius: "24px",
  padding: "18px 22px 34px 22px",
  outline: "none",
  textAlign: "center",
  "@media screen and (max-width: 500px)": {
    width:"80%",
    padding: "18px 22px 34px 22px",
  }
};

const buttonsContainerSxStyles = {
  flexDirection: {xs: 'column', sm:'row'},
  padding: {xs: '0 30px', sm:'0'},
  gap: {xs: '0', sm:'10px'},
}

const ModalComponent = ({
  confirmationHandler,
  closeModalHandler,
  openModal = false,
  title,
  subtitle,
  secondaryButtonLabel,
  primaryButtonLabel,
  primaryButtonAttributes
}) => {
  return (
    <Modal
      sx={{ textAlign: 'center' }}
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display='flex' justifyContent='end' id="crossIcon">
          <Box component={'img'} sx={{width: "25px", height: "25px", cursor:"pointer"}} src={crossIcon} onClick={closeModalHandler} data-testid='crossicon' />
        </Box>
        <Typography variant={'h3'} color='primary.main' my={2} mx={"38px"} id="modalTitle" data-testid='title'>{title}</Typography>
        {subtitle && <Typography variant={'p1'} color='primary.main' mt={2} sx={{marginBottom: {xs:'72px', sm: '0' } }} id="modalSubtitle">{subtitle}</Typography>}
        <Box display="flex" justifyContent='center' sx={buttonsContainerSxStyles}>
          {secondaryButtonLabel && <Button id="closeModal" sx={{ marginTop: "32px" }} buttonType="secondary" onClick={closeModalHandler} data-testid='cancelBtn'>{secondaryButtonLabel}</Button>}
          <Button className={secondaryButtonLabel ? "confRemoveBtn" : "cancelBtn"} id="confirm" sx={{ marginTop: "32px", marginBottom: {xs:'46px', sm: "0"} }} onClick={confirmationHandler} {...primaryButtonAttributes} data-testid='okBtn'>{primaryButtonLabel}</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export { ModalComponent as Modal };