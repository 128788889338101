import { createAsyncThunk } from "@reduxjs/toolkit";
import authAxiosInstance from "../../sevices/authAxiosInstance";
import noAuthAxiosInstance from "../../sevices/noAuthAxiosInstance";

export const createPaymentMethodApi = async (payload) => {
    try {
        const res = await authAxiosInstance({
            url: `paymentsservice/payment-method`,
            method: "POST",
            data: JSON.stringify(payload)
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const updatePaymentMethodApi = async (payload, paymentMethodId) => {
    try {
        const res = await authAxiosInstance({
            url: `paymentsservice/payment-method/${paymentMethodId}`,
            method: "POST",
            data: JSON.stringify(payload)
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const deletePaymentMethodApi = async (paymentMethodId) => {
    try {
        const res = await authAxiosInstance({
            url: `paymentsservice/payment-method/${paymentMethodId}`,
            method: "DELETE",
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const listPaymentMethodsApi = async (payload = {}) => {
    try {
        const res = await authAxiosInstance({
            url: `paymentsservice/payment-method/list`,
            method: "POST",
            data: JSON.stringify(payload)
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const describePaymentMethodApi = async (paymentMethodId) => {
    try {
        const res = await authAxiosInstance({
            url: `paymentsservice/payment-method/${paymentMethodId}`,
            method: "GET",
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const createGuestCustomerApi = async (payload) => {
    try {
        const res = await noAuthAxiosInstance({
            url: `paymentsservice/guest`,
            method: "POST",
            data: JSON.stringify(payload)
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const updateGuestPaymentMethodApi = async (payload, paymentMethodId, customerId) => {
    try {
        const res = await noAuthAxiosInstance({
            url: `paymentsservice/guest/payment-method/${paymentMethodId}`,
            method: "POST",
            data: JSON.stringify(payload),
            headers: {
                'Customer-Id': customerId
            }
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const captureGuestPaymentApi = createAsyncThunk(
    "commerce/captureGuestPayment",
    async ({ payload, customerId }, { rejectWithValue }) => {
        try {
            const res = await noAuthAxiosInstance({
                url: `paymentsservice/guest/payment/capture`,
                method: "POST",
                data: JSON.stringify(payload),
                headers: {
                    'Customer-Id': customerId
                }
              });
            return res;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);