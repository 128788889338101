import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react"
import { styled } from '@mui/material/styles';
import { Button } from "../Button";
import { useSelector } from "react-redux";
import { Typography, Box } from "@mui/material";

const Container = styled(Box)(
    ({ bgimgurl, theme: { palette: { secondary }, breakpoints }}) => `
    background-color: ${secondary.main};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    width: 100%;
    padding: 42px 36px;
    background-image: none;
    height: fit-content;
    ${[breakpoints.up('sm')]} {
        background-image: url(${bgimgurl});
        height: 600px;
        padding: 10% 43% 10% 10%;
    }
   ${[breakpoints.up('md')]} {
        height: 700px;
        padding: 15% 43% 10% 6.5%;
    }
    ${[breakpoints.up('lg')]} {
        height: 860px;
    }
    ${[breakpoints.up('xl')]} {
        height: 1260px;
    }
`)

export const LandingPageInfograpic = () => {
    const { landingPageContent: { infographic: staticContent } } = useSelector((store) => store?.contentful)
    const { baseStoreData } = useSelector((store) => store?.commerce);
    const isOrderingEnabled = baseStoreData?.commerceEnabled;

    return (
        <Container data-testid='landingPageInfoBackgroundImg' bgimgurl={isOrderingEnabled ? staticContent?.backgroundImage?.fields?.file?.url : staticContent?.backgroundImagePrebook?.fields?.file?.url}>
            <Typography variant='p2' fontWeight={'bold'} color={'primary.main'}>{documentToReactComponents(staticContent?.message?.content[0])}</Typography>
            <Typography variant='h2' component={'div'} color={'primary.main'} fontSize={{ xs: '34px', sm: '20px', md: '27px', lg: '34px' }}>{documentToReactComponents(staticContent?.message?.content[1])}{documentToReactComponents(staticContent?.message?.content[2])}</Typography>
            <Typography variant={'p2'} component={'div'} color={'primary.main'} lineHeight={'2'} sx={{ maxWidth: '620px', marginTop: '22px' }}>{documentToReactComponents(staticContent?.message?.content[3])}</Typography>
            {staticContent?.callToActionButtonLabel && <Button buttonType="secondary" sx={{ marginTop: "22px" }}>{staticContent?.callToActionButtonLabel}</Button>}
        </Container>
    )
}