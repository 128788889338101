import { Box, Typography } from "@mui/material";
import { Button } from '../Button';
import { ModalComponent } from '../ModalComponent';
import { TypographyH1, TypographyP1 } from "../../assets/typographyTheme";
import MessageBanner from "../MessageBanner/MessageBanner";

const DeletePaymentConfirmationModal = ({ closeModal, openModal, cardInfo, handleDeletePaymentMethod }) => {
    const DeletePaymentConfirmation = () => {
        return (
            <>
                <Box  display="flex" justifyContent={ 'left'} alignItems={'flex-start'}>
                    <TypographyH1 id="modalTitle" data-testid='title'>Delete payment method</TypographyH1>
                </Box>
                <Box textAlign={'start'}>
                    <MessageBanner
                        type="warning"
                        headerText={`Are you sure you want to delete the ${cardInfo?.name} ending in ${cardInfo?.lastFour}?`}
                        dataTestId="deletePaymentWarningMessage"
                    />
                </Box>
                <TypographyP1 textAlign="left">
                    This payment method will be removed from your account. However, all current orders using this payment method will still be charged upon order shipment.
                </TypographyP1>
                <Box data-testid="buttonGroup" display="flex" justifyContent="center" gap="10px" width="100%">
                    <Button
                        buttonType='mds-secondary'
                        sx={{ padding: "16px 0px", textTransform: "none", width: '100%' }}
                        onClick={(e) => {e.stopPropagation(); closeModal()}}
                    >
                        <Typography fontFamily="Aeonik Regular" fontSize="14px" fontWeight="700">
                            No, keep payment method
                        </Typography>
                    </Button>
                    <Button buttonType='mds-primary' sx={{ padding: "16px 0px", textTransform: "none",  width: '100%' }}
                            onClick={async (e) => {e.stopPropagation(); await handleDeletePaymentMethod(cardInfo?.cardId)}}>
                        <Typography fontFamily="Aeonik Regular" fontSize="14px" fontWeight="700">
                            Yes, delete this payment method
                        </Typography>
                    </Button>
                </Box>
            </>
        )}

    return (
        <ModalComponent
            openModal={openModal}
            closeModalHandler={closeModal}
            style={{
                maxWidth: '521px',
                width: 'auto',
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '23px',
                '#titleContainer':{
                    marginLeft: '0px',
                    padding: '5px'
                },
            }}
            modalContent={DeletePaymentConfirmation()}
        />
    )
}

export default DeletePaymentConfirmationModal