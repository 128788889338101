import { Button } from "../components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export function toTitleCase(str) {
    if (str) {
        return str.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }   
}

export const getErrorMessage = (globalErrorMessage, code, getAllFields=false) => {
    let messageData = globalErrorMessage && globalErrorMessage.length
        && globalErrorMessage.filter((data) => {
            let source = data?.fields?.code ? (data?.fields?.code).trim() : ''
            return source === code
        })
    return messageData && (getAllFields ? messageData[0]?.fields : messageData[0]?.fields?.message);
}


export const generateButtonLinkComponent = (testID, onClickPath, buttonLabel) => {
    const locale = useSelector((state) => state.globalStates.locale);
    const history = useHistory();

    return(
        <Button data-testid={testID}
                buttonType="primary"
                onClick={() => history.push(`/${locale}/${onClickPath}`)}>
            { buttonLabel }
        </Button>
    )
}

export const commonAddressValidationErrorMessage = {
    "Address Line 1": "Address cannot be empty",
    "City" : "City cannot be empty",
    "State": "State cannot be empty",
    "Zip code": "Please enter a valid ZIP code",
    "Email": "Please enter a valid email",
    "Phone": "Please enter a valid phone number",
}