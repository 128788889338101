import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const StyledTooltip = styled(({className, variant="light", ...props}) => (
    <Tooltip {...props} classes={{popper: className}} arrow/>
))(({theme: {palette: {primary, neutral}, variant}}) => ({
  "& .MuiTooltip-tooltip": {
    fontSize: "12px",
    backgroundColor: variant === 'light' ? neutral.white : primary.main,
    color: variant === 'light' ? neutral.main : neutral.white,
    padding: "10px",
    border: variant === 'light' ? `2px solid ${primary.blue}` : 'none',
    borderRadius: "10px",
    boxShadow: '0px 3px 6px #00000029'
  },
  "& .MuiTooltip-arrow": {
    color: variant === 'light' ? neutral.white : primary.main,
  },

}));

