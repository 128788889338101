import Coins from "../../assets/images/coins.svg";
import MapIcon from "../../assets/images/mapIcon.svg";
import BuildingOffice from "../../assets/images/buildingOffice.svg";
import { Box, Typography } from "@mui/material";

const addressInfo = {
    bill_to: {
        title: "Bill to address: ",
        iconType: Coins
    },
    ship_to: {
        title: "Ship to address: ",
        iconType: MapIcon
    },
    sold_to: {
        title: "Legal address: ",
        iconType: BuildingOffice
    }
};

export const AddressCard = ({
                                address,
                                dbaName,
                                addressType,
                                hasTitle = false,
                                sx = {}
                            }) => {
    const addressTypeInfo = address?.addressType || addressType;
    const { title='', iconType='' } = addressInfo[addressTypeInfo];
    const { line1 = '', line2 = '', city = '', state = '', zip = '' } = address || {};

    return (
        <Box display="flex" gap="10px" sx={sx}>
            <img src={iconType} alt={`${title} icon`} width="24" height="22" />
            <div>
                <Typography fontWeight="700" fontFamily="Aeonik Regular">
                    {hasTitle ? title : dbaName}
                </Typography>
                {address ? (
                    <Box>
                        {hasTitle && <Typography variant="body1">{dbaName}</Typography>}
                        <Typography variant="body1">{line1}</Typography>
                        {line2 && <Typography variant="body1">{line2}</Typography>}
                        <Typography variant="body1">{`${city}, ${state}, ${zip}`}</Typography>
                    </Box>
                ) : (
                    <Typography sx={{ fontStyle: 'italic', color: 'grey' }}>
                        Not yet entered. If you choose this organization, you will be required to enter a billing address to proceed.
                    </Typography>
                )}
            </div>
        </Box>
    );
};
