import React from "react";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { LocaleSelector } from "../LocaleSelector";
import { useSelector } from "react-redux";
import cart  from "../../assets/images/cart.svg";
import { Avatar, Box, IconButton } from "@mui/material";

const BottomAppContainer = styled('div')(({ theme: { palette: { neutral }, breakpoints }}) => `
  height: 90px;
  padding: 20px 72px;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr auto auto;
  justify-content: space-between;
  align-items: center;
  background-color: ${neutral.white};
  box-shadow: 0px 3px 16px #00000029;
  @media(max-width: 620px){
    padding: 20px 20px;
  }
  ${[breakpoints.down('sm')]} {
    height:80px;
    padding: 0px 30px;
    position: fixed;
    z-index: 1000000;
    width: 100vw;
  }
`);

const LogoContainer = styled('div')(({ theme: { breakpoints } }) => `
  padding-right: 30px;
`);

export const NonAuthHeader = ({ headerData, baseStoreData }) => {
  const locale = useSelector((state) => state.globalStates.locale);
  return ( 
    <BottomAppContainer>
      <LogoContainer>
        <Link to={`/${locale}`}>
          <img
            id="UnAuthLogo"
            src={headerData?.logo?.fields.file?.url}
            alt="Moderna Logo"
            width="158px"
          />
        </Link>
      </LogoContainer>
      <LocaleSelector baseStoreData={baseStoreData} />
      { baseStoreData?.commerceEnabled && <Box component={'img'} sx={{objectFit: 'cover'}} alt='cart icon' height="32px" width="32px" src={cart}/>}
    </BottomAppContainer>
  );
};
