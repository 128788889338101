import { useEffect, useRef, useState } from "react";
import { Box, Divider, Typography, useTheme} from "@mui/material";
import Chip from '@mui/material/Chip';
import { ComboSelect } from "../ComboSelect";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import { getOrderCartApi, removeFromOrderCartApi, updateOrderCartApi } from "../../features/commerce/commerceThunkApi";
import { useUserInfoContext } from "../../contexts";
import { AlertMessage } from "../AlertMessage";
import { Spinner } from "../Spinner";
import { useHistory } from "react-router-dom";
import CartDeliveryComponent from "../CartDeliveryComponent";
import {getAnalyticsProductDataAttributes} from "../../utils/Helper";

const CartEntryComponent = ({isCheckout, entryData, cartContent, selectedLocations, index, setCheckoutBtnDisabled, onLocaleErrorChange}) => {
  const [dropDownError, setDropDownError] = useState([]);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [totalDoseQty, setTotalDoseQty] = useState({ label: '', value: 0 });
  const dispatch = useDispatch();
  const { userInfo } = useUserInfoContext() || '';
  const { uid : userId } = userInfo || '';
  const [errorMsg, setErrorMsg] = useState('');
  const alertRef = useRef(null);
  const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
  const locale = useSelector((state) => state.globalStates.locale);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const qtyExceedsSkuOrderErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "QUANTITY_EXCEEDS_SKU_ORDER");
  const qtyExceedsSkuOrderError = qtyExceedsSkuOrderErrorData && qtyExceedsSkuOrderErrorData[0]?.fields?.message;
  const quantityExceedsErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "QUANTITY_EXCEEDS_INVENTORY")
  const quantityExceedsError = quantityExceedsErrorData && quantityExceedsErrorData[0]?.fields?.message
  const[processing, setProcessing] = useState(false);
  const history = useHistory();
  const { addresses } = useSelector(store => store.commerce);
  const [fullShippingConfig, setFullShippingConfig] = useState([]);
  const [lastEventData, setLastEventData] = useState({});

  const getDosesOptions = (dosesPerCarton) => { // the drop down doses values are updated
    let minDoses = { "label": dosesPerCarton?.toString(), "value": dosesPerCarton };
    let doses = cartContent?.quantityIncrements?.quantityIncrements?.map(item => {
      return (
        {
          "label": item.toString(),
          "value": item,
        }
      )
    });
    doses?.unshift(minDoses);
    return doses;
  };

  useEffect(() => { // mapping the address details from config data as per applicable filters
    const allCofigs = entryData?.configurationInfos?.map((config) => {
      const shippingConfigs = JSON.parse(JSON.stringify(config))
      shippingConfigs['fullAddressesObj'] = addresses?.data?.addresses?.filter(obj => obj?.uid === config?.deliveryAddress?.uid && obj.partnerFunction === "SHIP_TO")[0]
      return shippingConfigs;
    })
    setFullShippingConfig(allCofigs)
  }, [addresses, entryData])

  const selectChangeHandler = async (e, value, productId, entryNumber, dosesPerCarton) => { // main on changes handler for doses drop down. Called from onBlur and onKeyUp as well
    if (lastEventData?.value !== value?.value || lastEventData?.productId !== productId) {
      let eventData = {};
      if (value.value !== 0) {
        setProcessing(true);
        const updatedData = {
          quantity: value.value / dosesPerCarton,
        };
        dispatch(updateOrderCartApi({ userId, entryNumber, updatedData})).unwrap()
        .then((updateOrderCartData) => {
          if (updateOrderCartData?.statusCode === 'maxOrderQuantityExceeded') {
            setErrorMsg(qtyExceedsSkuOrderError);
            alertRef.current?.openAlert(qtyExceedsSkuOrderError);
          }
          dispatch(getOrderCartApi({ userId })).unwrap()
          .then((data) => {
            setProcessing(false);
            if(data?.entries?.length === 0)
              history.push(`/${locale}/products`)
              
            eventData.value = value.value;
            eventData.productId = productId;
            setLastEventData(eventData)
          })
          .catch((error) => {
            setErrorMsg(generalApiError);
            alertRef.current?.openAlert(error);
            setProcessing(false);
          })
        })
        .catch((error) => {
          setErrorMsg(quantityExceedsError);
          alertRef.current?.openAlert(error);
          setProcessing(false);
        })
      }
    }
  };
  const selectBlurHandler = async (event, dosesPerCarton, productId, enteryNumber) => {
    let dropDownValue = (Math.ceil(parseInt(event.target.value) / parseInt(dosesPerCarton))) * parseInt(dosesPerCarton);
    setTotalDoseQty({
      label: dropDownValue.toString(),
      value: dropDownValue
    })
    selectChangeHandler(event, { "label": dropDownValue.toString(), "value": dropDownValue }, productId, enteryNumber, dosesPerCarton)
    if (event.target.value === 0) {
      setDropDownError([...dropDownError, productId]);
    } else {
      const dropDownErrorIds = [...dropDownError];
      const index = dropDownErrorIds.indexOf(productId);
      dropDownErrorIds.splice(index, 1);
      setDropDownError(dropDownErrorIds);
    }
  }
  const selectKeyUpHandler = async (event, dosesPerCarton, productId, entryNumber) => {
    if (event.key === "Enter") {
      let dropDownValue = (Math.ceil(parseInt(event.target.value) / parseInt(dosesPerCarton))) * parseInt(dosesPerCarton);
      selectChangeHandler(event, { "label": dropDownValue.toString(), "value": dropDownValue }, productId, entryNumber, dosesPerCarton)
      if (event.target.value === 0) {
        setDropDownError([...dropDownError, productId]);
      } else {
        const dropDownErrorIds = [...dropDownError];
        const index = dropDownErrorIds.indexOf(productId);
        dropDownErrorIds.splice(index, 1);
        setDropDownError(dropDownErrorIds);
      }
    }
  }

  const deleteEntryHandler = (entryNumber) => {
    setProcessing(true);
    dispatch(removeFromOrderCartApi({ userId, entryNumber })).unwrap()
      .then(() => {
        dispatch(getOrderCartApi({ userId })).unwrap()
        .then((data) => {
          setProcessing(false);
          if(data?.entries?.length === 0)
            history.push(`/${locale}/products`)
        })
        .catch((error) => {
          setErrorMsg(generalApiError);
          alertRef.current?.openAlert(error);
          setProcessing(false);
        })
      })
      .catch((error) => {
        setErrorMsg(generalApiError);
        alertRef.current?.openAlert(error);
        setProcessing(false);
      })
  }
  const getImageUrl = (imagesData) => {

    if(!imagesData || imagesData.length === 0) return false;
    const imageFilteredData = imagesData.filter((data) => data?.imageType === "PRIMARY");
    return imageFilteredData[0]?.url;
  }
  
  useEffect(() => { // doses quantity in drop down gets updated as per carton values
    setTotalDoseQty({
      label: (entryData?.quantity * entryData?.product?.dosesPerCarton).toString() || "0",
      value: entryData?.quantity * entryData?.product?.dosesPerCarton || 0,
    })
  }, [entryData])

  return (
    <>
      <AlertMessage variant={"filled"}
                    type={"warning"}
                    color={"error"}
                    message={errorMsg}
                    sx={mobile?
                        { width: '90%', left: '5%'}:
                        { top: 120, left: '25%', width: '50%' }}
                    ref={alertRef}
      />
      <Spinner processing={processing} />
    <Box {...getAnalyticsProductDataAttributes(entryData?.product)} data-testid="BoxContainer" key={index} display='flex' sx={{flexDirection: { xs:"column", sm:"row"}}} m={{lg:'25px 10px', md:'25px 20px 25px 0', xs:'10px'}}>
      <Box component='img' sx={{ float: "left", width: "70px", height: "75px"}} src={getImageUrl(entryData?.product?.images)} alt="logo" />
      {mobile ? 
        (
          <Box sx={{width: '100%'}}>
            <Chip label={entryData?.product?.ageGroup} sx={{ bgcolor: 'primary.blue', color: 'neutral.white', borderRadius: '4px', height: '24px', width: 'fit-content', textTransform: 'uppercase', mt: "5px"}} />
            <Box display='flex' justifyContent='space-between' flexDirection={'column'} width='100%'>
              <Typography variant='h5' mt={"10px"}>{entryData?.product?.name}</Typography>
              <Typography variant='p1' mt={"10px"}>{entryData?.product?.summary}</Typography>
              {isCheckout ? 
                <Box display='flex' flexDirection={'row'} gap={1}>
                  <Typography variant='p1' mt={"10px"}>{entryData?.quantity * entryData?.product?.dosesPerCarton || 0}</Typography>
                  <Typography variant='p1' mt={"10px"} color={'neutral.gray1'}>({entryData?.quantity} cartons)</Typography>
                </Box>
                :
                <Box display='flex' mt={"10px"} gap={1} alignItems={'center'} >
                  <ComboSelect
                    variant={"square"}
                    inputProps={{ className: "selectDoses" }}
                    label={cartContent?.dosesLabel?.toUpperCase()}
                    sx={{
                        width: 130,
                        height: 48
                    }}
                    options={getDosesOptions(entryData?.product?.dosesPerCarton) || []}
                    defaultValue={dropDownError?.includes(entryData?.product?.code) ? { label: "0", value: 0 } : {
                      label: (entryData?.quantity * entryData?.product?.dosesPerCarton).toString() || "0",
                      value: entryData?.quantity * entryData?.product?.dosesPerCarton || 0,
                    }}
                    error={dropDownError?.includes(entryData?.product?.code)?.toString()}
                    onChangeHandler={(e, value) => {
                      selectChangeHandler(e, value, entryData?.product?.code, entryData.entryNumber, entryData?.product?.dosesPerCarton);
                    }}
                    onBlurHandler={(event) => selectBlurHandler(event, entryData?.product?.dosesPerCarton, entryData?.product?.code, entryData?.entryNumber)}
                    onKeyUpHandler={(event) => selectKeyUpHandler(event, entryData?.product?.dosesPerCarton, entryData?.product?.code, entryData?.entryNumber)}
                  />
                  <Typography variant='p1' m={"25px 5px"}>{entryData?.quantity} cartons</Typography>
                  <img className='removeProduct' src={DeleteIcon} alt={"deleteIcon"} style={{ width: "24px", height: "24px", margin: "25px 5px", cursor: "pointer" }} onClick={() => deleteEntryHandler(entryData?.entryNumber)}/>
                </Box>
              }
              <Typography variant='h5' mt={"20px"}>{entryData?.totalPrice?.formattedValue || 0.0}</Typography>
              <Typography variant='p1'>{entryData?.dosePrice?.formattedValue} / dose</Typography>
              {entryData?.discountedPrice &&
                <Typography variant='p1' sx={{ textDecoration: 'line-through' }}>{entryData?.defaultDosePrice?.formattedValue} / dose</Typography>
              }
            </Box>
            {isCheckout ?
              fullShippingConfig?.map(item => <Box display='flex' flexDirection={'column'} mt={2}>
                <Box display='grid' key={index} flexDirection={'column'}>
                  <Typography variant='h6'>{cartContent?.deliveryLocationLabel?.toUpperCase()}</Typography>
                  <Typography variant='p1'>{item?.fullAddressesObj?.companyName}</Typography>
                  <Typography variant='p1'>{`${item?.fullAddressesObj?.line1 ? item?.fullAddressesObj?.line1 + ',' : ''}`}</Typography>
                  <Typography variant='p1'>{`${item?.fullAddressesObj?.line2 ? item?.fullAddressesObj?.line2 + ',' : ''} ${item?.fullAddressesObj?.line3 ? item?.fullAddressesObj?.line3 + ',' : ''}`}</Typography>
                  <Typography variant='p1'>{`${item?.fullAddressesObj?.town || ''} ${item?.fullAddressesObj?.region?.isocode?.split('-')[1] || ''}, ${item?.fullAddressesObj?.postalCode || ''}`}</Typography>
                  {item?.deliveryInstruction && item?.deliveryInstruction?.trim() !== "" &&
                    <Typography variant='p2' color={'neutral.gray1'}>{cartContent?.deliveryInstructionsText}: {item?.deliveryInstruction}</Typography>
                  }
                </Box>
                <Box key={index} display='grid' flexDirection={'column'} mt={2}>
                  <Typography variant='h6'>{cartContent?.dosesLabel?.toUpperCase()}</Typography>
                  <Typography variant='p1'>{item?.quantity * entryData?.product?.dosesPerCarton}</Typography>
                </Box>
              </Box>) : 
              <Box my={3}>
                <CartDeliveryComponent cartContent={cartContent} entryData={entryData} selectedLocations={selectedLocations} index={index} dosesPerCarton={entryData?.product?.dosesPerCarton} setCheckoutBtnDisabled={setCheckoutBtnDisabled} onLocaleErrorChange={onLocaleErrorChange} />
              </Box>
            }
            <Divider orientation="horizontal" variant="middle" width={'100%'} sx={{marginTop:2}} />
          </Box>
        )
        :
        (<Box ml={{xs:'20px', md:'10px', lg:'20px'}} sx={{width: '100%'}} display={'grid'} gap={'5px'}>
          <Chip label={entryData?.product?.ageGroup} sx={{ bgcolor: 'primary.blue', color: 'neutral.white', borderRadius: '4px', height: '24px', width: 'fit-content', textTransform: 'uppercase' }} />
          <Box display='flex' justifyContent='space-between' width='100%'>
            <Typography variant='h5' pr='10px'>{entryData?.product?.name}</Typography>
            <Typography variant='h5'>{entryData?.totalPrice?.formattedValue}</Typography>
          </Box>
          <Box data-testid="ChildContainer" display='flex' justifyContent='space-between' width='100%'>
            <Typography variant='p1' pr='10px'>{entryData?.product?.summary}</Typography>
            <Box display='flex' flexDirection={'column'}>
              <Typography variant='p1'>{entryData?.dosePrice?.formattedValue} / dose</Typography>
              {entryData?.discountedPrice &&
                <Typography variant='p1' sx={{ textDecoration: 'line-through' }} color={'neutral.gray1'}>{entryData?.defaultDosePrice?.formattedValue} / dose</Typography>
              }
              </Box>
          </Box>
          {isCheckout ? 
            <Box display='flex' flexDirection={'row'} gap={1} mb={'20px'}>
              <Typography variant='p1' mt={"10px"}>{entryData?.quantity * entryData?.product?.dosesPerCarton || 0}</Typography>
              <Typography variant='p1' mt={"10px"} color={'neutral.gray1'}>({entryData?.quantity} cartons)</Typography>
            </Box>
            :
            <>
              <Box display='flex' gap={1} alignItems={'center'}>
                <ComboSelect
                  variant={"square"}
                  inputProps={{ className: "selectDoses" }}
                  label={cartContent?.dosesLabel?.toUpperCase()}
                  options={getDosesOptions(entryData?.product?.dosesPerCarton) || []}
                  defaultValue={totalDoseQty}
                  error={dropDownError?.includes(entryData?.product?.code)?.toString()}
                  onChangeHandler={(e, value) => {
                    selectChangeHandler(e, value, entryData?.product?.code, entryData.entryNumber, entryData?.product?.dosesPerCarton);
                  }}
                  onBlurHandler={(event) => selectBlurHandler(event, entryData?.product?.dosesPerCarton, entryData?.product?.code, entryData?.entryNumber)}
                  onKeyUpHandler={(event) => selectKeyUpHandler(event, entryData?.product?.dosesPerCarton, entryData?.product?.code, entryData?.entryNumber)}
                />
                <Typography variant='p1' m={"30px 5px"}>{entryData?.quantity} cartons</Typography>
                <img className= 'removeProduct' src={DeleteIcon} alt={"deleteIcon"} style={{ width: "24px", height: "24px", margin: "30px 5px", cursor: "pointer" }} onClick={() => deleteEntryHandler(entryData?.entryNumber)} />
              </Box>
             <CartDeliveryComponent cartContent={cartContent} entryData={entryData} selectedLocations={selectedLocations} index={index} dosesPerCarton={entryData?.product?.dosesPerCarton} setCheckoutBtnDisabled={setCheckoutBtnDisabled} onLocaleErrorChange={onLocaleErrorChange}/>
            </>
          }
          {isCheckout &&
            fullShippingConfig?.map(item => <Box display='flex' gap={10} mt={1} justifyContent={'space-between'}>
              <Box display='flex' key={index} flexDirection={'column'}>
                <Typography variant='h6'>{cartContent?.deliveryLocationLabel?.toUpperCase()}</Typography>
                <Typography variant='p1'>{item?.fullAddressesObj?.companyName || ''}</Typography>
                <Typography variant='p1'>{`${item?.fullAddressesObj?.line1 ? item?.fullAddressesObj?.line1 + ',' : ''}`}</Typography>
                <Typography variant='p1'>{`${item?.fullAddressesObj?.line2 ? item?.fullAddressesObj?.line2 + ',' : ''} ${item?.fullAddressesObj?.line3 ? item?.fullAddressesObj?.line3 + ',' : ''}`}</Typography>
                <Typography variant='p1'>{`${item?.fullAddressesObj?.town || ''} ${item?.fullAddressesObj?.region?.isocode?.split('-')[1] || ''}, ${item?.fullAddressesObj?.postalCode || ''}`}</Typography>
                {item?.deliveryInstruction && item?.deliveryInstruction?.trim() !== "" &&
                  <Typography variant='p2' color={'neutral.gray1'}>{cartContent?.deliveryInstructionsText}: {item?.deliveryInstruction}</Typography>
                }
                </Box>
              <Box key={index} display='flex' flexDirection={'column'}>
                <Typography variant='h6'>{cartContent?.dosesLabel?.toUpperCase()}</Typography>
                <Typography variant='p1' textAlign={'right'}>{item?.quantity * entryData?.product?.dosesPerCarton}</Typography>
              </Box>
            </Box>)
          }  
          <Divider orientation="horizontal" variant="middle" width={'100%'} sx={{ marginTop:4}} />
            
        </Box>)}
    </Box>
  </>
  );
};

export default CartEntryComponent;