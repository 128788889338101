import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../Button";
import { Container, Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { TextInput } from "../TextInput";
import { AlertMessage } from "../AlertMessage";
import { SelectDropdown } from "../SelectDropdown";
import { validateFields } from "../../utils/Helper";
import { COMPANY_NAME, FIRST_NAME, LAST_NAME, LINE_1, LINE_2, CITY, STATE, ZIP_CODE, EMAIL, PHONE, HIN, DEA } from "../../constants";
import { loadAddressComponent } from "../../features/contentful/contentfulThunkApi";
import { createAddressApi } from "../../utils/AddressesApi";
import { fetchUserApi, getAddressesApi } from "../../features/commerce/commerceThunkApi";

const RequiredSymbol = styled('span')({
    color: 'red',
  });

const StyledTextInput = styled(TextInput)(({ theme: { breakpoints } }) => `
  width: 100%;
  margin-top: 20px !important;
  ${[breakpoints.down('sm')]} {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
`);

const buttonsContainerSxStyles = {
    flexDirection: {xs: 'column', sm:'row'},
    padding: {xs: '0', sm:'0'},
    gap: {xs: '0', sm:'10px'},
  }

export const AddressesForm = ({userId, closeModal, handleNewAddresses}) => {
    
    const defaultFields = {
        firstName: '',
        lastName: '',
        companyName: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipcode: '',
        email: '',
        phone: '',
        hin: '',
        dea: ''
    }

    const [inputFields, setInputFields] = useState(defaultFields);
    
    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        companyName: false,
        line1: false,
        city: false,
        state: false,
        zipcode: false,
        email: false,
        phone: false,
        hin: false,
        dea: false
    });

    const dispatch = useDispatch();
    const { addressComponent: addressContent } = useSelector((store) => store?.contentful);
    const locale = useSelector((state) => state.globalStates.locale);
    const country = locale.split('-')[1];
    const [errorMsg, setErrorMsg] = useState('');
    const alertRef = useRef(null);
    const successAlertRef = useRef(null);
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const validationErrorMessage = {
        "Company Name": "Company name cannot be empty",
        "Address Line 1": "Address cannot be empty",
        "First Name": "First Name cannot be empty",
        "Last Name": "Last Name cannot be empty",
        "City" : "City cannot be empty",
        "ZIP code": "Please enter a valid ZIP code",
        "Account Email Address": "Please enter a valid email",
        "Account Phone Number": "Please enter a valid phone number",
        "Health Industry Number (HIN)": "Please enter a valid HIN",
        "DEA Number": "Please enter a valid DEA Number"
    }

    useEffect(() => {
        if (addressContent && Object.keys(addressContent).length === 0)
          dispatch(loadAddressComponent());
      }, [addressContent, dispatch]);

    const allStates = addressContent?.USStates?.map((state) => {
        return({
            "value": state,
            "label": state,
        })
    })

    const validate = (fieldType, value) => {
        const requireRegexCheck = [DEA, EMAIL, HIN, PHONE, ZIP_CODE];

        if (fieldType === LINE_2 ) return true

        if (value === '' || value === null) {
            // skipping empty checks for HIN/DEA as 1 of those can be empty
            if (fieldType === HIN || fieldType === DEA) return true
            return false
        }

        if (!requireRegexCheck.includes(fieldType)) return true
        
        return validateFields(fieldType, value)
    }
    
    const handleChange = (value, key) => {
        setInputFields({...inputFields, [key]: value });
        setErrors({...errors, [key]: !validate(key, value) });
    };

    const allErrorsResolved = Object.values(errors).every(v => v === false);
    const requiredFields = [FIRST_NAME, LAST_NAME, COMPANY_NAME, LINE_1, CITY, STATE, ZIP_CODE, EMAIL, PHONE]
    const requiredFieldsFilled = requiredFields.every((key) => inputFields[key] !== '') && (inputFields?.hin !== '' || inputFields?.dea !== '');

    const handleSubmit = async () => {
        try {
            let addressBody = {
                hin: inputFields?.hin,
                deaLicense: inputFields?.dea,
                addresses: [
                    {
                        companyName: inputFields?.companyName,
                        contactName: `${inputFields?.firstName} ${inputFields?.lastName}`,
                        line1: inputFields?.line1,
                        line2: inputFields?.line2,
                        town: inputFields?.city,
                        region: {
                            isocode: `${country}-${inputFields?.state}`
                        },
                        postalCode: inputFields?.zipcode,
                        country: {
                            isocode: country
                        },
                        email: inputFields?.email,
                        phone: inputFields?.phone 
                    }
                ],  
            }
            const response = await createAddressApi(addressBody);
            if (response?.status === 201) {
                successAlertRef.current?.openAlert();
                setInputFields(defaultFields);
                dispatch(getAddressesApi({ userId }))
                .unwrap()
                .then((addressResponse) => {
                    if (addressResponse?.data) {
                        handleNewAddresses(addressResponse.data?.addresses)
                        closeModal();
                    }
                })
                .then(
                    dispatch(fetchUserApi({userId: userId}))
                    .unwrap()
                    .then((user) => {
                        if (user?.data) {
                            localStorage.setItem("currentUser", JSON.stringify(user?.data));
                        }
                    })
                )
            }
        } catch(error) {
            setErrorMsg(generalApiError);
            alertRef.current?.openAlert(error);
        }
    }

    const renderRows = (label, key, placeholder, rowWidth, required=true) => {
        return (
            <Grid item xs={12} sm={rowWidth} paddingBottom={'1px'} key={key} >
                <StyledTextInput
                    required={required}
                    label={label}
                    inputType="text"
                    variant="standard"
                    value={inputFields[key]}
                    focused
                    placeholder={placeholder}
                    error={errors[key]}
                    helperText={errors[key] && validationErrorMessage[label]}
                    onChange={(event) => {
                        handleChange(event.target.value, key)
                    }}
                />
            </Grid>
        )
    }

    const renderDropdown = (label, key, options, rowWidth) => {
        return (
            <Grid item xs={12} sm={rowWidth}>
                <SelectDropdown
                    className="addressForm"
                    required
                    inputLabelSx={{ fontSize: "20px" }}
                    label={label}
                    placeholder={{id: "State", value: addressContent?.statePlaceholder}}
                    options={options}
                    value={inputFields[key] || "State"}
                    error={errors[key]}
                    helperText={errors[key] && validationErrorMessage[label]}
                    onChangeHandler={(value) => handleChange(value, key)}
                    sx={{ mb:"7px" }}
                />
            </Grid>
        )
    }

    const rowWidth = { LARGE: 12, MEDIUM: 6, SMALL: 4 }

    return (
        <>
        <AlertMessage data-testid='errorAlert' variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120}} ref={alertRef} />
        <AlertMessage data-testid='successAlert' variant={"filled"} type={"success"} color={"info"} message={"Address was added successfully."} ref={successAlertRef} />
        <Container data-testid='addAddressContainer'>
            <Grid container spacing={2} justifyContent="start" alignItems="center">
                {renderRows(addressContent?.firstName, FIRST_NAME, addressContent?.firstNamePlaceholder, rowWidth.MEDIUM)}
                {renderRows(addressContent?.lastName, LAST_NAME, addressContent?.lastNamePlaceholder, rowWidth.MEDIUM)}
                {renderRows(addressContent?.companyName, COMPANY_NAME, addressContent?.companyNamePlaceholder, rowWidth.LARGE)}
                {renderRows(addressContent?.addressLine1, LINE_1, addressContent?.line1Placeholder, rowWidth.LARGE)}
                {renderRows(addressContent?.addressLine2, LINE_2, addressContent?.line2Placeholder, rowWidth.LARGE, false)}
                {renderRows(addressContent?.city, CITY, addressContent?.cityPlaceholder, rowWidth.SMALL)}
                {renderDropdown(addressContent?.state, STATE, allStates, rowWidth.SMALL)}
                {renderRows(addressContent?.zipCode, ZIP_CODE, addressContent?.zipCodePlaceholder, rowWidth.SMALL)}
                {renderRows(addressContent?.accountEmailAddress, EMAIL, addressContent?.emailPlaceholder, rowWidth.MEDIUM)}
                {renderRows(addressContent?.accountPhoneNumber, PHONE, addressContent?.phonePlaceholder, rowWidth.MEDIUM)}
                <Grid display={"flex"} flexWrap={"wrap"} margin={{xs: "10px 0px 0px 15px"}} paddingTop={{xs: '20px', lg: '30px'}} textAlign={'left'} justifyContent={"left"}>
                    <Typography fontFamily={"Aeonik Regular"} fontSize={"16px"}>Please provide one and / or the other of the following numbers <RequiredSymbol>*</RequiredSymbol></Typography>
                </Grid>
                {renderRows(addressContent?.healthIndustryNumberHin, HIN, addressContent?.hinPlaceholder, rowWidth.MEDIUM, false)}
                {renderRows(addressContent?.deaNumber, DEA, addressContent?.deaPlaceholder, rowWidth.MEDIUM, false)}
            </Grid>
            <Grid display="flex" justifyContent='left' sx={buttonsContainerSxStyles} marginBottom={"20px"} marginTop={"20px"}>
                <Button id="submit" 
                    sx={{ marginTop: "32px", marginBottom: {xs:'46px', sm: "0"} }} 
                    onClick={handleSubmit} 
                    data-testid='submitBtn'
                    disabled={!requiredFieldsFilled || !allErrorsResolved }>
                    {addressContent?.useThisAddressLabel}
                </Button>
            </Grid>
        </Container>
        </>
    )
}