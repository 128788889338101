import { Box } from "@mui/material";
import { ContactInfoForm } from "./ContactInfoForm";
import { OrganizationForm } from "./OrganizationForm";
import { useSelector } from "react-redux";
import { ADDRESS_TYPE, USER_STATUS } from "../../../constants";
import { useState } from "react";
import { MatchFoundSection } from "./MatchFoundSection/MatchFoundSection";
import FallbackErrorPage from "../../../pages/FallbackErrorPage";
import { searchOrganizations } from "../../../utils/UserService/UserApis";
import { Spinner } from "../../Spinner";
import { getUserErrorMessages } from "../../../utils/getErrorMessage";

export const OrganizationTab = ({ tabIndex, onTabChange = () => {}, customerSegmentClasses, handleErrorMsg }) => {
    
    const [userStatus, setUserStatus] = useState(useSelector((store) => store?.globalStates?.userStatus) || '')

    const [showMatchFoundForm, setShowMatchFoundForm] = useState(false)
    const [userOrganizationInfo, setUserOrganizationInfo] = useState({})
    const [searchOrgResults, setSearchOrgResults] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const getSearchRequest = (pageNumber) => {
        const address = userOrganizationInfo?.organization?.address;
        return {
            pagination: {
                pageNumber,
                pageSize: 4,
            },
            name: userOrganizationInfo?.organization?.dbaName,
            address: {
                addressType: ADDRESS_TYPE.SOLD_TO,
                line1: address?.line1,
                line2: address?.line2,
                city: address?.city,
                state: address?.state,
                zip: address?.zip,
            }
        }
    }

    const searchForOrganizations = async (pageNumber) => {
        try {
            setIsLoading(true)
            const response = await searchOrganizations(getSearchRequest(pageNumber))
            if (response?.status === 200) {
                setSearchOrgResults(response?.data)
            }
        } catch(error) {
            const errorMessage = getUserErrorMessages(error?.response?.data?.errorCode)
            handleErrorMsg(errorMessage)
        } finally {
            setIsLoading(false)
        }
    }

    const renderTabContent =  () => {
        switch (userStatus) {
            case USER_STATUS.VERIFIED:
                return <ContactInfoForm />;

            case USER_STATUS.ONBOARDING_PERSON_COMPLETE:
                return showMatchFoundForm ? (
                    <MatchFoundSection
                        handleErrorMsg={handleErrorMsg}
                        tabIndex={tabIndex}
                        onTabChange={onTabChange}
                        userOrganizationInfo={userOrganizationInfo}
                        searchOrgResults={searchOrgResults}
                        searchForOrganizations={searchForOrganizations}
                    />
                ) : (
                    <OrganizationForm
                        tabIndex={tabIndex}
                        onTabChange={onTabChange}
                        customerSegmentClasses={customerSegmentClasses}
                        setUserOrganizationInfo={setUserOrganizationInfo}
                        setShowMatchFoundForm={setShowMatchFoundForm}
                        setSearchOrgResults={setSearchOrgResults}
                        handleErrorMsg={handleErrorMsg}
                    />
                );

                case USER_STATUS.ONBOARDING_ORG_COMPLETE:
                    return onTabChange(1);

                case USER_STATUS.ONBOARDING_SHIP_COMPLETE:
                    return onTabChange(2);

                case USER_STATUS.ONBOARDING_BILL_COMPLETE:
                    return onTabChange(3);

            default:
                return <FallbackErrorPage />;
        }
    };
    
    return (
        <Box maxWidth={'700px'}>
            <Spinner processing={isLoading} />
            {
                renderTabContent()
            }
        </Box>
    )
}