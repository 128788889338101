import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, useTheme } from "@mui/material";
import { Button } from "../../Button";
import { ModalComponent } from "../../ModalComponent"
import PlusIcon from "../../../assets/images/plusIcon.svg"
import MedicalLicenseCard from "./MedicalLicenseCard";
import { setCartData } from "../../../features/commerce/commerceSlice";
import { getMedicalLicense } from "../../../utils/HelperFunctions/OrderAndReservationHelper";

const buttonsContainerSxStyles = {
    display: 'flex',
    justifyContent: 'center',
    gap: '24px'
}

const medicalLicenseCardStyles = {
    width: '375px',
    height: '146px',
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    borderRadius: '16px',
    border: '1px solid #D1D2D5',
    boxShadow: ' 0px 2px 4px 0px rgba(0, 0, 0, 0.50);',
    textAlign: 'left',
    "&:hover": {
        cursor: 'pointer'
    },
    justifyContent: 'space-around'
}

const MedicalLicenseSelectionModal = ({ 
    isMedicalLicenseSelectionModalOpen,
    handleCloseModal,
    medicalLicensesForSelectedState,
    setSelectedMedicalLicense,
    setOpenLicenseFormModal,
    setAddressFormErrors,
    addressFormErrors,
    currentAddress
    }) => {

    const theme = useTheme()
    const [licenseSelection, setLicenseSelection] = useState(medicalLicensesForSelectedState[0])
    const { cartData }  = useSelector((store) => store?.commerce);
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()

        if (currentAddress) {
            const updatedCartLineItems = cartData?.cartLineItems.map((entry) => {
                if (String(entry?.shipToAddress?.addressId) === String(currentAddress?.id)) {
                    return { ...entry, healthcareLicense: getMedicalLicense(licenseSelection)};
                }
                return entry
            })
            dispatch(setCartData({cartLineItems: updatedCartLineItems}))
        }
        setSelectedMedicalLicense(licenseSelection)
        setAddressFormErrors && setAddressFormErrors({ ...addressFormErrors, ['medicalLicense']: '' });
        handleCloseModal()
    }

    const handleAddLicense = () => {
        handleCloseModal()
        setOpenLicenseFormModal(true)
    }

    const MedicalLicenseSelection = (
            <Box data-testid='medicalLicensesContainer'>
                <Box display={'flex'} alignItems={'flex-start'} gap={'16px'} flexWrap={'wrap'} marginBottom={'48px'}>
                    { medicalLicensesForSelectedState.map((license, i) => {
                        const isSelected = license?.number === licenseSelection?.number
                        return (
                            <Box
                                key={i}
                                data-testid={`medicalLicenseCard-${i}`}
                                onClick={() => {setLicenseSelection(license)}}
                                tabIndex="0"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setLicenseSelection(license);
                                    }
                                }}
                            >
                                <MedicalLicenseCard selectedMedicalLicense={license} style={{...medicalLicenseCardStyles, border: isSelected && '1px solid #079AE0'}}/>
                            </Box>
                        )
                    })}
                    {!currentAddress && 
                        <Box 
                            data-testid="addMedicalLicenseCard" 
                            sx={{
                                ...medicalLicenseCardStyles,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={handleAddLicense}
                        >
                            <Box display="flex" justifyContent="center" alignItems="center" gap="8px">
                                <img src={PlusIcon} alt="plus icon" width="32" height="32"/>
                                <Typography fontFamily="Aeonik Regular" color="#0379B2">Enter a new license</Typography>
                            </Box>
                        </Box>
                    }
                </Box>
                <Box data-testid='buttonsSection' sx={buttonsContainerSxStyles}>
                    <Button 
                        sx={{ padding: "16px 24px", textTransform: "none" }}
                        buttonType="mds-secondary"
                        data-testid='cancelBtn'
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </Button>
                    <Button 
                        id="submit"
                        sx={{ padding: "16px 24px", textTransform: "none" }}
                        buttonType="mds-primary"
                        data-testid='saveBtn'
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
    )
    return (
        <ModalComponent 
            title="Medical License"
            openModal={isMedicalLicenseSelectionModalOpen}
            closeModalHandler={handleCloseModal}
            modalContent={MedicalLicenseSelection}
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                '#crossIcon': {
                    marginTop: '5px'
                },
                '#titleContainer':{
                    padding: '5px',
                    marginBottom: '0px',
                    marginLeft: '0'
                },
                maxWidth: '850px',
                width: 'fit-content',
                padding: '32px',
                '#modalTitle': {
                    marginRight: {xs: '40px'},
                },
                [theme.breakpoints.down('md')]: {
                    width: '500px',
                },
                [theme.breakpoints.down('sm')]: {
                    width: '400px',
                    marginTop: '60px'
                },
            }}
        />
    )
}

export default MedicalLicenseSelectionModal