"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Storage {
    constructor() {
        this.getCookie = (name) => {
            var _a;
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) {
                return ((_a = parts.pop()) === null || _a === void 0 ? void 0 : _a.split(';').shift()) || null;
            }
            else {
                return null;
            }
        };
    }
    getItem(key) {
        return this.getCookie(key);
    }
    setItem(key, value = '', expiryDays = 30) {
        const { host, port } = window.location;
        let domain = '';
        // If it is a .com.country domain
        if (/\.com\.[a-z]+$/i.test(host)) {
            const hostPieces = host.split('.').reverse();
            domain = !port && hostPieces.length >= 3 ? `; domain=.${hostPieces[2]}.${hostPieces[1]}.${hostPieces[0]}` : '';
        }
        else {
            const hostPieces = host.split('.').reverse();
            domain = !port && hostPieces.length >= 2 ? `; domain=.${hostPieces[1]}.${hostPieces[0]}` : '';
        }
        const date = new Date();
        date.setTime(date.getTime() + expiryDays * 24 * 60 * 60 * 1000);
        document.cookie = `${key}=${value}; expires=${date.toUTCString()}${domain}; path=/`;
    }
}
exports.default = new Storage();
