import { styled } from '@mui/material/styles';
import { Box, Typography } from "@mui/material";
import { EmailVerificationTokenExpiredConstants } from "../../assets/constants/EmailVerificationConstants";
import { useHistory } from "react-router-dom";
import { Button } from "../Button";
import { Spinner } from "../Spinner";
import {useState} from "react";

const CustomContainer = styled('div')(({ theme: { breakpoints } }) => `
  margin-top: 48px;
  width: 640px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${[breakpoints.down('sm')]} {
    width: 90%;
    margin-top: 10px;
  }
`);

export const EmailVerificationTokenExpired = () => {
  const history = useHistory();
  const [processing, setProcessing] = useState(false);

  const { headlineIcon: HeadlineIcon } = EmailVerificationTokenExpiredConstants;

  const handleResendVerificationEmail = async () => {
    try {
      setProcessing(true);
      // TODO call backend API to resend the email, ECOM-959

      // TODO if the API succeed, then go to the page, ECOM-945, pass in email
      history.push(`/registration/email-verification`);
    } catch (error) {

    } finally {
      setProcessing(false);
    }
  }

  return (
    <>
      <Spinner processing={processing} />
      <CustomContainer data-testid='emailVerificationTokenExpired'>
        <Box display='flex' alignItems='flex-start' gap='8px' >
          <img src={HeadlineIcon} alt={'warning icon'} style={{ paddingTop: '5px' }} />
          <Typography variant='h3' color='#E8A061'  >
            { EmailVerificationTokenExpiredConstants.headline }
          </Typography>
        </Box>
        <Typography fontFamily="Aeonik Regular" fontSize="16px" fontWeight="400">
          { EmailVerificationTokenExpiredConstants.message }
        </Typography>
        <Box display="flex" justifyContent="flex-start">
          <Button
            sx={{textTransform: 'none', padding: '16px 32px' }}
            buttonType='mds-primary'
            onClick={() => handleResendVerificationEmail() }
          >
            <Typography fontFamily="Aeonik Regular" fontSize="16px" fontWeight="700">
              { EmailVerificationTokenExpiredConstants.callToActionButtonLabel }
            </Typography>
          </Button>
        </Box>
      </CustomContainer>
    </>
  );
};

