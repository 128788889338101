import React from "react";
import { styled } from "@mui/material/styles";
import { Box, FormControlLabel, Radio } from "@mui/material";

const CustomizeRadio = styled(Radio)(({ theme, size = 'medium' }) => {
    const sizeToFontSizeMap = {
        small: 17,
        medium: 20,
    };

    return {
        "&:hover": {
            backgroundColor: "transparent",
            opacity: 0.8,
        },
        '& .MuiTouchRipple-root': {
            display: 'none',
        },
        '&.Mui-checked:hover': {
            backgroundColor: 'transparent',
        },
        "&.Mui-checked": {
            color: theme.palette.neutral.darkGrey,
        },
        '& .MuiSvgIcon-root': {
            fontSize: sizeToFontSizeMap[size],
            marginLeft: `-${Math.floor(sizeToFontSizeMap[size] / 2)}px`,
            marginTop: `-${(sizeToFontSizeMap[size]/2 - 4)}px`,
        },
    };
});

const CustomFormControlLabel = styled(FormControlLabel)(({}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    ".MuiFormControlLabel-label": {
        fontSize: "16px",
    },
    margin: 0,
}));

export const CustomRadio = ({ checked, onChange, id, size, label, value, disabled, customComponent, sx, ...props }) => {
    const customizeStyle = {
        display: 'flex',
        justifyContent: 'center',
        ...sx,
    };
    return (
        <Box>
            <CustomFormControlLabel
                disabled={disabled}
                value={value}
                control={
                    <CustomizeRadio
                        id={`radio_field_${id}`}
                        checked={checked}
                        onChange={onChange}
                        size={size}
                        {...props}
                    />
                }
                label={label}
            />
            <Box style={customizeStyle}>
                {customComponent}
            </Box>
        </Box>
    );
};
