import { Box, Typography, useTheme } from "@mui/material";
import { ModalComponent } from "./index";
import { LoginForm } from "../LoginForm/LoginForm";
import { Button } from "../Button";

export const LoginModal = ({isLoginModalOpen, handleCloseModal, postLoginRedirectPath, handleOpenCreateAccountModal}) => {
    const theme = useTheme();

    const handleCreateAccount = () => {
        handleOpenCreateAccountModal()
    }

    const loginFormContent = (
        <Box
            display={'flex'}
            flexDirection={'row'}
            gap={'24px'}
            sx={{
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'center',
                },
            }}
        >
            <LoginForm postLoginRedirectPath={postLoginRedirectPath} />
            <Box
                component={'div'}
                sx={{
                    border:"1px solid",
                    borderColor:'neutral.gray6',
                    height:'330px',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        height: '1px',
                        margin: '24px 0',
                    },
                }}
            />
            <Box
                width={'302px'}
                height='320px'
                gap={'24px'}
                display='flex'
                flexDirection={'column'}
                alignItems={'flex-start'}
                sx={{
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                    },
                }}
            >
                <Typography 
                    fontSize={'24px'} 
                    fontFamily={'Aeonik Regular'}
                    fontWeight={700}
                    lineHeight={'30px'}
                >
                    Create an account
                </Typography>
                <Typography 
                    fontSize={'16px'} 
                    fontFamily={'Aeonik Regular'}
                    fontWeight={400}
                    textAlign={'left'}
                    lineHeight={'22px'}
                >
                    To order, please create an account for Moderna Direct, even if you have an account on other Moderna websites.
                </Typography>
                <Button 
                    sx={{height: '48px', textTransform: "none"}} 
                    buttonType= 'mds-primary' 
                    onClick={handleCreateAccount}
                >
                    Create a new account
                </Button>
            </Box>
        </Box>
    );


    return(
        <ModalComponent
            openModal={isLoginModalOpen}
            closeModalHandler={handleCloseModal}
            modalContent={loginFormContent}
            style={{ 
                width: '829px',
                padding: '24px',
                alignItems: 'flex-start',
                display: 'flex',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    width: '80%',
                    maxWidth: '100vw',
                    padding: '16px',
                    margin: '80px 0px 0px 0px'
                },
            }}
        />
    )
}