import { useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Button } from "../Button";
import { sortPaymentCardList } from "./Helper";
import { PaymentMethodsModal } from "./PaymentMethodsModal";
import { ModalComponent } from "../ModalComponent";
import useAddPaymentMethod from "../../hooks/useAddPaymentMethod";
import { listPaymentMethodsApi } from "../../utils/PaymentsService/PaymentMethodApis";
import { PAYMENT_METHOD_STATUS } from "../../constants";
import { PaymentMethodInfoCard } from "./PaymentMethodInfoCard";

const customCardStyle = {
    display: 'flex',
    paddingRight: '16px',
    borderRadius: '8px',
    border: '1px solid #D1D2D5',
    alignItems: 'center'
}

export const PaymentMethodBasicInfo = ({ handleErrorMsg, handlePaymentMethodSelection, setPaymentMethodAdded, handleSetAsDefaultPayment, handleDeletePaymentMethod }) => {
    const selectedPaymentMethod = useSelector((store) => store.commerce.selectedPaymentMethod);
    const [openPaymentMethodsModal, setOpenPaymentMethodsModal] = useState(false);
    const [listPaymentMethods, setListPaymentMethods] = useState([]);

    const {
        showPaymentForm,
        onAddPaymentMethodClick,
        handleClosePaymentModal,
        StripeForm
    } = useAddPaymentMethod(handleErrorMsg, setPaymentMethodAdded);

    const handleCloseModal = () => {
        setOpenPaymentMethodsModal(false);
    }

    const handleChangeBtnClick = async () => {
        const response = await listPaymentMethodsApi({ status: PAYMENT_METHOD_STATUS.ACTIVE });
        let allPaymentMethods = sortPaymentCardList(response?.data?.paymentMethodsList, selectedPaymentMethod.paymentMethodId);
        setListPaymentMethods(allPaymentMethods);
        setOpenPaymentMethodsModal(true);
    }

    const handleAddNewPaymentMethod = async () => {
        setOpenPaymentMethodsModal(false);
        await onAddPaymentMethodClick();
    }

    return(
        <Box display={'flex'} flexDirection={'row'} style={customCardStyle}>
            <PaymentMethodInfoCard paymentMethod={selectedPaymentMethod} />
            <Button buttonType="link" sx={{textTransform:"none"}} onClick={() => handleChangeBtnClick()}>Change</Button>
            {openPaymentMethodsModal && (
                <PaymentMethodsModal
                    openModal={openPaymentMethodsModal}
                    closeModal={handleCloseModal}
                    paymentList={listPaymentMethods}
                    handleAddNewPaymentMethod={handleAddNewPaymentMethod}
                    handlePaymentMethodSelection={handlePaymentMethodSelection}
                    handleSetAsDefaultPayment={handleSetAsDefaultPayment}
                    handleDeletePaymentMethod={handleDeletePaymentMethod}
                />
            )}
            {showPaymentForm && <ModalComponent
                openModal={showPaymentForm}
                closeModalHandler={handleClosePaymentModal}
                modalContent={<StripeForm />}
                style={{
                    maxWidth: '521px',
                    width: 'auto',
                    padding: '24px'
                }}
            />}
        </Box>
    )
}
