import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box, Typography } from '@mui/material'
import { loadReservationPage } from "../../features/contentful/contentfulThunkApi";
import CustomCard from "../CustomCardComponent/CustomCard";
import GroupAffiliationSelectionModal from "../Reservation/GroupAffiliationSelectionModal";
import { Button} from "../Button";
import { GPO_MESSAGES } from '../../constants';

const GPOComponent = ({ productsForPricing, onContractChange }) => {
    const dispatch = useDispatch();
    const userData = useSelector((store) => store?.commerce?.userData);
    const selectedGpoAffiliatedId = userData?.selectedContract?.id || '';
    const [openSelectionModal, setOpenSelectionModal] = useState(false);
    const { reservationPage: reservationPageContent } = useSelector((store) => store?.contentful);

    useEffect(() => {
        if (reservationPageContent && Object.keys(reservationPageContent).length === 0){
            dispatch(loadReservationPage());
        }
    }, [reservationPageContent, dispatch]);
    const isGPOSelected = () => {return !!userData?.selectedContract?.id}

    const handleConfirm = (selectedPriceContract) => {
        onContractChange(selectedPriceContract);
    };

    return (
        <Box display="flex" flexDirection="column" mt={2} minHeight={'50px'}>
            { !isGPOSelected() ? (
                <CustomCard
                    title={'Contract affiliation'}
                    sx={{padding: "16px 32px", display: 'flex', flexDirection: 'column'}}
                >
                    <Typography fontFamily={'Aeonik Regular'} mt={1}>{GPO_MESSAGES.GPO_MISSING_LINE_1}</Typography>
                    <Typography fontFamily={'Aeonik Regular'} mb={2}>{GPO_MESSAGES.GPO_MISSING_LINE_2}</Typography>
                    <Button
                        buttonType="mds-primary"
                        data-testid="selectAffiliationBtn"
                        sx={{ width: "260px", gap: "12px",
                            fontSize: "14px", fontWeight: 700,
                            textTransform:"none",
                            padding: "16px 24px"
                        }}
                        onClick={() => setOpenSelectionModal(true)}>
                        Select a contract affiliation
                    </Button>
                </CustomCard>
            )
                :
                (
                    <CustomCard
                        sx={{padding: "16px 32px"}}
                    >
                        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                            <Box display="flex" alignItems="center">
                                <Typography variant='h5' style={{ fontSize: '18px', marginRight: '8px' }}>
                                    {'Contract affiliation:' + '\u00A0' + (userData?.selectedContract?.displayName || '')}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color='secondary.textLinks'
                                    data-testid="selectAffiliationBtn"
                                    onClick={() => setOpenSelectionModal(true)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: '18px'
                                    }}
                                >
                                    Change
                                </Typography>
                            </Box>
                            <Box>
                                <Typography>
                                    Incorrect pricing?
                                    <Link to={"/contact-us"} style={{ color: "#0379B2" }}> Contact us</Link>
                                </Typography>
                            </Box>
                        </Box>
                    </CustomCard>
                )
            }
            { openSelectionModal &&
                <GroupAffiliationSelectionModal
                    openModal={openSelectionModal}
                    closeModal={()=> setOpenSelectionModal(false)}
                    handleConfirm={ handleConfirm }
                    defaultGpoAffiliatedId={selectedGpoAffiliatedId}
                    productsForPricing={productsForPricing}
                />
            }
        </Box>
    )
}

export default GPOComponent
