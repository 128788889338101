import commerceAuthAxiosInstance from "../sevices/commerceAuthAxiosInstance";
import commerceNoAuthAxiosInstance from "../sevices/commerceNoAuthAxiosInstance";

export const updateUserApiAuth = async (userId, updatedData) => {
  try {
    const res = await commerceAuthAxiosInstance({
      url: `users/${userId}`,
      method: "PATCH",
      data: JSON.stringify(updatedData),
      params: {
        userId: userId
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateUserApiNoAuth = async (userId, updatedData) => {
  try {
    const res = await commerceNoAuthAxiosInstance({
      url: `users/${userId}`,
      method: "PATCH",
      data: JSON.stringify(updatedData),
      params: {
        userId: ''
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};
