import { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material'
import { styled } from "@mui/material/styles";
import MapIcon from '../../assets/images/mapIcon.svg';
import CoinIcon from '../../assets/images/coins.svg';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import { Button } from '../../components';
import MessageBanner from '../MessageBanner/MessageBanner';


const PONumberSection = ({ poNumber, setPONumber, handlePoNumberChange, cartLineItems, addPONumberClicked, setAddPONumberClicked }) => {
    useEffect(() => {
        let PONumber = ""
        cartLineItems?.forEach(item => {
            if(item?.purchaseOrderNumber){
                PONumber = item?.purchaseOrderNumber
            }
        })
        setPONumber(PONumber)
        setAddPONumberClicked(!!PONumber)
    },[cartLineItems])

    const handlePONumberUpdate = (event) => {
        setPONumber(event.target.value);
        handlePoNumberChange(cartLineItems, event.target.value);
    };

    const handlePOButtonClick = () => {
        setAddPONumberClicked(true);
    };

    return (
        <>
            {addPONumberClicked ? (
                <>
                    <Typography padding="0 34px" fontWeight="700" fontFamily="Aeonik Bold">P.O. number</Typography>
                    <TextField
                        type="text"
                        size="small"
                        variant="outlined"
                        sx={{ padding: "0 34px" }}
                        onChange={handlePONumberUpdate}
                        value={poNumber}
                        inputProps={{ maxLength: 35 }}
                    />
                </>
            ) : 
                <Button
                    data-testid="addPONumberBtn"
                    buttonType='link'
                    sx={{ textTransform: "none", padding: "0 34px" }}
                    onClick={handlePOButtonClick}
                >
                    Add a P.O. number
                </Button>
            }
        </>
    );
};

const RequiredSymbol = styled('span')({
    color: 'red',
});

const OrderingAddressBlock = ({ shipToAddress, setOpenAddressModal, deleteAddressRow, billToAddress, handlePoNumberChange, 
    cartLineItems, setOpenLicenseSelectionModal, handleAddNewAddress }) => {
    const [PONumber, setPONumber] = useState(cartLineItems?.[0]?.purchaseOrderNumber)
    const [addPONumberClicked, setAddPONumberClicked] = useState(!!cartLineItems?.[0]?.purchaseOrderNumber);
    const selectedMedicalLicense = cartLineItems?.[0]?.healthcareLicense;

    const onAddAddress = (addressType) => {
        addressType === 'ship_to' && handleAddNewAddress()
    }

    const DeleteAddressRow = ({requireStyle}) => {
        let customPadding = requireStyle ? "10px 34px" : "0px";
        return (
            <Button data-testid="deleteAddresRow" buttonType='link' sx={{textTransform: "none", padding: customPadding, color:"#E31737"}} onClick={deleteAddressRow}>Delete this row</Button>
        )
    }

    const AddressSection = ({addressType, iconType}) => {
        const addressInfo = {
            bill_to: {
                title: "Bill To: ",
                address: billToAddress,
                dbaName: ""
            },
            ship_to: {
                title: "Ship To: ",
                address: shipToAddress,
                dbaName: shipToAddress?.dba
            }
        };

        const { title: addressTitle, address, dbaName: addressDbaName } = addressInfo[addressType];

        return (
            <Box display="flex" gap="10px">
                <img src={iconType} alt="address icon" width="24" height="22" />
                <div>
                    <Typography fontWeight="700" fontFamily="Aeonik Regular">{addressTitle}</Typography>
                    <Typography fontWeight="700" fontFamily="Aeonik Regular">{addressDbaName}</Typography>
                    <Typography fontWeight="400" fontFamily="Aeonik Regular">{address?.line1}</Typography>
                    <Typography fontWeight="400" fontFamily="Aeonik Regular">{address?.line2}</Typography>
                    <Typography fontWeight="400" fontFamily="Aeonik Regular">
                        {`${address?.city}, ${address?.state}, ${address?.zip}`}
                    </Typography>
                </div>
            </Box>
        )
    }

    const AddAddressButton = ({addressType, iconType}) => {
        const buttonName = addressType === "ship_to" ? "Add shipping address" : "Add billing address";
        return (
            <Button
                buttonType={'mds-secondary'}
                leftIcon={iconType}
                sx={{ padding: "16px 32px", textTransform: "none", fontWeight: "700" }}
                onClick={() => onAddAddress(addressType)}
                data-testid="addAddressBttn"
            >
                {buttonName} <RequiredSymbol> *</RequiredSymbol>
            </Button>
        )
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            paddingTop="25px"
            alignItems="flex-start"
        >
            {shipToAddress ? 
                <>
                    <AddressSection addressType="ship_to" iconType={MapIcon} />
                    <Button data-testid="changeAddressBtn" buttonType='link' sx={{textTransform: "none", padding: "0 34px", marginBottom:'16px'}} onClick={() => {setOpenAddressModal(true)}}>Change</Button>
                </>
                : 
                <AddAddressButton addressType="ship_to" iconType={MapIcon} />
            }
            {billToAddress ? <AddressSection addressType="bill_to" iconType={CoinIcon} />
            :
            <Box display="flex" gap="10px">
                <img src={CoinIcon} alt="address icon" width="24" height="22" />
                <div>
                    <Typography fontWeight="700" fontFamily="Aeonik Regular">Bill To: </Typography>
                    <MessageBanner type='warning' messageText='Could not access billing address'/>
                </div>
            </Box>}
            {Object.keys(selectedMedicalLicense || {}).length ?
                <>
                    <Box display="flex" gap="10px" marginTop={"16px"}>
                        <MedicalInformationOutlinedIcon sx={{color: "#383D47"}}/>
                        <div>
                            <Typography fontWeight="700" fontFamily="Aeonik Regular">Medical License:</Typography>
                            <Typography fontWeight="700" fontFamily="Aeonik Regular">
                                {selectedMedicalLicense?.name}
                            </Typography>
                            <Typography fontWeight="400" fontFamily="Aeonik Regular">{`License No. ${selectedMedicalLicense?.licenseNumber}`}</Typography>
                            <Typography fontWeight="400" fontFamily="Aeonik Regular">{`Exp ${selectedMedicalLicense?.expirationDate}`}</Typography>
                        </div>
                    </Box> 
                    <Button data-testid="changeMedicalLicenseBtn" buttonType='link' sx={{textTransform: "none", padding: "0 34px", marginBottom:'16px'}} onClick={() => setOpenLicenseSelectionModal(true)}>Change</Button>
                </>
                :
                shipToAddress && <Button
                    buttonType={'link'}
                    sx={{ padding: "16px 32px", textTransform: "none" }}
                    onClick={() => setOpenLicenseSelectionModal(true)}
                    data-testid="addMedicalLicenseBttn"
                >
                    Select a medical license <RequiredSymbol> *</RequiredSymbol>
                </Button>
            }
            <PONumberSection 
                poNumber={PONumber}
                setPONumber={setPONumber}
                handlePoNumberChange={handlePoNumberChange}
                cartLineItems={cartLineItems}
                addPONumberClicked={addPONumberClicked}
                setAddPONumberClicked={setAddPONumberClicked}
                />
            <DeleteAddressRow requireStyle={true} />
        </Box>
    )
}

export default OrderingAddressBlock
