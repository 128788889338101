import algoliasearch from "algoliasearch/lite";

const apiKey = process.env.ALGOLIA_API_KEY
const applicationId = process.env.ALGOLIA_APP_ID
const isSearchEnabled = process.env.IS_ALGOSEARCH_ENABLED ? JSON.parse(process.env.IS_ALGOSEARCH_ENABLED) : false
const algoliaClient = algoliasearch(applicationId, apiKey);

const searchClient = {
    ...algoliaClient,
    search(requests) {
        if (!isSearchEnabled) {
            return Promise.resolve({
                results: requests.map(() => ({
                    hits: [],
                    nbHits: 0,
                    nbPages: 0,
                    page: 0,
                    processingTimeMS: 0,
                    hitsPerPage: 0,
                    exhaustiveNbHits: false,
                    query: "",
                    params: ""
                }))
            })
        }
        return algoliaClient.search(requests)
    }
}

export default searchClient