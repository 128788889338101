import axios from "axios";

const getBaseUrl = () => {
  let baseUrl = process.env.ORCHESTRATOR_BASE_URL;
  return baseUrl;
}

const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "application/json"
  }
});

export default axiosInstance;
