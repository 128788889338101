import { Box } from "@mui/material"
import { Button, ModalComponent } from "../../../components"
import { TypographyH1, TypographyP1 } from "../../../assets/fonts/typographyTheme"
import { AddressCard } from "../../AddressCard/AddressCard"
import CustomSpinner from "../../Spinner/CustomSpinner"

const addressTileStyle = {
    display: 'flex',
    width: '348px',
    height: '147px',
    padding: '24px',
    alignItems: 'flex-start',
    borderRadius: '16px',
    border: '1px solid #E6E8ED',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)'
}

const spinnerStyle = {
    width: '100%',
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

const ShippingAddressesModal = ({ openModal, closeModal, shippingLocations, dbaName, processing }) => {

    const NoShippingAddresses = () => {
        return (
            <Box width={'100%'} height={'400px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <TypographyH1 sx={{fontWeight: 300}}>No shipping addresses currently exist for this organization.</TypographyH1>
            </Box>
        )
    }

    const ShippingAddresses = (
        <Box width={'100%'} display={'flex'} alignItems={'flex-start'} gap={'23px'} flexDirection={'column'}>
            <TypographyH1>{`Shipping addresses for ${dbaName}`}</TypographyH1>
            <TypographyP1>You can add more shipping locations after you associate your account with this organization.</TypographyP1>
            { processing ? <CustomSpinner message="Loading shipping addresses..." sx={spinnerStyle} /> :
                <Box width={'100%'} display={'flex'} alignItems={'flex-start'} alignContent={'flex-start'} gap={'24px'} flexWrap={'wrap'} textAlign={'left'}>
                    {shippingLocations?.length > 0 ? shippingLocations.map((address) => {
                        return (
                            <AddressCard key={address?.organizationId} address={address} dbaName={address.dbaName} sx={addressTileStyle} />
                        )
                    }) : <NoShippingAddresses />}
                </Box>
            }
            <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Button
                    sx={{ padding: "16px 24px", textTransform: "none" }}
                    buttonType="mds-secondary"
                    data-testid='closeShippingAddressesModalBtn'
                    onClick={() => {closeModal()}}
                >
                    Close
                </Button>
            </Box>
        </Box>
    )
    
    return (
        <ModalComponent
            openModal={openModal}
            closeModalHandler={closeModal}
            style={{
                '#crossIcon': {
                        marginTop: '5px'
                    },
                width: '775px',
                padding: '24px',
                display: 'flex',
                alignItems: 'flex-start',
            }}
            modalContent={ShippingAddresses}
        />        
    )
}

export default ShippingAddressesModal