
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Box from "@mui/material/Box";
import { Button } from "../";
import Modal from "@mui/material/Modal";
import { styled } from '@mui/material/styles';
import crossIcon from "../../assets/images/crossIcon.svg";
import { Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  zIndex: 10,
  borderRadius: "24px",
  padding: "18px 22px 34px 22px",
  outline: "none",
  textAlign: "center",
  "@media screen and (max-width: 500px)": {
    width:"80%",
    padding: "18px 22px 34px 22px",
  }
};

const ButtonsContainer = styled('div')(({ theme: { breakpoints } }) => `
    display: flex;
    justify-content: center;
    gap: 10px;
    ${[breakpoints.down('sm')]} {
      flex-direction:column;
      padding: 8px 52px 0px 52px;
      gap:24px;
    }
`);


export const RouterPrompt = ({ 
  when,
  onOK, 
  onCancel, 
  title, 
  confirmationHandler,
  closeModalHandler,
  subtitle,
  secondaryButtonLabel,
  primaryButtonLabel }) => {

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history?.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history?.block(() => {setShowPrompt(false)});
    }

    return () => {
      history?.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history?.block(() => {confirmationHandler()});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK, confirmationHandler]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history?.block(() => {closeModalHandler()});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel, closeModalHandler]);

    return (
    <Modal
      sx={{textAlign:'center'}}
      open={showPrompt}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display='flex' justifyContent='end'>
          <Box component={'img'} width='25px' height='25px' sx={{cursor:'pointer'}} src={crossIcon} onClick={handleCancel} data-testid='crossicon' />
        </Box>
        <Typography variant={'h3'} m='12px auto 0 auto' p={{xs:'80px 52px 24px 52px;', sm:'unset'}} color='primary.main' maxWidth='450px' width='100%' lineHeight='26px' data-testid='title'>{title}</Typography>
        {subtitle && <Typography variant={'p2'} color='primary.main' mt='12px'>{subtitle}</Typography>}
        <ButtonsContainer>
          {secondaryButtonLabel && <Button sx={{ marginTop: "32px" , "@media screen and (max-width: 500px)": {marginTop:"0px"}}} buttonType="secondary" onClick={handleCancel} data-testid='cancelBtn'>{secondaryButtonLabel}</Button>}
          <Button sx={{ marginTop: "32px" , "@media screen and (max-width: 500px)": {marginTop:"0px" , marginBottom:"46px"}}} onClick={handleOK} data-testid='okBtn'>{primaryButtonLabel}</Button>
        </ButtonsContainer>

      </Box>
    </Modal>
  );
}