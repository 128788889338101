import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { Button, TextInput, BackComponent } from "..";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { validateFields } from "../../utils/Helper";
import { BLOCKS } from "@contentful/rich-text-types";
import useMediaQuery from '@mui/material/useMediaQuery';
import { loadUserRegistrationPasswordStaticContent } from "../../features/contentful/contentfulThunkApi";
import { setInvalidPasswordMsg } from "../../features/globalStates/globalStatesSlice";
import { AlertMessage } from "../../components";
import { Box, Typography, useTheme } from "@mui/material";

const StyledTextInput = styled(TextInput)`
  height: 70px;
  width: 100%;
  margin-top: 30px !important;
`;

const PasswordList = styled('ul')(({ theme: { palette: { primary }, breakpoints }}) => `
  list-style-type: initial;
  color: ${primary.main};
  margin: 10px auto 60px auto;
  display: flex;
  justify-content: center;
  ${[breakpoints.down('sm')]} {
    margin-bottom: 30px;
  }
`);

export const SetPassword = ({ setComp, setProgressValue }) => {
  const [password, setPassword] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [showpassword, setShowpassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmpassword] = useState(false);
  const { userRegistrationPasswordContent } = useSelector((store) => store?.contentful);
  const { staticContent = [] } = userRegistrationPasswordContent;
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [errorMsg, setErrorMsg] = useState('');
  const alertRef = useRef(null)

  const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
  const invalidPasswordFormatErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "INVALID_PASSWORD_FORMAT");
  const invalidPasswordFormatError = invalidPasswordFormatErrorData && invalidPasswordFormatErrorData[0]?.fields?.message;

  const mismatchPasswordFormatErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "PASSWORD_MISMATCH");
  const mismatchPasswordFormatError = mismatchPasswordFormatErrorData && mismatchPasswordFormatErrorData[0]?.fields?.message;

  const invalidPasswordMsg = useSelector((state) => state.globalStates.invalidPasswordMsg);

  useEffect(() => {
    window.scrollTo(0,0);
    if (Object.keys(userRegistrationPasswordContent).length === 0) {
      dispatch(loadUserRegistrationPasswordStaticContent());
    }
  },[]);

  const handleNext = (e) => {
    e.preventDefault();
    if(validate()) {
      setProcessing(true);
      let userData = JSON.parse(localStorage.getItem('contactInfo'));
      userData["password"] = password;
      localStorage.setItem('contactInfo', JSON.stringify(userData));
      setProcessing(false);
      setComp('review');
      setProgressValue(82);
    }
  };
  const handlePassword = (event) => {
    setPassword(event.target.value);
    setInvalidPassword("");
  };

  const handleClickShowPassword = () => {
    setShowpassword(!showpassword);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    setInvalidConfirmPassword("");
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmpassword(!showconfirmpassword);
  };

  const validate = () => {
    let isValid = true;
    let passwordError;
    let confPasswordError;

    if (!password) {
      isValid = false;
      passwordError = "Please enter your password.";
      setInvalidPassword(passwordError);
    }

    if (!validateFields("password", password)) {
      isValid = false;
      passwordError = invalidPasswordFormatError;
      setInvalidPassword(passwordError);
    }

    if (!confirmPassword) {
      isValid = false;
      confPasswordError = "Please enter confirm password.";
      setInvalidConfirmPassword(confPasswordError);
    }

    if (confirmPassword !== "undefined") {
      if(password !== confirmPassword){
        isValid = false;
        confPasswordError = mismatchPasswordFormatError;
        setInvalidConfirmPassword(confPasswordError);
      }
    }

    return isValid;
  };

  const options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => <Typography variant={'p1'}>{children}</Typography>
    }
  };

  const backBtnHandler = () => {
    setComp('contactInfo');
    setProgressValue(50);
  };

  useEffect(() => {
    if(invalidPasswordMsg?.length > 0){
      setErrorMsg(invalidPasswordMsg);
      alertRef.current?.openAlert();
      dispatch(setInvalidPasswordMsg(""))
    }
  }, []);

  return (
    <>
    {desktop && <BackComponent sx={{top: "110px"}} onClickHandler={backBtnHandler} />}
    <AlertMessage variant={"filled"} type={"error"} message={errorMsg} ref={alertRef}/>
    <Box position='relative' m='30px auto 0 auto' width={{xs:'85%', sm:'60%'}}>
      {mobile && <BackComponent sx={{top: "110px"}} onClickHandler={backBtnHandler} />}
      <Typography variant={'h1'} textAlign='center' color='primary.main' mt={{xs:'20px', sm:'0'}} id="headline">{staticContent?.headline}</Typography>
      <Typography variant={'p1'} component={'div'} textAlign='center' mt='20px' id="subtitle">{documentToReactComponents(staticContent?.message?.content[0])}</Typography>
      <PasswordList>
        {documentToReactComponents(staticContent?.message?.content[1], options)}
      </PasswordList>
      <StyledTextInput
        required
        error={!!invalidPassword}
        value={password}
        inputType={showpassword ? 'text' : 'password'}
        label={staticContent?.passwordLabel}
        helperText={invalidPassword}
        variant="standard"
        focused
        placeholder={staticContent?.passwordPlaceholder}
        showpassword={showpassword}
        handleClickShowPassword={handleClickShowPassword}
        onChange={handlePassword}
        fieldType="password"
      />
      <StyledTextInput
        required
        focused
        placeholder={staticContent?.confirmPasswordPlaceholder}
        error={!!invalidConfirmPassword}
        value={confirmPassword}
        inputType={showconfirmpassword ? 'text' : 'password'}
        label={staticContent?.confirmPasswordLabel}
        helperText={invalidConfirmPassword}
        variant="standard"
        showpassword={showconfirmpassword}
        handleClickShowPassword={handleClickShowConfirmPassword}
        onChange={handleConfirmPassword}
        fieldType="password"
      />
      <Box display='flex' justifyContent='space-evenly' width='40%' margin='15% auto 70px auto'>
        <Button 
          loading={processing}
          buttonType="primary" 
          onClick={handleNext}>
            {staticContent?.nextButtonLabel}
          </Button>
      </Box>
    </Box>
  </>
  )
};
