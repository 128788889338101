import { Box, Typography } from "@mui/material";
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from "@mui/icons-material";

export const CustomPagination = ({ page, pageCount, onPageChange }) => {
    const handleBackButtonClick = () => {
        if (page > 1) {
            onPageChange(page - 1);
        }
    };

    const handleNextButtonClick = () => {
        if (page < pageCount) {
            onPageChange(page + 1);
        }
    };

    return (
        <Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center', gap: 10 }} id="Pagination" data-testid="pagination">
            <KeyboardArrowLeftOutlined
                onClick={handleBackButtonClick}
                fontSize="large"
                sx={{
                    color: 'secondary.graphical',
                    opacity: page === 1 ? 0.5 : 1,
                    pointerEvents: page === 1 ? 'none' : 'auto',
                    cursor: 'pointer'
                }}
                aria-label="Go to previous page"
            />
            <Typography variant="body1">{page} of {pageCount}</Typography>
            <KeyboardArrowRightOutlined
                onClick={handleNextButtonClick}
                fontSize="large"
                sx={{
                    color: 'secondary.graphical',
                    opacity: page === pageCount ? 0.5 : 1,
                    pointerEvents: page === pageCount ? 'none' : 'auto',
                    cursor: 'pointer'
                }}
                aria-label="Go to next page"
            />
        </Box>
    );
}