import axios from "axios";

let expiryTime;
const baseUrl = process.env.COMMERCE_BASE_URL; 
let conditionalBaseUrl = window.location.hostname === "localhost"
                    ? `${process.env.COMMERCE_API_URL}/${process.env.COMMERCE_BASE_SITE_ID}` 
                    : `/modernawebservices/v2/${process.env.COMMERCE_BASE_SITE_ID}/`;
const commerceEmpAxiosInstance = axios.create({
  baseURL: conditionalBaseUrl,
  headers: {
    "Content-Type": "application/json"
  }
});

commerceEmpAxiosInstance.interceptors.request.use(async req => {
  let token = JSON.parse(localStorage.getItem("commerceEmpAuthToken"));
  let oktaAccessTokenObj = JSON.parse(localStorage.getItem("okta-token-storage"));
  let empEmail = req.params?.empEmail === undefined ? '' : req.params?.empEmail.toLowerCase();
  req.params = req.params || {};
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    auth:{
      username: process.env.COMMERCE_OAUTH_ID,
      password: process.env.COMMERCE_OAUTH_SECRET
    },
    data: `grant_type=custom&clientId=${empEmail}&accessToken=${oktaAccessTokenObj?.accessToken?.accessToken}`,
    url: window.location.hostname === "localhost" ? `${baseUrl}/authorizationserver/oauth/token` : `/authorizationserver/oauth/token`,
  }

  req.params['asmuser'] = `${empEmail}`; //pass employee ID for emp APIs
  const now = new Date();
  const commerceEmpTokenExpiry = localStorage.getItem("commerceEmpTokenExpiry")
  const isExpired = new Date(commerceEmpTokenExpiry).getTime() < now.getTime();
  
  if(!token || isExpired) {
    const rs = await axios.request(options); 
    const commerceEmpAuthToken = rs.data;
    expiryTime = new Date();
    expiryTime.setSeconds(expiryTime.getSeconds() + commerceEmpAuthToken?.expires_in);
    localStorage.setItem("commerceEmpTokenExpiry", expiryTime);
    localStorage.setItem("commerceEmpAuthToken", JSON.stringify(commerceEmpAuthToken));
    req.headers["Authorization"] = `Bearer ${commerceEmpAuthToken?.access_token}`;
    return req;
  } 

  if(token && !isExpired) {
    req.headers["Authorization"] = `Bearer ${token?.access_token}`;
    return req;
  }
});

export default commerceEmpAxiosInstance;