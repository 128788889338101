import { Box, Typography } from '@mui/material'
import {SelectDropdown} from "../SelectDropdown";
import {TextInput} from "../TextInput";
import { useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

const placeholder = {value: "Select an address", id: "selectAnAddress"};

const OrderDetailsComponent = ({setPONumberValue, PONumberValue, setBillingAddress, billingAddress, handleBillingAddressChange }) => {
    const userData = useSelector((store) => store?.commerce?.userData);
    const { authState } = useOktaAuth() || '';
    const isAuthenticated = authState?.isAuthenticated;
    const defaultBillToAddress = userData?.addresses?.filter(address => address.addressType === 'bill_to');

    const formatAddress = (billingAddress) => {
        if (!billingAddress) return '';
        
        const { line1, line2, city, state, zip } = billingAddress;
        const addressParts = [line1, line2, city, state, zip].filter(part => part);
        
        return addressParts.join(', ');
    };

    const getDropDownOptions = () => {
        return defaultBillToAddress?.slice(0, 1).map((address) => {
            return { label: formatAddress(address), value: address?.id};
        }) || [];
    };

    const onChangeHandler = (value) => {
        setBillingAddress(value);
        handleBillingAddressChange(value);
    }

    return (
        <>
            <Typography variant='h5' mt={1}>Bill to address</Typography>
            <Box width={'80%'} maxWidth={'500px'} minWidth={'200px'}>
                <SelectDropdown
                    className="border"
                    menuPropsSx={{
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                        '& .MuiMenu-list': {
                            padding: '0px',
                        },
                        border: `1px solid gray`,
                    }}
                    menuItemStyleSx={{height: '48px'}}
                    options={getDropDownOptions()}
                    value={billingAddress}
                    onChangeHandler={onChangeHandler}
                    placeholder={placeholder}
                    sx={{ mb:"7px"}}
                    disabled={!isAuthenticated}
                />
            </Box>

            <Typography variant='h5' mt={1}>PO Number (all delivery locations)</Typography>

            <Box width={'50%'} maxWidth={'400px'} minWidth={'150px'} marginBottom={'8px'}>
                <TextInput
                    sx={{ width: '100%'}}
                    focused
                    inputType="text"
                    stylevariant={"reservationOrder"}
                    name="poNumber"
                    data-testid="reservationPoNumberInput"
                    value={PONumberValue}
                    onChange={(e) => {setPONumberValue(e.target.value);}}
                    maxLength={35}
                    disabled={!isAuthenticated}
                />
            </Box>
        </>
    )
}

export default OrderDetailsComponent
