import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import crossIcon from "../../assets/images/crossIcon.svg";
import { styled } from "@mui/material/styles";
import FormGroup from '@mui/material/FormGroup';
import { Button, Checkbox } from "..";

const CustomBox = styled(Box)(({ theme: { palette: { neutral }, breakpoints }}) => ({
  position: "absolute",
  top: "50%",
  marginLeft: "60%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  backgroundColor: neutral.white,
  padding: "40px",
  paddingLeft:"8%",
  borderRadius:"24px",
  [breakpoints.down('sm')]: {
    marginLeft: "unset",
    transform: "translate(0,-50%)",
    width: "unset",
    padding: "24px"
  }
}));

const CrossIconContainer = styled('div')(({ theme: { breakpoints } }) => `
  display: flex;
  justify-content: end;
  ${[breakpoints.down('sm')]} {
    margin-bottom: 40px;
  }
`);

const CrossIconComponent = styled('img')`
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-top: -22px;
  margin-right: -22px;
`;

export const ExternalRouterPrompt = ({isOpen, closeModalHandler, externalLink,checkBoxLabel, onOpenLink,buttonLabel, type, ...props}) => {
  const [isChecked, setIsChecked] = useState(false);
  const openExternalLink = () => {
    window.open(externalLink, '_blank');
    onOpenLink(false)
    if(isChecked){
      type === "extrenalLink" ? localStorage.setItem("dontRemindMeExternal", true) : localStorage.setItem("dontRemindMe", true);  
    }
  }

  return (
    <div>
      <Modal
       sx={{width:"80% !important" , '@media screen and (max-width: 500px)': {margin : '24px', width: "unset !important"},}}
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBox sx={{...props?.modalCustomStyle}}>
          <CrossIconContainer>
            <CrossIconComponent src={crossIcon} onClick={closeModalHandler}/>
          </CrossIconContainer>

          <Typography id="modal-modal-title" variant="h3" component="h2" sx={props?.headingStyle}>
            { props?.heading}            
          </Typography>
          
          <Typography id="modal-modal-description" sx={{ mt: 2, fontFamily:"Aeonik Regular", fontSize:"12px", width:"80%", color:"primary.main" , '@media screen and (max-width: 500px)': {fontSize : '16px' , width : "unset"}}}>
            { props?.content }
          </Typography>

          
          <FormGroup sx={{marginTop:"20px" , '@media screen and (max-width: 500px)': {mt : 3 , mb : 3}}}>
            <Checkbox label={checkBoxLabel} value={isChecked} onChange={()=>setIsChecked(!isChecked)} />
          </FormGroup>

         <Button onClick={openExternalLink}  data-exit-link={externalLink} sx={{marginTop:'7px', '@media screen and (max-width: 500px)': {mb: 10}}}>{buttonLabel}</Button>
        </CustomBox>

       </Modal>
    </div>
  );
};

