"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const text = {
    accept: 'Accept',
    amplitudeDescription: 'Product Analytics to capture on-site engagement and conversion data.',
    amplitudeDuration: '30 days',
    amplitudeProvider: 'Amplitude',
    deny: 'Deny',
    duration: 'Duration:',
    heading: 'We collect cookies',
    headingDescription: 'This website stores data such as cookies to enable essential site functionality, as well as marketing and performance.',
    headingReadMore: 'Read more',
    marketingDescription: "These items are used to deliver advertising that is more relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns. Advertising networks usually place them with the website operator's permission.",
    marketingHeading: 'Marketing',
    performanceDescription: "These items help the website operator understand how its website performs, how visitors interact with the site, and whether there may be technical issues. This storage type usually doesn't collect information that identifies a visitor.",
    performanceHeading: 'Performance',
    purpose: 'Purpose:',
    showCookieConsentOptions: 'Show cookie consent options',
    showDetails: 'Show details',
    showLess: 'Show less',
    strictlyNecessaryDescription: 'These items are required to enable basic website functionality.',
    strictlyNecessaryHeading: 'Strictly necessary',
    tradeDeskDescription: "The Trade Desk's cookies allow Moderna to understand how users engage with our media content and our website. These insights help us refine our content to provide more relevant experiences to our customers.",
    tradeDeskDuration: '1 year',
    tradeDeskProvider: 'The Trade Desk'
};
exports.default = text;
