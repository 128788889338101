import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import crossIcon from "../../assets/images/crossIcon.svg";
import { styled } from "@mui/material/styles";
import FormGroup from '@mui/material/FormGroup';
import { Button, Checkbox } from "..";
import { isExternalURL } from "../../utils/Helper";

const CustomBox = styled(Box)(({ theme: { palette: { neutral }, breakpoints }}) => ({
  position: "absolute",
  top: "50%",
  marginLeft: "60%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  backgroundColor: neutral?.white,
  padding: "40px",
  paddingLeft:"8%",
  borderRadius:"24px",
  [breakpoints.down('sm')]: {
    marginLeft: "unset",
    transform: "translate(0,-50%)",
    width: "unset",
    padding: "24px"
  }
}));

const CrossIconContainer = styled('div')(({ theme: { breakpoints } }) => `
  display: flex;
  justify-content: end;
  ${[breakpoints.down('sm')]} {
    margin-bottom: 40px;
  }
`);

const CrossIconComponent = styled('img')`
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-top: -22px;
  margin-right: -22px;
`;

export const ExternalLinkModal = ({ redirectUrl, key, ...props }) => {
  
  const handleRedirect = useCallback((url) => {
    if (isExternalURL(url) && !localStorage.getItem("dontRemindMeExternal")) {
      setShowNavigationAlert(true);
      setExternalLink(url);
      setExternalLinkModalType("externalLink");
    } else {
      window.open(url, '_blank');
      closeModalHandler();
      setExternalLink("");
    }
  }, []);

  useEffect(() => {
    handleRedirect(redirectUrl);
  }, [redirectUrl, handleRedirect]);
  
  const [isChecked, setIsChecked] = useState(false);
  const [showNavigationAlert, setShowNavigationAlert] = useState(false);
  const [externalLinkModalType, setExternalLinkModalType] = useState("");
  const [externalLink, setExternalLink] = useState("");
  const { interstitialComponentContent: staticContent  } = useSelector((store) => store?.contentful);

  const closeModalHandler = () => {
    setExternalLink(null);
    setShowNavigationAlert(false);
  };

  const openExternalLink = () => {
  window.open(externalLink, '_blank');
  if(isChecked){
    externalLinkModalType === "externalLink" ? localStorage.setItem("dontRemindMeExternal", true) : localStorage.setItem("dontRemindMe", true);  
  }
  closeModalHandler();
};

  return (
    <div>
      <Modal
       sx={{width:"80% !important" , '@media screen and (max-width: 500px)': {margin : '24px', width: "unset !important"},}}
        open={showNavigationAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBox sx={{...props?.modalCustomStyle}}>
          <CrossIconContainer>
            <CrossIconComponent src={crossIcon} onClick={closeModalHandler}/>
          </CrossIconContainer>

          <Typography id="modal-modal-title" variant="h3" component="h2" 
            sx={{
              fontFamily: "Aeonik Bold",
              fontSize: "30px",
              '@media screen and (max-width: 500px)': {
                  fontSize: '24px',
                  paddingBottom: "24px"
              }
            }}
          >
          {staticContent?.externalLink?.header}          
          </Typography>
          
          <Typography id="modal-modal-description" sx={{ mt: 2, fontFamily:"Aeonik Regular", fontSize:"12px", width:"80%", color:"primary.main" , '@media screen and (max-width: 500px)': {fontSize : '16px' , width : "unset"}}}>
            {staticContent?.externalLink?.subHeader}
          </Typography>

          
          <FormGroup sx={{marginTop:"20px" , '@media screen and (max-width: 500px)': {mt : 3 , mb : 3}}}>
            <Checkbox label={staticContent?.externalLink?.remindMeText} value={isChecked} onChange={()=>setIsChecked(!isChecked)} />
          </FormGroup>

         <Button onClick={openExternalLink}  data-exit-link={externalLink} sx={{marginTop:'7px', '@media screen and (max-width: 500px)': {mb: 10}}}>{staticContent?.externalLink?.buttonLabel}</Button>
        </CustomBox>

       </Modal>
    </div>
  );
};

