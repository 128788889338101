import React from "react";
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Typography, useTheme } from "@mui/material";

const Icon = styled('img')(({ theme: { breakpoints } }) => `
  cursor: pointer;
  margin: 20px 0;
  ${[breakpoints.down('sm')]} {
    margin:0 0 20px 0;
  }
`);

export const GridItem = ({ cardImage, cardTitle, cardDescription, index}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box p={{xs:'126px 20px 95px 20px', sm:'11em 4.25em'}}>
      {mobile ?
        <Icon
          src={cardImage}
          alt="Icon"
          height="53px"
          width="53px"
          data-testid="cardIcon"
        />:
        <Icon
          src={cardImage}
          alt="Icon"
          height="60px"
          width="60px"
          data-testid="cardIcon"
        /> }
      <Typography variant={'h3'} p='5px 0' id={'cardTitle' + index} data-testid={"cardTitle" + index}>{cardTitle}</Typography>
      <Typography variant={'p1'} sx={{cursor:'pointer'}} p='5px 0' data-testid={"cardDescription" + index}>{cardDescription}</Typography>
    </Box>
  );
};