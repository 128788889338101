import { ModalComponent } from "../ModalComponent";
import { PaymentMethods } from "./PaymentMethods";

export const PaymentMethodsModal = ({openModal,
                                        closeModal,
                                        paymentList,
                                        handleAddNewPaymentMethod,
                                        handlePaymentMethodSelection,
                                        handleSetAsDefaultPayment,
                                        handleDeletePaymentMethod
                                    }) => {
    return (
        <ModalComponent
            openModal={openModal}
            closeModalHandler={closeModal}
            style={{
                width: 'fit-content',
                minWidth:'400px',
                padding: '32px',
            }}
            modalContent={
                <PaymentMethods 
                    paymentList={paymentList}
                    handleAddNewPaymentMethod={handleAddNewPaymentMethod}
                    handlePaymentMethodSelection={handlePaymentMethodSelection}
                    closeModal={closeModal}
                    handleSetAsDefaultPayment={handleSetAsDefaultPayment}
                    handleDeletePaymentMethod={handleDeletePaymentMethod}
                />
            }
        />
    )
}