import * as React from "react";
import { styled } from "@mui/material/styles";
import { Button } from "../Button";
import { useHistory } from "react-router";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

const ContactUsContainer = styled('section')(
  ({ backgroundImgUrl, theme: { palette: { secondary } } }) => `
    background: ${secondary.main} 0% 0% no-repeat padding-box;
    padding: 90px;
    text-align: center;
    background-image: url(${backgroundImgUrl});
    background-size: cover;
`);


export const ContactUs = ({ data }) => {
  const history = useHistory();
  const locale = useSelector((state) => state.globalStates.locale);
  const click = async () => {
  }
  const contactUsBtnClicked = () => {
    history.push(`/${locale}/contact-us`)
  }
  const buttonHref = `/${locale}/contact-us`

  const { fields } = data;
  const backgroundImageUrl = fields?.backgroundImage?.fields?.file?.url;
  return (
    <ContactUsContainer backgroundImgUrl={backgroundImageUrl}>
      <Typography variant={'h3'} textAlign='center' mb='30px' color='primary.main'>{fields?.contactUsMessage}</Typography>
      <Button onClick={contactUsBtnClicked} data-link-href={buttonHref}>{fields?.contactUsButtonLabel}</Button>
    </ContactUsContainer>
  );
};
