"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeTradeDesk = void 0;
let hasTradeDeskInitialized = false;
const initializeTradeDesk = (tradeDeskAdvertiser, tradeDeskPixelId) => {
    // The script can only be initialized once and can't be turned off
    if (!hasTradeDeskInitialized) {
        hasTradeDeskInitialized = true;
        const script = document.createElement('script');
        script.onload = () => {
            // @ts-ignore
            window.ttd_dom_ready(() => {
                // @ts-ignore
                if (typeof TTDUniversalPixelApi === 'function') {
                    // @ts-ignore
                    const universalPixelApi = new TTDUniversalPixelApi();
                    universalPixelApi.init(tradeDeskAdvertiser, [tradeDeskPixelId], 'https://insight.adsrvr.org/track/up');
                }
            });
        };
        script.src = 'https://js.adsrvr.org/up_loader.1.1.0.js';
        document.head.appendChild(script);
    }
};
exports.initializeTradeDesk = initializeTradeDesk;
