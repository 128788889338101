import { createSlice } from "@reduxjs/toolkit";
import { loadGlobalMessage } from "./globalMessageThunkApi";

const initialState = {
    globalSuccessMessage: {},
    globalErrorMessage: {},
    loaded: false
}

export const globalMessageSlice = createSlice({
    name: "globalMessages",
    initialState,
    reducers: {
        clearGlobalMessageStore: (state, action) => {
            return {
                ...initialState
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadGlobalMessage.fulfilled, (state, { payload }) => {
                state.globalErrorMessage = payload?.errorMessages
                state.globalSuccessMessage = payload?.successMessages
                state.loaded = true
            })

    }
})

export const { clearGlobalMessageStore } = globalMessageSlice.actions;

export default globalMessageSlice.reducer