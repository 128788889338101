import React from "react";
import { styled } from '@mui/material/styles';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Typography, useTheme } from "@mui/material";
import { BLOCKS } from '@contentful/rich-text-types';
import { Button } from "../Button";
const CustomHr = styled(Typography)`
  color: red;
`;

export const NoAccountMatch = ({ staticContent, handleNext }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Typography variant={'p1'} color={'primary.main'} component={'p'} m={'30px 0'}>{children}</Typography>,
    }
  };
  const isUnvalidatedRegistrationEnabled = process.env.IS_UNVALIDATED_REGISTRATION_ENABLED;

  return (
      <div>
        {desktop ? <Box component={'img'} m='20px auto' display='flex' src={staticContent?.noAccountsIcon?.fields?.file?.url} width="90px" height="90px"></Box> :
            <Box m='20px auto' display='flex' src={staticContent?.noAccountsIcon?.fields?.file?.url} width="114" height="114"></Box>}
        <Typography variant={'h1'} textAlign='center' color='primary.main' mt={{xs:'20px', sm:'0'}} id="headline">{staticContent?.noAccountsHeadline}</Typography>
        <Typography variant={'p1'} component={'div'} textAlign='center' mt='20px' mb='60px' id="subtitle">{documentToReactComponents(staticContent?.noAccountsMessage, options)}</Typography>
        <Box display='flex' justifyContent='space-evenly' width='40%' margin='40px auto'>
          {isUnvalidatedRegistrationEnabled === 'true' ? <Button buttonType="primary" onClick={handleNext}>{staticContent?.nextButtonLabel}</Button> : null}
        </Box>
      </div>
  )
};