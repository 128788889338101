import commerceAuthAxiosInstance from "../sevices/commerceAuthAxiosInstance";

export const createCartApi = async (userId) => {
  try {
    const res = await commerceAuthAxiosInstance({
      url: `users/${userId}/carts`,
      method: "POST",
      params: {
        userId: userId
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const addToCartApi = async (userId, cartId, cartData) => {
  try {
    const res = await commerceAuthAxiosInstance({
      url: `users/${userId}/carts/${cartId}/entries`,
      method: "POST",
      data: JSON.stringify(cartData),
      params: {
        userId: userId
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const fetchCartApi = async (userId, cartId) => {
  try {
    const res = await commerceAuthAxiosInstance({
      url: `users/${userId}/carts/${cartId}?fields=DEFAULT`,
      method: "GET",
      params: {
        userId: userId
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const removeFromCartApi = async (userId, cartId, entryNumber) => {
  try {
    const res = await commerceAuthAxiosInstance({
      url: `users/${userId}/carts/${cartId}/entries/${entryNumber}`,
      method: "DELETE",
      params: {
        userId: userId
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateCartApi = async (userId, cartId, entryNumber, updatedData) => {
  try {
    const res = await commerceAuthAxiosInstance({
      url: `users/${userId}/carts/${cartId}/entries/${entryNumber}`,
      method: "PATCH",
      data: JSON.stringify(updatedData),
      params: {
        userId: userId
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const updatePreBookStatusApi = async (userId, cartId, preBookStatus, updatedData) => {
  try {
    const res = await commerceAuthAxiosInstance({
      url: `users/${userId}/carts/${cartId}/updateStatus?action=${preBookStatus}&fields=DEFAULT`,
      method: "PATCH",
      data: JSON.stringify(updatedData),
      params: {
        userId: userId
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getCartsApi = async (userId) => {
  try {
    const res = await commerceAuthAxiosInstance({
      url: `/users/${userId}/carts`,
      method: "GET",
      params: {
        userId: userId
      }
    })
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const cancelReservationApi = async (userId, cartId) => {
  try {
    const res = await commerceAuthAxiosInstance({
      url: `users/${userId}/carts/${cartId}/updateStatus?action=cancelled&fields=DEFAULT`,
      method: "PATCH",
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const editReservationApi = async (userId, cartId) => {
  try {
    const res = await commerceAuthAxiosInstance({
      url: `users/${userId}/carts/${cartId}/edit?fields=DEFAULT`,
      method: "POST",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const discardEditApi = async (userId, cartId) => {
  try {
    const res = await commerceAuthAxiosInstance({
      url: `users/${userId}/carts/${cartId}/discard`,
      method: "POST",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const saveEditCartApi = async (userId, cartId) => {
  try {
    const res = await commerceAuthAxiosInstance({
      url: `users/${userId}/carts/${cartId}/saveEditCart`,
      method: "POST",
    });
    return res;
  } catch (error) {
    throw error;
  }
};


