import CustomCard from "../../../CustomCardComponent/CustomCard";
import { TypographyH2 } from "../../../../assets/typographyTheme";
import { Button } from "../../../Button";
import MapIcon from "../../../../assets/images/mapIcon.svg";
import { Box, Typography } from "@mui/material";
import { ADDRESS_TYPE } from "../../../../constants";
import { AddressCard } from "../../../AddressCard/AddressCard";
import {useState} from "react";
import AffiliateOrgConfirmationModal from "../AffiliateOrgConfirmationModal";
import ShippingAddressesModal from "../ShippingAddressesModal";
import { getLocations } from "../../../../utils/UserService/UserApis";
import { getUserErrorMessages } from "../../../../utils/getErrorMessage";

export const MatchedOrgCards = ({ orgInfo, handleErrorMsg, ...props }) => {
    //TBD: replace this once filtering is done on the BE
    const billToAddress = orgInfo?.organization?.addresses?.find(address => address?.addressType === ADDRESS_TYPE.BILL_TO) ?? null;
    const soldToAddress = orgInfo?.organization?.addresses?.find(address => address?.addressType === ADDRESS_TYPE.SOLD_TO) ?? null;
    const orgId= orgInfo?.organization?.id;
    const dbaName= orgInfo?.organization?.dbaName;
    const [openAffiliateOrgConfirmationModal, setOpenAffiliateOrgConfirmationModal] = useState(false);
    const [openShippingAddressesModal, setOpenShippingAddressesModal] = useState(false);
    const [shippingLocations, setShippingLocations] = useState([])
    const [processing, setProcessing] = useState(false)

    const closeConfirmationModal = () => {
        setOpenAffiliateOrgConfirmationModal(false)
    }

    const closeShippingAddressesModal = () => setOpenShippingAddressesModal(false);

    const handleViewShippingAddresses = async (orgId) => {
        try {
            setProcessing(true)
            setOpenShippingAddressesModal(true);
            const response = await getLocations(orgId);
            if (response?.status === 200) {
                setShippingLocations(response?.data?.organization?.locations)
            }
        } catch (error) {
            const errorMessage = getUserErrorMessages(error?.response?.data?.errorCode);
            handleErrorMsg(errorMessage)
        } finally {
            setProcessing(false)
        }
    }

    return(
        <CustomCard
            sx={{
                padding: "16px 24px 24px 24px",
                marginBottom: "24px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
            }}
        >
            {openShippingAddressesModal &&
                <ShippingAddressesModal
                    openModal={openShippingAddressesModal}
                    closeModal={closeShippingAddressesModal}
                    shippingLocations={shippingLocations}
                    dbaName={dbaName}
                    processing={processing}
                />
            }
            <TypographyH2 mb={'16px'}>
                {dbaName}
            </TypographyH2>
            <Box display="flex" minWidth={'600px'} mb={'16px'} alignItems="flex-start">
                <AddressCard address={soldToAddress}
                             dbaName={dbaName}
                             addressType={ADDRESS_TYPE.SOLD_TO}
                             hasTitle={true}
                             sx={{width: '326px'}}
                />
                <AddressCard address={billToAddress}
                             dbaName={dbaName}
                             addressType={ADDRESS_TYPE.BILL_TO}
                             hasTitle={true}
                             sx={{width: '326px'}}
                />
            </Box>

            <Button
                buttonType="link"
                data-testid='viewShippingAddressBtn'
                sx={{ textTransform: "none", marginBottom: '24px'}}
                onClick={() => {handleViewShippingAddresses(orgInfo?.organization?.id)}}
                leftIcon={MapIcon}
            >
                <Typography fontSize={'16px'}>
                    View Shipping Addresses
                </Typography>
            </Button>

            <Box
                style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Button
                    sx={{ padding: "16px 24px", textTransform: "none", }}
                    buttonType="mds-outline"
                    data-testid='confirmOrgBtn'
                    onClick={() => { setOpenAffiliateOrgConfirmationModal(true) }}
                >
                    <Typography fontWeight={700}>
                        {dbaName} is my organization
                    </Typography>
                </Button>
            </Box>
            { openAffiliateOrgConfirmationModal &&
                <AffiliateOrgConfirmationModal
                    openModal={openAffiliateOrgConfirmationModal}
                    closeModal={closeConfirmationModal}
                    soldToAddress={soldToAddress}
                    billToAddress={billToAddress}
                    orgId={orgId}
                    dbaName={dbaName}
                    handleErrorMsg={handleErrorMsg}
                    {...props}
                />
            }
        </CustomCard>

    )
}
