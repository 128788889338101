import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchCartApi, removeFromCartApi, updateCartApi, updatePreBookStatusApi, saveEditCartApi, discardEditApi } from "../../utils/ReservationApis";
import { styled } from '@mui/material/styles';
import { PreBookPageHeader, PreBookPageFooter, Modal, BackComponent, AlertMessage, ComboSelect } from "../";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import { Typography, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useTheme } from "@mui/material/";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { HandlerBarTemplateHandler } from "../../utils/Helper";
import useMediaQuery from '@mui/material/useMediaQuery';
import { PreBookStickyFooter } from "../PreBookStickyFooter";
import { RouterPrompt } from "../RouterPrompt";
import { getBaseStoreDataApi } from "../../features/commerce/commerceThunkApi";
import { changeDateFormat } from "../../utils/Helper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCartComponentStaticContent } from "../../features/contentful/contentfulThunkApi";
// import TextField from '@mui/material/TextField';

const CustomTableCell = styled(TableCell)(({ theme: { palette: { primary }, breakpoints }}) => `
  font-weight: 600 !important;
  border-bottom: 2px solid ${primary.main} !important;
  text-transform: uppercase;
`);

const CustomTableBodyCell = styled(TableCell)`
  height: 100px !important;
`;

const ProductImage = styled('img')`
  width:100%;
`;

const DeleteIconStyle = styled('img')`
  width: 21px;
  cursor: pointer;
`;

const TotalContainer = styled('div')(({ theme: { breakpoints } }) => `
  text-align: right;
  margin-top: 10px;
  padding-right: 5%;
  ${[breakpoints.down('sm')]} {
    text-align: left;
    padding-right: 0;
  };
  &&.edit {
    text-align: right;
  };
  &&.details {
    text-align: right;
  }
`);

const CustomContainer = styled(Container)(({ theme: { breakpoints } }) => `
  width:70% !important;
  ${[breakpoints.down('sm')]} {
    width:90% !important;
  }
`);

const MobileRow = styled('div', {shouldForwardProp: () => true})`
  display: flex;
  flex-direction: row;
`
const MobileColumn = styled('div')`
  display: flex;
  flex-direction: column;
`

const AddLink = styled('a')(({ theme: { palette: { secondary } }}) => `
  margin-left:10px;
  color:${secondary.textLinks};
  cursor: pointer;
  font-family: 'Aeonik Regular', 'Arial';
`);

const AddButton = styled('div')`
  display: inline-flex;
  align-items: center;
  height: fit-content;
  cursor:pointer;
  margin-top: 10px;
`
const TotalDetailsContainer = styled('div')(({ theme: { breakpoints } }) => `
  display:flex;
  justify-content: space-between;
  align-items: center;
  ${[breakpoints.down('sm')]} {
    padding-top: 16px;
    flex-direction: column;
    align-items: unset;
  }
`)

// const StyledTextInput = styled(TextField)(({ theme: { palette: { neutral, status } }}) => ({
//   "& label": {
//     fontFamily: "Aeonik Regular, Arial",
//     "&.Mui-focused": {
//       color: neutral.black,
//     },
//     "&.Mui-error": {
//       color: status.error,
//     },
//   },

//   "& .MuiInput-underline": {
//     "&:after": {
//       borderBottomColor: neutral.gray2,
//     },
//   },
// });


export const CartComponent = ({ cart, updateProgressValue, userId, cartId, pageType, ...props }) => {
  const history = useHistory();
  const [cartData, setCartData] = useState([]);
  const { cartComponentContent: content} = useSelector(store => store?.contentful)
  const { baseStoreData } = useSelector((store) => store?.commerce);
  const { cartComponentStaticContent , staticContent } = content;
  const [totalPriceData, setTotalPriceData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [removeProductEntryId, setRemoveProductEntryId] = useState("");
  const [preBookResponse, setPreBookResponse] = useState({});
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [totalDoses, setTotalDoses] = useState(0);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [modalContent, setModalContent] = useState({});
  const [processing, setProcessing] = useState(false);
  const [showPrompt, setShowPrompt] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [cutOffMsg, setCutoffMsg] = useState('');
  // const [dosesEnteredFieldValue, setDosesEnteredFieldValue] = useState({});
  const [removeConfirmationDataAttr, setRemoveConfirmationDataAttr] = useState({});
  const [dropDownError, setDropDownError] = useState([]);
  const [lastEventData, setLastEventData] = useState({});

  const dispatch = useDispatch();
  const alertRef = useRef(null);

  const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const locale = useSelector((state) => state.globalStates.locale);
  const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);

  const clickHandler = async () => {
    const preBookStatus = "prebook";
    const updatedData = {
      userId: userId,
      cartId: cartId,
      action: preBookStatus,
    };
    try {
      setProcessing(true);
      const res = await updatePreBookStatusApi(userId, cartId, preBookStatus, updatedData);
      if (res?.status === 200) {
        setProcessing(false);
        updateProgressValue(100);
      }
    } catch (err) {
      setErrorMsg(generalApiError);
      alertRef.current?.openAlert()
      setProcessing(false);
    }

  };

  useEffect(() => {
    dispatch(loadCartComponentStaticContent({ pageType: pageType, title: `Reservation Cart Review - ${localeISOCode}`, title1: `Detailed Reservation View Component - ${localeISOCode}`} ))
  }, [pageType]);

  useEffect(() => {
    if (Object.keys(baseStoreData).length === 0) {
      dispatch(getBaseStoreDataApi({ baseSiteId: locale === 'en-DE' ? 'modernaDirect-DE' : 'modernaDirect-US' }));
    }
  }, [baseStoreData])

  const fetchCartData = async (userId, cartId) => {
    try {
      const res = await fetchCartApi(userId, cartId);
      if (res?.status === 200) {
        const { entries, totalPrice } = res?.data;
        setCartData(entries);

        setTotalPriceData(totalPrice);
        setPreBookResponse(res?.data);
        props?.setProductsData && props?.setProductsData(entries);
        let totalQuantityDoses = 0;
        entries.forEach(el => {
          totalQuantityDoses += el.product.dosesPerCarton * el.quantity;
        });
        setTotalDoses(totalQuantityDoses);
        props?.setTotalDoses && props?.setTotalDoses(totalQuantityDoses);
        props?.setTotalPrice && props?.setTotalPrice(totalPrice);
        if (pageType === "edit" && localStorage.getItem("saveChangeBtn") !== null) {
          setDisableSaveChanges(false);
          localStorage.removeItem("saveChangeBtn")
        }
      }
    } catch (error) {
      if (pageType === "edit") {
        localStorage.setItem("error", error?.response?.data?.errors[0]?.message);
        history.push(`/${locale}/manage-reservations`);
      } else {
        setErrorMsg(generalApiError);
        alertRef.current?.openAlert()
      }
    }
  };

  useEffect(() => {
    if (cartId && userId) {
      window.scrollTo(0, 0);
      fetchCartData(userId, cartId);
    }
  }, [cartId, userId]);

  useEffect(() => {
    if (disableSaveChanges) {
      setShowPrompt(false);
    } else {
      setShowPrompt(true);
    }
  }, [disableSaveChanges]);

  useEffect(() => {
    const cutOff = async () => {
      try {
        const cutoffDate = baseStoreData?.cutoffDate
        const remainingDaysMsg = HandlerBarTemplateHandler({reservationCutoffDate: changeDateFormat(cutoffDate)+'.' }, staticContent?.fields?.reservationCutoffMessage) || null;
        setCutoffMsg(remainingDaysMsg);
      } catch(error) {
        setErrorMsg(generalApiError);
        alertRef.current?.openAlert()
       }
    };
    cutOff();
  });

  const getDosesOptions = (dosesPerCarton) => {
    let doses = [
      { label: "1", value: 10 },
      { label: "2", value: 20 },
      { label: "3", value: 30 },
      { label: "4", value: 40 },
      { label: "5", value: 50 },
    ];
    let dosesOptions = doses.map((dose, i) => {
      return {
        label: (dose.value * dosesPerCarton).toString(),
        value: dose.value * dosesPerCarton,
      };
    });
    return dosesOptions;
  };
  const selectChangeHandler = async (e, value, productId, entryNumber, dosesPerCarton) => {
    try {
      if (lastEventData?.value !== value?.value || lastEventData?.productId !== productId) {
        let eventData = {};
        let res;
        if (value.value !== 0) {
          const updatedData = {
            product: {
              code: productId,
            },
            quantity: value.value / dosesPerCarton,
          };
          if (pageType === "edit") {
            if (e.type === 'click' || e.type === 'keyup' || e.type === 'blur') {
              setDisableSaveChanges(true)
            } else {
              return;
            }
          }
          res = await updateCartApi(userId, cartId, entryNumber, updatedData);
          if (res?.status === 200) {
            await fetchCartData(userId, cartId);
            if (pageType === "edit") {
              setDisableSaveChanges(false);
            }
            eventData.value = value.value;
            eventData.productId = productId;
            setLastEventData(eventData)
          }
        }
      }
    } catch (error) {
      setErrorMsg(generalApiError);
      alertRef.current?.openAlert()
    }
  };
  const selectBlurHandler = async (event, dosesPerCarton, productId, enteryNumber) => {
    let dropDownValue = (Math.ceil(parseInt(event.target.value) / parseInt(dosesPerCarton))) * parseInt(dosesPerCarton);
    selectChangeHandler(event, { "label": dropDownValue.toString(), "value": dropDownValue }, productId, enteryNumber, dosesPerCarton)
    if (event.target.value === 0) {
      setDropDownError([...dropDownError, productId]);
    } else {
      const dropDownErrorIds = [...dropDownError];
      const index = dropDownErrorIds.indexOf(productId);
      dropDownErrorIds.splice(index, 1);
      setDropDownError(dropDownErrorIds);
    }
  }
  const selectKeyUpHandler = async (event, dosesPerCarton, productId, enteryNumber) => {
    if (event.key === "Enter") {
      let dropDownValue = (Math.ceil(parseInt(event.target.value) / parseInt(dosesPerCarton))) * parseInt(dosesPerCarton);
      selectChangeHandler(event, { "label": dropDownValue.toString(), "value": dropDownValue }, productId, enteryNumber, dosesPerCarton)
      if (event.target.value === 0) {
        setDropDownError([...dropDownError, productId]);
      } else {
        const dropDownErrorIds = [...dropDownError];
        const index = dropDownErrorIds.indexOf(productId);
        dropDownErrorIds.splice(index, 1);
        setDropDownError(dropDownErrorIds);
      }
    }
  }

  // const inputFieldChangeHandler = (event, productId) => {
  //   let matchdData = event.target.value ? event.target.value.match(/(\d+)/) : null;
  //   let entredValue = matchdData !== null ? matchdData[0] : '';
  //   let enterData = {
  //     productId: productId,
  //     value:entredValue,
  //     error:entredValue.length === 0 || entredValue == 0
  //   } 
  //   setDosesEnteredFieldValue(enterData);
  // }

  // const inputBlurHandler = async (event, dosesPerCarton, productId, entryNumber) => {
  //   try {
  //       let res;
  //       const enteredValue = event.target.value;
  //       if (enteredValue && enteredValue > 0) {
  //         const updatedData = {
  //           product: {
  //             code: productId,
  //           },
  //           quantity: Math.ceil(enteredValue / dosesPerCarton),
  //         };
  //         if (pageType === "edit") {
  //           if (event.type === 'click' || event.type === 'keyup' || event.type === 'blur') {
  //             setDisableSaveChanges(true)
  //           } else {
  //             return;
  //           }
  //         }
  //         res = await updateCartApi(userId, cartId, entryNumber, updatedData);
  //         if (res?.status === 200) {
  //           await fetchCartData(userId, cartId);
  //           if (pageType === "edit") {
  //             setDisableSaveChanges(false);
  //           }
  //           setDosesEnteredFieldValue({});
  //         }
  //       }else{
  //         setDosesEnteredFieldValue({});
  //         await fetchCartData(userId, cartId);
  //       }
  //   } catch (error) {
  //     setErrorMsg(generalApiError);
  //     alertRef.current?.openAlert()
  //   }
  // }

  const cancelModal = () => {
    setRemoveProductEntryId("");
    setOpenModal(false);
    setShowPrompt(true);
    setRemoveConfirmationDataAttr({})
  };

  const removeFromCart = async () => {
    try {
      const response = await removeFromCartApi(userId, cartId, removeProductEntryId);
      if (response.status === 200) {
        fetchCartData(userId, cartId);
        if ((pageType !== "edit") && (cartData.length === 1)) {
          backButtonAction();
        }
        else if ((pageType === "edit") && (cartData.length === 1)) {
          props?.openProductSelection(true);
        }
        else {
          setRemoveProductEntryId("");
          setOpenModal(false);
          if (pageType === "edit") {
            setDisableSaveChanges(false);
          }
        }
      }
    } catch (error) {
      setErrorMsg(generalApiError);
      alertRef.current?.openAlert()
    }
  };

  const deleteClickHandler = (entryId) => {
    setRemoveProductEntryId(entryId);
    setModalContent({
      modalText: cartComponentStaticContent?.fields?.removeProductPromptHeader,
      primaryButtonLabel: cartComponentStaticContent?.fields?.removeProductPromptProceedButtonLabel,
      secondaryButtonLabel: cartComponentStaticContent?.fields?.removeProductPromptCancelButtonLabel,
      actionType: "delete"
    });
    setOpenModal(true);
    let data = cartData.length > 0 && cartData.filter((data, index) => data?.entryNumber === entryId)
    data?.length > 0 && setRemoveConfirmationDataAttr({
      product_id:data[0]?.product?.code, 
      product_name: data[0]?.product?.name,
      product_detail: `${data[0]?.product?.summary}|${data[0]?.product?.ageGroup}`,
    })
  };

  const backButtonAction = () => {
    if (pageType === "details") {
      history.push(`/${locale}/manage-reservations`);
    } else if (pageType === "edit") {
      history.goBack();
    }
    else {
      updateProgressValue(33)
    }
  }

  /**
   * UPDATE THIS IF YOU CHANGE backButtonAction to keep them consistent please. 
   */
  const backButtonHref = pageType === "details" ? `/${locale}/manage-reservations` : null; //For Analytics

  const saveChanges = async () => {
    setShowPrompt(false)
    try {
      setProcessing(true)
      const res = await saveEditCartApi(userId, cartId);
      if (res?.status === 200) {
        setProcessing(false)
        const { code } = res?.data;
        localStorage.setItem("isSaved", true);
        history.push(`/${locale}/view-reservations/${code}`);
      }
    } catch (err) {
      setProcessing(false)
      setErrorMsg(generalApiError);
      alertRef.current?.openAlert()
    }
  }

  const discardChanges = async () => {
    setModalContent({
      modalText: cartComponentStaticContent?.fields?.discardPromptHeader,
      primaryButtonLabel: cartComponentStaticContent?.fields?.discardPromptProceedButtonLabel,
      secondaryButtonLabel: cartComponentStaticContent?.fields?.discardPromptCancelButtonLabel,
      actionType: "discard"
    });
    setOpenModal(true);
  }

  const discardEdiCartChanges = async () => {
    try {
      const res = await discardEditApi(userId, cartId);
      if (res?.status === 200) {
        const { code } = res?.data;
        localStorage.removeItem("newCartId")
        history.push(`/${locale}/view-reservations/${code}`);
      }
    } catch (error) {
      setErrorMsg(generalApiError);
      alertRef.current?.openAlert()
    }
  }

  const getImageUrl = (imagesData) => {
    const imageFilteredData = imagesData.filter((data) => data?.imageType === "PRIMARY");
    return imageFilteredData[0]?.url;
  }

  const savingText = HandlerBarTemplateHandler({ totalDiscounts: preBookResponse?.totalDiscounts?.formattedValue, gpoName: preBookResponse?.gpoDetail?.gpoName }, staticContent?.fields?.savingsText) || null;
  
  return (
    <>
      <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
      {mobile ? "" : <BackComponent onClickHandler={backButtonAction} {...(backButtonHref&& {"data-link-href": backButtonHref})} />}
      {/* <Prompt when={!disableSaveChanges && !hidePrompt} 
              message={()=>"There are Some Unsaved Changes. Do you want to go Away?"}></Prompt> */}
      {pageType === "edit" && !openModal ? <RouterPrompt
        when={showPrompt}
        onOK={() => true}
        onCancel={() => false}
        title={cartComponentStaticContent?.fields?.discardPromptHeader}
        primaryButtonLabel={cartComponentStaticContent?.fields?.discardPromptProceedButtonLabel}
        secondaryButtonLabel={cartComponentStaticContent?.fields?.discardPromptCancelButtonLabel}
        closeModalHandler={cancelModal}
        confirmationHandler={discardEdiCartChanges}
        openModal={!showPrompt}
      /> :
        <Modal
          title={modalContent?.modalText}
          primaryButtonLabel={modalContent?.primaryButtonLabel}
          secondaryButtonLabel={modalContent?.secondaryButtonLabel}
          closeModalHandler={cancelModal}
          confirmationHandler={modalContent?.actionType === "delete" ? removeFromCart : discardEdiCartChanges}
          openModal={openModal}
          primaryButtonAttributes={removeConfirmationDataAttr}
        />}

      <CustomContainer data-testid="cartComponentContainer">
        {pageType === "details" || pageType === "edit"
          ? <PreBookPageHeader
            setShowPrompt={setShowPrompt}
            pageType={pageType}
            reservationDetails={{
              cutOffMsgTemplate: staticContent?.fields?.reservationCutoffDateCountdownMessage,
              orderStatus: preBookResponse?.status, orderCreatedDate: preBookResponse?.creationDate,
              orderModifiedDate: preBookResponse?.modifiedDate,
              orderCancelledDate: preBookResponse?.cancellationDate,
              dateModifiedLabel: staticContent?.fields?.dateModifiedLabel,
              datePlacedLabel: staticContent?.fields?.datePlacedLabel,
              dateCanceledLabel: staticContent?.fields?.dateCanceledLabel,
              canceledStatusMessage: staticContent?.fields?.canceledStatusMessage,
              confirmationNumberLabel: staticContent?.fields?.confirmationNumberLabel,
              confirmationNumber: preBookResponse?.code,
              originalCartId: props?.originalCartId,
              userId: userId,
              modified: preBookResponse?.modified,
            }}
            editReservationHandler={props?.editReservationHandler}
          />
          : <PreBookPageHeader pageInfoHeading={staticContent?.fields?.headline} hoverText={staticContent?.fields?.gpoAffiliationHelpMessage} pageInfoDescription={cutOffMsg} gpoAffiliationMessage={staticContent?.fields?.gpoAffiliationMessage} />}
        {mobile ?
          <div>
            {cartData &&
              cartData.length > 0 &&
              cartData.map((row) => (
                <MobileRow style={{ padding: "40px 0px", borderBottom: "1px solid #c0c0c0" }} key={row?.product?.code} product_id={row?.product?.code} product_name={row?.product?.name} product_detail={row?.product?.summary + "|" + row?.product?.ageGroup}>
                  <div id={"img" + row?.product?.code} style={{ paddingRight: "14px" }}><ProductImage src={getImageUrl(row?.product?.images)} /></div>
                  <MobileColumn><div id={"productName" + row?.product?.code} style={{ paddingBottom: "10px", fontFamily: "Aeonik Medium" }}>{row?.product?.name}</div>
                    <div id={"doseVolume" + row?.product?.code} style={{ paddingBottom: "23px", fontSize: "14px" }}>{`${row?.product?.summary} (${row?.product?.ageGroup})`}</div>
                    <MobileRow style={{ alignItems: "center", paddingBottom: "24px", justifyContent: "space-between" }}>{pageType === "details" ?
                      (<div id={"doses" + row?.product?.code} style={{ fontSize: "14px" }}><span style={{ fontWeight: "bold" }}>{row?.quantity * row?.product?.dosesPerCarton} doses</span> / {row?.quantity} cartons</div>) : (
                        <ComboSelect
                          variant={localeISOCode === "DE" ? "square" : "round"}
                          inputProps={{ className: "selectDoses" }}
                          sx={{
                            "@media screen and (max-width: 500px)": {
                              width: 130,
                              height: 48,
                            }
                          }}
                          options={getDosesOptions(row?.product?.dosesPerCarton)}
                          defaultValue={dropDownError?.includes(row?.product?.code) ? { label: "0", value: 0 } : {
                            label: (row?.quantity * row?.product?.dosesPerCarton).toString() || "0",
                            value: row?.quantity * row?.product?.dosesPerCarton || 0,
                          }}
                          error={dropDownError?.includes(row?.product?.code)?.toString()}
                          onChangeHandler={(e, value) => {
                            selectChangeHandler(e, value, row?.product?.code, row.entryNumber, row?.product?.dosesPerCarton);
                          }}
                          onBlurHandler={(event) => selectBlurHandler(event, row?.product?.dosesPerCarton, row?.product?.code, row?.entryNumber)}
                          onKeyUpHandler={(event) => selectKeyUpHandler(event, row?.product?.dosesPerCarton, row?.product?.code, row?.entryNumber)}
                        />)}
                        {/* <StyledTextInput
                            inputProps={{ className: "selectDoses" }}
                            id={`dosesField_${row?.product?.code}`}
                            name={`dosesField_${row?.product?.code}`}
                            variant="standard" 
                            placeholder="[Qty]"
                            focused
                            value={dosesEnteredFieldValue && dosesEnteredFieldValue?.productId ===  row?.product?.code ? dosesEnteredFieldValue?.value : row?.quantity * row?.product?.dosesPerCarton}
                            onBlur={(event) => {
                                inputBlurHandler(event, row?.product?.dosesPerCarton, row?.product?.code, row?.entryNumber)
                            }}
                            onChange={(event) => {
                              inputFieldChangeHandler(event, row?.product?.code);
                            }}
                            error={ dosesEnteredFieldValue && dosesEnteredFieldValue?.productId ===  row?.product?.code ? dosesEnteredFieldValue?.error : false }
                         /> */}
                      
                      {pageType !== "details" &&
                        <div style={{ fontSize: "14px" }}>{row?.quantity} cartons</div>}
                      {
                        pageType !== "details" &&
                        <DeleteIconStyle src={DeleteIcon} style={{ width: "24px", height: "24px" }} onClick={() => deleteClickHandler(row?.entryNumber)} />
                      }
                    </MobileRow>
                    { baseStoreData && !baseStoreData?.hidePrice && 
                      <>
                        <Typography id={"totalPrice" + row?.product?.code} variant="caption" display="block" gutterBottom sx={{ color: 'primary.main', fontSize: "16px", fontFamily: "Aeonik Medium", marginBottom: "-2px" }}>
                          {row?.totalPrice?.formattedValue || 0.0}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom sx={{ color: "primary.main", fontSize: "12px", fontFamily: "Aeonik Regular", marginBottom: "-2px" }}>
                          <span id={"perDosePrice" + row?.product?.code}>{row?.dosePrice && `${row?.dosePrice?.formattedValue} / dose`} </span>
                          <span id={"savingsPerDose" + row?.product?.code} style={{ textDecoration: "line-through", padding: "0px !important", fontSize: "10px", color: "primary.main", opacity: "40%", fontFamily: "Aeonik Regular", width: "8%" }}>{row?.discountedPrice && `${row?.defaultDosePrice?.formattedValue} / dose`}</span>
                        </Typography>
                        <Typography id={"perCartonPrice" + row?.product?.code} variant="caption" display="block" gutterBottom sx={{ color: "primary.main", fontSize: "12px", fontFamily: "Aeonik Regular" }}>
                          {row?.basePrice && `${row?.basePrice?.formattedValue} / carton`}
                        </Typography>
                      </>
                    }
                  </MobileColumn>
                </MobileRow>))}</div>
          :
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table sx={{ minWidth: 650, border: "none" }} aria-label="simple table">
              <TableHead>
                <TableRow >
                  <CustomTableCell width="15%">{staticContent?.fields?.productColumnLabel}</CustomTableCell>
                  <CustomTableCell></CustomTableCell>
                  <CustomTableCell>{staticContent?.fields?.presentationsColumnLabel}</CustomTableCell>
                  <CustomTableCell>{staticContent?.fields?.dosesColumnLabel}</CustomTableCell>
                  <CustomTableCell>{staticContent?.fields?.unitColumnLabel}</CustomTableCell>
                  { 
                    baseStoreData && !baseStoreData?.hidePrice && 
                    <>
                      <CustomTableCell></CustomTableCell>
                      <CustomTableCell align="right">{staticContent?.fields?.priceColumnLabel}</CustomTableCell>
                    </>
                  }
                  <CustomTableCell></CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
                {cartData &&
                  cartData.length > 0 &&
                  cartData.map((row, index) => (
                    <TableRow className="prodDetail" sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={row?.product?.code} product_id={row?.product?.code} product_name={row?.product?.name} product_detail={row?.product?.summary + "|" + row?.product?.ageGroup}>
                      <CustomTableBodyCell id={"img" + row?.product?.code} component="th" scope="row" sx={{ padding: "10px" }}>
                        <ProductImage src={getImageUrl(row?.product?.images)} />
                      </CustomTableBodyCell>
                      <CustomTableBodyCell id={"productName" + row?.product?.code}
                        sx={{
                          fontFamily: "Aeonik Medium",
                          color: "primary.main",
                          paddingLeft: "0px",
                        }}
                      >
                        {row?.product?.name}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell id={"doseVolume" + row?.product?.code} sx={{ width: "21%" }}>{`${row?.product?.summary} (${row?.product?.ageGroup})`}</CustomTableBodyCell>
                      <CustomTableBodyCell id={"doses" + row?.product?.code}>
                        {pageType === "details" ? (
                          row?.quantity * row?.product?.dosesPerCarton
                        ) : (
                          <ComboSelect
                            variant={localeISOCode === "DE" ? "square" : "round"}
                            inputProps={{ className: "selectDoses" }}
                            label={"Doses"}
                            options={getDosesOptions(row?.product?.dosesPerCarton)}
                            defaultValue={dropDownError?.includes(row?.product?.code) ? { label: "0", value: 0 } : {
                              label: (row?.quantity * row?.product?.dosesPerCarton).toString() || "0",
                              value: row?.quantity * row?.product?.dosesPerCarton || 0,
                            }}
                              error={dropDownError?.includes(row?.product?.code)?.toString()}
                            onChangeHandler={(e, value) => {
                            }}
                              onBlurHandler={(event) => selectBlurHandler(event, row?.product?.dosesPerCarton, row?.product?.code, row?.entryNumber)}
                            onKeyUpHandler={(event) => selectKeyUpHandler(event, row?.product?.dosesPerCarton, row?.product?.code, row?.entryNumber)}
                            />
                        )}
                          {/* <StyledTextInput
                           inputProps={{ className: "selectDoses" }}
                            id={`dosesField_${row?.product?.code}`}
                            name={`dosesField_${row?.product?.code}`}
                            variant="standard" 
                            placeholder="[Qty]"
                            focused
                            value={dosesEnteredFieldValue && dosesEnteredFieldValue?.productId ===  row?.product?.code ? dosesEnteredFieldValue?.value : row?.quantity * row?.product?.dosesPerCarton}
                            onBlur={(event) => {
                                inputBlurHandler(event, row?.product?.dosesPerCarton, row?.product?.code, row?.entryNumber)
                            }}
                            onChange={(event) => {
                              inputFieldChangeHandler(event, row?.product?.code);
                            }}
                            error={ dosesEnteredFieldValue && dosesEnteredFieldValue?.productId ===  row?.product?.code ? dosesEnteredFieldValue?.error : false }
                          /> */}
                          
                      </CustomTableBodyCell>
                      <CustomTableBodyCell id={"cartons" + row?.product?.code}>{row?.quantity}</CustomTableBodyCell>
                      {   
                       baseStoreData && !baseStoreData?.hidePrice &&
                        <>
                          <CustomTableBodyCell id={"savingsPerDose" + row?.product?.code} sx={{ textDecoration: "line-through", padding: "0px !important", fontSize: "10px", color:"primary.main", opacity: "40%", fontFamily: "Aeonik Regular", width: "8%" }}>
                            {row?.discountedPrice && `${row?.defaultDosePrice?.formattedValue} / dose`}
                          </CustomTableBodyCell>
                          <CustomTableBodyCell align="right" width="15%">
                            <Typography id={"totalPrice" + row?.product?.code} variant="caption" display="block" gutterBottom sx={{ color: "primary.main", fontSize: "15px", fontFamily: "Aeonik Medium", marginBottom: "-2px" }}>
                              {row?.totalPrice?.formattedValue || 0.0}
                            </Typography>
                            <Typography id={"perDosePrice" + row?.product?.code} variant="caption" display="block" gutterBottom sx={{ color: "primary.main", fontSize: "9px", fontFamily: "Aeonik Regular", marginBottom: "-2px" }}>
                              {row?.dosePrice && `${row?.dosePrice?.formattedValue} / dose`}
                            </Typography>
                            <Typography id={"perCartonPrice" + row?.product?.code} variant="caption" display="block" gutterBottom sx={{ color: "primary.main", fontSize: "9px", fontFamily: "Aeonik Regular" }}>
                              {row?.basePrice && `${row?.basePrice?.formattedValue} / carton`}
                            </Typography>
                          </CustomTableBodyCell>
                        </>
                      }
                      
                      <CustomTableBodyCell>
                        {
                          pageType !== "details" &&
                          <DeleteIconStyle id={"deleteIcon" + row?.product?.code} src={DeleteIcon} onClick={() => deleteClickHandler(row?.entryNumber)} />
                        }
                      </CustomTableBodyCell>

                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        <TotalDetailsContainer>
          <div>
            {pageType === "edit" && <AddButton id="addProductButton" onClick={() => props?.openProductSelection(true)}>
              <AddCircleOutlineOutlinedIcon sx={{ color:'secondary.graphical' }} />
              <AddLink>Add product</AddLink>
            </AddButton>}
          </div>
          {
            baseStoreData && !baseStoreData?.hidePrice &&  
            <TotalContainer className={pageType}>
              <Typography id="totalLabel" variant="h6" sx={{ color: "primary.main", textTrasform: 'uppercase' }}>
                {staticContent?.fields?.totalLabel}
              </Typography>
              <Typography id="modal-modal-title" variant="h4" gutterBottom sx={{ color: "primary.main" }}>
                {totalPriceData?.formattedValue}
              </Typography>
              <Typography id="savingText" variant="p2" display="block" gutterBottom sx={{ color: "primary.main" }}>
                {preBookResponse?.gpoDetail && savingText}
              </Typography>
            </TotalContainer>
          }
        </TotalDetailsContainer>

      </CustomContainer>
      {
        pageType === "details" || pageType === "edit"
          ?
          <CustomContainer>
            <PreBookPageFooter
              pageType={pageType}
              processing={processing}
              preBookFooterData={{ gpoAffiliationMessage: staticContent?.fields?.gpoAffiliationMessage, gpoName:preBookResponse?.gpoDetail?.gpoName, disclaimerMessage: staticContent?.fields?.disclaimerMessage }}
              footerText={documentToReactComponents(staticContent?.fields?.disclaimerMessage)}
              disableSaveChanges={disableSaveChanges}
              saveChangesHandler={saveChanges}
              actionHandlers={{
                saveChangesHandler: saveChanges,
                discardChangesHandler: discardChanges,
              }}
            />
          </CustomContainer>
          :
          <div>
            <PreBookPageFooter footerText={staticContent?.fields?.disclaimerMessage} />
            <PreBookStickyFooter totalDoses={totalDoses} buttonLabel={staticContent?.fields?.prebookButtonLabel} buttonType={"primary"} onClickHandler={clickHandler} backClickHandler={backButtonAction} processing={processing} 
                                {...(backButtonHref&& {"data-link-href": backButtonHref})}
            />
          </div>
      }
    </>
  );
};
