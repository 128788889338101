import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Typography } from '@mui/material'
import { styled } from "@mui/material/styles";
import CustomCard from "../CustomCardComponent/CustomCard";
import { Button } from '../Button';
import GroupAffiliationSelectionModal from './GroupAffiliationSelectionModal';
import { GPO_MESSAGES } from '../../constants';

const RequiredSymbol = styled('span')({
    color: 'red',
  });

const GroupAffiliationComponent = ({ isAuthenticated, reservationPageContent, productsForPricing, onContractChange }) => {
    const { commerceUser } = useSelector((store) => store?.commerce);
    const GPOAccountFound = !! commerceUser?.data?.orgUnit?.gpoDetails?.length;
    const GPOName = commerceUser?.data?.orgUnit?.defaultGPO?.gpoName
    const locale = useSelector((state) => state.globalStates.locale);
    const history = useHistory();
    const userData = useSelector((store) => store?.commerce?.userData);
    const selectedGpoAffiliatedId = userData?.selectedContract?.id || '';

    const isGpoSelectionModalEnabled = process.env.IS_GPO_SELECTION_MODAL_ENABLED === 'true';
    const [openSelectionModal, setOpenSelectionModal] = useState(false);

    const isGpoAccountFound = () => {
        return isGpoSelectionModalEnabled ? !!userData?.selectedContract?.id : GPOAccountFound;
    }

    const getGpoName = () => {
        return isGpoSelectionModalEnabled ? userData?.selectedContract?.displayName : GPOName;
    }

    const handleConfirm = (selectedPriceContract) => {
        onContractChange(selectedPriceContract);
    };

    const handleChangeGroupAffiliation = () => {
        isGpoSelectionModalEnabled ?
          setOpenSelectionModal(true) :
          history.push(`/${locale}/my-profile/affiliations`);
    };

    return (            
        <Box display="flex" flexDirection="column" mt={3}>
            { isGpoAccountFound() ? (
                <CustomCard
                    sx={{padding: "16px 32px"}}
                >
                    <Box display="flex">
                        <Box mr={2}>
                            <Typography variant='h5' style={{fontSize: '18px'}}>Contract affiliation</Typography>
                        </Box>
                        <Box>
                            <Box display="flex" alignItems="center" mb={1}>
                                <Typography variant='h5' style={{fontSize: '18px'}} mr={1}>
                                    {getGpoName()}
                                </Typography>
                                <Typography
                                    variant="p1"
                                    color='secondary.textLinks'
                                    onClick={ handleChangeGroupAffiliation }
                                    style={{cursor: 'pointer', textDecoration: 'underline', fontSize: '18px'}}
                                >
                                    Change
                                </Typography>
                            </Box>
                            <Typography variant='p1'>{reservationPageContent?.gpoAffiliationMessage}</Typography>

                        </Box>
                    </Box>
                </CustomCard>
            ) : (
                <CustomCard
                    title={'Contract affiliation'}
                    sx={{padding: "16px 32px", display: 'flex', flexDirection: 'column'}}
                >
                    { isGpoSelectionModalEnabled ?
                        <>
                            <Typography fontFamily={'Aeonik Regular'} mt={1}>{GPO_MESSAGES.GPO_MISSING_LINE_1}</Typography>
                            <Typography fontFamily={'Aeonik Regular'} mb={2}>{GPO_MESSAGES.GPO_MISSING_LINE_2}</Typography>
                            <Button buttonType="mds-secondary" 
                                sx={{ width: "260px", gap: "12px", 
                                fontSize: "14px", fontWeight: 700, 
                                textTransform:"none",
                                padding: "16px 24px"
                            }} 

                                onClick={() => setOpenSelectionModal(true)}>
                                    Select a group affiliation
                                    <RequiredSymbol> *</RequiredSymbol>
                            </Button> 
                        </> :
                        <Typography fontFamily={'Aeonik Regular'}>{GPO_MESSAGES.GPO_UNAVAILABLE}</Typography>
                    }
                </CustomCard>
            )}
            { openSelectionModal &&
                <GroupAffiliationSelectionModal
                    openModal={openSelectionModal}
                    closeModal={()=> setOpenSelectionModal(false)}
                    handleConfirm={ handleConfirm }
                    defaultGpoAffiliatedId={selectedGpoAffiliatedId}
                    productsForPricing={productsForPricing}
                />
            }
        </Box>
    )
}

export default GroupAffiliationComponent
