import { Box, Typography } from "@mui/material";
import { Button, ModalComponent } from "../../../components";
import { TypographyH3 } from "../../../assets/fonts/typographyTheme";
import { TypographyP1 } from "../../../assets/typographyTheme";
import { getUserByEmail, updateUserDetailsApi } from "../../../utils/UserService/UserApis";
import { setUserStatus } from "../../../features/globalStates/globalStatesSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getUserErrorMessages } from "../../../utils/getErrorMessage";
import CustomSpinner from "../../Spinner/CustomSpinner";

const spinnerStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    backgroundColor: '#F9F9FA',
    pointerEvents: 'none',
    flexDirection: 'column'
}

const spinnerMessageStyle = {
    fontSize: '24px',
    fontWeight: 400,
}

const OrgNotFoundConfirmationModal = ({ closeModal, openModal, tabIndex, onTabChange, handleErrorMsg, userOrganizationInfo }) => {
    const [processing, setProcessing] = useState(false)
    const dispatch = useDispatch();

    const handleUpdateNoOrgFound = async () => {
        try {
            setProcessing(true)
            const response = await updateUserDetailsApi(userOrganizationInfo);
            if (response?.status === 200) {
                dispatch(setUserStatus(response?.data?.userStatus));
                onTabChange(tabIndex + 1);
                dispatch(getUserByEmail({ emailAddress: response?.data?.email }))
            }
        } catch (error) {
            const errorMessage = getUserErrorMessages(error?.response?.data?.errorCode)
            handleErrorMsg(errorMessage)
        } finally {
            setProcessing(false)
        }
    }

    const OrgNotFoundContent = (
        <Box flexWrap="wrap" gap="23px">
            { processing && <CustomSpinner
                message="Updating Organization Details..."
                sx={spinnerStyles}
                messageSx={spinnerMessageStyle}
            /> }
                <Typography
                fontSize="23px"
                fontWeight="700"
                lineHeight="30px"
                textAlign={"left"}
                paddingBottom={"8px"}
            >
                Confirm your selection
            </Typography>
            <Box>
                <TypographyH3 mb={2}>
                    Are you sure none of the organizations listed is yours?
                </TypographyH3>
                <TypographyP1 mb={6}>
                    Choosing a matching organization will import the associated billing and shipping addresses,
                    medical licenses, and purchasing contract associations, and could expedite the set-up of
                    your account and shipping time for your first order.
                </TypographyP1>
            </Box>

            <Box width="100%" display="flex" justifyContent="center" gap="8px">
                <Button
                    buttonType='mds-secondary'
                    sx={{ padding: "16px 32px", textTransform: "none", }}
                    onClick={closeModal}
                >
                    Cancel
                </Button>
                <Button
                    buttonType='mds-primary'
                    sx={{ padding: "16px 32px", textTransform: "none", }}
                    onClick={handleUpdateNoOrgFound}
                    color='primary'
                    variant="contained"
                >
                    None of the organizations listed are mine
                </Button>
            </Box>
        </Box>
    )

    return (
        <ModalComponent
            openModal={openModal}
            closeModalHandler={closeModal}
            style={{
                width: '675px',
                padding: '24px',
                display: 'flex',
                alignItems: 'flex-start',
                minHeight: '240px',
                textAlign: 'left',
                color:"#191F2A",
            }}
            modalContent={OrgNotFoundContent}
        />
    )
}

export default OrgNotFoundConfirmationModal