import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ModalComponent, Radio, Button, AlertMessage, Spinner } from '../../components';
import warningInfoIcon from '../../assets/images/warningInfoIcon.svg';
import InfoIconImage from "../../assets/images/infoIcon2.svg";
import { fetchEligibleContractsApi } from '../../utils/PricingService/PricingApi';
import { isCommitmentPricing, filterContracts, getContractsToRender } from '../../utils/PricingService/pricingUtils';

const MessageContentStyles = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '18px',
    padding: '12px 16px',
    borderRadius: '0px 8px 8px 0px',
    borderLeft: '2px solid #FDB022',
    background: '#FFF9EC',
};

const RadioContainer = styled(Box)(({ theme: { breakpoints } }) => `
    display: flex;
    margin-right: 8px;
    flex-wrap: wrap;
    flex-direction: column;
    ${[breakpoints.down('sm')]} {
        flex-direction: column;
    }
`)

const CustomTypography = styled(Typography)(() => `
    font-family:"Aeonik Regular";
    font-size: 16px;
    font-weight: 400;
    text-align: left;
`)

const BoldText = styled('span') (() => `
  font-weight: 700;
`);

const NoteContainer = styled(Box)(({ theme: { breakpoints } }) => `
    display: flex;
    background: #F2FAFD;
    border-radius: 8px;
    border: 0.25px solid #079AE0;
    padding: 10px;
    align-items: center;
    gap: 10px;
    width: 100%;
    ${[breakpoints.down('sm')]} {
        flex-direction: column;
    }
`)

const GroupAffiliationSelectionModal = ({ closeModal, openModal, handleConfirm, defaultGpoAffiliatedId, productsForPricing }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedAffiliationId, setSelectedAffiliationId] = useState(defaultGpoAffiliatedId);
  const oktaUserId = useSelector((store) => store?.commerce?.userData?.authenticatedId) || '';
  const userId = useSelector((store) => store?.commerce?.userData?.userId) || '';
  const { priceContracts = []} = useSelector((state) => state?.commerce);
  const userLicenses = useSelector((store) => store?.commerce?.userData?.licenses);

  const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const alertRef = useRef(null);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [processing, setProcessing] = useState(false);

  const alert = (message, type) => {
    setMessage(message);
    setType(type);
  }

  const handleAffiliationSelection = (e) => setSelectedAffiliationId(e.target.value);

  const priceContractsPayload = () => {
    let medicalIdentifiers = userLicenses?.filter(license => license.issuer === 'HIN' || license.issuer === 'DEA').map(license => license.number);

    return {
        "medicalIdentifiers": medicalIdentifiers,
        "productNumbers": productsForPricing
    }
  }

  useEffect(() => {
    const fetchEligibleContracts = async () => {
        try {
            setProcessing(true);
            await dispatch(fetchEligibleContractsApi({ payload: { productNumbers: productsForPricing, userId: userId }})).unwrap();
        } catch (error) {
            let errorMsg = 'We are unable to fetch your contracts at the moment. Please refresh and try again or contact us at 1-866-MODERNA.';
            alert(errorMsg, 'error');
            alertRef.current?.openAlert(error);
        } finally {
            setProcessing(false);
        }
    };

    fetchEligibleContracts();
  }, [dispatch]);

  const getRadioOptions = () => {
    return getContractsToRender(priceContracts).map((contract, index) => {
        //After sorting contracts, select the first contract by default if no contract is selected
        if (!selectedAffiliationId && index === 0) {
            setSelectedAffiliationId(contract.id);
        }

        return (
            <>
                <Radio
                    className="answer"
                    id={contract.id}
                    label={contract.displayName}
                    name={contract.displayName}
                    value={contract.id}
                    checked={selectedAffiliationId === contract.id}
                    onChange={handleAffiliationSelection}
                    size={'large'}
                    labelsx={{ fontSize: '16px', fontWeight: selectedAffiliationId === contract.id ? 700 : 400 }}
                    radiosx={{ padding: '9px 9px 9px 0px'}}
                    customCheckedColor={"#383D47"}
                />
                { isCommitmentPricing(contract) && <CustomTypography sx={"padding-left:  45px; font-size: 14px"}>
                    Our records indicate that your organization has an active Letter of Commitment (LOC) with this group.
                </CustomTypography>}
            </>
        )
    })
  }

  const showNoMatchMessage = () => {
    return (
        <>
            <Box sx={{...MessageContentStyles, background: null, borderLeft: null}}>
                <img src={warningInfoIcon} alt="info icon" width="32" height="32" />
                <Box data-testid="noMatchesmessageContent" display="flex" flexDirection="column" alignItems="flex-start" gap="24px" flex="1 0 0">
                    <CustomTypography sx={"font-weight: 700; line-height: 24px; font-size: 18px"}>
                        No matches found.
                    </CustomTypography>
                    <CustomTypography>
                        We couldn't find a contract affiliation associated with the HIN or DEA numbers provided.
                    </CustomTypography>
                    
                    <Box display="flex" flexDirection="row" alignContents="center">
                        <Button buttonType="mds-secondary" 
                                        sx={{gap: "12px", 
                                            fontSize: "14px", fontWeight: 700, 
                                            textTransform:"none",
                                            padding: "16px 24px"
                                        }}
                            onClick={() => history.push('/contact-us')}
                        >
                            Contact us
                        </Button>
                        <CustomTypography paddingLeft="8px" alignContent="center">
                            to update your medical identifiers.
                        </CustomTypography>
                    </Box>          
                </Box>
            </Box>
            {showNotes()}
        </>
        
    )
  }

  const showNotes = () => {
    return (
        <NoteContainer>
            <img src={InfoIconImage} alt='infoIconImage'/>
            <CustomTypography sx={"line-height: 24px; color: #191F2A"}>
                Note: Not all contracts may be available at this time. Newly signed contracts could take several business days to display. Please check back again soon.
            </CustomTypography>
        </NoteContainer>
    )
  }

  const handleCloseModal = () => {
    setSelectedAffiliationId(defaultGpoAffiliatedId);
    closeModal();
  }

  const handleSaveChanges = () => {
    const selectedPriceContract = priceContracts?.find(contract => contract?.id === selectedAffiliationId);
    if (selectedPriceContract) {
      handleConfirm(selectedPriceContract);
    }
    closeModal();
  }

  const modalContent = () => { 
    return (
    <>  
    <AlertMessage variant={"filled"} type={type} message={message} sx={{ top: 120 }} ref={alertRef} />
    <Spinner processing={processing} />
    { !processing && (filterContracts(priceContracts)?.length === 0 ? showNoMatchMessage() : 
        <> 
            <CustomTypography>
                Your product pricing is determined by the affiliation you choose.
            </CustomTypography>
            <RadioContainer sx={{ padding: '16px 32px', textAlign: 'left' }} data-testid="radioOptions">   
                {getRadioOptions()}
                <CustomTypography sx={"font-weight: 700; line-height: 24px; padding-top: 16px"}>
                    Don’t see the right contract?
                    <Link to={"/contact-us"} style={{ color: "#0379B2" }}> Contact us</Link>
                </CustomTypography>
            </RadioContainer>

            {showNotes()}
            
            <Box data-testid="buttonGroup" display="flex" justifyContent="center" gap="10px" width="100%">
                <Button
                    buttonType='mds-secondary'
                    sx={{ padding: "16px 24px", textTransform: "none" }}
                    onClick={handleCloseModal}
                >
                    <CustomTypography sx={"font-weight: 700"}>
                    Cancel
                    </CustomTypography>
                </Button>
                <Button buttonType='mds-primary' sx={{ padding: "16px 24px", textTransform: "none" }}
                        onClick={handleSaveChanges}>
                    <CustomTypography sx={"font-weight: 700"}>
                    Save changes
                    </CustomTypography>
                </Button>
            </Box>
        </>)
    }
    </>
  )}

  return (
    <ModalComponent 
      title={"Contract affiliation"}
      openModal={openModal}
      closeModalHandler={closeModal}
      style={{ 
        maxWidth: '699px',
        width: 'auto',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '23px',
        '#titleContainer':{
            marginLeft: '0px',
            padding: '5px'
        },
    }}
    modalContent={modalContent()}
    />
  )
}

export default GroupAffiliationSelectionModal;
