import React from "react";
import { styled } from '@mui/material/styles';
import { Box, IconButton, Typography } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Card = styled(Box)(({ theme: { palette: { neutral }, breakpoints }}) => `
    background-color: ${neutral.white};
    border-radius: 14px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: fit-content;
   ${[breakpoints.down('sm')]} {
        min-width: 100% !important;
        flex-direction: column;
    }
`);

const IconButtonSx = {
    width: "34px", height: "34px",
    color: "primary.main",
    backgroundColor: "none",
    border: "1px solid",
    borderColor: "primary.main",
    '&:hover': {
        color: 'neutral.white',
        backgroundColor: 'primary.blue',
        borderColor: "primary.blue"
    },
    '@media screen and (max-width: 500px)': {
        height : '48px',
        width : '48px'
    }
}

export const ResourceCardComponent = ({ cardData, arrow, index, id }) => {
    const locale = useSelector((state) => state.globalStates.locale);
    const history = useHistory();
    const handleRedirect = (e, str) => {
        history.push(`${locale}/` + str);
    }

    return (
        <Card key={index} id={id} data-testid="ResourceCardTest">
            <div>
                <img src={cardData?.fields?.icon?.fields?.file?.url} alt={cardData?.fields?.icon?.fields?.title} style={{ width: "45px", height: "45px" }} />
                <Typography variant='h4' color={'primary.main'} sx={{ mt: { xs: '16px', sm: '12px' } }}>{cardData?.fields?.headline}</Typography>
                <Typography variant='p1' component={'p'} color={'primary.main'} maxWidth={'240px'} sx={{ mt: { xs: '16px', sm: '10px' }, lineHeight: { xs: '24px', sm: '16px' } }} data-testid="ResourceCardParagraphTest">{cardData?.fields?.message?.content[0]?.content[0]?.value}</Typography>
            </div>
            {arrow &&
                <Box alignSelf={'end'} mt={{ xs: 3, sm: 2 }}>
                    <IconButton
                        variant="outlined"
                        onClick={(e) => handleRedirect(e, cardData?.fields?.link)}
                        sx={IconButtonSx}>
                        <ArrowForward />
                    </IconButton>
                </Box>
            }
        </Card>
    );
};