import React from "react";
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ReviewStrand from "../../assets/images/reviewStrand.svg";
import { Button } from "../";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { useHistory } from "react-router";
import { Box } from "@mui/material";

const PageFooterContainer = styled('div')(
  ({ backgroundImgUrl, theme: { breakpoints } }) => `
  background-image: url(${backgroundImgUrl});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 108% 278% !important;
  background-size: 450px;
  height: auto;
  text-align: center;
  padding: 72px 0 30px 0;
  ${[breakpoints.down('sm')]} {
    height:auto;
  }
`
);

const PageFooterText = styled('div')(({ theme: { breakpoints } }) => `
  width:50%;
  margin:auto;
  margin-top: 50px;
  ${[breakpoints.down('sm')]} {
    width:85%
  }
`);

const CustomButton = styled(Button)(({ theme: { palette: { neutral } }}) => `
  color: ${neutral.white} !important;
`);

const DiscardButton = styled(Button)(({ theme: { breakpoints } }) => `
  padding: 10px 20px !Important;
  margin-right:10px !important;
  ${[breakpoints.down('sm')]} {
    margin-right: 0px !important;
    margin-top: 24px !important;
    width: fit-content;
  }
`);

const SaveChangesButton = styled(Button)(({ theme: { breakpoints } }) => `
  padding: 10px 20px !Important;
  margin-left:10px !important;
  color: white !important;
  ${[breakpoints.down('sm')]} {
    margin-left: 0px !important;
    width: fit-content;
  }
`);


const ButtonContainer = styled('div')(({ theme: { breakpoints } }) => `
  display:flex;
  justify-content: center;
  margin-bottm:30px;
  ${[breakpoints.down('sm')]} {
    flex-direction: column-reverse;
    justify content: space-between;
    align-items: center;
  }
`);

const CustomBold = styled('b')`
    font-family: 'Aeonik Bold', 'Arial';
`;

export const PreBookPageFooter = ({
  footerText,
  buttonLabel,
  buttonStyle,
  buttonType,
  buttonDisabled = false,
  onClickHandler,
  preBookFooterData,
  pageType,
  processing,
  ...props
}) => {
  const clickHandler = () => {
    onClickHandler();
  };

  const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Typography component={'p'} variant={'disclaimerParagraph'} color='neutral.gray1'>{children}</Typography>,
        [BLOCKS.HEADING_3]: (node, children) => <Typography component={'h3'} variant={'disclaimerH3'} color='primary.red'>{children}</Typography>,
        [BLOCKS.HEADING_4]: (node, children) => <Typography component={'h4'} variant={'disclaimerParagraph'} color='neutral.gray1'>{children}</Typography>,
    },
    renderMark: {
        [MARKS.BOLD]: (text) => <CustomBold>{text}</CustomBold>
    }
  };

  return (
    <PageFooterContainer backgroundImgUrl={ pageType !== "details" ? ReviewStrand : ""}>
      {
        pageType === "details" ? (
          <div>
            <Typography id="gpoAffiliationMessage" variant="body2" gutterBottom sx={{color:"primary.main", fontFamily:"Aeonik Regular"}} data-testid="gpoAffiliationMessage">
              {preBookFooterData?.gpoName && `${preBookFooterData?.gpoAffiliationMessage} ${preBookFooterData?.gpoName}`}
            </Typography>
            <Box id="DisclaimerText" sx={{display: "flex", flexDirection: "column"}}>
              {documentToReactComponents(preBookFooterData?.disclaimerMessage, options)}
            </Box>
          </div>
        )
        : pageType === "edit" ? ( 
            <Box display='flex' flexDirection='column'>
              <ButtonContainer>
                <DiscardButton
                      id="discardBtn"
                      buttonType={"secondary"}
                      onClick={props?.actionHandlers?.discardChangesHandler}
                      icon={false}
                      data-testid="discardButton"
                    >
                      {"DISCARD"}
                </DiscardButton>
                <SaveChangesButton
                      className="saveBtn"
                      id="saveBtn"
                      buttonType={"primary"}
                      disabled={props?.disableSaveChanges}
                      onClick={props?.saveChangesHandler}
                      icon={false}
                      loading={processing}
                      data-testid="saveButton"
                    >
                      {"SAVE CHANGES"}
                </SaveChangesButton>
              </ButtonContainer>
              <div style={{"paddingTop":"20px"}}>
                <Typography id="gpoAffiliationMessage1" variant="body2" gutterBottom sx={{color:"primary.main", fontFamily:"Aeonik Regular",}} data-testid="gpoAffiliationMessage1">
                  {preBookFooterData?.gpoName && `${preBookFooterData?.gpoAffiliationMessage} ${preBookFooterData?.gpoName}`}
                </Typography>
                <Box id="disclaimerMessage" sx={{display: "flex", flexDirection: "column"}} data-testid="disclaimerMessage">
                  {documentToReactComponents(preBookFooterData?.disclaimerMessage, options)}
                </Box>
              </div>
            </Box>
          )
        : ( 
          <div>
            {buttonLabel ?
            <CustomButton
                  id="buttonLabel"
                  buttonType={buttonType ? buttonType : "primary"}
                  disabled={buttonDisabled}
                  onClick={clickHandler}
                  icon={false}
                  sx={buttonStyle}
                  data-testid="buttonLabel"
                >
                  {buttonLabel}
            </CustomButton> : 
            ""
          }
            {
              footerText && <PageFooterText id="pageFooterText"  style={{display: "flex", flexDirection: "column"}}>
                {documentToReactComponents(footerText, options)}
              </PageFooterText>
            }

          </div> 
        )

      }
    </PageFooterContainer>
  );
};
