import { Typography, Box } from "@mui/material";
import { BankIcon } from "../../assets/logos/cardLogo";
import { CREDIT_CARD_MAPPING } from "./Helper";

const checkoutPageStyle = {
    display: 'flex',
    padding: '16px 24px',
    alignItems: 'center'
}

const boldText = {
    fontWeight: 'bold',
    display: 'inline'
}

const orderDetailsPageStyle = {
    display: 'flex',
    padding: '16px 24px',
    borderRadius: '8px',
    border: '1px solid var(--Light-Stroke-stroke-secondary, #D1D2D5)',
    alignItems: 'center'
}

export const PaymentMethodInfoCard = ({ paymentMethod, isOrderDetailsPage=false }) => {
    const paymentMethodInfo = { type: paymentMethod?.paymentMethodType, info: paymentMethod?.paymentMethodInfo}
    const isBank = paymentMethodInfo.type === 'ACH';

    return(
        <Box display={'flex'} flexDirection={'row'} style={isOrderDetailsPage ? orderDetailsPageStyle : checkoutPageStyle}>
            <Box width={'32px'} paddingBottom={ isBank ? '6px' : '0px'}>
                { isBank ? <BankIcon/> : CREDIT_CARD_MAPPING[paymentMethodInfo.info?.brand]?.logo }
            </Box>
            <Box ml={2} display={'flex'} flexDirection={'column'} width={'100%'} >
                <Box style={{width: '100%', marginTop:'3px'}}>
                    <Typography style={{ fontSize: '16px', fontWeight: 400 }}>
                        <span style={boldText}>
                        { isBank ? paymentMethodInfo?.info?.bankName : CREDIT_CARD_MAPPING[paymentMethodInfo?.info?.brand]?.name }
                        </span>
                        { isBank ? ' account ending in ' : ' ending in '}
                        <span style={boldText}>
                            {paymentMethodInfo.info?.lastFour}
                        </span>
                    </Typography>
                    {isOrderDetailsPage && <Typography>
                        { isBank ? paymentMethodInfo.info?.billingName : `Exp. ${paymentMethodInfo.info?.expirationDate}` }
                    </Typography>}
                </Box>
            </Box>
        </Box>
    )
}
