import React from "react";
import PropTypes from "prop-types";
import { GridItem } from "../GridItem";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { colors } from './utils';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const StyledGrid = styled(Grid)(({ griditem, theme: { palette: { neutral } }}) => `
  color: ${neutral.white};
  ${calculateBackgrounds(griditem)}
`);

const getBackgroundColor = (i, colorsIndex, item) => {
  return `
      &:nth-of-type(${i+1}) {
        background-color: ${colors[colorsIndex]};
        background-image: url(${item.fields?.backgroundImage?.fields?.file?.url});
        background-size: 100% 100%;
      }
    `;
  }

  const calculateBackgrounds = (item) => {
    let str = '';
    let colorsIndex = 0;
    for (let index = 0; index < colors.length; index += 1) {
      if (colorsIndex === colors.length) 
        colorsIndex = 0;
      str += getBackgroundColor(index, colorsIndex, item);
      colorsIndex += 1;
    }
    return str;
  }

export const BenefitCardComponent = ({ content }) => {
  const { baseStoreData } = useSelector((store) => store?.commerce);
  const isOrderingEnabled = baseStoreData?.commerceEnabled;
  return (
    <Box display='flex' flexDirection='column' overflow='visible' margin='0 auto'>
      <Typography variant={'h1'} color='neutral.white' letterSpacing='0px' mb={{xs: '60px', sm:'12px'}} mt='50px' ml={{xs:'20px', sm:'64px'}} position='absolute' id="benefitCardTitle">{content?.fields?.headline}</Typography>
      <Grid container>
        {content?.fields?.cards && content?.fields?.cards.map((item, index) => {
          
            return (
              <StyledGrid griditem={item} xs={12} md={4} key={index} item={true}>
                <GridItem
                  index={index}
                  cardSubTitle={item.fields.subTitle}
                  cardDescription={isOrderingEnabled ? item.fields.description : item.fields.descriptionPrebook}
                  cardImage={item.fields.icon.fields.file.url}
                  cardTitle={isOrderingEnabled ? item.fields.title : item.fields.titlePrebook}
                  buttonLink={item.fields.link?.fields.url}
                  buttonLabel={item.fields.link?.fields.label}
                />
              </StyledGrid>
            );
          })
        }
      </Grid>
    </Box>
  );
};

BenefitCardComponent.propTypes = {
  content: PropTypes.object
};
