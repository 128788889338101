export const OrderErrorCodes = {
    PRICING: {
        PRICING_CALCULATION_ERROR: 'PRICING_CALCULATION_ERROR',
    },
    GENERAL: {
        INVALID_REQUEST_PARAMETERS: 'INVALID_REQUEST_PARAMETERS',
    },
    PAYMENTS: {
        GENERIC_DECLINE: 'GENERIC_DECLINE',
        FRAUDULENT: 'FRAUDULENT',
        CARD_DECLINED: 'CARD_DECLINED',
        EXPIRED_CARD: 'EXPIRED_CARD',
        CAPTURE_FAILURE: 'CAPTURE_FAILURE',
        BALANCE_DATA_UNAVAILABLE: 'BALANCE_DATA_UNAVAILABLE',
        INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
        CAPTURE_SUCCEEDED_EXCEPTIONALLY: 'CAPTURE_SUCCEEDED_EXCEPTIONALLY'
    }
};
