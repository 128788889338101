import React from 'react';
import {Box, Typography} from "@mui/material";

const NoRowsOverlay = () => {
  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
      <Typography variant={'p1'}>
        You have no orders to display.
      </Typography>
    </Box>
  );
};

const NoReservationRowsOverlay = () => {
    return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
            <Typography variant={'p1'}>
                You have no reservations to display.
            </Typography>
        </Box>
    );
};

export { NoRowsOverlay, NoReservationRowsOverlay}
