import { Box } from '@mui/material';
import WebLink from '../../assets/images/webLinkIcon.svg'
import { TypographyP1, TypographyH2 } from "../../assets/fonts/typographyTheme";

export const INLINE_VERSION = 'inline';

export const taxExemptionContent = (contentVersion) => {

  const inlineContent = (
    <>
      <TypographyP1
        paddingBottom="15px"
      >
        If your order is tax exempt, please send your tax exempt certificates to <a href="https://taxform.modernatx.com/" target="_blank" style={{fontWeight: 700}}>taxform.modernatx.com </a>
        If we do not receive a valid tax exempt certificate within 10 days and sales tax applies, 
        we will bill the sales tax amount to you.
      </TypographyP1>
    </>
  );

  const blockContent = (
    <>
      <TypographyP1
        paddingBottom="15px"
      >
        If your order is tax-exempt, please send your tax exempt certificates to
      </TypographyP1>
      <TypographyH2
        color="#53A1C9"
      >
        <Box sx={{ display: "flex", flexDirection: "row", height: "30px"}}>
          taxform.modernatx.com{" "}
          <a href="https://taxform.modernatx.com/" target="_blank" >
            <img src={WebLink} height="20px" alt="Tax Exempt" style={{ verticalAlign: "middle", paddingLeft:"5px" }}/>
          </a>
        </Box>
      </TypographyH2>
      <TypographyP1
        paddingTop="15px"
      >
        If we do not receive a valid tax exempt certificate within 10 days and sales tax applies, we will bill the sales tax amount to you.
      </TypographyP1>
    </>
  )

  return (
    <>
      {contentVersion === INLINE_VERSION ? inlineContent : blockContent}
    </>
  )
};