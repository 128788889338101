import React from "react"
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import { Typography } from "@mui/material";

export const LeftCard = ({ username = "", leftCardContent, isOrderingEnabled }) => {
    return (
        <div>
            <Typography variant='h3' component={'h2'} color={'neutral.white'} fontSize={{ xs: '30px', sm: '20px', md: '32px', lg: '42px' }}>
                {leftCardContent?.introMessage} {username},
            </Typography>
            <Typography variant='h2' component={'p'} mt={{ xs: '14px', sm: '8px', md: '8px', lg: '8px' }} mb={{ xs: '8px', sm: '8px' }} color={'neutral.white'} fontSize={{ xs: '24px', sm: '16px', md: '24px', lg: '32px' }}>
                {isOrderingEnabled? leftCardContent?.headline : leftCardContent?.headlinePrebook}
            </Typography>
            <Typography variant='p2' component={'div'} color={'neutral.gray2'} mt={{ xs: 3, sm: 3, md: 2, lg: 0 }} mb={7} fontSize={{ xs: '10px', sm: '8px', md: '10px', lg: '10px' }}>
                {documentToReactComponents(isOrderingEnabled? leftCardContent?.message : leftCardContent?.messagePrebook)}
            </Typography>
        </div>
    )
}