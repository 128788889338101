import React from 'react';
import { Card, Typography } from '@mui/material';
import { theme } from "../../assets/theme";

const defaultCardStyles = {
    bgcolor: 'neutral.white',
    borderRadius: '16px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.50)',
    width: '100%',
    height: '100%',
};

const CustomCard = ({ title, children, sx, hasBorder=false }) => {
    const borderTopStyle = hasBorder ? { borderTop: `7px solid ${theme.palette.primary.blue}` } : {};

    const cardStyles = {
        ...defaultCardStyles,
        ...borderTopStyle,
        ...sx,
    };

    return (
        <Card sx={cardStyles} data-testid='customerCard'>
            {title && (
                <Typography variant="h3">
                    {title}
                </Typography>
            )}
            {children}
        </Card>
    );
};

export default CustomCard;