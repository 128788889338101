import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, BackComponent, Checkbox } from "..";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ProfileIcon from "../../assets/images/profile.svg";
import accountIcon from "../../assets/images/accountSummaryIcon.svg";
import { Typography, useTheme } from "@mui/material";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { createUserInOktaAndCommerceApi } from "../../utils/commerceOktaApi";
import { AlertMessage } from "../../components";
import { AccountDetails } from "./AccountDetails";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useRef } from "react";
import { loadUserRegistrationReviewStaticContent } from "../../features/contentful/contentfulThunkApi";
import { loadProfileStaticContent } from "../../features/contentful/contentfulThunkApi";
import { setInvalidPasswordMsg } from "../../features/globalStates/globalStatesSlice";
import { AddressDetails } from "./AddressDetails";
import ViewIcon from "../../assets/images/MyProfile/View_Icon.svg";

const StyledBox = styled(Box)(({ theme: { palette: { secondary }, breakpoints }}) => `
  margin: 0 30px;
  display: flex;
  background-color: ${secondary.main};
  ${[breakpoints.down('sm')]} {
    margin: 0px;
    flex-direction: column;
  };
  border-radius: 13px;
`);

const Label = styled(Typography)(({ theme: { palette: { primary }, breakpoints }}) => `
  color: ${primary.main};
  margin-top: 5px;
`);

const ProfileIconImg = styled('img')`
  filter: invert(.5) sepia(1) saturate(5) hue-rotate(180deg);
`;

const EditLink = styled('button')(({ theme: { palette: { secondary }, breakpoints }}) => `
  color:${secondary.textLinks};
  cursor: pointer;
  ${[breakpoints.down('sm')]} {
    position: relative;
    right: 0px;
    text-align: right;
    margin-right: 24px;
  }
`);

const CustomGrid = styled(Grid)`
    &.removePadding {
        padding-top: 0 !important;
    }
`;

const LicenseInfo = ({ staticContent, userInfo }) => {
  const [showDea, setShowDea] = useState(false);
  const [showHin, setShowHin] = useState(false);
  const toggleDea = () => {
      setShowDea(prev => !prev)
  }
  const toggleHin = () => {
      setShowHin(prev => !prev)
  }

  const NotFoundValue = styled('p')(({ theme: { palette: { neutral } }}) => `
    color: ${neutral.gray2};
  `);

  return(
    <Grid container justifyContent='space-between' gap={3} p={3}>
        <Grid item xs={11} md={5}>
            <Label variant={'h6'} id="deaNumberLabel">{staticContent?.deaNumberLabel}</Label>
                {userInfo?.orgUnit?.deaLicense ? <><Typography variant={'p1'} id="deaNumber">{showDea ? userInfo?.orgUnit?.deaLicense : '**********'}</Typography>
                <img src={ViewIcon} alt="view icon" height="30" width="30" onClick={toggleDea} /></> : 
                <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>}
        </Grid>
        <Grid item xs={11} md={5}>
            <Label variant={'h6'} id="hinNumberLabel">{staticContent?.hinNumberLabel}</Label>
                {userInfo?.orgUnit?.hin ? <><Typography variant={'p1'} id="hinNumber">{showHin ? userInfo?.orgUnit?.hin : '**********'}</Typography>
                <img src={ViewIcon} alt="view icon" height="30" width="30" onClick={toggleHin} /></> : 
                <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>}
        </Grid>
        <Grid item xs={11} md={5}>
            <Label variant={'h6'}>{staticContent?.medicalLicenseLabel}</Label>
            {userInfo?.medicalLicense ? <Typography variant={'p1'}>{userInfo?.medicalLicense}</Typography> : 
            <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>}
        </Grid>
        <Grid item xs={11} md={5}>
            <Label variant={'h6'}>{staticContent?.medicalLicenseStateLabel}</Label>
            {userInfo?.licenseState ? <Typography variant={'p1'}>{userInfo?.licenseState}</Typography> : 
            <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>}
        </Grid>
      </Grid>
  )
}

export const Review = ({ setComp, setProgressValue, accountStatus, shipToAddresses, soldToAddresses, ...props }) => {
  const isASMuser = props?.isASMuser;
  const { userRegistrationReviewContent } = useSelector((store) => store?.contentful);
  const { staticContent = [] } = userRegistrationReviewContent;
  const { fields: addressContent = [] } = staticContent.registrationAddressDisplayComponent || {fields: {}};
  const dispatch = useDispatch();
  const [contactData, setContactData] = useState({});
  const [licenseData, setLicenseData] = useState({});
  const [accountData, setAccountData] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [processing, setProcessing] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { profilePageContent } = useSelector((store) => store?.contentful)  //Reusing license content in profile page in registration review page
  const { staticContent: licenseContent = [] } = profilePageContent

  const alertRef = useRef(null)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  
  useEffect(() => {
    //Profile page content contains content for license info, so reusing it here
    if (Object.keys(profilePageContent).length === 0)
        dispatch(loadProfileStaticContent())
}, [])

  useEffect(() => {
    let licenseInfo = {
      orgUnit: {
        deaLicense: accountData?.deaLicense,
        hin: accountData?.hin,
      },
      ...licenseData
    };
    setUserInfo(licenseInfo);
  }, [accountData,licenseData])

  useEffect(() => {
    window.scrollTo(0, 0);
    setContactData(JSON.parse(localStorage.getItem('contactInfo')));
    setAccountData(JSON.parse(localStorage.getItem('accountInfo')));
    setLicenseData(JSON.parse(localStorage.getItem('licenseInfo')));
  }, []);

  useEffect(() => {
    if (Object.keys(userRegistrationReviewContent).length === 0) {
      dispatch(loadUserRegistrationReviewStaticContent());
    }
  }, []);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha)
      return;

    const token = await executeRecaptcha('user_registration');
    return token
  }, [executeRecaptcha]);

  const handleConfirm = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const oktadata = {
      "profile": {
        "firstName": contactData?.firstName,
        "lastName": contactData?.lastName,
        "email": contactData?.email,
        "login": contactData?.email
      },
      "credentials": {
        "password": { "value": contactData?.password },
        "provider": {
          "type": "OKTA",
          "name": "OKTA"
        }
      }
    };

    const commercedata = {
      "firstName": contactData?.firstName,
      "lastName": contactData?.lastName,
      "email": contactData?.email,
      "jobTitle": ( contactData?.jobTitle === staticContent?.notFoundText ? "" : contactData?.jobTitle ),
      "userRole": "FullAccessUser",
      "optin": contactData?.promotions,
      "officeAddress": accountData?.officeAddress,
      "dbaName": accountData?.dbaName,
      "deaLicense": accountData?.deaLicense,
      "hin": accountData?.hin,
      "medicalLicense": licenseData?.medicalLicense,
      "legalAccountName": accountData?.legalAccountName,
      "position": ( contactData?.role === staticContent?.notFoundText ? "" : contactData?.role ),
      "licenseState": licenseData?.licenseState,
      "status": accountStatus,
    };
    try {
      const recaptchaToken = await handleReCaptchaVerify()
      const captchaData = {
        userCaptchaResponse: recaptchaToken
      }

      const res = await createUserInOktaAndCommerceApi({
        commercedata: commercedata,
        oktadata: oktadata,
        captcha: captchaData
      });
      if (res?.status === 201) {
        setProcessing(false);
        setComp('finalStep');
        setProgressValue(100);
      }
    } catch (err) {
      setErrorMsg(generalApiError);
      alertRef.current?.openAlert()
      setProcessing(false);
      if(err?.response?.data?.errorCode === "E0000001"){
        dispatch(setInvalidPasswordMsg(err?.response?.data?.errorSummary))
        backBtnHandler();
      }
    }
  };

  const backBtnHandler = () => {
    isASMuser ? setComp('contactInfo') : setComp('setPassword');
    isASMuser ? setProgressValue(60) : setProgressValue(66);
  };

  const editHandler = () => {
    setComp('contactInfo');
    isASMuser ? setProgressValue(60) : setProgressValue(50);
  }

  const renderAddressDetails = (addresses, label) => {
    return(
      <> 
          <Box display='flex' alignItems='center' m={{xs:'20px 0', sm:'20px 30px'}}>
            <ProfileIconImg src={ProfileIcon} height="30" width="30" alt="user profile" />
            <Typography variant="h5" marginLeft="10px">{label}</Typography>
          </Box>
          <StyledBox sx={{ flexGrow: 1 }}>
            <AddressDetails staticContent={addressContent} addresses={addresses}/>
          </StyledBox>
      </>
    )
  }

  return (
    <>
      {desktop && <BackComponent sx={{ top: "110px" }} onClickHandler={backBtnHandler} />}
      <AlertMessage variant={"filled"} type={"error"} message={errorMsg} ref={alertRef}/>
      <Box position='relative' m='30px auto 0 auto' width={{xs:'85%', sm:'60%'}}>
        {mobile && <BackComponent sx={{ top: "110px" }} onClickHandler={backBtnHandler} />}
        <Typography variant={'h1'} textAlign='center' color='primary.main' mt={{xs:'20px', sm:'0'}} id="headline">{staticContent?.headline}</Typography>
        <Typography variant={'p1'} component={'div'} textAlign='center' mt='20px' mb='60px' id="subtitle">{documentToReactComponents(staticContent?.message)}</Typography>
        <Box display='flex' alignItems='center' m={{xs:'20px 0', sm:'20px 30px'}}>
          <img id="accountSummary" src={accountIcon} height="30" width="30" alt="account summary" />
          <Typography id="contactInfo" variant="h5" marginLeft="10px">{staticContent?.accountSummaryLabel}</Typography>
        </Box>
        <StyledBox sx={{ flexGrow: 1 }}>
          <AccountDetails staticContent={staticContent} accountDetails={accountData} singleAccount={false} />
        </StyledBox>
        <Box display='flex' alignItems='center' m={{xs:'20px 0', sm:'20px 30px'}}>
          <ProfileIconImg src={ProfileIcon} height="30" width="30" alt="user profile" />
          <Typography variant="h5" marginLeft="10px">{staticContent?.contactInfoLabel}</Typography>
        </Box>
        <StyledBox sx={{ flexGrow: 1 }}>
          <Grid justifycontent='space-between' container gap={3} p={3}>
            <Grid item xs={9} md={5}>
              <Label variant={'h6'} id="nameLabel">{staticContent?.contactNameLabel}</Label>
              {accountData?.lastName === "" ? <Typography variant={'p1'} color='neutral.gray2'>Not Found</Typography> : <Typography variant={'p1'} id="nameValue">{contactData?.firstName} {contactData?.lastName}</Typography>}
            </Grid>
            {mobile &&
              <Grid item xs={1} md={1}>
                { !isASMuser && <EditLink onClick={editHandler}>Edit</EditLink> }
              </Grid>}
            <Grid item xs={11} md={5}>
              <Label variant={'h6'} id="emailLabel">{staticContent?.contactEmailLabel}</Label>
              {accountData?.email === "" ? <Typography variant={'p1'} color='neutral.gray2'>Not Found</Typography> : <Typography variant={'p1'} id="emailValue">{contactData?.email}</Typography>}
            </Grid>
            {desktop &&
              <Grid item xs={11} md={1}>
                { !isASMuser && <EditLink onClick={editHandler}>Edit</EditLink> }
              </Grid>}
            <CustomGrid item xs={11} md={5} className={!(contactData?.role) && 'removePadding'}>
              {contactData?.role && <>
                <Label variant={'h6'} id="roleLabel">{staticContent?.contactRoleLabel}</Label>
                <Typography variant={'p1'} id="roleValue">{contactData?.role}</Typography>
              </>}
            </CustomGrid>
            <CustomGrid item xs={11} md={5} className={!(contactData?.jobTitle) && 'removePadding'}>
              {contactData?.jobTitle && <>
                <Label variant={'h6'} id="jobTitleLabel">{staticContent?.contactTitleLabel}</Label>
                <Typography variant={'p1'} id="jobTitleValue">{contactData?.jobTitle}</Typography>
              </>}
            </CustomGrid>
            <Grid item xs={11} md={1}>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              {
               !isASMuser && (contactData?.promotions ? <Checkbox label={staticContent?.consentMessage} disabled={true} value={true} /> :
                <Checkbox label={staticContent?.consentMessage} disabled={true} value={false} />)
              }

            </Grid>
          </Grid>
        </StyledBox>
        <Box display='flex' alignItems='center' m={{xs:'20px 0', sm:'20px 30px'}}>
          <ProfileIconImg src={ProfileIcon} height="30" width="30" alt="user profile" />
          <Typography variant="h5" marginLeft="10px">{"License Information"}</Typography>
        </Box>
        <StyledBox sx={{ flexGrow: 1 }}>
          <LicenseInfo staticContent={licenseContent} userInfo={userInfo}/>
        </StyledBox>
        {soldToAddresses?.length > 0 && renderAddressDetails(soldToAddresses, addressContent?.soldToLabel)}
        {shipToAddresses?.length > 0 && renderAddressDetails(shipToAddresses, addressContent?.shipToLabel)}
        <Box display='flex' justifyContent='space-evenly' width='40%' m='15% auto 70px auto'>
          <Button
            loading={processing}
            buttonType="primary"
            onClick={handleConfirm}>
            {staticContent?.nextButtonLabel}
          </Button>
        </Box>
      </Box>
    </>
  )
};
