import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

const LoginCallback = ({ errorComponent, loadingElement = null, onAuthResume }) => { 
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const [callbackError, setCallbackError] = React.useState(null);
  const locale = useSelector((state) => state.globalStates.locale);
  
  React.useEffect(() => {
    const isInteractionRequired = oktaAuth.idx.isInteractionRequired || oktaAuth.isInteractionRequired.bind(oktaAuth);
    if (onAuthResume && isInteractionRequired()) {
      onAuthResume();
      return;
    }
    oktaAuth.handleLoginRedirect().catch(e => {
      setCallbackError(e);
      history.push(`/${locale}/not-authorized`)
    });
  }, [oktaAuth, locale, onAuthResume, history]);

  return loadingElement;
};

export default LoginCallback;