import { useMemo } from 'react';
import { useSelector } from "react-redux";
import { Box, Grid, Typography, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { formattedPrice, sortProductsByPriority } from '../../utils/Helper';
import MapIcon from '../../assets/images/mapIcon.svg';
import CoinIcon from '../../assets/images/coinIcon.svg';
import { TAX_PENDING } from '../../constants';

const TypographyHeading = styled(Typography)(({ theme: { breakpoints } }) => `
    font-family: Aeonik Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`);

const TypographyText = styled(Typography)(({ theme: { breakpoints } }) => `
    font-family: Aeonik Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`);

const BoxContainer = styled(Box)`
  display: flex;
  border: 1px solid #D1D2D5;
  flex-direction: column;
   .MuiDataGrid-cellContent, .MuiDataGrid-columnHeaderTitle {
    font-size: 16px;
     text-transform: none;
   }
`

const columns = [
    {
        field: 'title',
        headerName: 'Item',
        flex: 1,
        minWidth: 100,
        sortable: false,
    },
    {
        field: 'doses',
        headerName: 'Doses',
        headerAlign: 'right',
        align: 'right',
        width: 120,
        sortable: false,
        valueFormatter: (params) => params.value ? params.value.toLocaleString() : '',
    },
    {
        field: 'listPriceValue',
        headerName: 'List Price',
        headerAlign: 'right',
        width: 130,
        align: 'right',
        sortable: false,
        cellClassName: 'listPrice',
        valueFormatter: (params) => params.value ? formattedPrice(params.value) : '',

    },
    {
        field: 'contractPriceValue',
        headerName: 'Price',
        width: 130,
        headerAlign: 'right',
        align: 'right',
        sortable: false,
        valueFormatter: (params) => {
            if (params.value === 'Pending') {
                return 'Pending';
            } else {
                return params.value ? formattedPrice(params.value) : '';
            }
        }
    },
];

const NOT_USED = null;
const BASE_HEIGHT_SMALL = 330;
const BASE_HEIGHT_MEDIUM = 240;
const INCREMENT_HEIGHT = 90;

const OrderSummaryTable = ({orderDetail, isTaxPending, sx}) => {

    const showContractPrice = useSelector((state) => state.globalStates.showContractPrice);

    const StyledDataGrid = styled(DataGrid)(
      ({ theme: { breakpoints } }) =>`
        letter-spacing: -0.32px;
        padding: 14px;
    
        & .listPrice {
            text-decoration: ${showContractPrice ? "line-through" : "none"};
            text-align: right;
        }
    
        & .divider-row [role="cell"] {
            border-color: #85888E;
        }
    
        & .taxes-row {
            min-height: 25px !important;
            max-height: 25px !important;
        
            & [role="cell"] {
                margin-top: unset;
                min-height: 25px !important;
            }
        }
    
        & .MuiDataGrid-row--lastVisible .MuiDataGrid-cell[data-colindex="3"] {
            border-top: 1px solid #D1D2D5;
        }
    
        & .MuiDataGrid-cell[data-field="contractPriceValue"] {
            font-weight: 700;
        }
    
        & .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell {
            white-space: pre-wrap;
        }

        & .MuiDataGrid-row[data-rowindex="3"] .MuiDataGrid-cell[data-field="contractPriceValue"],
        & .MuiDataGrid-row[data-rowindex="4"] .MuiDataGrid-cell[data-field="contractPriceValue"] {
            font-weight: normal;
        }
        
        ${breakpoints.between('sm', 'md')} {
            &[data-testid="product-rows"]{
                height: ${(orderDetail?.items?.length * INCREMENT_HEIGHT + BASE_HEIGHT_SMALL) + 'px !important'};
            }

            & .MuiDataGrid-row:nth-child(-n + ${orderDetail?.items?.length}) {
                min-height: 125px !important;
                max-height: 125px !important;
        
                & [role="cell"] {
                min-height: 115px !important;
                max-height: 115px !important;
                }
            }
        }

        ${breakpoints.between('md', 'xl')} {
            &[data-testid="product-rows"] {
                height: ${(orderDetail?.items?.length * INCREMENT_HEIGHT + BASE_HEIGHT_MEDIUM) + 'px !important'};
            }

            & .MuiDataGrid-row:nth-child(-n + ${orderDetail?.items?.length}) {
                min-height: 95px !important;
                max-height: 95px !important;

                & [role="cell"] {
                    min-height: 85px !important;
                    max-height: 85px !important;
                }
            }
        }
    `);
    
    const orderCartEntries = orderDetail?.items || [];

    const getTotalDoses = () => {
        return orderCartEntries?.reduce((sum, item) => sum + item.doses, 0);
    }

    const getTotalPrice = () => {
        return orderDetail?.totalContractPrice || (getSubtotalContractedPrice() + getTotalTax());
    }

    const getTotalTax = () => {
        return orderDetail?.totalTax || (orderCartEntries?.length === 0 ? 0 : 
        orderCartEntries?.filter(row => row?.doses !== 0)
                            .reduce((sum, item) => sum + Number(item?.priceTotal?.totalTax?.displayValue || 0), 0))
    };

    const getSubtotalListPrice = () => {
        return orderDetail?.subtotalListPrice || (orderCartEntries?.length === 0 ? 0 : 
            orderCartEntries?.reduce((sum, item) => sum + Number(item?.priceTotal?.subtotalListPrice?.displayValue || 0), 0));
    };

    const getSubtotalContractedPrice = () => {
        return orderDetail?.subtotalContractPrice || (orderCartEntries?.length === 0 ? 0 : 
            orderCartEntries?.reduce((sum, item) => sum + Number(item?.priceTotal?.subtotalContractPrice?.displayValue || 0), 0));
    };

    const constructTableRow = (id, titleCol, listPriceCol, contractPriceCol, dosesCol, isDivider) => {
        return {
            id: id,
            title: titleCol,
            listPriceValue: listPriceCol,
            contractPriceValue: contractPriceCol,
            doses: dosesCol,
            isDivider: isDivider
        }
    };

    const productRows = () => {
        const sortedOrderCartEntries = sortProductsByPriority(orderCartEntries, 'productId');
    
        let entriesToReturn = sortedOrderCartEntries?.filter(row => row?.doses !== 0)
            ?.map((row, index) => (
                constructTableRow(index, 
                                    row?.productName, 
                                    row?.listPrice, 
                                    row?.contractPrice, 
                                    row?.doses, 
                                    index === sortedOrderCartEntries.length - 1 ? true : false)
        )) ?? [];
        return entriesToReturn;
    };

    const getDataGridRows = useMemo(() => {
        let rows = productRows();
        let taxValue = isTaxPending ? TAX_PENDING : getTotalTax().toString();
        rows.push(constructTableRow(rows.length, 'Subtotal', getSubtotalListPrice(), getSubtotalContractedPrice(), getTotalDoses(), false));
        rows.push(constructTableRow(rows.length, 'Taxes', NOT_USED, taxValue, NOT_USED, false));
        rows.push(constructTableRow(rows.length, NOT_USED, NOT_USED, getTotalPrice(),NOT_USED,  NOT_USED, false));
        
        return rows;

    }, [orderCartEntries]);

    const ShippingAndBillingGrid = ({children}) => {
        return (
            <Grid container data-testid={'shipping-billing-grid'}>
                {children.map((child, index) => (
                    <Grid item xs={6} id={index} 
                    sx={{
                        borderRight: '1px solid #D1D2D5',
                        borderBottom: '1px solid #D1D2D5',
                        padding: '16px 24px',
                        '&:nth-of-type(2n)': {
                            borderRight: 'none',
                        }
                    }}
                    key={index}
                    >
                        {child}
                    </Grid>
                ))}
            </Grid>
        );
    };

    const shippingAndBillingData = () => {
        const getAddressDisplay = (address) => {
            return (
                address?.street1 ?
                    <>
                        <TypographyText>{address?.name}</TypographyText>
                        <TypographyText>{address?.street1}</TypographyText>
                        <TypographyText>{address?.street2}</TypographyText>
                        <TypographyText>{`${address?.city}, ${address?.state}, ${address?.zipCode}`}</TypographyText>
                    </> :
                    <>
                        <TypographyText>Information not available</TypographyText>
                    </>
            );
        }
        return [
            {
                icon: {
                    value: MapIcon,
                    alt: "map icon"
                },
                id: "shipTo",
                heading: "Ship to:",
                text: getAddressDisplay(orderDetail?.shipToAddress)
            },
            {
                icon: {
                    value: CoinIcon,
                    alt: "coin icon"
                },
                id: "billTo",
                heading: "Bill to:",
                text: getAddressDisplay(orderDetail?.billToAddress)
            },
            {
                id: "poNumber",
                heading: "P.O. Number:",
                text: <TypographyText>{orderDetail?.purchaseOrderNumber}</TypographyText>
            }
        ];
    };
    
    const getShippingAndBillingComponents = () => {
        let itemsToDisplay = shippingAndBillingData();

        const addressDisplay = (item) => {
            let poNumber = item.id === "billTo" ? itemsToDisplay.find(item => item.id === "poNumber") : null;
            return (
            <>
                <Grid container spacing={"10px"} alignItems="flex-start">
                    <Grid item xs={1}>
                        <img src={item.icon.value} height="24" width="24" alt={item.icon.alt} />
                    </Grid>
                    <Grid item xs={11}>
                        <TypographyHeading>{item.heading}</TypographyHeading>
                        {item.text}
                        
                        {poNumber && <Grid display="flex" justifyContent="left" mt={2} alignItems="center" gap={1}>
                            <TypographyHeading>{poNumber.heading}</TypographyHeading>
                            {poNumber.text}
                        </Grid>}
                    </Grid>
                    
                </Grid>
            </>
            )
        }
        return['shipTo', 'billTo'].map((target) => addressDisplay(itemsToDisplay.find(item => item.id === target)));
    };
    
    const setRowClassName = (params) => {
        if (params.row.isDivider) return 'divider-row';
        if (params.row.title === 'Taxes') return 'taxes-row';
        return '';
    };

    return (
        <Box sx={sx}>
            <BoxContainer id="boxContainer" display='flex' flexDirection={'column'} >
                <Box minWidth={'350px'}>
                    <ShippingAndBillingGrid children={getShippingAndBillingComponents()} />
                    <StyledDataGrid
                        data-testid={'product-rows'}
                        rows={getDataGridRows}
                        columns={columns}
                        hideFooter
                        disableColumnFilter
                        disableColumnMenu
                        getRowClassName={setRowClassName}
                        onFilterModelChange={(model) => {}}
                    />                   
                </Box>
            </BoxContainer>
        </Box>
    )
};

export default OrderSummaryTable;