import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Container, Grid, TextField } from "@mui/material";
import { Button } from "../../Button";
import { Spinner } from "../../../components";
import Dropdown from "../Dropdown";
import { getUserByEmail, searchOrganizations, updateUserDetailsApi } from "../../../utils/UserService/UserApis";
import { ADDRESS_TYPE, US_STATES } from "../../../constants";
import { FormLabel, helperTextStyles, textFieldStyles, validateRequiredField, validateZipcode } from "../Helper";
import { setUserStatus } from "../../../features/globalStates/globalStatesSlice";
import { getUserErrorMessages } from "../../../utils/getErrorMessage";

const buttonsContainerSxStyles = {
    flexDirection: {xs: 'column', sm:'row'},
    display:"flex",
    justifyContent:'left',
    marginY: "24px",
}

export const OrganizationForm = ({
        tabIndex,
        onTabChange = () => {},
        customerSegmentClasses,
        setUserOrganizationInfo,
        setShowMatchFoundForm,
        setSearchOrgResults,
        handleErrorMsg,
    }) => {

    const defaultInputFields = {
        orgLegalName: '',
        dbaName: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipcode: '',
        zip4: '',
        customerSegment: '',
    };

    const [inputFields, setInputFields] = useState(defaultInputFields);
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const requiredFields = ['dbaName', 'line1', 'city', 'state', 'zipcode'];
    const [isLoading, setIsLoading] = useState(false);
    const userId = useSelector((store) => store?.commerce?.userData?.userId) || '';

    useEffect(() => {
        const checkIsFormValid = () => {
            const requiredFieldsFilled = requiredFields.every((field) => inputFields[field].trim().length !== 0);
            return requiredFieldsFilled;
        };
        checkIsFormValid();
    
    }, [inputFields, errors]);

    const USStates = US_STATES.map((state) => {
        return({
            "value": state,
            "label": state,
        })
    });

    const userOrgInfo = {
        "userId": userId,
        "updateType": "organization",
        "organization": {
            "forceCreate": true,
            "orgType": "sold_to",
            "customerSegment": inputFields?.customerSegment,
            "legalName": inputFields?.orgLegalName,
            "dbaName": inputFields?.dbaName,
            "address": {
                "addressType": ADDRESS_TYPE.SOLD_TO,
                "line1": inputFields?.line1,
                "line2": inputFields?.line2,
                "city": inputFields?.city,
                "state": inputFields?.state,
                "zip": inputFields?.zipcode,
                "zipExtension": inputFields?.zip4
            }
        }
    }

    const searchOrgRequestBody = {
        pagination: {
            pageNumber: 0,
            pageSize: 4,
        },
        name: inputFields?.dbaName,
        address: {
            addressType: ADDRESS_TYPE.SOLD_TO,
            line1: inputFields?.line1,
            line2: inputFields?.line2,
            city: inputFields?.city,
            state: inputFields?.state,
            zip: inputFields?.zipcode,
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newInputFields = { ...inputFields, [name]: value };
        setInputFields(newInputFields);
    };

    const createSoldToOrg = async () => {
        const updateDetailsResponse = await updateUserDetailsApi(userOrgInfo)
        if (updateDetailsResponse?.status === 200) {
            dispatch(setUserStatus(updateDetailsResponse?.data?.userStatus))
            setInputFields(defaultInputFields);
            onTabChange(tabIndex + 1);
            dispatch(getUserByEmail({ emailAddress: updateDetailsResponse?.data?.email })).unwrap()
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = {};
        Object.keys(inputFields).forEach((fieldName) => {
            const errorMsg = validateInputFields(fieldName, inputFields[fieldName]);
            if (errorMsg) validationErrors[fieldName] = errorMsg;
        });

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            setIsLoading(true);
            const response = await searchOrganizations(searchOrgRequestBody);
            if (response?.status === 200) {
                if (response?.data?.total === 0) {
                    await createSoldToOrg()
                } else {
                    setUserOrganizationInfo(userOrgInfo);
                    setSearchOrgResults(response?.data)
                    setShowMatchFoundForm(true);
                }
            }
        } catch (error) {
            const errorMessage = getUserErrorMessages(error?.response?.data?.errorCode)
            handleErrorMsg(errorMessage)
        } finally {
            setIsLoading(false);
        }
    };

    const handleStateSelection = (event) => {
        const { value } = event.target;
        const updatedInputs = { ...inputFields, state: value };
        setInputFields(updatedInputs);

        const newErrors = { ...errors, state: "" }
        setErrors(newErrors);
    };

    const handleCustomerSegment = (event) => {
        const { value } = event.target;
        const updatedInputs = { ...inputFields, customerSegment: value };
        setInputFields(updatedInputs);

        const newErrors = { ...errors, customerSegment: "" }
        setErrors(newErrors);
    };

    const handleBlur = (event, index) => {
        const { name, value } = event.target;
        const errorMsg = validateInputFields(name, value);
        const newErrors = { ...errors };
  
        newErrors[name] = errorMsg;
        setErrors(newErrors);
    };
    
    const fieldValidations = {
        dbaName: validateRequiredField,
        line1: validateRequiredField,
        city: validateRequiredField,
        state: validateRequiredField,
        zipcode: validateZipcode,
        customerSegment: validateRequiredField,
    };
    
    const validateInputFields = (field, value) => {
        const validationFunction = fieldValidations[field];
        return validationFunction ? validationFunction(value) : "";
    };

    const createTextField = (key, label, placeholder, value, onChange, onBlur, isRequired, error, index=null) => {
        const errorKey = index !== null ? `${key}${index}` : key;

        return (
            <>
                <FormLabel label={label} isRequired={isRequired}/>
                <TextField
                    id={errorKey}
                    name={errorKey}
                    data-testid={errorKey}
                    type="text"
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    focused
                    variant="outlined"
                    fullWidth
                    error={Boolean(error) || Boolean(errors[errorKey])}
                    helperText={error || errors[errorKey]}
                    sx={textFieldStyles(errors[errorKey])}
                    FormHelperTextProps={helperTextStyles}
                />
            </>
        )
    };

    const OrganizationForm = (
        <>
        <Spinner processing={isLoading} />
        <form onSubmit={handleSubmit}>
            <Container key='onboardingContactInfo' data-testid='onboardingContactInfo'>
                <Box display={'flex'} justifyContent={'left'} padding={'20px 0px'}>
                    <Typography fontSize={'24px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Your organization</Typography>
                </Box>
                <Grid container rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="center">
                    <Grid item xs={12} paddingBottom={'1px'}>
                        {createTextField("orgLegalName", "Official organization name (legal)", "e.g., Townsville Health Partners, LLC, Pacifico Companies Inc., etc", 
                            inputFields.orgLegalName, handleChange, handleBlur, false)}
                    </Grid>
                    <Grid item xs={12} paddingBottom={'1px'}>
                        {createTextField("dbaName", `“Doing business as” name`, "e.g., Townsville Pharmacies, Pacific Health Partners, etc", 
                            inputFields.dbaName, handleChange, handleBlur, true)}
                    </Grid>
                    <Grid item xs={12} paddingBottom={'1px'} gap={'24px'} data-testid='customerSegment'>
                        <Dropdown
                            dataTestId={"customerSegmentDropdown"}
                            name={"customerSegment"}
                            label={"Organization type"}
                            options={customerSegmentClasses}
                            value={inputFields.customerSegment}
                            onChangeHandler={handleCustomerSegment}
                            isRequired={true}
                            errors={errors}
                        />
                    </Grid>
                </Grid>

                <Box display={'flex'} justifyContent={'left'} padding={'20px 0px'} flexDirection={'column'} gap={2}>
                    <Typography fontSize={'24px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Your organization's legal address</Typography>
                    <Typography fontSize={'16px'} fontWeight={400} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">
                        Enter the address your organization uses for legal and contract documentation. This address can be used as your “Sold to” address and is financially responsible for orders. 
                    </Typography>
                </Box>

                {/* TO-DO: Move this into own address form component */}
                <Grid container rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" style={{ marginBottom: '8px' }}>
                    <Grid item xs={12} paddingBottom={'1px'}>
                        {createTextField("line1", "Address Line 1", "e.g., 123 Main St", inputFields.line1, handleChange, handleBlur, true)}
                    </Grid>
                    <Grid item xs={12} paddingBottom={'1px'}>
                        {createTextField("line2", "Suite Number", "e.g., Dock 5, Ste 301", inputFields.line2, handleChange, handleBlur, false)}
                    </Grid>
                    <Grid item xs={12} sm={4} paddingBottom={'1px'}>
                        {createTextField("city", "City", "City", inputFields.city, handleChange, handleBlur, true)}
                    </Grid>
                    <Grid item xs={12} sm={4} paddingBottom={'1px'} data-testid='state'>
                        <Dropdown
                            dataTestId={"stateDropdownSelection"}
                            name={"state"}
                            label={"State"}
                            options={USStates}
                            value={inputFields.state}
                            onChangeHandler={handleStateSelection}
                            isRequired={true}
                            errors={errors}
                        />
                     </Grid>
                    <Grid item xs={12} sm={2} paddingBottom={'1px'}>
                        {createTextField("zipcode", "ZIP Code", "12345", inputFields.zipcode, handleChange, handleBlur, true)}
                    </Grid>
                    <Grid item xs={12} sm={2} paddingBottom={'1px'}>
                        {createTextField("zip4", "ZIP +4", "0123", inputFields.zip4, handleChange, handleBlur, false)}
                    </Grid>
                </Grid>

                <Grid sx={buttonsContainerSxStyles}>
                    <Button 
                        type="submit"
                        id="submit"
                        sx={{ padding: "16px 24px", textTransform: "none" }}
                        buttonType="mds-primary"
                        data-testid='submitBtn'
                    >
                        Continue
                    </Button>
                </Grid>
            </Container>
        </form>
        </>
    )

    return (
        <Box maxWidth={'700px'}>{OrganizationForm}</Box>
    )
}