import React, {lazy, Suspense, useEffect, useState} from 'react';
import dayjs from "dayjs";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ArrowDropDownOutlined, ArrowDropUpOutlined, KeyboardArrowRightOutlined} from "@mui/icons-material";
import { StatusIndicators} from '../StatusIndicators';
import {NoRowsOverlay} from "../NoRowsOverlay";
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getOrdersApi} from "../../utils/OrderService/OrderApi";
import {loadOrderHistoryTableComponent} from "../../features/contentful/contentfulThunkApi";
import DataGridPagination from "../Pagination/DataGridPagination";

const DataGrid = lazy(() => import('../DataGrid'));

const mapStatus = (() => {
    const statuses = {
        'ON_HOLD':'On Hold',
        'INTERNAL_HOLD': 'On Hold',
        'CREATED':'Processing',
        'PENDING':'Processing',
        'CONFIRMED': 'Processing',
        'CANCELLED':'Cancelled',
        'COMPLETED': 'Shipped',
        'FULFILLED':'Shipped'
    };
    return (value) => statuses[value] ?? ''
})();

const statusMappingForIcon = {
    'ON_HOLD': 'warning',
    'INTERNAL_HOLD': 'warning',
    'CREATED': 'processing',
    'PENDING': 'processing',
    'CONFIRMED':'processing',
    'CANCELLED':'cancelled',
    'COMPLETED': 'checked',
    'FULFILLED': 'checked'
}

const LOCALE_CURRENCY_NAME_MAP = {
    'en-US': 'USD'
}

export const OrderHistoryList = ({ userId, handleErrorMsg, setLoading }) => {
    const { orderHistoryTableComponent } = useSelector((store) => store?.contentful);
    const isNewOrderingEnabled = process.env.IS_NEW_ORDER_EXPERIENCE_ENABLED === 'true';
    const locale = useSelector((state) => state.globalStates.locale);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [orderTableData, setOrderTableData] = useState([]);

    useEffect(() => {
    if (orderHistoryTableComponent && Object.keys(orderHistoryTableComponent).length === 0)
            dispatch(loadOrderHistoryTableComponent());
    }, [orderHistoryTableComponent, dispatch]);
    
    useEffect(() => {
        const fetchOrders = async () => {
            if (!userId) {
                handleErrorMsg('We are unable to fetch your orders. Please refresh and try again.');
                return;
            }

            try {
                const data = await getOrdersApi(userId);
                const tabledata = data?.map((item, index) => {
                    const { name, street1, street2 = '', zipCode, state } = item?.shipToAddress || {};
                    return {
                        id: index + 1,
                        orderNumber: item?.orderNumber,
                        subtotal: item?.orderPriceTotals?.subtotalContractPrice?.displayValue,
                        datePlaced: item?.orderCreatedDate?.split('T')[0],
                        poNumber: item?.purchaseOrderNumber ?? '-',
                        deliveryLocation: `${name} - ${street1},${street2},${zipCode},${state}`,
                        status: item?.orderStatus,
                        orderId: item?.orderId
                    };
                });
                setLoading(false);
                setOrderTableData(tabledata || []);
            } catch (error) {
                setLoading(false);
                handleErrorMsg(error);
            }
        }

        fetchOrders();
    }, []);
    

    const mobileColumns = [
        {
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: isNewOrderingEnabled?'Order Id':orderHistoryTableComponent?.modernaIdColumnLabel?.toUpperCase(),
            field: 'orderNumber',
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: true,
            headerName: `${orderHistoryTableComponent?.orderStatusColumnLabel?.toUpperCase()}`,
            field: 'status',
            flex: 1,
            renderCell: ({value}) => {
                return <StatusIndicators status={statusMappingForIcon[value] || value} children={mapStatus(value)}></StatusIndicators>
            }
        },
        {
            field: 'null',
            headerName: '',
            disableColumnMenu: true,
            sortable: false,
            width: 32,
            renderCell: ({row}) => {
                return <Link
                    to={`/${locale}/orders/${row.orderId}`}
                    underline={'none'}
                    sx={{
                        svg: {
                            color: 'secondary.main',
                            '&:hover':{
                                color: 'primary.darker',
                            }
                        }
                    }}
                    display={'flex'}
                    variant={'link'}>
                    <KeyboardArrowRightOutlined/>
                </Link>;
            }
        }
    ]

    const desktopColumns = [
        {
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: isNewOrderingEnabled?'Order Id':orderHistoryTableComponent?.modernaIdColumnLabel?.toUpperCase(),
            field: 'orderNumber',
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            headerName: `${orderHistoryTableComponent?.subtotalColumnLabel?.toUpperCase()}`,
            field: 'subtotal',
            renderCell: ({row}) => {
                return new Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency: LOCALE_CURRENCY_NAME_MAP[locale],
                }).format(row.subtotal)
            },
            flex: 1
        },

        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: false,
            headerName: `${orderHistoryTableComponent?.poNumberColumnLabel?.toUpperCase()}`,
            field: 'poNumber',
            flex: 1
        },
        {
            disableColumnMenu: true,
            headerName: `${orderHistoryTableComponent?.datePlacedColumnLabel?.toUpperCase()}`,
            field: 'datePlaced',
            flex: 1,
            renderCell: ({value}) => dayjs(value, 'YYYY-MM-DD').format('MMMM D, YYYY')
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: true,
            headerName: `${orderHistoryTableComponent?.orderStatusColumnLabel?.toUpperCase()}`,
            field: 'status',
            flex: 1,
            renderCell: ({value}) => {
                return <StatusIndicators status={statusMappingForIcon[value] || value} children={mapStatus(value)}></StatusIndicators>
            }
        },
        {
            type: 'nonSelectable',
            disableColumnMenu: true,
            sortable: false,
            headerName: `${orderHistoryTableComponent?.actionLabel?.toUpperCase()}`,
            field: 'action',
            flex: 1,
            renderCell: ({row}) => <ActionsComponent value={row}/>
        }
    ]

    const ActionsComponent = ({value}) => {
        return (
            <Box>
                <Link
                    to={`/${locale}/orders/${value?.orderId}`}
                    underline={'none'}
                    sx={{
                        color: 'primary.main',
                        fontFamily: 'Aeonik Bold',
                        '&:hover':{
                            color: 'primary.darker',
                        }
                    }}
                    display={'flex'}
                    variant={'link'}
                >
                    View
                </Link>
            </Box>
        )
    }

    const [, setApiRef] = useState(null);
    
    return (
        <>
            <Box mx={{xs:'32px', lg:'100px'}} my={'60px'}>
                <Typography variant={'h2'} mb={'10px'}>Orders</Typography>
                <Suspense>
                    <DataGrid
                        onApiRef={(ref) => setApiRef(ref)}
                        getRowId={(rows) => rows.id}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick={true}
                        sx={{
                            '.MuiDataGrid-iconButtonContainer': {
                                visibility: 'visible',
                            },
                            '.MuiDataGrid-sortIcon': {
                                opacity: 'inherit !important',
                            },
                            '.MuiDataGrid-virtualScroller': {
                                overflow: 'hidden !important',
                            },
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: "normal",
                                lineHeight: "20px"
                            }
                        }}
                        slots={{
                            pagination: DataGridPagination,
                            noRowsOverlay: NoRowsOverlay,
                            columnSortedAscendingIcon: ArrowDropUpOutlined,
                            columnSortedDescendingIcon: ArrowDropDownOutlined
                        }}
                        slotProps={{
                            noRowsOverlay: {
                                orderTableData
                            },
                            pagination: {
                                disabled: false
                            }
                        }}
                        getCellClassName={({field}) => ['confirmationNumber', 'date', 'poNumber', 'frieghtAmt', 'total'].includes(field) ? 'nonSelectable' : ''}
                        hideFooterSelectedRowCount={true}
                        checkboxSelection={false}
                        autoHeight={true}
                        columns={mobile ? mobileColumns : desktopColumns}
                        sortingOrder={['desc', 'asc']}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'datePlaced', sort: 'desc' }],
                            },
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        rows={orderTableData}/>
                </Suspense>
            </Box>
    </>
    );
};
