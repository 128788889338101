import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { isExternalURL } from "../../utils/Helper";
import { useSelector, useDispatch } from "react-redux";
import { loadFooterStaticContent } from "../../features/contentful/contentfulThunkApi";
import { styled } from '@mui/material/styles';
import { Box, IconButton, Typography, useTheme } from "@mui/material";

const SocialIconButtonBase = ({...props}) => {
  const handleMouseEnter = useCallback((event) => {
    event.target.classList.add('in');
    event.target.classList.remove('out');
  }, []);
  const handleMouseLeave = useCallback((event) => {
    event.target.classList.remove('in');
    event.target.classList.add('out');
  }, []);

  return <IconButton {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>;
};

const StyledIconButton = styled(SocialIconButtonBase, {
  shouldForwardProp: (propName) => !['color', 'hoverColor'].includes(propName)
})(({ theme }) => ({
  color: theme.palette.neutral.white,
  "&:hover": {
    backgroundColor: theme.palette.primary.blueFaded
  },
  "&.Mui-focusVisible": {
    border: `1px solid ${theme.palette.neutral.main}`
  }
}));
const FooterTitleStyled = styled('div')(({ theme: { palette: { neutral }, breakpoints }}) => `
  width: 100%;
  display: flex;
  flex-direction :row;
  align-items: flex-start;
  padding-bottom: 40px;
  border-bottom: 1px solid ${neutral.gray6};
  flex-wrap: wrap;
  ${[breakpoints.down('sm')]} {
    display:grid;
    padding-bottom:0px;
    border-bottom:0px;
    grid-template-rows: auto auto auto;
  }
`);

const FooterStyled = styled('footer')(({ theme: { palette: {primary, secondary}, breakpoints }}) => `
  background: ${secondary.main};
  padding: 60px 72px;
  color: ${primary.main};
  position: relative;
  font-family: "Aeonik Regular", "Arial";
  @media(max-width:620px){
    padding: 60px 20px;
  }
  ${[breakpoints.down('sm')]} {
    padding:20px;
  }
  @media print {
    display: none;
  }
`);

const FooterPolicyAndSocial = styled('div')(({ theme: { breakpoints }}) => `
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  ${[breakpoints.down('sm')]} {
    flex-direction: column-reverse;
    paddin-top:30px;
  }
`);

const LogoImage = styled('img')(({ theme: { breakpoints }}) => `
  margin-right: 62px;
  width: 164px;
  cursor: pointer;
  ${[breakpoints.up('md')]} {
    margin-right: 20px;
  }
  ${[breakpoints.down('sm')]} {
    margin-right: 62px;
    margin-top:40px;
    margin-bottom:20px;
  }
`);

const Boxing = styled('div')(({ theme: { breakpoints }}) => `
  display: flex;
  flex-direction: column;
  margin:0 80px;
  ${[breakpoints.down('lg')]} {
    margin: 0 45px;
  }
  ${[breakpoints.down('md')]} {
    margin: 0 20px;
  }
  @media(max-width: 620px){
    margin: 0 15px;
  }
  ${[breakpoints.down('sm')]} {
    margin:30px 0px;
  } 
`);

const ListItem = styled('li')`
  list-style-type: none;
  margin-bottom: 10px;
`;

const PrivacySpan = styled('span')`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: "Aeonik Medium", "Nunito", "Arial";
  letter-spacing: 0px;
`;

const SocialIcon = styled('img')(({ theme: { breakpoints }}) => `
  ${[breakpoints.down('sm')]} {
    margin-left: 0px;
    margin-right: 16px;
    margin-bottom:56px;
    border-bottom:1px
  }
`);

const styledLinkStyle = {
  color: 'primary.main',
  textDecoration: 'none'
}

const FooterLinkStyle = {
  color: 'primary.main',
  textDeecoration: 'none',
  paddingBottom: '12px'
};

const FooterCopyright = styled('p')(({ theme: { palette: { primary }, breakpoints }}) => `
  display: flex;
  color: ${primary.main};
  text-decoration: none;
  font-size: 14px;
  ${[breakpoints.down('sm')]} {
    flex-direction: column;
  }
`);

const Footer = (props) => {
  const [footerImageData, setFooterImageData] = useState([]);
  const [footerGroups, setFooterGroups] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [footerLinks, setFooterLinks] = useState([]);
  const [copyrightMessage, setCopyrightMessage] = useState([]);
  const [personalInfoLink, setPersonalInfoLink] = useState({});
  const [dataExternalModal, setDataExternalModal] = useState(true);
  const [dataExternalSocialModal, setDataExternalSocialModal] = useState(true);
  const locale = useSelector((state) => state.globalStates.locale);
  const { authState } = useOktaAuth() || '';
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const remindMeExternal = localStorage.getItem("dontRemindMeExternal");
  const remindMeSocial = localStorage.getItem("dontRemindMe");
  const { footerContent: footerData } = useSelector((store) => store?.contentful);
  const dispatch = useDispatch();
  const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);

  useEffect(() => {
      dispatch(loadFooterStaticContent({ title: 'Footer - ' + localeISOCode }))
  }, [dispatch, localeISOCode]);
  
  useEffect(() => {
    setCopyrightMessage(footerData?.copyrightMessage)
    setFooterImageData(footerData?.imageData)
    setSocialLinks(footerData?.socialLinks)
    setFooterGroups(footerData?.footerGroups)
    const footerLinks = footerData?.footerLinks || [];

    const itemsWithoutPersonalInfoLink = footerLinks.filter(item => {
      if (item?.fields?.title === "Personal Information FooterLink") {
        setPersonalInfoLink(item?.fields);
        return false;
      }
      return true;
    });

    setFooterLinks(itemsWithoutPersonalInfoLink);
  },[footerData, authState]);

  useEffect(() => {
    if(remindMeExternal === "true") {
      setDataExternalModal(false);
    }
  }, [remindMeExternal])

  useEffect(() => {
    if(remindMeSocial === "true") {
      setDataExternalSocialModal(false);
    }
  }, [remindMeSocial])

const handleLink = (url) => {
 if(locale !== 'en-US' && window.location.href.includes(locale)) {
    return window.location.origin + "/" + locale + "/" + url;
 }
  else{ 
    return "/" + locale + "/" + url;
  }
}

  const openExternalLinkHandler = (e, url, type) => {
    props?.setExternalLinkModalType(type);
    if(type === "extrenalLink"){
      if(isExternalURL(url) && !localStorage.getItem("dontRemindMeExternal")){
        props?.setShowNavigationAlert(true);
        props?.setExternalLink(url);
        e.preventDefault();
      }else{
        props?.setShowNavigationAlert(false);
        props?.setExternalLink("");
      }
    }else{
      if(isExternalURL(url) && !localStorage.getItem("dontRemindMe")){
        props?.setShowNavigationAlert(true);
        props?.setExternalLink(url);
        e.preventDefault();
      }else{
        props?.setShowNavigationAlert(false);
        props?.setExternalLink("");
      }
    }
  }

  return (
    <FooterStyled>
      <FooterTitleStyled>
        <Link to={authState?.isAuthenticated ? `/${locale}/home` : `/${locale}`} data-testid="footerLogoLink">
          <LogoImage id="footerLogo" src={footerImageData} alt="Moderna Logo" width="158px" />
        </Link>
        <Box display='grid' gridTemplateColumns={{xs: 'unset', sm: 'auto auto auto'}} gridTemplateRows={{xs: 'auto auto auto', sm: 'unset'}}>
          {footerGroups
            ? footerGroups.map((item, index) => (
                <Boxing key={index}>
                  <Typography variant='h6' mb='24px' letterSpacing='0px' textTransform='uppercase' id={"footerTitle"+index}>{item?.subHeading}</Typography>
                  <ul>
                    {item?.footerGroupLinks.map((link, index) => (
                      <ListItem key={index}>
                        {link?.fields?.url ? 
                          <Link id={item?.subHeading.replace(/\s+/g, '')+index} to={{ pathname: link?.fields?.url }} target="_blank" data-external-modal={dataExternalModal} onClick={(e)=> openExternalLinkHandler(e, link?.fields?.url, "extrenalLink")}>
                            <Typography variant={'p1'} sx={styledLinkStyle}>{link?.fields?.label}</Typography>
                          </Link> :
                            (link === "faqs" && authState?.isAuthenticated) ?
                            <Link style={styledLinkStyle} to={`/${locale}/`+link}>
                              <Typography variant={'p1'} sx={styledLinkStyle}>{item?.label[index]}</Typography>
                            </Link> :
                            <Link to={`/${locale}/`+link}>
                              <Typography variant={'p1'} sx={styledLinkStyle}>{item?.label[index]}</Typography>
                            </Link>
                        }
                      </ListItem>
                    ))}
                  </ul>
                </Boxing>
              ))
            : "No Footer Group"}
        <Boxing>
          <Typography variant='h6' mb='24px' letterSpacing='0px' textTransform='uppercase' data-testid='accreditationsLabel'>{footerData?.accreditationsLabel}</Typography>
          <Link to={{ pathname: handleLink(footerData.nabpAccreditedUrl) }} target="_blank" rel="noreferrer" data-external-modal={dataExternalModal} onClick={(e)=> openExternalLinkHandler(e, footerData?.nabpAccreditedUrl, "extrenalLink")}>
            <img src={footerData?.accreditationsLogo} height={150} width={150} data-testid='accreditationsLogo' />
          </Link>
        </Boxing>
        </Box>
      </FooterTitleStyled>
      <FooterPolicyAndSocial>
        {mobile ?
        <PrivacySpan style={{flexDirection:'column',alignItems:'start'}}>
          {footerLinks?.map((item, index) => {
            return (
              <Link id={"footerLink"+index} to={item?.fields?.url ? { pathname: handleLink(item?.fields?.url)} : { pathname: (handleLink(item.fields?.pageReference?.fields?.slug)) }} key={index} target="_blank" rel="noreferrer">
                <Typography variant={'p2'} sx={FooterLinkStyle}>{item.fields.label}</Typography>
              </Link>
            )
          })}
          </PrivacySpan>:
          <PrivacySpan>  
            {footerLinks?.map((item, index) => {
              return (
                <Link id={"footerLink"+index} to={item?.fields?.url ? { pathname: handleLink(item?.fields?.url)} : { pathname: (handleLink(item.fields?.pageReference?.fields?.slug)) }} key={index} target="_blank" rel="noreferrer">
                   <Typography variant={'p2'} sx={FooterLinkStyle}>{(index === 0 ? '' : '| ') + item.fields.label}&nbsp;</Typography>
                </Link>
              )
            })}
          </PrivacySpan>}
        <PrivacySpan id="socials">
          {socialLinks?.map((item, index) => (
            <Link to={{ pathname: `${item.fields.link.fields.url}` }} key={index} target="_blank" rel="noreferrer" data-external-modal={dataExternalSocialModal} onClick={(e)=> openExternalLinkHandler(e, item.fields.link.fields.url, "socialLink")}>
              <StyledIconButton disableTouchRipple size="small" data-link-href={item.fields.link.fields.url}>
                <SocialIcon src={item.fields.icon?.fields?.file?.url} alt={item.fields.link?.fields?.title}
                  height="32px" width="32px" viewBox={'0 0 32 32'}/>
              </StyledIconButton>
            </Link>
            
          ))}
        </PrivacySpan>
      </FooterPolicyAndSocial>

      <FooterCopyright id="personalInfoLink" key={personalInfoLink?.label} data-testid="copyrightMesssage">{personalInfoLink?.label} { mobile ? <br/> : <span>&nbsp;|&nbsp;</span> }
        {copyrightMessage}</FooterCopyright>
    </FooterStyled>
  );
};

export default Footer;
