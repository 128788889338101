import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { HandlerBarTemplateHandler } from "../../utils/Helper";
import { loadUserRegistrationFinalStepStaticContent } from "../../features/contentful/contentfulThunkApi";
import { Typography } from "@mui/material";

const Text = styled('ul')(({ theme: { palette: { primary }, breakpoints }}) => ({
  "& > p": {
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
    [breakpoints.down('sm')] : {
      fontSize: 20,
      marginBottom: 15
    }
  },
  listStyleType: "none",
  color: primary.main,
  display: "block",
  margin: "20px auto",
  fontFamily: 'Aeonik Regular',
  [breakpoints.down('sm')] : {
    margin: 30,
    fontSize: 14
  },
  "& li": {
    display: "flex",
    alignItems: "center",
    '&:before': {
      content: '"✓"',
    },
    "& > p": {
      paddingLeft: "10px"
    }
  }
}));

export const FinalStep = () => {
  const { userRegistrationFinalStepContent } = useSelector((store) => store?.contentful);
  const { staticContent = [] } = userRegistrationFinalStepContent;
  const dispatch = useDispatch();
  const [headline, setHeadline] = useState('');
  
  useEffect(()=>{
    const userData = JSON.parse(localStorage.getItem('contactInfo'));
    if(userData?.firstName){
      setHeadline(HandlerBarTemplateHandler({ firstName: userData?.firstName}, staticContent?.headline) || null);
      localStorage.removeItem('accountInfo');
      localStorage.removeItem('licenseInfo');
    }
  }, [staticContent?.headline])
  
  const options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => <div>{children}</div>
    }
  };

  useEffect(() => {
    window.scrollTo(0,0);
    if (Object.keys(userRegistrationFinalStepContent).length === 0) {
      dispatch(loadUserRegistrationFinalStepStaticContent({title : "Registration Confirmation Component"}));
    }
  },[]);

  return (
    <Box position='relative' m='60px auto 0 auto' width={{xs:'90%', sm:'60%'}}>
      <Box component={'img'} m={{xs: '20px auto 40px auto', sm: '20px auto'}} display='flex' src={staticContent?.icon?.fields?.file?.url} sx={{width:"90px",height:"90px"}}></Box>
      <Typography variant={'h2'} textAlign='center' color='primary.main'>{headline}</Typography>
      <Typography variant={'p1'} component={'div'} m='20px auto 60px auto' textAlign='center' color='primary.main' width='90%'>{staticContent?.message}</Typography>
      <Box m='20px 30px' display='flex' backgroundColor='secondary.main' borderRadius='16px' sx={{ flexGrow: 1 }}>
        <Text>{documentToReactComponents(staticContent?.benefitsMessage, options)}</Text>
      </Box>
    </Box>
  )
};