import React from 'react';

export const MastercardIcon = ({ color = "#000000", width = "32", height = "32" }) => (
        <svg width={width} height={height} viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon/Mastercard">
                <rect x="0.5" y="0.833374" width="31" height="20.3333" rx="1.5" fill="white" />
                <rect x="0.5" y="0.833374" width="31" height="20.3333" rx="1.5" stroke="#2A2A2A" />
                <g id="Group">
                    <path id="Intersect" fillRule="evenodd" clipRule="evenodd" d="M15.9984 6.05286C14.5592 7.1927 13.6367 8.9559 13.6367 10.9368C13.6367 12.9177 14.5592 14.6809 15.9984 15.8208C17.4377 14.6809 18.3602 12.9177 18.3602 10.9368C18.3602 8.9559 17.4377 7.1927 15.9984 6.05286Z" fill={color} />
                    <path id="Left" d="M15.5019 16.1797C14.0011 14.9301 13.0455 13.0458 13.0455 10.9368C13.0455 8.82789 14.0011 6.94356 15.5019 5.69398C14.5368 5.07407 13.3882 4.71082 12.1518 4.71082C8.7102 4.71082 5.92578 7.49524 5.92578 10.9369C5.92578 14.3785 8.7102 17.1629 12.1518 17.1629C13.3882 17.1629 14.5368 16.7996 15.5019 16.1797Z" fill={color} />
                    <path id="Right" d="M19.8462 17.1629C23.2878 17.1629 26.0722 14.3785 26.0722 10.9369C26.0722 7.51253 23.2878 4.71082 19.8462 4.71082C18.6098 4.71082 17.4611 5.07407 16.4961 5.69398C17.9968 6.94356 18.9524 8.82789 18.9524 10.9368C18.9524 13.0458 17.9968 14.9301 16.4961 16.1797C17.4611 16.7996 18.6098 17.1629 19.8462 17.1629Z" fill={color} />
                </g>
            </g>
        </svg>
);

export const DiscoverCardIcon = ({ color = "#000000", width = "32", height = "32" }) => (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 22" fill="none">
            <g clipPath="url(#clip0_12508_156585)">
                <rect y="0.333344" width="32" height="21.3333" rx="2" fill="white"/>
                <path d="M32 14.5556C32 14.5556 24.8836 19.4651 11.8518 21.6667H32V14.5556Z" fill={color}/>
                <path d="M3.61882 8.57086H2.37036V12.9444H3.61882C4.27883 12.9444 4.75595 12.7854 5.1774 12.4435C5.67838 12.03 5.9726 11.4097 5.9726 10.7656C5.96465 9.46943 5.00246 8.57086 3.61882 8.57086ZM4.62077 11.863C4.3504 12.1015 4.00847 12.2129 3.45183 12.2129H3.22122V9.31835H3.45183C4.00051 9.31835 4.3345 9.41377 4.62077 9.66823C4.91499 9.93065 5.08993 10.3362 5.08993 10.7577C5.08993 11.1791 4.91499 11.6006 4.62077 11.863Z" fill={color}/>
                <path d="M7.21316 8.57086H6.3623V12.9444H7.21316V8.57086Z" fill={color}/>
                <path d="M9.29648 10.2487C8.78756 10.0579 8.63647 9.93066 8.63647 9.70006C8.63647 9.42174 8.90683 9.21499 9.27262 9.21499C9.52709 9.21499 9.74179 9.31836 9.95649 9.57283L10.4018 8.99233C10.036 8.67425 9.59865 8.50726 9.11359 8.50726C8.34225 8.50726 7.74585 9.048 7.74585 9.76367C7.74585 10.368 8.02417 10.6781 8.81936 10.9644C9.15335 11.0837 9.32034 11.1632 9.40781 11.2109C9.5748 11.3223 9.66227 11.4813 9.66227 11.6642C9.66227 12.022 9.38395 12.2844 9.00226 12.2844C8.59671 12.2844 8.27068 12.0777 8.07188 11.704L7.52319 12.2367C7.91284 12.8172 8.38996 13.0717 9.03407 13.0717C9.91673 13.0717 10.5449 12.4832 10.5449 11.6324C10.5608 10.9247 10.2666 10.6066 9.29648 10.2487Z" fill={color}/>
                <path d="M10.8232 10.7656C10.8232 12.0538 11.8331 13.0478 13.1293 13.0478C13.4951 13.0478 13.8132 12.9763 14.1949 12.7934V11.7914C13.8529 12.1334 13.5508 12.2685 13.1691 12.2685C12.3103 12.2685 11.698 11.6483 11.698 10.7577C11.698 9.91477 12.3262 9.25476 13.1293 9.25476C13.5349 9.25476 13.845 9.39789 14.1949 9.74778V8.74583C13.8211 8.55498 13.511 8.47546 13.1452 8.47546C11.8649 8.47546 10.8232 9.49331 10.8232 10.7656Z" fill={color}/>
                <path d="M20.9699 11.5131L19.801 8.57086H18.8706L20.7234 13.0558H21.1846L23.0692 8.57086H22.1468L20.9699 11.5131Z" fill={color}/>
                <path d="M23.459 12.9444H25.8764V12.2049H24.3098V11.028H25.8207V10.2885H24.3098V9.31835H25.8764V8.57086H23.459V12.9444Z" fill={color}/>
                <path d="M29.2559 9.86705C29.2559 9.04799 28.6913 8.57883 27.7053 8.57883H26.4409V12.9524H27.2918V11.195H27.4031L28.58 12.9524H29.6297L28.254 11.1076C28.8981 10.9724 29.2559 10.535 29.2559 9.86705ZM27.5383 10.5907H27.2918V9.2627H27.5542C28.087 9.2627 28.3732 9.48535 28.3732 9.91476C28.3732 10.3521 28.087 10.5907 27.5383 10.5907Z" fill={color}/>
                <path d="M16.8109 13.1035C18.0977 13.1035 19.1408 12.0603 19.1408 10.7736C19.1408 9.48678 18.0977 8.44363 16.8109 8.44363C15.5241 8.44363 14.481 9.48678 14.481 10.7736C14.481 12.0603 15.5241 13.1035 16.8109 13.1035Z" fill={color}/>
            </g>
            <rect x="0.5" y="0.833344" width="31" height="20.3333" rx="1.5" stroke="#D8D8D8" style={{ mixBlendMode: 'difference' }}/>
            <defs>
                <clipPath id="clip0_12508_156585">
                    <rect y="0.333344" width="32" height="21.3333" rx="2" fill="white"/>
                </clipPath>
            </defs>
        </svg>
);

export const VisaCardIcon = ({ color = "#000000", width = "32", height = "32" }) => (
        <svg width={width} height={height} viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon/Visa">
            <rect x="0.5" y="0.833344" width="31" height="20.3333" rx="1.5" fill="white"/>
            <rect x="0.5" y="0.833344" width="31" height="20.3333" rx="1.5" stroke="#2A2A2A"/>
            <path id="Visa Logo" d="M19.7667 6.83527C17.9995 6.83527 16.4202 7.75124 16.4202 9.44358C16.4202 11.3844 19.2211 11.5184 19.2211 12.4934C19.2211 12.904 18.7506 13.2714 17.9471 13.2714C16.8067 13.2714 15.9544 12.758 15.9544 12.758L15.5897 14.4657C15.5897 14.4657 16.5716 14.8995 17.8751 14.8995C19.8073 14.8995 21.3276 13.9385 21.3276 12.2172C21.3276 10.1664 18.5151 10.0364 18.5151 9.1314C18.5151 8.80981 18.9013 8.45744 19.7025 8.45744C20.6066 8.45744 21.3441 8.83089 21.3441 8.83089L21.7011 7.18149C21.7011 7.18149 20.8985 6.83527 19.7667 6.83527ZM3.59846 6.95975L3.55566 7.20872C3.55566 7.20872 4.29913 7.34478 4.96874 7.6162C5.83093 7.92745 5.89235 8.10863 6.03755 8.6714L7.61985 14.7711H9.74093L13.0086 6.95975H10.8924L8.79272 12.2707L7.93592 7.76889C7.85735 7.25366 7.45934 6.95975 6.97215 6.95975H3.59846ZM13.8596 6.95975L12.1995 14.7711H14.2175L15.8717 6.95975H13.8596ZM25.1146 6.95975C24.628 6.95975 24.3702 7.22027 24.181 7.67553L21.2245 14.7711H23.3407L23.7502 13.5885H26.3283L26.5773 14.7711H28.4446L26.8156 6.95975H25.1146ZM25.3898 9.07013L26.0171 12.0013H24.3366L25.3898 9.07013Z" fill={color}/>
        </g>
    </svg>

);

export const AmexCardIcon = ({ color = "#000000", width = "32", height = "32" }) => (
                <svg width={width} height={height} viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Icon/Amex">
                        <rect x="0.5" y="0.833252" width="31" height="20.3333" rx="1.5" fill="white"/>
                        <rect x="0.5" y="0.833252" width="31" height="20.3333" rx="1.5" stroke="#2A2A2A"/>
                        <g id="AmEx Logo">
                            <path fillRule="evenodd" clipRule="evenodd" d="M31.6907 16.1556V18.4738H28.7467L27.6587 17.2009L26.528 18.4738H18.1654V11.6969H15.4062L18.8552 3.88892H22.2116L23.0222 5.65958V3.88892H27.1894L27.8862 5.74492L28.5689 3.88892H31.6907V4.99113H29.28L28.1849 7.87826L27.8934 8.66046L26.4925 4.99113H24.0818V10.68L21.5787 4.99113H19.6374L17.12 10.68H18.7698L19.2107 9.58492H21.9698L22.4107 10.68H24.0818H25.5325V6.97514L25.5254 6.22846L25.8098 6.97514L27.1965 10.68H28.576L29.9698 6.97514L30.24 6.23558V10.68H31.6907V12.9058L30.0836 14.52L31.6907 16.1556ZM19.3316 17.3716V11.704H24.0818V12.9342H20.7822V13.9298H24.0178V15.1529H20.7822V16.1413H24.0818V17.3716H19.3316ZM31.2498 17.3716H29.3654L27.6445 15.5227L25.9165 17.3716H24.0818L26.7342 14.5485L24.0818 11.704H25.9734L27.68 13.5458L29.3938 11.704H31.2498L28.5902 14.5271L31.2498 17.3716Z" fill={color}/>
                            <path d="M20.5902 6.20713L20.3058 6.91826L19.7156 8.35467H21.4649L20.8747 6.91826L20.5902 6.20713Z" fill={color}/>
                        </g>
                    </g>
                </svg>
);

export const BankIcon = ({ color = "#000000", width = "32", height = "32" }) => (
        <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Bank">
                <path id="Vector" d="M3 12H29L16 4L3 12Z" stroke="#343330" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_2" d="M7 12V22" stroke="#343330" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_3" d="M13 12V22" stroke="#343330" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_4" d="M19 12V22" stroke="#343330" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_5" d="M25 12V22" stroke="#343330" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_6" d="M4 22H28" stroke="#343330" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_7" d="M2 26H30" stroke="#343330" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
);

export const GenericCardIcon = ({ color = "#000000", width = "32", height = "32" }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="5.83334" width="31" height="20.3333" rx="1.5" fill="white"/>
        <rect x="0.5" y="5.83334" width="31" height="20.3333" rx="1.5" stroke="#383D47"/>
        <rect x="1" y="9" width="30" height="4" fill="#383D47"/>
        <rect x="21" y="18" width="8" height="6" rx="1" fill="#383D47"/>
    </svg>
);