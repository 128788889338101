import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { Box, Typography } from '@mui/material';

const GridContainer = styled(Grid)(({ theme: { palette: { neutral, secondary  }, breakpoints }}) => `
  background-color: ${secondary.main};
  border-radius: 16px;
  padding: 10px 10px 26px 10px;
  margin: 0 !important;
  width: 100% !important;
  &&.edit {
    background-color: ${neutral.gray5};
  }
`);

const Label = styled(Typography)(({ theme: { palette: { primary }, breakpoints }}) => `
  color: ${primary.main};
  margin-top: 5px;
  margin-Bottom: 5px;
  text-transform: uppercase;
  ${[breakpoints.down('sm')]} {
    line-height: 16.5px;
  }
`);

const Value = styled(Typography)(({ theme: { breakpoints } }) => `
  user-select: text;
  ${[breakpoints.down('sm')]} {
    line-height: 24px;
  }
`);

export const AccountDetails = ({ staticContent, userInfo, pageType }) => {
    const soldToAddress = userInfo?.orgUnit?.addresses.filter(address => address?.partnerFunction === "SOLD_TO")[0];
    const accountName = userInfo?.legalAccountName || '-'
    const officeAddress = soldToAddress?.formattedAddress || '-';
    const officePhone = soldToAddress?.phone || '-';
    const officeEmail = soldToAddress?.email || '-';
    return(
        <Box margin='30px auto 50px auto' width='80%' id="AccountDetails" data-testid='accountDetails'>
            <Typography variant={'h3'} fontFamily={'Aeonik Light'} color='primary.main' textAlign='left' mb='25px' mt='30px' data-testid='accountDetailsSectionLabel'>{staticContent?.accountDetailsSectionLabel}</Typography>
            <GridContainer container spacing={2} className={pageType}>
                <Grid item xs={12} md={12}>
                    <Label variant={'h6'} id="accountNameLabel" data-testid='accountNameLabel'>{staticContent?.accountNameLabel}</Label>
                    <Value variant={'p1'} id="accountNameVal" data-testid='accountName'>{accountName}</Value>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label variant={'h6'} id="officeAddressLabel" data-testid='officeAddressLabel'>{staticContent?.officeAddressLabel}</Label>
                    <Value variant={'p1'} id="officeAddress"  data-testid='officeAddress'>{officeAddress}</Value>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label variant={'h6'} id="officePhoneLabel" data-testid='officePhoneLabel'>{staticContent?.officePhoneLabel}</Label>
                    <Value variant={'p1'} id="officePhone" data-testid='officePhone'>{officePhone}</Value>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label variant={'h6'} id="officeEmailLabel" data-testid='officeEmailLabel'>{staticContent?.officeEmailLabel}</Label>
                    <Value variant={'p1'} id="officeEmail" data-testid='officeEmail'>{officeEmail}</Value>
                </Grid>
            </GridContainer>
        </Box>
    )
}