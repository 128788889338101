import * as React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Warning from "../../assets/images/warning.svg";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import IconButton from "@mui/material/IconButton";
import { Search } from "@mui/icons-material";

const CustomTextInput = styled(TextField)(
    ({ stylevariant, theme: { palette: { primary, status, neutral }, ...props }}) => {
        const styleVariantsMapping = {
            border : {
                "& .MuiInput-root": {
                    marginTop: '25px',
                },
                "& input": {
                    fontSize: "16px",
                    padding: "2px 10px 2px",
                    border: '1px solid',
                    borderColor: neutral.gray2,
                    borderRadius: '5px',
                    minHeight: '30px',
                    marginTop: '5px',
                },
            },
            reservationOrder : {
                "& .MuiInput-root": {
                    marginTop: '8px',
                },
                "& input": {
                    fontSize: "16px",
                    padding: "2px 10px 2px",
                    border: '1px solid',
                    borderColor: neutral.gray2,
                    borderRadius: '5px',
                    minHeight: '44px',
                    marginTop: '5px',
                },
            }, 
            reservationAddressForm : {
                "& .MuiInput-root": {
                    paddingTop: '7px',
                },
                "& input": {
                    fontSize: "16px",
                    padding: "12px",
                    border: '1px solid',
                    borderColor: neutral.gray7,
                    borderRadius: '6px',
                    minHeight: '22px',
                    marginTop: '5px',
                },
               
            }
        };

        const variantStyles = styleVariantsMapping[stylevariant]

        return {
            "& .MuiInput-root": {
                marginTop: '15px',
            },
            "& textarea": {
                height: '70px !important',
                overflow: 'hidden',
                border: '1px solid',
                borderColor: neutral.gray2,
                padding: '10px',
                borderRadius: '6px',
            },
            "& input": {
                fontSize: "16px",
                padding: "7px 0 5px",
                border: 'none',
                borderColor: 'none',
                borderRadius: 'none',
                minHeight: 'auto',
                marginTop: '0px',
            },
            ...variantStyles,
            "& label": {
                color: primary.main,
                fontFamily:"Aeonik Bold",
                fontSize: "20px",
                "&.Mui-error": {
                    color: status.error,
                },
                "&.Mui-focused": {
                    color: primary.main,
                },
                "& .subLabel": {
                    color: neutral.gray1,
                    fontSize: "14px"
                },
            },
            "& .MuiFormHelperText-root": {
                fontFamily: "Aeonik Regular, Nunito",
                color: neutral.main,
                fontSize: '12px',
                "&.Mui-error": {
                    color: status.error,
                },
            },
            "& .MuiInput-underline": {
                "&:after": {
                    borderBottomColor: 'transparent',
                },
                "&.Mui-error:after": {
                    borderBottomColor: status.error,
                },
            },
            "& .MuiInput-input": {
                fontFamily: "Aeonik Regular, Nunito",
                color: primary.main,
            },
        };
    }
);

export const TextInput = ({
                              id,
                              label,
                              inputType,
                              value,
                              onChange,
                              error,
                              showpassword,
                              handleClickShowPassword,
                              handleAndornmentClick,
                              handleMouseDownPassword,
                              fieldType,
                              multiline,
                              icon,
                              iconColor,
                              name,
                              maxLength,
                              stylevariant,
                              ...props
                          }) => {
    const iconType = error ? "error" : fieldType;
    const getIcon = (iconType) => {
        switch (iconType) {
            case "error":
                return (
                    <InputAdornment position="end">
                        <img
                            src={Warning}
                            alt="Warning Red Icon"
                            height="24px"
                            width="24px"
                        />
                    </InputAdornment>
                );
            case "password":
                return (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end">
                            {showpassword ? <VisibilityOutlinedIcon sx={{color: 'primary.blue', fontSize: 18}}/> : <VisibilityOffOutlinedIcon sx={{color: 'primary.blue', fontSize: 18}}/> }
                        </IconButton>
                    </InputAdornment>
                );
            case "search":
                return (
                    <InputAdornment position="end">
                        <IconButton aria-label="search button" edge="end" onClick={handleAndornmentClick}>
                            {icon ? <img src={icon} alt="Search Icon" /> : <Search style={{ color: iconColor }}/>}
                        </IconButton>
                    </InputAdornment>
                );
            default:
                return "";
        }
    };

    const FieldLabel = props?.sublabel ? <>{ label } <span className="subLabel">{props?.sublabel }</span></> : label;
    return (
        <CustomTextInput
            id={label?.replace(/\s+/g, '')}
            name={name}
            label={ FieldLabel }
            value={value}
            type={inputType}
            variant="standard"
            stylevariant={stylevariant}
            error={error}
            onChange={onChange}
            multiline={multiline}
            inputProps={{
                maxLength: maxLength
            }}
            InputProps={{
                endAdornment: getIcon(iconType),
                sx: {
                    "&:before": {
                        borderBottom: stylevariant ? "transparent": "2px solid #c0c0c0",
                    },
                    "&:hover:not(.Mui-disabled):before": {
                        borderBottom: stylevariant ? "transparent": "2px solid #c0c0c0",
                    },
                    "&.Mui-error:hover:not(.Mui-disabled):before": {
                        borderBottom: "none",
                    },
                },
            }}
            {...props}
        />
    );
};