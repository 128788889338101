"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.locales = void 0;
exports.locales = [
    'de-AT',
    'de-BE',
    'de-CH',
    'de-DE',
    'de-LI',
    'de-LU',
    'en-AE',
    'en-AU',
    'en-BN',
    'en-BT',
    'en-BW',
    'en-CA',
    'en-GB',
    'en-HK',
    'en-IE',
    'en-IL',
    'en-KW',
    'en-MT',
    'en-PH',
    'en-QA',
    'en-SA',
    'en-SG',
    'en-TM',
    'en-US',
    'es-AD',
    'es-AR',
    'es-CL',
    'es-CO',
    'es-ES',
    'es-LATAM',
    'es-PE',
    'es-PY',
    'es-US',
    'fr-BE',
    'fr-CA',
    'fr-CH',
    'fr-FR',
    'fr-LU',
    'fr-MC',
    'it-CH',
    'it-IT',
    'it-SM',
    'ja-JP',
    'ko-KR',
    'nl-BE',
    'nl-NL',
    'pt-BR',
    'pt-PT',
    'zh-HK',
    'zh-SG',
    'zh-TW'
];
