import axios from "axios";
import { OktaAuth, urlParamsToObject } from "@okta/okta-auth-js";
import { oidcConfig } from "../config";

const baseUrl = process.env.ORCHESTRATOR_BASE_URL;

const authAxiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json"
  }
});


const getOktaAuth = (() => {
  const { state } = urlParamsToObject(window.location.search);
  return new OktaAuth(
    Object.assign({}, oidcConfig, {
      state,
    })
  );
});

authAxiosInstance.interceptors.request.use(async req => {
  let oktaAccessToken = getOktaAuth().getAccessToken();
  req.headers["Authorization"] = `Bearer ${oktaAccessToken}`;
  return req;
});

export default authAxiosInstance;
