import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from '@mui/material/styles';
import { Checkbox } from "../";

const FilterLabels = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Filter = ({
  onSelection = (selected) => console.log(selected),
  filterOptions = null,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          borderRadius: "6px !important",
          boxShadow: "none",
          borderBottom: `1px solid #D1D1D1`
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            justifyContent: "space-between",
            alignItems: "flex-start",
            padding: 0,
            "& .MuiAccordionSummary-content": {
              margin: "0px",
              "&.Mui-expanded": {
                marginBottom: "16px",
                marginTop: "0px !important",
              },
            },
          }}
        >
          <FilterLabels>
            <Typography
              sx={{ fontSize: "16px", color: "#222222", fontWeight: "medium" }}
            >
              Label
            </Typography>
            <Typography
              sx={{ fontSize: "16px", color: "#222222", opacity: "0.38" }}
            >
              Selected Items
            </Typography>
          </FilterLabels>
        </AccordionSummary>
        <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
          {filterOptions ? (
            filterOptions.map((option, index) => (
              <Checkbox
                key={index}
                label={option.label}
                value={option.value}
                onChange={onSelection}
              />
            ))
          ) : (
            <>
              <Checkbox
                label="Checked"
                value="first"
                onChange={onSelection}
                checkedByDefault
              />
              <Checkbox
                label="Unchecked"
                value="second"
                onChange={onSelection}
              />
              <Checkbox
                label="Unchecked"
                value="third"
                onChange={onSelection}
              />
              <Checkbox
                label="Unchecked"
                value="fourth"
                onChange={onSelection}
              />
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
