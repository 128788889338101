import { useEffect } from "react";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Typography,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import { loadOrderItemGridTableComponent } from "../../features/contentful/contentfulThunkApi";
import {getAnalyticsProductDataAttributes} from "../../utils/Helper";


export const OrderItemGrid = ({ productsData }) => {
  const dispatch = useDispatch();
  const { orderItemGridTableComponent: orderItemGridTableComponent } = useSelector((store) => store?.contentful);

  useEffect(() => {
    if (orderItemGridTableComponent && Object.keys(orderItemGridTableComponent).length === 0)
      dispatch(loadOrderItemGridTableComponent());
  }, [orderItemGridTableComponent, dispatch]);

  const { commerceUser } = useSelector((store) => store?.commerce);
  const gpoName = commerceUser?.data?.orgUnit?.defaultGPO?.gpoName || null;

  return (
      <Box display="flex" flexDirection="column" justifyContent="start">
        <TableContainer component={Paper} sx={{boxShadow: "none"}}>
          <Table aria-label="item table">
            <TableHead
                sx={{display: {xs: 'none', sm: "table-header-group"}}}
            >
              <TableRow>
                <TableCell align="left" sx={{paddingLeft: 0}}>
                  <Typography variant="h6" color="primary.main">
                    {orderItemGridTableComponent?.productLabel}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6" color="primary.main">
                    {orderItemGridTableComponent?.typeLabel}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6" color="primary.main">
                    {orderItemGridTableComponent?.ageLabel}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6" color="primary.main">
                    {orderItemGridTableComponent?.quantityLabel}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6" color="primary.main">
                    {orderItemGridTableComponent?.dosePriceLabel}
                  </Typography>
                </TableCell>
                <TableCell align="left" sx={{paddingRight: 0}}>
                  <Typography variant="h6" color="primary.main">
                    {orderItemGridTableComponent?.subtotalLabel}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
                sx={{display: {xs: 'none', sm: "table-row-group"}}}
            >
              {productsData?.map((item, index) => (
                  <TableRow key={index} {...getAnalyticsProductDataAttributes(item?.product)}>
                    <TableCell align="left" sx={{paddingLeft: 0}}>
                      <Box display="flex" alignItems="center">
                        {item?.product?.images && (
                            <Box
                                component={"img"}
                                title="item image"
                                src={item?.product.images[0].url}
                                sx={{marginRight: "32px"}}
                                width={46}
                                height={46}
                            />
                        )}
                        <Typography variant="p2">{item?.product?.name ?? '-'}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="p2">{item?.product?.series ?? '-'}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="p2">{item?.product?.ageGroup ?? '-'}</Typography>
                    </TableCell>
                    <TableCell align="left" display="grid">
                      <Typography variant="p2" component={"p"}>
                        {item?.quantity * item?.product?.dosesPerCarton ?? '-'} {orderItemGridTableComponent?.dosesLabel}
                      </Typography>
                      <Typography
                          variant="p2"
                          component={"p"}
                          color={"neutral.gray1"}
                      >
                        ({item?.quantity} {orderItemGridTableComponent?.cartonsLabel})
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                        <Box display="flex" flexDirection="column"  marginLeft={3}>
                          {gpoName &&
                              <Typography variant="p2">
                                {item?.dosePrice.formattedValue ?? '-'}
                              </Typography>
                          }
                          <Typography
                              variant="p2"
                              color={"neutral.gray1"}
                              sx={{textDecoration: gpoName ? 'line-through' : 'none'}}
                          >
                            {item?.defaultDosePrice.formattedValue ?? '-'}
                          </Typography>
                        </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="p2">
                        {item?.totalPrice.formattedValue ?? '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>

          {/*Mobile Screen*/}
          {productsData?.map((item, index) => (
              <Box key={index}
                   sx={{
                     display: {xs: 'flex', sm: "none"},
                     mt: 4,
                     justifyContent: "center",
                   }}
              >
                {item?.product?.images && (
                    <Box
                        component={"img"}
                        title="item image"
                        src={item?.product.images[0].url}
                        sx={{marginRight: "32px"}}
                        width={46}
                        height={46}
                    />
                )}

                <Box display="flex" flexDirection={"column"}>
                  <Typography variant="p2">{item?.product?.name ?? '-'}</Typography>
                  <Typography variant="p2">{item?.product?.series ?? '-'}</Typography>
                  <Typography variant="p2" sx={{mb: 2}}>
                    {item?.product?.ageGroup ?? '-'}
                  </Typography>
                  <Typography variant="p2" component={"p"} sx={{mb: 2}}>
                    {item?.quantity * item?.product?.dosesPerCarton ?? '-'} {orderItemGridTableComponent?.dosesLabel}
                    <Typography variant="p2" color={"neutral.gray1"} sx={{mx: 1.5}}>
                      ({item?.quantity} {orderItemGridTableComponent?.cartonsLabel})
                    </Typography>
                  </Typography>
                  {gpoName &&
                      <Typography variant="p2">
                        {item?.dosePrice.formattedValue ?? '-'}
                      </Typography>
                  }
                  <Typography
                      variant="p2"
                      color={"neutral.gray1"}
                      sx={{textDecoration: gpoName ? 'line-through' : 'none'}}
                  >
                    {item?.defaultDosePrice.formattedValue ?? '-'}
                  </Typography>
                  <Typography variant="p2">
                    {item?.totalPrice.formattedValue ?? '-'}
                  </Typography>
                </Box>
                <Divider orientation="horizontal" variant="middle"/>
              </Box>
          ))}
        </TableContainer>
      </Box>
  );
};