import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { UserRegistration } from "../components";
import aboveFoldBGImage from "../assets/images/processing.svg";
import { ProgressBar } from "../components";
import { useHistory } from "react-router-dom";

const BackgroundContainer = styled('div')(
  ({ aboveFoldBGImage }) => `
    background-image: url("https://images.ctfassets.net/n9bebhw263o0/5R55rCHvR2pclS30rERenZ/5206bb28fbe5380812b856a80145123d/Res_BG3.png");
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: 90%;
    background-position-y: bottom;
    padding-bottom: 10px;
    min-height: 800px;
`);

const UserRegistrationPage = () => {
  const [progressValue, setProgressValue] = useState(16);
  const [comp, setComp] = useState('licenseInfo');
  const history = useHistory();
  const isASMuser = localStorage.getItem("employeeUser") ? true : false;
  
  useEffect(() => {
    isASMuser && setProgressValue(20)
  }, [isASMuser])

  useEffect(() => {
    
    if(localStorage.getItem("contactInfo")){
      localStorage.removeItem("contactInfo");
    }
    if(localStorage.getItem("currentUser") && localStorage.getItem("currentUser") !== "underfined"){
      history.push("/home")
    }
  }, [history]);
  
   return (
    <BackgroundContainer aboveFoldBGImage={aboveFoldBGImage}>
      <ProgressBar progressValue={progressValue} />
      <UserRegistration 
        comp={comp} 
        setComp={setComp}
        progressValue={progressValue} 
        setProgressValue={setProgressValue}
        isASMuser={isASMuser}
      />
    </BackgroundContainer>
  );
};


export default UserRegistrationPage
