import * as React from "react";
import { Accordion } from "../Accordion";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { Link } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";

const accordionCustomStyle = {
  borderRadius: "0px !important",
  boxShadow: "none",
  borderBottom: "1px solid #D1D1D1",
  borderTop: "none",
  margin: "0px !important",
};

export const FaqComponent = ({ title, data, index, iconUrl }) => {
  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return <Link to={node?.data?.target?.fields?.pageReference?.fields?.slug}>
          {children}
        </Link>;
      },
      [BLOCKS.PARAGRAPH]: (_,children)=> <p style={{whiteSpace: "pre-wrap"}}>{children}</p>,
      [BLOCKS.UL_LIST]: (_, children) => <ul style={{ listStyleType: "initial", marginLeft: "20px"}}>{children}</ul>,
    },
  };
  const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);
  return (
    <Box id={`faqContainer-${index}`}>
      <Box display={'flex'} alignItems={'center'} pt={3} sx={{height: "60px"}} id={`faqHeader-${index}`}>
        <Box component='img' sx={{float: "left", width: "45px", height: "45px"}} id={`faqHeaderImg-${index}`} src={iconUrl} alt="logo" />
        <Typography variant={'h2'} ml={2} sx={{color:'primary.main'}} id={`faqHeaderTitle-${index}`}>{title}</Typography>
      </Box>
      <Box mt={1} m={"10px 60px 0 60px"}>
        {data.length &&
          data.map((faqValue, index) => (
            faqValue?.fields?.country?.fields?.isocode === localeISOCode &&
              <Accordion
                id={`accordion-${index}`}
                key={index}
                heading={faqValue?.fields?.question}
                description={documentToReactComponents(faqValue?.fields?.answer, options)}
                accordionCustomStyle={accordionCustomStyle}
              />
          ))}
      </Box>
    </Box>
  );
};
