import React, { useState } from "react";
import Box from "@mui/material/Box";
import { LicenseInfo } from "./LicenseInfo";
import { ContactInfo } from "./ContactInfo";
import { SetPassword } from "./SetPassword";
import { AccountFound } from "./AccountFound";
import { Review } from "./Review";
import { FinalStep } from "./FinalStep";
import { AsmUserFinalStep } from "./AsmUserFinalStep"; 
import useAddressSorting from "../../utils/useShipToSoldToAddresses";

export const UserRegistration = ({comp, setComp, progressValue, setProgressValue, ...props}) => {
  const [accounts, setAccounts] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [shipToAddresses, soldToAddresses] = useAddressSorting(addresses);
  const isASMuser = props?.isASMuser || false;
  const [accountStatus, setAccountStatus] = useState("")

  return (
    <Box sx={{ flexGrow: 1 }} data-form-name="not brand aligned|hcp|sign-up form" data-form-type="signup form|non-dynamic form">
      {
      isASMuser 
      ? 
      <>
        {
          progressValue === 20 && comp === 'licenseInfo' ? <LicenseInfo setComp={setComp} setProgressValue={setProgressValue} setAccounts={setAccounts} setAddresses={setAddresses} isASMuser={true}/> :
          progressValue === 40 && comp === 'accountFound' ? <AccountFound setComp={setComp} setProgressValue={setProgressValue} accounts={accounts} setAccountStatus={setAccountStatus} shipToAddresses={shipToAddresses} soldToAddresses={soldToAddresses} isASMuser={true}/> :
          progressValue === 60 && comp === 'contactInfo' ? <ContactInfo setComp={setComp} setProgressValue={setProgressValue} isASMuser={true} accountStatus={accountStatus}/> :
          progressValue === 80 && comp === 'review' ? <Review setComp={setComp} setProgressValue={setProgressValue}  accountStatus={accountStatus} shipToAddresses={shipToAddresses} soldToAddresses={soldToAddresses} isASMuser={true}/> :
          progressValue === 100 && comp === 'finalStep' ? <AsmUserFinalStep setComp={setComp} setProgressValue={setProgressValue} isASMuser={true}/> : ''
        }
      </>
      :
      <>
        {
          progressValue === 16 && comp === 'licenseInfo' ? <LicenseInfo setComp={setComp} setProgressValue={setProgressValue} setAccounts={setAccounts} setAddresses={setAddresses} /> :
          progressValue === 33 && comp === 'accountFound' ? <AccountFound setComp={setComp} setProgressValue={setProgressValue} setAccountStatus={setAccountStatus} accounts={accounts} shipToAddresses={shipToAddresses} soldToAddresses={soldToAddresses} /> :
          progressValue === 50 && comp === 'contactInfo' ? <ContactInfo setComp={setComp} setProgressValue={setProgressValue} accountStatus={accountStatus}/> :
          progressValue === 66 && comp === 'setPassword' ? <SetPassword setComp={setComp} setProgressValue={setProgressValue}/> :
          progressValue === 82 && comp === 'review' ? <Review setComp={setComp} setProgressValue={setProgressValue} shipToAddresses={shipToAddresses} soldToAddresses={soldToAddresses} /> : 
          progressValue === 100 && comp === 'finalStep' ? <FinalStep setComp={setComp} setProgressValue={setProgressValue}/> : ''
        }
      </>
      }
    </Box>
  );
};
