import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import { Box, Divider, Typography, styled } from '@mui/material';
import { loadOrderSummaryComponentContent } from "../../features/contentful/contentfulThunkApi";
import { Button, Checkbox } from "../../components";
import CustomCard from "../CustomCardComponent/CustomCard";
import { setReservationData } from '../../features/commerce/commerceSlice';
import { setIsConfirmationPageAccessible } from '../../features/globalStates/globalStatesSlice';
import { confirmReservationApi } from '../../utils/Reservation/ReservationApi';
import { formattedPrice } from '../../utils/Helper';
import { useUserInfoContext } from "../../contexts";
import {BLOCKS, INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import { UNAVAILABLE } from '../../constants';

const TypographyTitle = styled(Typography)(({ theme: { breakpoints } }) => `
    font-family: Aeonik Regular;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
`);

const TypographyList = styled(Typography)(({ theme: { breakpoints } }) => `
    font-family: Aeonik Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
`);

const TypographyText = styled(Typography)(({ theme: { breakpoints } }) => `
    font-family: Aeonik Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`);

// const SHIP_WHEN_READY = "SHIP_WHEN_READY"; // Not currently used, see ticket ECOM-912 and PR #1254
const APPROVE_BEFORE_SHIP = "APPROVE_BEFORE_SHIP";

const ReservationSummaryCard = ({totalDoses, listPrice, totalPrice, editReservationID, handleAlert, showContractPrice}) => {
  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.globalStates.locale);
  const { orderSummaryComponentContent } = useSelector(store => store.contentful);
  const reservation = useSelector(store => store?.commerce?.reservation?.reservation);
  const reservationId = reservation?.reservationId;
  const productRows = reservation?.reservationEntries?.filter(row => row?.quantity !== 0);
  const shippingPreference = APPROVE_BEFORE_SHIP;
//   const [shippingPreference, setShippingPreference] = useState(reservation?.userShippingPreference || SHIP_WHEN_READY); //Not currently used, see ticket ECOM-912 and PR # 1254
  const { userInfo } = useUserInfoContext() || '';

  const reservationUpdate = {
    reservation: {
        ...reservation,
        userShippingPreference: shippingPreference
    }
  };

//   Not currently used, see ticket ECOM-912 and PR # 1254
//   const handleChange = (event) => {
//     setShippingPreference(event.target.value);
//   };

    const handlePlaceReservation = async () => {
        let confirmReservationData = {
            userId: reservation?.userId,
            reservation: {
                reservationId: reservationId,
                userId: reservation?.userId,
                userShippingPreference: shippingPreference,
            }
        };

        try {
            dispatch(setReservationData(reservationUpdate));
            await dispatch(confirmReservationApi({ payload: confirmReservationData, reservationId: reservationId, userEmail: userInfo?.email })).unwrap();
            dispatch(setIsConfirmationPageAccessible(true));
            if(!!editReservationID) history.push(`/${locale}/confirmation/${editReservationID}`);
            else history.push(`/${locale}/confirmation`);
        } catch (error) {
            handleAlert()
        } finally {
            await dispatch(setReservationData({ reservation: {}, reservationEntries: [] }));
        }
    };


  useEffect(() => {
        if (orderSummaryComponentContent && Object.keys(orderSummaryComponentContent).length === 0) {
            dispatch(loadOrderSummaryComponentContent())
        }
        }, [orderSummaryComponentContent]
  );

  const linkMap = {
        'Terms of Use Internal Link': '/terms-of-use',
        'Privacy Policy Internal Link': '/privacy-policy',
        'Terms and Conditions of Sale Internal Link': '/terms-and-conditions-of-sale',
  };

  const textStyle = {
        fontSize: '16px',
  };


  const options = {
      renderNode: {
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                const title= node.data.target.fields.title;
                // Use the title to find the correct URL
                const url = linkMap[title] || '';

                return (
                    <Box component={'span'}>
                        <Link to={`/${locale}${url}`} target="_blank">
                            <Typography variant={'link'} style={textStyle}>{children}</Typography>
                        </Link>
                    </Box>
                );
            },
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <Typography variant={'link'} style={textStyle}>
                    {children}
                </Typography>
            ),
      },
  };

  return (
    <Box width={'486px'} height={'730px'}>
        <CustomCard sx={{display:'flex', flexDirection: 'column', padding: '32px', overflow: 'scroll'}} >
            <TypographyTitle mb='24px' variant='h3' textTransform='capitalize'>Summary</TypographyTitle>
            <Box display='flex' justifyContent='space-between' alignItems={'center'} width='100%' mb='8px'>
                <TypographyList variant='h5'>Total Doses</TypographyList>
                <TypographyList variant='p1'>{totalDoses}</TypographyList>
            </Box>

            <Box display='flex' justifyContent='space-between' alignItems={'center'} width='100%' mb='8px'>
                <TypographyList variant='h5'>List Price</TypographyList>
                <TypographyList variant='p1'>{formattedPrice(listPrice)}</TypographyList>
            </Box>

            <Box display='flex' justifyContent='space-between' alignItems={'center'} width='100%' mb='8px'>
                <TypographyList variant='h5'>Contract Price</TypographyList>
                <TypographyList variant='p1' textAlign={'right'}>{showContractPrice ? formattedPrice(totalPrice) : UNAVAILABLE}</TypographyList>
            </Box>

            <Box display='flex' justifyContent='space-between' alignItems={'center'} width='100%' mb='8px'>
                <TypographyList variant='h5'>Shipping</TypographyList>
                <TypographyList variant='p1' textAlign={'right'}>TBD</TypographyList>
            </Box>

            <Divider orientation="horizontal" variant="middle" sx={{ marginBottom: 2, marginTop: 1 }} />
            
            <Box display='flex' justifyContent='space-between' alignItems={'center'} width='100%' mb='8px'>
                <TypographyTitle variant='h5'>Total</TypographyTitle>
                <TypographyTitle variant='p1' textAlign={'right'}>{formattedPrice(totalPrice)}</TypographyTitle>
            </Box>

            <TypographyText mt={2} mb={3}>
                Contract pricing will be available soon. When pricing is available, we will send you an email asking you to confirm your reservation. 
                Reservations will not be finalized or shipped until we receive your confirmation.
            </TypographyText>

            {/* Not currently used, see ticket ECOM-912 and PR # 1254 */}
            {/* <Box display='flex' flexDirection='column' justifyContent='space-between' alignItems={'flex-start'} width='100%' gap={2}>
                <TypographyTitle variant='h5' style={{'fontSize': '20px', 'lineHeight':'28px'}}>Shipping Authorization</TypographyTitle>
                <Box display='flex'>
                    <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="shipping-options"
                        name="shipping-options"
                        value={shippingPreference}
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            value={SHIP_WHEN_READY}
                            control={<Radio />}
                            label={<TypographyText sx={{fontWeight: 700}}>Ship this reservation without further confirmation.</TypographyText>}
                        />
                        <FormControlLabel
                            value={APPROVE_BEFORE_SHIP}
                            control={<Radio />}
                            label={<TypographyText sx={{fontWeight: 700}}>Before shipping, ask me to confirm this order when pricing is available.</TypographyText>}
                        />
                    </RadioGroup>
                    </FormControl>
                </Box>
            </Box> */}
            
            <Box>
                <Checkbox
                    sx={{ alignItems: "flex-start", marginTop: '24px' }}
                    name="privacyPolicyConsent"
                    label={documentToReactComponents(orderSummaryComponentContent?.reservationPrivacyPolicyConsentMessage, options)}
                    value={checked}
                    data-testid="privacyPolicyConsentCheckbox"
                    onChange={() => setChecked(prev => !prev)}
                />
            </Box>
            <Button 
                buttonType='mds-primary'
                sx={{textTransform: 'none', marginTop: '24px'}}
                onClick={handlePlaceReservation}
                disabled={!checked || productRows?.length === 0}
            >
                {!!editReservationID ? 'Update reservation' : 'Place Reservation'}
            </Button>
        </CustomCard>
    </Box>
  )
}

export default ReservationSummaryCard