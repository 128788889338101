import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import {FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const StyledInputLabel = styled(InputLabel)(({error = false, theme: {palette: {primary, status}, breakpoints}, className}) => ({
  fontFamily: className === 'organizationRole' ? "Aeonik Regular !important" : "Aeonik Bold !important",
  top: "32px !important",
  fontSize: className === 'cart' && '10px',
  transform: className === 'cart' && 'none',
  translate: className === 'cart' && 'none',
  "&.Mui-error": {
    color: `${error === "true" ? status.error : primary.main}`
  },
  "&.cart": {
    position: 'inherit',
    top: '0 !important',
    color: `${error === "true" ? status.error : primary.main}`,
    "&.Mui-error": {
      color: `${error === true ? status.error : primary.main}`
    },
  },
  "&.newAddressForm": {
    fontSize: '20px',
    top: '12px !important',
    [breakpoints.down('sm')]: {
      marginTop: '10px !important',
      marginBottom: '10px !important'
    }
  },

  "&.organizationRole": {
    fontSize: '22px',
    fontWeight: 700,
    top: '8px !important',
    [breakpoints.down('sm')]: {
      marginTop: '10px !important',
      marginBottom: '10px !important'
    }
  },
}));

const StyledFormHelperText = styled(FormHelperText)(({theme: {palette: {status}}}) => ({
  fontFamily: "Aeonik Regular",
  marginTop: '4px !important',
  "&.Mui-error": {
    color: status.error
  }
}));

const CustomSelect = styled(Select)(({error = false, theme: {palette: {neutral, primary, secondary, status}, breakpoints, className}}) => ({
  backgroundColor: 'transparent',
  "&": {
    width: "100%",
    marginTop: "50px !important",
    height: '30px',
    fontFamily: "Aeonik Regular, Arial",
    "&:before": {
      borderBottom: `2px solid ${neutral.gray2}`
    },
    "&:after": {
      borderBottom: `2px solid ${neutral.gray2}`
    },
    "&&.myProfile": {
      margin: "0 !important",
      width: "75%",
      [breakpoints.down('sm')]: {
        width: "100%",
        paddingRight:"16px !important"
      }
    },
    "& .MuiSvgIcon-root path": {
      fill: "primary.main"
    }
  },
  "&.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: `${error === "true" ? status.error : neutral.gray2}`,
    borderRadius: '0',
    fontFamily: 'Aeonik Regular'
  },
  '& .MuiSelect-select': {
    '&.MuiInputBase-input.MuiInput-input:focus': {
      backgroundColor: 'transparent'
    },
    fontFamily: 'Aeonik Regular',
    '&.MuiInputBase-input.MuiInput-input': {
      padding: '5px 20px 5px 10px'
    }
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderBottom: `1px solid ${error === "true" ? status.error : neutral.gray2}`,
    borderRadius: '0'
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: neutral.gray2,
    borderRadius: '0'
  },
  "& .MuiSelect-icon": {
    color: `${error === "true" ? status.error : secondary.graphical}`,
    marginRight: '10px'
  },

  "&.cart": {
    border: `1px solid ${error === true ? status.error : neutral.gray2}`,
    borderRadius: 5,
    height: '38px',
    marginTop: '9px !important',
    position: 'inherit',
    "&:before": {
      border: 'none !important'
    },
    "&:after": {
      border: 'none !important'
    },
    "&:hover": {
      borderColor: `${error === true ? primary.red : neutral.gray2}`,
      boxShadow: '0px 3px 6px #22222229'
    },
    "& .MuiSelect-icon": {
      color: `${error === true ? status.error : secondary.graphical}`,
      marginRight: '10px'
    },
  },
  "&.border": {
    border: `1px solid ${error === true ? status.error : neutral.gray2}`,
    borderRadius: 5,
    marginTop: '9px !important',
    height: '48px',
    position: 'inherit',
    "&:before": {
      border: 'none !important'
    },
    "&:after": {
      border: 'none !important'
    },
    "&:hover": {
      borderColor: `${error === true ? primary.red : neutral.gray2}`,
      boxShadow: '0px 3px 6px #22222229'
    },
    "& .MuiSelect-icon": {
      color: `${error === true ? status.error : neutral.gray7}`,
      marginRight: '10px'
    },
  },

  "&.addressForm": {
    '& .MuiSelect-select': {
      '&.MuiInputBase-input.MuiInput-input': {
        padding: '0px 80px 0px 0px'
      }
    },
  },
  "&.newAddressForm": {
    border: `1px solid ${error === true ? status.error : neutral.gray7}`,
    borderRadius: 5,
    marginTop: '40px !important',
    height: '49px',
    position: 'inherit',
    textAlign: 'left',
    "&:before": {
      border: 'none !important'
    },
    "&:after": {
      border: 'none !important'
    },
    "&:hover": {
      borderColor: `${error === true ? primary.red : neutral.gray7}`,
      boxShadow: '0px 3px 6px #22222229'
    },
    "& .MuiSelect-icon": {
      color: `${error === true ? status.error : neutral.gray7}`,
      marginRight: '10px'
    },
  },
  "&.organizationRole": {
    border: `1px solid ${error === true ? status.error : neutral.gray7}`,
    borderRadius: 5,
    marginTop: '40px !important',
    height: '47px',
    position: 'inherit',
    textAlign: 'left',
    "&:before": {
      border: 'none !important'
    },
    "&:after": {
      border: 'none !important'
    },
    "&:hover": {
      borderColor: `${error === true ? primary.red : neutral.gray7}`,
      boxShadow: '0px 3px 6px #22222229'
    },
    "& .MuiSelect-icon": {
      color: `${error === true ? status.error : neutral.gray7}`,
      marginRight: '10px'
    },
  },
}));

const defaultMenuItemStyle = {
  '&.Mui-selected': {
    backgroundColor: 'secondary.main',
    color: 'primary.main',
    fontFamily: 'Aeonik Bold',
    '&:hover': {
      color: 'primary.main',
      backgroundColor: 'secondary.main',
    }
  },
  '&:hover': {
    fontFamily: 'Aeonik Bold',
    backgroundColor: 'secondary.main',
  },
  fontFamily: 'Aeonik Regular', fontSize: '14px',
}
export const SelectDropdown = ({
                                 helperText,
                                 label,
                                 options,
                                 disabledOptions,
                                 error = false,
                                 formSx,
                                 menuPropsSx,
                                 menuItemStyleSx,
                                 placeholder,
                                 required = false,
                                 sx,
                                 onChangeHandler,
                                 value,
                                 className,
                                 onOpenHandler = (e) => false,
                                 disabled=false
                               }) => {

  const menuitemStyle = { ...defaultMenuItemStyle, ...menuItemStyleSx };

  return (
      <div>
        <FormControl
            className={className}
            required={required}
            focused={true}
            variant="standard"
            sx={{ ...formSx, padding: 0 }}
            error={error}
            fullWidth
            disabled={disabled}
        >
          <StyledInputLabel
              disableAnimation={true}
              id={`label-${label?.replace(/\s+/g, '')}`}
              className={className}
              error={error}
              sx={{ color: 'neutral.gray2' }}
          >
            {label}
          </StyledInputLabel>
          <CustomSelect
              required={required}
              placeholder={'state'}
              error={error}
              labelId={`label-${label?.replace(/\s+/g, '')}`}
              id={label?.replace(/\s+/g, '')}
              className={className ?? ''}
              value={value}
              label={label}
              onChange={(e) => onChangeHandler(e.target.value)}
              onOpen={(e) => onOpenHandler(e)}
              IconComponent={KeyboardArrowDownOutlinedIcon}
              inputProps={{ "data-testid": "select-input" }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow: '0px 3px 6px #00000029',
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                    maxHeight: 200,
                    maxWidth: 'fit-content',
                    overflow: 'auto',
                    right: '16px',
                    ...menuPropsSx
                  },
                },
              }}
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: '10px' },
                boxSizing: 'border-box',
                ...sx,
                color: (value === placeholder?.id) && 'gray'
              }}
          >
            {options?.map(({ value, label }) => (
                <MenuItem sx={menuitemStyle} id={value} key={value} value={value}>
                  {value === '' ? '-' : label}
                </MenuItem>
            ))}
            {disabledOptions && disabledOptions?.map(({ value, label }) => (
                <MenuItem sx={{ ...menuitemStyle, display: 'none' }} id={value} key={value} value={value}>
                  {value === '' ? '-' : label}
                </MenuItem>
            ))}
            {placeholder && (
                <MenuItem sx={{ ...menuitemStyle, display: 'none' }} id={placeholder.id} key={placeholder.id} value={placeholder.id}>
                  {placeholder.value}
                </MenuItem>
            )}
          </CustomSelect>
          <StyledFormHelperText sx={{ marginTop: 0 }}>
            {helperText}
          </StyledFormHelperText>
        </FormControl>
      </div>
  );
}