import React, {useEffect} from "react";
import { styled } from '@mui/material/styles';
import { RequestResetPassword } from "../components";
import aboveFoldBGImage from "../assets/images/passResetStrand.svg";
import { useHistory } from "react-router-dom";

const BackgroundContainer = styled('div')(
  ({ aboveFoldBGImage }) => `
    background: url(${aboveFoldBGImage});
    background-repeat: no-repeat;
    background-position-x: left;
    background-size: 35%;
    background-position-y: bottom;
`);

const ResetContainer = styled('div')(({ theme: { breakpoints } }) => `
    position: relative;
    margin: 120px auto 0 auto;
    display: flex;
    width: 55%;
    ${[breakpoints.down('sm')]} {
      margin-top: 60px;
      width: 80%;
    }
`);

const RequestResetPasswordPage = () => {

  const history = useHistory();
  useEffect(() => {
    if(localStorage.getItem("currentUser") && localStorage.getItem("currentUser") !== "underfined"){
      history.push("/home")
    }
  }, [history]);


  return (
    <BackgroundContainer aboveFoldBGImage={aboveFoldBGImage}>
      <ResetContainer>
        <RequestResetPassword />
      </ResetContainer>
    </BackgroundContainer>
  );
};


export default RequestResetPasswordPage
