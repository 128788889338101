import React from "react";
import crossIconBlack from "../../assets/images/crossIconBlack.svg";
import { Backdrop, Box, Modal, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const modalStyle = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    minHeight: "20%",
    maxHeight: "90%",
    bgcolor: "background.paper",
    overflowY: "auto",
    boxShadow: 24,
    zIndex: 10,
    borderRadius: "24px",
    padding: "25px",
    outline: "none",
    textAlign: "center",
    "@media screen and (max-width: 500px)": {
        width:"80%"
    },
    position: 'relative'
};

const buttonsContainerSxStyles = {
    flexDirection: {xs: 'column', sm:'row'},
    padding: {xs: '0 15px', sm:'0'},
    gap: {xs: '0', sm:'10px'},
}

const CrossIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '24px',
    right: '24px',
    width: "30px",
    height: "30px",
    color: "primary.main",
    backgroundColor: "none",
    borderRadius: 0,
    '&:hover &:focus': {
        background: 'none',
        border: 'none'
    },
    '&:focus-visible': {
        outline: '-webkit-focus-ring-color auto 1px'
    }
}));

export const ModalComponent = ({
                                   closeModalHandler,
                                   openModal,
                                   title,
                                   modalContent,
                                   style: externalStyle
                               }) => {

    return (
        <Modal
            data-testid="modalComponent"
            sx={{textAlign: 'center'}}
            open={openModal}
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    onClick: closeModalHandler
                },
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={{ ...modalStyle, ...externalStyle }}>
                <CrossIconButton id="crossIcon" onClick={closeModalHandler} data-testid="closeBtn">
                    <img alt='x' src={crossIconBlack} data-testid='crossIconBlack' height="30px" width="30px"/>
                </CrossIconButton>
                {title && <Box id="titleContainer" display="flex" justifyContent={{sm: 'left'}} alignItems={{xs: 'flex-start'}}
                               sx={buttonsContainerSxStyles} marginLeft={{xs: "0px", sm: "20px"}}
                               marginBottom={{xs: "20px", sm: "10px"}} data-testid="modalTitle">
                    <Typography marginRight={{xs: '0px', sm: "250px"}} variant={'h3'} color='primary.main' id="modalTitle"
                                data-testid='title'>{title}</Typography>
                </Box>
                }
                {modalContent}
            </Box>
        </Modal>
    );
};