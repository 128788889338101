import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, TextInput } from "../";
import Modal from "@mui/material/Modal";
import crossIcon from "../../assets/images/crossIcon.svg";
import { Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  zIndex: 10,
  borderRadius: "24px",
  padding: "25px",
  outline: "none",
  textAlign: "center",
  "@media screen and (max-width: 500px)": {
    width:"80%"
  }
};

const buttonsContainerSxStyles = {
  flexDirection: {xs: 'column', sm:'row'},
  padding: {xs: '0 30px', sm:'0'},
  gap: {xs: '0', sm:'10px'},
}

const ModalComponent = ({
  closeModalHandler,
  openModal,
  title,
  primaryButtonLabel,
  cartContent,
  deliveryInstructions,
  setDeliveryInstructions
}) => {
  const [deliveryInstructionsValue, setDeliveryInstructionsValue] = useState(deliveryInstructions);
  useEffect(() => {
    setDeliveryInstructionsValue(deliveryInstructions);
  }, [deliveryInstructions, openModal])

  const handleSubmitDeliveryInstructions = () => {
    setDeliveryInstructions(deliveryInstructionsValue)
    setDeliveryInstructionsValue("")
    closeModalHandler()
  }
  return (
    <Modal
      sx={{ textAlign: 'center' }}
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box display='flex' justifyContent='end' id="crossIcon">
          <Box component={'img'} sx={{width: "25px", height: "25px", cursor:"pointer"}} src={crossIcon} onClick={closeModalHandler} data-testid='crossicon' />
        </Box>
        <Typography variant={'h3'} color='primary.main' my={2} mx={"38px"} id="modalTitle" data-testid='title'>{title}</Typography>
        
        <TextInput
          data-testid='textarea'
          sx={{width:'100%'}}
          focused
          placeholder={cartContent?.addDeliveryInstructionsPlaceholderText}
          value={deliveryInstructionsValue}
          onChange={(e) => setDeliveryInstructionsValue(e.target.value.replace(/\r?\n|\r/g, ''))}
          inputType="text"
          multiline={true}
          varient="standard"
          stylevariant={"border"}
          maxLength={70}
        />
        <Box display="flex" justifyContent='center' sx={buttonsContainerSxStyles}>
          <Button id="confirm" sx={{ marginTop: "32px", marginBottom: {xs:'46px', sm: "0"} }} onClick={handleSubmitDeliveryInstructions} data-testid='saveBtn'>{primaryButtonLabel}</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export { ModalComponent as DeliveryIntructionModal };