import React, {useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import { Button } from "../components";
import { useHistory } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { useDispatch, useSelector } from "react-redux";
import { loadNotAuthorizedPageContent } from "../features/contentful/contentfulThunkApi";

const NotAuthorizedContainer = styled('div')(({ theme: { breakpoints } }) => `
  margin: 100px 500px 250px 300px;
  ${[breakpoints.down('sm')]} {
    margin: 240px 50px 270px 50px;
  }
`);

const BackgroundContainer = styled('div')(
  ({ NavBGImage }) => `
    background: url(${NavBGImage});
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: 50%;
    background-position-y: bottom;
    display: flex;
`);

const NotAuthorized = () => {
  const { loading, notAuthorizedPageContent } = useSelector((store) => store?.contentful)
  const { errorCode, headline, message, backgroundImage, buttonLabel } = notAuthorizedPageContent
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.globalStates.locale);
  const history = useHistory();
  const returnBtnHandler = () => {
    history.push(`/${locale}`);
  }

  useEffect(() => {
    window.scrollTo(0,0);
    if (Object.keys(notAuthorizedPageContent).length === 0) {
      dispatch(loadNotAuthorizedPageContent())
    }
  },[]);

  return (
    <div> 
      <BackgroundContainer NavBGImage={backgroundImage?.fields?.file?.url}>
        <NotAuthorizedContainer>
          <Typography variant="h1" sx={{ fontFamily: "Aeonik Bold"}} data-testid="errorCode">{errorCode}</Typography>
          <Typography variant="h3" sx={{ fontFamily: "Aeonik Light"}} data-testid="headline">{headline}</Typography>
          <Typography variant="h6" sx={{ fontFamily: "Aeonik Regular", marginTop: 2, marginBottom: 3}} data-testid="message">{documentToReactComponents(message)}</Typography>
          <Button onClick={returnBtnHandler} sx={{ marginTop: {xs:7, sm:3 }}} data-testid="button">{buttonLabel}</Button>
        </NotAuthorizedContainer>
      </BackgroundContainer>
    </div>
  )
}

export default NotAuthorized;
