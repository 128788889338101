import {useEffect, useRef, useState} from "react";
import { Box } from "@mui/system";
import { Spinner } from "../components";
import { EmailVerificationSucceed } from "../components/Registration";
import LogoHeader from "../components/Header/LogoHeader";
import { activateUserApi } from "../utils/IdentityService/EmailVerificationApi";
import { AlertMessage } from "../components";

const RegistrationConfirmationPage = () => {
  const GENERAL_API_ERROR_MESSAGE = 'We have encountered technical difficulties. Please try logging in or contact us at 1-866-MODERNA.';

  const alertRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [processing, setProcessing] = useState(false);
  const [activateStatus, setActivateStatus] = useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const activateToken = urlParams.get('activateToken');
  const email = urlParams.get('email');


  useEffect(() => {
    const activateUser = async () => {
      if (!activateToken || !email) return;

      try {
        setProcessing(true);
        await activateUserApi({emailAddress: email, activationToken: activateToken});
        setActivateStatus('succeed');
      } catch (error) {
        setErrorMsg(GENERAL_API_ERROR_MESSAGE);
        alertRef.current?.openAlert(error);
      } finally {
        setProcessing(false);
      }
    };

    activateUser();
  }, []);

  return (
    <>
      <Spinner processing={processing} />
      <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
      <Box
        sx={{ width: '100%', height: '100vh' }}
        display="flex"
        flexDirection='column'
        alignItems='center'
      >
        <Box maxWidth='640px' width={'60%'} >
          <LogoHeader />
        </Box>
        <Box maxWidth='640px' width={'60%'} data-testid='emailVerificationHeader'>
          { activateStatus === 'succeed' && <EmailVerificationSucceed /> }
          {/*TODO: we may need it in the future */}
          {/*{ activateStatus === 'expired' && <EmailVerificationTokenExpired /> }*/}
        </Box>
      </Box>
    </>
  );
};

export default RegistrationConfirmationPage;
