import React from "react";
import { styled } from '@mui/material/styles';
import { ContactUsForm } from "../components/ContactUsForm";
import { useEffect } from "react";
import { useState } from "react";
import { contentfulClient } from "../sevices/useContentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { Box, IconButton, Typography } from "@mui/material";
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from "react-router";
import { isUrl, isExternalURL } from "../utils/Helper";
import { loadContactUsStaticContent } from "../features/contentful/contentfulThunkApi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const BannerWrapper = styled('div')(
    ({ bgImageUrl, theme: { palette: { primary, neutral }, breakpoints } }) => `
    background-color: ${primary.main};
    background-image: url(${bgImageUrl});
    background-size: cover;
    height: 300px;
    display: flex;
    padding: 0 32px;
    align-items: center;
    overflow-y: hidden;
    ${[breakpoints.up('md')]} {
        padding: 0 94px;
    }
    ${[breakpoints.down('sm')]} {
        align-items: unset;
    }
    `
)

const CardContainer = styled('div')(({ theme: { breakpoints } }) => `
    padding: 30px 32px;
    display: flex;
    gap: 12px;
    margin-top: -84px;
    flex-wrap: nowrap;
    overflow: auto;
    ${[breakpoints.up('md')]} {
        padding: 30px 94px;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`);

const Card = styled('div')(({ theme: { palette: { neutral }, breakpoints }}) => `
    background-color: ${neutral.white};
    border-radius: 14px;
    box-shadow: 0px 3px 6px #00000029;
    flex-grow: 1;
    padding: 22px;
    display: flex;
    justify-content: space-between;
    min-width: fit-content;
   ${[breakpoints.down('sm')]} {
        min-width: 100% !important;
        flex-direction: column;
    }
`);

const IconButtonSx = {
    width: {xs:"48px", sm:"34px"}, 
    height: {xs:"48px", sm:"34px"},
    color: "primary.main",
    backgroundColor: "none",
    border: "1px solid",
    borderColor: "primary.main",
    '&:hover': {
        color: 'neutral.white',
        backgroundColor: 'primary.blue',
        borderColor: "primary.blue"
    }
}

const ContactUs = ({...props}) => {
    const [staticContent, setStaticContent] = useState({});
    const { authState } = useOktaAuth() || '';
    const [dataExternalModal, setDataExternalModal] = useState(true);
    const remindMeExternal = localStorage.getItem("dontRemindMeExternal");
    const { contactusPageContent: cardContent } = useSelector(store => store.contentful);
    const dispatch = useDispatch();
    const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);

    useEffect(() => {
        if(remindMeExternal === "true") {
          setDataExternalModal(false);
        }
    }, [remindMeExternal])

    useEffect(() => {
        dispatch(loadContactUsStaticContent({ title1: `Care Team Contact Us Card - ${localeISOCode}`, title2: `Report Adverse Event Contact Us Card - ${localeISOCode}`, title3: `FAQ Contact Us Card - ${localeISOCode}`}));
    }, [dispatch, localeISOCode])

    useEffect(() => {
        window.scrollTo(0, 0);
        const pQuery = {
            skip: 0,
            content_type: "pageContactUsPageTemplate",
            include: 5,
            select: "fields"
        }

        contentfulClient?.getEntries(pQuery).then(response => setStaticContent({
            banner: response.items[0].fields.modules?.[0]?.fields,
            card: [
                response.items[0].fields.modules?.[1]?.fields,
                response.items[0].fields.modules?.[2]?.fields,
                response.items[0].fields.modules?.[3]?.fields
            ],
            form: response.items[0].fields.modules?.[4]?.fields,
            formSubmitted: response.items[0].fields.modules?.[5]?.fields
        }) || null)
    }, []);

    const history = useHistory()

    const handleRedirect = (e, str) => {
        if(isUrl(str)){
            if(isExternalURL(str) && !localStorage.getItem("dontRemindMeExternal")){
                props?.setShowNavigationAlert(true);
                props?.setExternalLink(str);
                props?.setExternalLinkModalType("extrenalLink");
                e.preventDefault();
            }else{
                window.open(str, '_blank');
                props?.setShowNavigationAlert(false);
                props?.setExternalLink("");
            }
        }else{
           history.push(str);
        }
    }

    return (
        <div style={authState?.isAuthenticated ? {} : {position: "relative", margin: "0px auto 0 auto"}}>
            <BannerWrapper bgImageUrl={staticContent?.banner?.backgroundImage?.fields?.file?.url}>
                <div>
                    <Typography variant={'h1'} color='neutral.white' mt={{xs:'60px', sm:'unset'}} data-testid='headline'>{staticContent?.banner?.headline}</Typography>
                    <Typography variant={'p1'} component={'p'} color='neutral.white' mt={1} data-testid='message'>{staticContent?.banner?.message}</Typography>
                </div>
            </BannerWrapper>
            <CardContainer>
                {cardContent && cardContent.length > 0 && cardContent.map((card, index) => (
                    authState?.isAuthenticated || card?.headline !== "FAQs" ?
                        <Card key={index} id={"contactUscard"+index} data-testid={'contactUscard'}>
                        <div>
                            <img src={card?.icon?.fields?.file?.url} alt={card?.icon?.title} style={{ width: "45px", height: "45px" }} />
                            <Typography variant={'h3'} color="primary.main" mt={1}>{card?.headline}</Typography>
                            <Typography variant={'p1'} color="primary.main" mt={1}>{documentToReactComponents(card?.message)}</Typography>
                        </div>
                        {card?.link &&
                            <Box alignSelf='end'>
                                <IconButton
                                    variant="outlined"
                                    onClick={(e) => handleRedirect(e, card?.link?.fields?.url || card?.link?.fields.pageReference.fields?.slug)}
                                    data-external-modal={dataExternalModal}
                                    data-link-href={card?.link?.fields?.url || card?.link?.fields.pageReference.fields?.slug}
                                    sx={IconButtonSx}>
                                    <ArrowForward />
                                </IconButton>
                            </Box>
                        }
                    </Card> : ""
                ))
                }
            </CardContainer>
            <ContactUsForm staticContent={staticContent}/>
        </div>
    )
}

export default ContactUs
