import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { TextInput } from "../TextInput";
import { SelectDropdown } from "../SelectDropdown";
import { Box, Typography } from '@mui/material';

const GridContainer = styled(Grid)(({ theme: { palette: { secondary } }}) => `
  background-color: ${secondary.main};
  border-radius: 16px;
  padding: 10px 10px 26px 10px;
  margin: 0 !important;
  width: 100% !important;
  &&.edit {
    box-shadow: 0px 3px 6px #22222229;
    }
`);

const Label = styled(Typography)(({ theme: { palette: { primary }, breakpoints }}) => `
  color: ${primary.main};
  margin-top: 5px;
  margin-Bottom: 5px;
  text-transform: uppercase;
  ${[breakpoints.down('sm')]} {
    line-height: 16.5px;
  }
`);

const Value = styled(Typography)(({ theme: { breakpoints } }) => `
  user-select: text;
  ${[breakpoints.down('sm')]} {
    line-height: 24px;
  }
`);

const StyledTextInput = styled(TextInput)(({ theme: { palette: { neutral }, breakpoints }}) => `
  width: 75%;
  && input {
    color: ${neutral.gray1};
  }
  ${[breakpoints.down('sm')]} {
    width: 100%;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding-right: 16px !important;
  }
`);

const StyledSelectDropdown = styled(SelectDropdown)(({ theme: { breakpoints } }) => `
    ${[breakpoints.down('sm')]} {
        padding-right: 16px !important;
    }
`);

const NotFoundValue = styled('p')(({ theme: { palette: { neutral } }}) => `
  color: ${neutral.gray2};
`);

const CustomGrid = styled(Grid)`
    &.removePadding {
        padding-top: 0 !important;
    }
`;

export const ContactInfo = ({ staticContent, staticJobRoles, staticJobTitles, pageType, contactName, contactEmail, jobTitle, setJobTitle, contactRole, setContactRole, setSaveButtonDisabled }) => {
    const jobList = staticJobTitles?.map((title) => {
        return(
            {
                "value": title?.fields?.displayValue,
                "label": title?.fields?.displayName
            }
        )
    })

    const roleList = staticJobRoles?.map((role) => {
        return(
            {
                "value": role?.fields?.displayValue,
                "label": role?.fields?.displayName
            }
        )
    })

    return(
        <Box margin='30px auto 0 auto' width='80%' id="ContactInformation">
            <Typography variant={'h3'} fontFamily={'Aeonik Light'} color='primary.main' textAlign='left' mb='25px' mt='30px'>{staticContent?.contactInfoSectionLabel}</Typography>
            <GridContainer container className={pageType} spacing={2}>
                <Grid item xs={12} md={6}>
                    <Label variant={'h6'} id="contactNameLabel">{staticContent?.contactNameLabel}</Label>
                    {pageType !== 'edit' ? (contactName ? <Value variant={'p1'} id="contactNameValue">{contactName}</Value> : <NotFoundValue variant={'p1'} id="contactNameNFValue">{staticContent?.notFoundText}</NotFoundValue>) : 
                    (contactName ? <StyledTextInput
                        required
                        focused
                        value={contactName}
                        inputType="text"
                        variant="standard"
                    /> : <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>)}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label variant={'h6'} id="contactInfoEmailLabel">{staticContent?.contactInfoEmailLabel}</Label>
                    {pageType !== 'edit' ? (contactEmail ? <Value variant={'p1'} id="contactEmail">{contactEmail}</Value> : <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>) :
                    (contactEmail ? <StyledTextInput
                        required
                        focused
                        value={contactEmail}
                        inputType="text"
                        variant="standard"
                    /> : <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>)}
                </Grid>
                <CustomGrid item xs={12} md={6}>
                    <Label variant={'h6'} id="contactRoleLabel">{staticContent?.contactRoleLabel}</Label>
                    {pageType !== 'edit' ? ( <Value variant={'p1'} id="contactRole">{contactRole ? contactRole : staticContent?.notFoundText}</Value> ) :
                        <StyledSelectDropdown
                            id="contactRoleDropdown"
                            options={roleList}
                            value={contactRole ?? staticContent?.notFoundText}
                            onChangeHandler={(value) => { setContactRole(value); setSaveButtonDisabled(false); }}
                            className="myProfile"
                        />}
                </CustomGrid>
            </GridContainer>
        </Box>
    )
}