import { Box } from "@mui/material";
import { Button } from "../Button";
import { useSelector } from "react-redux";
import { CheckCircleIcon, WarningInfoIcon } from "../../assets/images/Icons";
import { TypographyH3, TypographyP1 } from "../../assets/fonts/typographyTheme";
import DeletePaymentConfirmationModal from "./DeletePaymentConfirmationModal";
import { useState } from "react";
import { BankIcon } from "../../assets/logos/cardLogo";
import { checkIsExpired, CREDIT_CARD_MAPPING } from "./Helper";

export const paymentBox = {
    width: '333px',
    height: '180px',
    display: 'flex',
    padding: '16px 24px',
    borderRadius: '16px',
    background: '#FFF',
    boxShadow:' 0px 2px 4px 0px rgba(0, 0, 0, 0.50)',
    border: '1px solid transparent',
    textAlign: 'start',
    transition: 'border-color 0.3s ease',
}

const boldText = {
    fontWeight: 'bold',
    display: 'inline'
}

const hoverStyles = {
    border: '2px solid #079AE0',
    transform: 'translateY(-2px)'
}

export const PaymentCard = ({ cardInfo, handleSetAsDefaultPayment, isDefault , handlePaymentMethodSelection, closeModal, isProfilePage, handleDeletePaymentMethod }) => {
    const isBank = cardInfo?.paymentMethodType === 'ACH';
    const selectedPayment = useSelector((store) => store?.commerce?.selectedPaymentMethod);
    const isSelected = selectedPayment?.paymentMethodId === cardInfo?.paymentMethodId;

    const getCustomizePaymentBox = (isSelected, isExpired) => {
        let additionalStyles = {};
        if (isSelected && !isProfilePage) additionalStyles['border'] = '2px solid #079AE0';
        if (isDefault && isProfilePage) additionalStyles['border'] = '2px solid #079AE0';
        if (isExpired) additionalStyles['backgroundColor'] = 'rgba(234, 235, 239, 0.6)';
        return { ...paymentBox, ...additionalStyles };
    };

    const isExpired = checkIsExpired(cardInfo?.paymentMethodInfo?.expirationDate);
    const customizePaymentBox = getCustomizePaymentBox(isSelected, isExpired);
    const [showDeletePaymentModal, setShowDeletePaymentModal] = useState(false);

    const expirationComponent = () => {
        if(isExpired){
            return(
                <Box style={{display: 'flex', flexDirection:'row'}}>
                    <WarningInfoIcon color="#E22929"/>
                    <TypographyP1 style={{color:'#E22929', marginLeft:'6px'}}>
                        {`Expired ${cardInfo?.paymentMethodInfo?.expirationDate}`}
                    </TypographyP1>
                </Box>
            )
        }
        else{
            return(
                <TypographyP1>
                    {`Expires ${cardInfo?.paymentMethodInfo?.expirationDate}`}
                </TypographyP1>
            )
        }
    }

    const accountInfo = {
        name: isBank ? `${cardInfo?.paymentMethodInfo?.bankName} account` : `${CREDIT_CARD_MAPPING[cardInfo?.paymentMethodInfo?.brand]?.name} card`,
        lastFour : cardInfo?.paymentMethodInfo?.lastFour,
        cardId: cardInfo?.paymentMethodId
    }

    const handleSelection = (paymentMethodId) => {
        handlePaymentMethodSelection(paymentMethodId);
        closeModal();
    }

    return(
        <Box 
            sx={isProfilePage ? {...customizePaymentBox} :
            {
                ...customizePaymentBox,
                cursor: 'pointer',
                '&:hover': hoverStyles,
            }}
            onClick={() => handleSelection(cardInfo?.paymentMethodId)}
        >
            {showDeletePaymentModal &&
                <DeletePaymentConfirmationModal
                    openModal={showDeletePaymentModal}
                    closeModal={() => setShowDeletePaymentModal(false)}
                    cardInfo={accountInfo}
                    handleDeletePaymentMethod={handleDeletePaymentMethod}
                />
            }
            <Box width={'32px'} paddingTop={ isBank ? '6px' : '4px'}>
                {isBank ?
                    <BankIcon />
                    :
                    CREDIT_CARD_MAPPING[cardInfo?.paymentMethodInfo?.brand]?.logo
                }
            </Box>
            <Box ml={2} display={'flex'} flexDirection={'column'} width={'100%'} >
                <Box style={{width: '100%', marginTop:'3px'}}>
                    <TypographyP1>
                        <span style={boldText}>
                             { isBank ? cardInfo?.paymentMethodInfo?.bankName : CREDIT_CARD_MAPPING[cardInfo?.paymentMethodInfo?.brand]?.name }
                        </span>
                        { isBank? ' account ending in ' : ' ending in '}
                        <span style={boldText}>
                        {cardInfo?.paymentMethodInfo?.lastFour}
                        </span>
                    </TypographyP1>

                    <TypographyP1>
                        {cardInfo?.paymentMethodInfo?.billingName}
                    </TypographyP1>
                    {!isBank && expirationComponent()}
                </Box>
                <Box style={{marginTop: 'auto', height: '24px', width: '100%', display:'flex', justifyContent:'space-between'}}>
                    <Box>
                        {
                            isDefault?
                                <Box style={{display: 'flex', flexDirection:'row'}}>
                                    <CheckCircleIcon color={'#0379B2'}/>
                                    <TypographyH3 color="#0379B2" style={{marginLeft:'6px'}}>Default</TypographyH3>
                                </Box>
                            :
                                !isExpired && <Button
                                    id="setDefaultBtn"
                                    sx={{ 
                                        textTransform: "none",
                                        margin: '2px 0 0 0', 
                                        "& .ButtonText": { fontSize: "16px"},
                                        '&:hover': {
                                            transform: 'scale(1.05)'
                                        }
                                    }}
                                    buttonType="link"
                                    data-testid='setDefaultBtn'
                                    onClick={() => {handleSetAsDefaultPayment(cardInfo?.paymentMethodId)}}
                                 >
                                    Set as default
                                </Button>
                        }

                    </Box>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Button
                            id="setDeleteBtn"
                            sx={{
                                textTransform: "none",
                                color:'#E22929',
                                margin: '2px 0 0 0',
                                "& .ButtonText": { fontSize: "16px"},
                                '&:hover': {
                                transform: 'scale(1.05)'
                                }
                            }}
                            buttonType="link"
                            data-testid='deleteBtn'
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowDeletePaymentModal(true);
                            }}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}