import commerceNoAuthAxiosInstance from "../sevices/commerceNoAuthAxiosInstance";

export const recoverPasswordApi = async (values) => {
  const reqData = {
    userCaptchaResponse: values?.recaptchaToken
  }
  try {
    const res = await commerceNoAuthAxiosInstance({
      url: `/recoverpassword?userName=${values?.email}`,
      method: "POST",
      data: reqData
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getOktaUserStatusApi = async (email) => {
  try {
    const res = await commerceNoAuthAxiosInstance({
      url: `/getUserAccountStatusFromOkta?userName=${email}`,
      method: "GET"
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const createUserInOktaAndCommerceApi = async (data) => {
  try {
    const res = await commerceNoAuthAxiosInstance({
      url: '/modernaOrgUsers',
      method: "POST",
      data: JSON.stringify(data)
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const activateOktaUserApi = async (token) => {
  try {
    const res = await commerceNoAuthAxiosInstance({
      url: `/activateAccount?activationToken=${token}`,
      method: "POST"
    });
    return res;
  } catch (error) {
    throw error;
  }
};