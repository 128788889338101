import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const Label = styled(Typography)(({ theme: { palette: { primary }, breakpoints }}) => `
  color: ${primary.main};
  margin-top: 5px;
  text-transform: uppercase;
`);

export const AddressDetails = ({ staticContent, addresses }) => {
  const encryptEmail = (email) => {
    if(email){
      let emailUsername = email[0];
      let emailDomain = email.substring(email.indexOf("@"), email.length);
      return (emailUsername + '********' + emailDomain);
    }
    return;
  };

  const contentfulTemplate = {
    "name1": "Name 1",
    "name2": "Name 2", 
    "name3": "Name 3",
    "addressLine1": "Address Line 1",
    "addressLine2": "Address Line 2",
    "addressLine3": "Address Line 3",
    "city": "City",
    "state": "State",
    "postalCode": "Zip Code",
    "country": "Country",
    "phone": "Phone Number",
    "phoneExtension": "Phone Extension",
    "faxNumber": "Fax Number",
    "contactName": "Contact Name",
    "email": "Contact Person Email"
  }

  const dataTemplate = {
    "name1": "firstName",
    "name2": "middleName", 
    "name3": "lastName",
    "addressLine1": "line1",
    "addressLine2": "line2",
    "addressLine3": "line3",
    "city": "town",
    "state": "region",
    "postalCode": "postalCode",
    "country": "country",
    "phone": "phone",
    "phoneExtension": "extension",
    "faxNumber": "faxNumber",
    "contactName": "contactName",
    "email": "contactemail"
  }

  const generateTemplate = () => {
        return (
      <> 
          {
            addresses.map((address, index) => {
                let section = Object.keys(contentfulTemplate).map((key, index) => {
                    return (
                        <Grid item xs={11} 
                              md={(key === 'name3' || key === 'addressLine3' || key === 'faxNumber') ? 11 : 5} 
                              key={`${key}${index}`}>
                            <Label variant={'h6'} id={key}>{(staticContent && staticContent.hasOwnProperty(key))? staticContent[key] : contentfulTemplate[key]}</Label>
                            <Typography variant={'p1'} >{key === 'email' ? encryptEmail(address[dataTemplate[key]]): address[dataTemplate[key]]}</Typography>
                        </Grid>
                    )
                })
                return section;
            })
          } 
          </>
        )
    }

  return (
    <Grid container justifyContent='space-between' gap={3} p={3}>
        {generateTemplate()}
    </Grid>
  )
};